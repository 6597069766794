import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Divider } from '@material-ui/core';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import {
  Participant,
  BaseAdonisPaginateReponseInterface,
  Role,
  User,
  Options,
} from 'types';
import { cnpjMask, cpfMask } from 'utils/format';
import * as Yup from 'yup';

import Button from 'components/Button';
import { Input, Select } from 'components/Form';
import { ContainerInputResponsive, Header } from 'components/Layout';
import api from 'services/api';
import errorHandler from 'services/errorHandler';

import { Container } from './styles';

interface ApiResponseData extends BaseAdonisPaginateReponseInterface {
  data: Participant[];
}

interface ApiReponse {
  data: ApiResponseData;
}

interface ApiResponseDataRole extends BaseAdonisPaginateReponseInterface {
  data: Role[];
}

interface ApiReponseRole {
  data: ApiResponseDataRole;
}

interface ApiResponseUserData extends BaseAdonisPaginateReponseInterface {
  data: User;
}

export default function Add() {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [participants, setParticipants] = useState<Options[]>([]);
  const [roles, setRoles] = useState<Options[]>([]);

  const loadClients = useCallback(async () => {
    try {
      const { data }: ApiReponse = await api.get('/participants');

      setParticipants(
        data.data.map(participant => {
          if (participant.type === 'fisical') {
            return {
              value: String(participant.id),
              label: `${participant.fisical?.name} (${cpfMask(
                participant.fisical?.cpf || '',
              )})`,
            };
          }
          return {
            value: String(participant.id),
            label: `${participant.juridical?.social_name} (${cnpjMask(
              participant.juridical?.cnpj || '',
            )})`,
          };
        }) || [],
      );
    } catch (err) {
      errorHandler(err);
    }
  }, []);

  const loadRoles = useCallback(async () => {
    try {
      const { data }: ApiReponseRole = await api.get('/roles');

      setRoles(
        data.data.map(role => {
          return {
            value: role.slug,
            label: role.name,
          };
        }) || [],
      );
    } catch (err) {
      errorHandler(err);
    }
  }, []);

  const loadAll = useCallback(() => {
    try {
      setLoading(true);
      loadClients();
      loadRoles();
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  }, [loadClients, loadRoles]);

  useEffect(() => {
    loadAll();
  }, [loadAll]);

  const handleSubmit = useCallback(
    async (data: SubmitHandler<FormData>) => {
      try {
        // Remove all previous errors
        if (formRef && formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          participant_id: Yup.string()
            .oneOf(
              participants.map(participant => participant.value),
              'O cliente deve ser selecionado!',
            )
            .required('O tipo do cliente é obrigatório!'),
          username: Yup.string().required('O usuário é obrigatório!'),
          email: Yup.string().required('O email é obrigatório!'),
          roles: Yup.array()
            .of(
              Yup.string().oneOf(
                roles.map(role => role.value),
                'Um dos valores informados não confere com os permitidos',
              ),
            )
            .required('Pelo menos uma regra deve ser selecionada!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        try {
          const password = Math.random().toString(36).substring(3);
          const response: ApiResponseUserData = await api.post('users', {
            ...data,
            password,
            password_confirmation: password,
          });

          toast.success('Usuário cadastrado com sucesso!');

          history.push(`/users/${response.data.id}/show`);

          if (formRef && formRef.current) {
            formRef.current.reset();
          }
        } catch (err) {
          errorHandler(err);
        }
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          if (formRef && formRef.current) {
            formRef.current.setErrors(validationErrors);
          }
        }
      }
    },
    [history, participants, roles],
  );

  if (loading) {
    return <Container>Carregando....</Container>;
  }

  return (
    <Container>
      <Header>
        <h1>Cadastro de usuário</h1>
        <Divider />
      </Header>

      <Form onSubmit={handleSubmit} ref={formRef}>
        <ContainerInputResponsive>
          <Input name="username" label="Usuário" />
          <Input name="email" label="Email" />
          <Select name="roles" label="Papéis" options={roles} multiple />
          <Select
            name="participant_id"
            label="Participante"
            options={participants}
          />
        </ContainerInputResponsive>
        <Button type="submit">Cadastrar</Button>
      </Form>
    </Container>
  );
}
