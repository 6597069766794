import styled from 'styled-components';

import Container from 'components/Container';

export const Wrapper = styled(Container)`
  header {
    margin: 5px 0px;

    h1 {
      margin-bottom: 5px;
    }
  }

  section {
    .wrapper-scope-inputs {
      margin: 15px 0px;
      div {
        padding: 0;
        margin: 0;
      }

      display: grid;
      @media (min-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);
      }
      gap: 20px;
    }
  }
`;
