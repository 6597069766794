import React from 'react';

// import Paper from '@material-ui/core/Paper';
import styled, { css } from 'styled-components';

interface MessageProps {
  type?: 'error' | 'success';
}

const Message: React.FC<MessageProps> = ({ children, ...props }) => {
  return <div {...props}>{children}</div>;
};

const dragActive = css`
  border-color: #78e5d5;
`;

const dragReject = css`
  border-color: #e57878;
`;

interface DropContainerProps {
  isDragActive?: boolean;
  isDragReject?: boolean;
}

export const DropContainer = styled.div<DropContainerProps>`
  padding: 20px;
  border: 1px dashed #ddd;
  border-radius: 4px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;

  transition: height 0.2s ease;

  ${props => props.isDragActive && dragActive}
  ${props => props.isDragReject && dragReject}
`;

const messageColors = {
  default: '#999',
  error: '#e57878',
  success: '#78e5d5',
};

export const UploadMessage = styled(Message)`
  display: flex;
  color: ${props => messageColors[props.type || 'default']};

  justify-content: center;
  justify-content: center;
  align-items: center;
`;
