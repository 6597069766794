import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Divider } from '@material-ui/core';
import { Scope, FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import {
  Region,
  Company,
  Participant,
  FileLoad,
  Witness,
  Options,
} from 'types';
import { cnpjMask, cpfMask } from 'utils/format';
import * as Yup from 'yup';

import Button from 'components/Button';
import { Input, Select, InputMask } from 'components/Form';
import ImagemDialogShow from 'components/ImagemDialogShow';
import {
  ContainerInputResponsive,
  ContainerSectionResponsive,
  Header,
} from 'components/Layout';
import ContainerInputBox from 'components/Layout/ContainerInputBox';
import api from 'services/api';
import errorHandler from 'services/errorHandler';
import { loadRequest } from 'store/ducks/companies/actions';

import { loadCitiesParsed } from '../../loaders/CitiesParsed';
import { loadStatesParsed } from '../../loaders/StateParsed';
import RenderSellersSection from '../components/RenderSellers';
import RenderWitnessSection from '../components/RenderWitness';
import { Wrapper } from './styles';

interface RouteParam {
  id: string;
}

export default function Add() {
  const dispatch = useDispatch();
  const params = useParams<RouteParam>();
  const formRef = useRef<FormHandles>(null);
  const formSellerRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState<Company>();
  const [states, setStates] = useState<Options[]>();
  const [cities, setCities] = useState<Options[]>();
  const [regions, setRegions] = useState<Options[]>();
  const [stateSelected, setStateSelected] = useState<string | undefined>();
  const [sellersAvailable, setSellersAvailable] = useState<Options[]>();
  const [witnessAvailable, setWitnessAvailable] = useState<Options[]>();

  const loadStates = useCallback(async () => {
    const statesParsed = await loadStatesParsed();

    setStates(statesParsed);
    if (statesParsed.length > 1) {
      setStateSelected(statesParsed[0].value);
    }
  }, []);

  const loadCities = useCallback(async (state: string | undefined) => {
    if (!state) return;
    try {
      const citiesParsed = await loadCitiesParsed(state);

      setCities(citiesParsed);
    } catch {
      toast.error('Não foi possível carregar os estados');
    }
  }, []);

  const loadRegions = useCallback(async () => {
    try {
      const { data } = await api.get<Region[]>(`regions`);

      setRegions(
        data.map(item => {
          return { value: String(item.id), label: `${item.description}` };
        }),
      );
    } catch {
      toast.error('Não foi possível carregar as regiões');
    }
  }, []);

  const loadCompany = useCallback(async (id: string) => {
    setLoading(true);
    try {
      const { data } = await api.get<Company>(`companies/${id}`);

      setCompany(data);
      setLoading(false);
    } catch (err) {
      errorHandler(err);
    }
  }, []);

  const loadSellersAvailable = useCallback(async (id: string) => {
    try {
      const { data } = await api.get<Participant[]>(
        `/company/${id}/participants?myClients=false`,
      );

      setSellersAvailable(
        data.map(participant => {
          if (participant.type === 'fisical') {
            return {
              value: String(participant.id),
              label: `${participant.fisical?.name} (${cpfMask(
                participant.fisical?.cpf || '',
              )})`,
            };
          }
          return {
            value: String(participant.id),
            label: `${participant.juridical?.social_name} (${cnpjMask(
              participant.juridical?.cnpj || '',
            )})`,
          };
        }) || [],
      );
    } catch (err) {
      errorHandler(err);
    }
  }, []);

  const loadWitnessAvailable = useCallback(async (id: string) => {
    try {
      const { data } = await api.get<Witness[]>(
        `/companies/${id}/witness?available=true`,
      );

      setWitnessAvailable(
        data.map(witness => {
          return {
            value: String(witness.id),
            label: `${witness.name} (${cpfMask(witness.cpf)})`,
          };
        }),
      );
    } catch (err) {
      errorHandler(err);
    }
  }, []);

  useEffect(() => {
    loadCities(stateSelected);
  }, [loadCities, stateSelected]);

  useEffect(() => {
    loadStates();
    loadRegions();
  }, [loadStates, loadRegions]);

  useEffect(() => {
    loadCompany(params.id);
    loadSellersAvailable(params.id);
    loadWitnessAvailable(params.id);
  }, [params.id, loadSellersAvailable, loadWitnessAvailable, loadCompany]);

  const handleSubmit = useCallback(
    async (data: SubmitHandler<FormData>) => {
      try {
        // Remove all previous errors
        if (formRef && formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          social_name: Yup.string().required('A Razão social é obrigatória'),
          fantasy_name: Yup.string().required('A Nome Fantasia é obrigatório'),
          cnpj: Yup.string()
            .length(14, 'O CNPJ deve ter 14 caracteres')
            .required('O CNPJ é obrigatório'),
          region_id: Yup.string(),
          address: Yup.object().shape({
            street: Yup.string().required('A rua é obrigatória'),
            neighborhood: Yup.string().required('O bairro é obrigatório'),
            complement: Yup.string(),
            description: Yup.string(),
            cep: Yup.string()
              .transform(value => {
                return value.replace(/\D/g, '');
              })
              .length(8, 'O CEP está inválido'),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        // Validation passed

        try {
          await api.put(`companies/${params.id}`, data);
          toast.success('Empresa atualizada com sucesso!');
          dispatch(loadRequest());
        } catch (err) {
          errorHandler(err);
        }
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          if (formRef && formRef.current) {
            formRef.current.setErrors(validationErrors);
          }
        }
      }
    },
    [dispatch, params.id],
  );

  const handleOnSendFile = useCallback(
    async (file: FileLoad) => {
      const formData = new FormData();

      formData.append('file', file.file, file.name);

      try {
        await api.post(`companies/${params.id}/signature`, formData);

        loadCompany(params.id);
      } catch (error) {
        toast.error('Houve uma inconsistência ao atualizar a assinatura');
      }
    },
    [loadCompany, params.id],
  );

  const handleDetachSeller = useCallback(
    async (id: number) => {
      try {
        await api.delete(`/company/${params.id}/participants/${id}`);
        await loadCompany(params.id);
        await loadSellersAvailable(params.id);
      } catch (err) {
        errorHandler(err);
      } finally {
        setLoading(false);
      }
    },
    [loadCompany, loadSellersAvailable, params.id],
  );

  const handleAttachSeller = useCallback(
    async (id: string) => {
      try {
        await api.post(`/company/${params.id}/participants`, {
          participant_id: id,
        });
        await loadCompany(params.id);
        await loadSellersAvailable(params.id);
      } catch (err) {
        errorHandler(err);
      } finally {
        setLoading(false);
      }
    },
    [params.id, loadCompany, loadSellersAvailable],
  );

  const handleOnAddSeller = useCallback(
    async (data: SubmitHandler<FormData>) => {
      if (!sellersAvailable) return;

      try {
        // Remove all previous errors
        if (formRef && formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          participant_id: Yup.string()
            .oneOf(
              sellersAvailable.map(participant => participant.value),
              'O cliente deve ser selecionado!',
            )
            .required('O tipo do cliente é obrigatório!'),
        });

        await schema
          .validate(data, {
            abortEarly: false,
          })
          .then(response => handleAttachSeller(response.participant_id));
        // Validation passed
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          if (formRef && formRef.current) {
            formRef.current.setErrors(validationErrors);
          }
        }
      }
    },
    [handleAttachSeller, sellersAvailable],
  );

  const handleOnAddWitness = useCallback(
    async (data: SubmitHandler<FormData>) => {
      if (!witnessAvailable) return;

      try {
        // Remove all previous errors
        if (formRef && formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          witness_id: Yup.string()
            .oneOf(
              witnessAvailable.map(witness => witness.value),
              'A testemunha deve ser selecionada!',
            )
            .required('A testemunha deve ser selecionada!'),
        });

        const dataResult = await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.post<Witness[]>(
          `/companies/${params.id}/witness`,
          {
            witness_ids: [dataResult.witness_id],
          },
        );

        setCompany(current => {
          if (!current) return;

          // eslint-disable-next-line consistent-return
          return {
            ...current,
            witness: response.data,
          };
        });

        await loadWitnessAvailable(params.id);

        // Validation passed
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          if (formRef && formRef.current) {
            formRef.current.setErrors(validationErrors);
          }
        }
      }
    },
    [params.id, loadWitnessAvailable, witnessAvailable],
  );

  const handleOnRemoveWitness = useCallback(
    async (id: number) => {
      try {
        await api.delete(`/companies/${params.id}/witness/${id}`);
        await loadWitnessAvailable(params.id);
        setCompany(current => {
          if (!current) return;

          // eslint-disable-next-line consistent-return
          return {
            ...current,
            witness: current.witness?.filter(item => item.id !== id),
          };
        });
      } catch (err) {
        errorHandler(err);
      } finally {
        setLoading(false);
      }
    },
    [params.id, loadWitnessAvailable],
  );

  const RenderSignature = useCallback(() => {
    return (
      <section>
        <h1>Assinatura</h1>
        <Divider />
        <ContainerSectionResponsive>
          <ImagemDialogShow
            url={company?.signature?.url || ''}
            alt="Imagem de assinatura"
            onSendFile={handleOnSendFile}
          />
        </ContainerSectionResponsive>
      </section>
    );
  }, [handleOnSendFile, company]);

  const RenderWitness = useCallback(() => {
    return (
      <>
        <ContainerInputBox>
          <Form ref={formSellerRef} onSubmit={handleOnAddWitness}>
            <Select
              containerStyle={{ flex: 1 }}
              name="witness_id"
              label="Testemunha"
              options={witnessAvailable || []}
            />
            <Button type="submit">Adicionar</Button>
          </Form>
        </ContainerInputBox>
        {company?.witness && (
          <RenderWitnessSection
            witnesses={company?.witness}
            onRemove={handleOnRemoveWitness}
          />
        )}
      </>
    );
  }, [handleOnAddWitness, company, handleOnRemoveWitness, witnessAvailable]);

  const RenderSellers = useCallback(() => {
    return (
      <ContainerInputBox>
        <Form ref={formSellerRef} onSubmit={handleOnAddSeller}>
          <Select
            containerStyle={{ flex: 1 }}
            name="participant_id"
            label="Vendedor"
            options={sellersAvailable || []}
          />
          <Button type="submit">Adicionar</Button>
        </Form>
        {company?.sellers && (
          <RenderSellersSection
            sellers={company?.sellers}
            onItemClick={handleDetachSeller}
          />
        )}
      </ContainerInputBox>
    );
  }, [sellersAvailable, company, handleDetachSeller, handleOnAddSeller]);

  if (loading || !states || !cities || !regions) {
    return <Wrapper>Carregando dados...</Wrapper>;
  }

  return (
    <Wrapper>
      <Header>
        <h1>Edição de Empresa</h1>
        <Divider />
      </Header>

      <Form
        onSubmit={handleSubmit}
        ref={formRef}
        initialData={{
          ...company,
          address: company?.address && {
            ...company?.address,
            state: company?.address.city?.state_id,
            city_id: company?.address.city?.id,
          },
          region_id: company?.region?.id,
        }}
      >
        <ContainerInputResponsive>
          <Input name="social_name" label="Razão social" />
          <Input name="fantasy_name" label="Nome Fantasia" />
          <InputMask
            name="cnpj"
            label="CNPJ"
            mask={[
              /\d/,
              /\d/,
              '.',
              /\d/,
              /\d/,
              /\d/,
              '.',
              /\d/,
              /\d/,
              /\d/,
              '/',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
            ]}
          />
          <Select
            name="region_id"
            label="Região"
            required={false}
            options={regions || []}
          />
        </ContainerInputResponsive>
        <h1>Endereço</h1>
        <Divider />
        <ContainerInputResponsive>
          <Scope path="address">
            <Input name="street" label="Rua" />
            <Input name="neighborhood" label="Bairro" />
            <Input name="complement" label="Complemento" required={false} />
            <Input name="description" label="Descrição" required={false} />
            <InputMask
              name="cep"
              label="CEP"
              mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
            />
            <Select
              name="state"
              label="Estado"
              onValueChange={(state: string | string[] | number | number[]) =>
                setStateSelected(state as string)
              }
              options={states || []}
            />
            <Select name="city_id" label="Cidade" options={cities || []} />
          </Scope>
        </ContainerInputResponsive>
        <Button type="submit">Atualizar</Button>
      </Form>
      <RenderSignature />
      <RenderWitness />
      <RenderSellers />
    </Wrapper>
  );
}
