// my-theme.ts
import { DefaultTheme } from 'styled-components';

const myTheme: DefaultTheme = {
  borderRadius: '5px',

  colors: {
    main: ' #004762',
    secondary: '#91A03F',
  },

  padding: '20px',
  background: '#fff',

  buttons: {
    primary: '#007bff',
    info: '#17a2b8',
    success: '#28a745',
    warning: '#ffc107',
    danger: '#dc3545',
    dark: '#343a40',
    secondary: '#6c757d',
    light: '#f8f9fa',
    default: '#ffffff',
  },
};

export default myTheme;
