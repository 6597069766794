import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 360px;
  margin: auto auto;
  padding: 20px;

  form {
    hr {
      margin: 1rem 0;
    }

    button {
      margin: 0 auto;
    }
  }
`;
