import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  & > * {
    font-size: 1rem;
  }

  b {
    margin-bottom: 5px;
    line-height: 32px;
  }

  padding: 10px 0px;
`;
