import styled from 'styled-components';

import DefaultContainer from 'components/Container';

export const Container = styled(DefaultContainer)`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const PlotInfo = styled.section`
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const BoxHectares = styled.div`
  padding: 10px;
  margin-left: 50px;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: #005500;
  }
`;

export const MapaAplicacaoContainer = styled.ul`
  flex: 1;
  min-width: 360px;
  max-width: 640px;
  padding: 10px;
  font-size: 1rem;
  flex-direction: column;
  align-items: center;
`;

export const MapaAplicacaoLegenda = styled.div`
  display: flex;
  align-items: center;

  & + div {
    margin-top: 10px;
  }
`;

interface BoxProps {
  color: string;
}

export const Box = styled.div<BoxProps>`
  margin-right: 10px;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  background: ${props => props.color};
`;
