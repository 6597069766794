import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItemMaterial from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Slider from '@material-ui/core/Slider';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MapIcon from '@material-ui/icons/Map';
import PersonIcon from '@material-ui/icons/Person';
import { Farm, Participant } from 'types';
import { getNameFromParticipant } from 'utils/renderNameParticipant';

import { HeaderSearchContainer } from 'components/DefaultRenders';
import api from 'services/api';
import { ApplicationState } from 'store';

import {
  Container,
  Content,
  FilterContent,
  ListOrders,
  ListItem,
  Item,
} from './styles';

interface ValueLabelComponentProps {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

function ValueLabelComponent(props: ValueLabelComponentProps) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    listNested: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      maxHeight: 300,
    },
  }),
);

interface ParamsFilter {
  vintage_id: number;
  participants: number[];
  min?: number;
  max?: number;
}

const SellerFilesShow: React.FC = () => {
  const classes = useStyles();
  const user = useSelector((state: ApplicationState) => state.auth.auth?.user);
  const selectedVintage = useSelector(
    (state: ApplicationState) => state.vintages.selectedVintage,
  );
  const [farms, setFarms] = useState<Farm[]>([]);
  const [farmsRangeValue, setfarmsRangeValue] = useState<
    number[] | undefined
  >();
  const [open, setOpen] = useState<{ [key: string]: boolean }>({
    participant: true,
  });
  const [clients, setClients] = useState<Participant[]>([]);
  const [selectedClient, setSelectedClient] = useState<number[]>([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [actualMinMax, setActualMinMax] = useState<number[] | undefined>();
  const [rangeValorPedido, setRangeValorPedido] = useState<
    number[] | undefined
  >();

  const loadFarms = useCallback(
    async (id: number, vintage_id: number) => {
      const params: ParamsFilter = {
        vintage_id,
        participants: selectedClient,
      };

      if (actualMinMax?.length === 2) {
        const [min, max] = actualMinMax;
        params.min = min;
        params.max = max;
      }

      const { data } = await api.get<Farm[]>(`seller/farms`, {
        params,
      });

      if (data.length >= 1 && isFirstLoad) {
        let min = data[0].total_ha;
        let max = data[0].total_ha;

        data.forEach(order => {
          if (order.total_ha <= min) {
            min = order.total_ha;
          } else if (order.total_ha >= max) {
            max = order.total_ha;
          }
        });
        setfarmsRangeValue([min, max]);
        setActualMinMax([min, max]);
        setIsFirstLoad(false);
      }
      setFarms(data);
    },
    [actualMinMax, selectedClient, isFirstLoad],
  );

  useEffect(() => {
    if (selectedVintage?.id && user?.participant_id) {
      loadFarms(user.participant_id, selectedVintage.id);
    }
  }, [user, selectedVintage, loadFarms]);

  useEffect(() => {
    async function loadClients(id: number) {
      const { data } = await api.get<Participant[]>(`/seller/${id}/clients`);

      setClients(
        data.map(client => ({
          ...client,
          nameFormatted: getNameFromParticipant(client),
        })),
      );
    }

    if (user) {
      loadClients(user.id);
    }
  }, [user]);

  const handleChangeCommitRangeFarmValue = useCallback(
    (event: any, newValue: number | number[]) => {
      setActualMinMax(newValue as number[]);
    },
    [],
  );

  const handleChangeRangeFarmValue = useCallback(
    (event: any, newValue: number | number[]) => {
      setRangeValorPedido(newValue as number[]);
    },
    [],
  );

  const handleClick = useCallback((key: string) => {
    setOpen(current => ({
      ...current,
      [key]: !current[key],
    }));
  }, []);

  const handleToggle = useCallback((value: number) => {
    setSelectedClient(current => {
      const currentIndex = current.indexOf(value);
      const newChecked = [...current];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      return newChecked;
    });
  }, []);

  return (
    <Container>
      <FilterContent>
        <h1>Filtros</h1>

        <div>
          {actualMinMax && actualMinMax?.length >= 2 && (
            <>
              <Typography id="range-slider" gutterBottom>
                Hectares da Fazenda
              </Typography>
              <Slider
                valueLabelDisplay="auto"
                defaultValue={actualMinMax}
                aria-labelledby="range-slider"
                ValueLabelComponent={ValueLabelComponent}
                min={farmsRangeValue && farmsRangeValue[0]}
                max={farmsRangeValue && farmsRangeValue[1]}
                onChange={handleChangeRangeFarmValue}
                onChangeCommitted={handleChangeCommitRangeFarmValue}
                value={rangeValorPedido || actualMinMax}
              />
            </>
          )}

          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
          >
            <ListItemMaterial button onClick={() => handleClick('participant')}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="Clientes" />
              {open.participant ? <ExpandLess /> : <ExpandMore />}
            </ListItemMaterial>
            <Collapse in={open.participant} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding
                className={classes.listNested}
              >
                {clients.map(client => {
                  const labelId = `checkbox-list-label-${client.id}`;
                  return (
                    <ListItemMaterial
                      key={client.id}
                      dense
                      button
                      className={classes.nested}
                      onClick={() => handleToggle(client.id)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={selectedClient.indexOf(client.id) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={client.nameFormatted} />
                    </ListItemMaterial>
                  );
                })}
              </List>
            </Collapse>
          </List>
        </div>
      </FilterContent>
      <Content>
        <HeaderSearchContainer textFieldLabel="Buscar fazendas" />
        <ListOrders>
          {farms.map(farm => {
            return (
              <ListItem key={farm.id} to={`/farms/${farm.id}/show`}>
                <Item>
                  <MapIcon />
                  {farm.name}
                </Item>
                <Item>
                  <PersonIcon />
                  {getNameFromParticipant(farm.owner)}
                </Item>
                <Item>{`${farm.total_ha} ha`}</Item>
                <Item>{`${farm.citie} - ${farm.state}`}</Item>
              </ListItem>
            );
          })}
        </ListOrders>
      </Content>
    </Container>
  );
};

export default SellerFilesShow;
