import styled from 'styled-components';

import DefaultContainer from 'components/Container';

export const Container = styled(DefaultContainer)`
  .wrapper-production-container {
    margin: 20px 0px;
    display: grid;
    grid-template-columns: repeat(auto-fill, max(200px));
    gap: 20px;

    .wrapper-production-seller {
      padding: 10px;
      font-size: 1rem;
      border-radius: 4px;
      border: 1px solid #333;
      max-width: 300px;
    }
  }
`;
