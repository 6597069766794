import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import TablePagination, {
  TablePaginationProps,
} from '@material-ui/core/TablePagination';
import { Edit, Delete, Visibility } from '@material-ui/icons';
import FaceIcon from '@material-ui/icons/Face';
import { AxiosError } from 'axios';
import { User, BaseAdonisPaginateReponseInterface } from 'types';
import { getNameFromParticipant } from 'utils/renderNameParticipant';

import BaseTableDataManager from 'components/BaseTableDataManager';
import DialogScroll from 'components/DialogScroll';
import ExportXLS from 'components/ExportXLS';
import api from 'services/api';
import errorHandler from 'services/errorHandler';

import { UserFilter as Filter } from './Filter';
import { Container } from './styles';

export interface ApiResponseData extends BaseAdonisPaginateReponseInterface {
  data: User[];
}

export interface ApiReponse {
  data: ApiResponseData;
}

const apiPageUrl = 'users';

export default function Users() {
  const history = useHistory();

  const [filterOpen, setFilterOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const [hideActions, setHideActions] = useState(false);
  const [loading, setLoading] = useState(true);
  const [informations, setInformations] = useState<User[]>([]);
  const [paginate, setPaginate] = useState<ApiResponseData>({
    lastPage: 1,
    page: 1,
    perPage: 5,
    total: '0',
    data: [],
  });
  const [exporting, setExporting] = useState<{
    exporting: boolean;
    columns: any[];
    data: any[];
  }>({
    exporting: false,
    columns: [],
    data: [],
  });

  const loadData = useCallback(async (page = 1, perPage = 5, filters = '') => {
    try {
      setLoading(true);
      const { data }: ApiReponse = await api.get(
        `${apiPageUrl}?page=${page}&perPage=${perPage}&${filters}`,
      );

      setInformations(
        data.data.map(item => ({
          ...item,
          participantName: getNameFromParticipant(item.participant),
        })),
      );
      setPaginate(data);

      setFilterOpen(false);
    } catch (error) {
      toast.error('Não foi possível carregar as informações');
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData(paginate.page, paginate.perPage, filter);
  }, [filter, loadData, paginate.page, paginate.perPage]);

  const handleOnDelete = useCallback(
    async (user: User) => {
      const { error }: any = await api
        .delete(`${apiPageUrl}/${user.id}`)
        .then(response => ({ response }))
        .catch((err: AxiosError) => ({ error: err }));

      if (error) {
        if (error.response.status === 404) {
          toast.error('Usuário não encontrado');
        }

        if (error.response.status === 500) {
          toast.error('A uma inconsistência no servidor. Contate o suporte');
        }
      } else {
        toast.success(`Usuário ${user.username} removido com sucesso!`);
        setInformations(informations.filter(i => i.id !== user.id));
      }
    },
    [informations],
  );

  const handleOnChangePage = useCallback(
    async (page: number) => {
      setLoading(true);
      await loadData(page + 1, paginate.perPage);
      setLoading(false);
    },
    [loadData, paginate.perPage],
  );

  const handleOnChangeRowsPerPage = useCallback(
    async (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setLoading(true);
      setPaginate({
        ...paginate,
        perPage: Number(event.target.value),
      });
      await loadData(paginate.page, Number(event.target.value));
      setLoading(false);
    },
    [loadData, paginate],
  );

  const handleOnFilterClose = useCallback(() => {
    setFilterOpen(false);
  }, []);

  const handleOnSubmitFilter = useCallback((filters: string) => {
    setFilter(filters);
  }, []);

  const RenderFilters = useCallback(() => {
    const separaretedFilters = filter
      .split('&')
      .filter(a => a !== '')
      .map(i => i.split('='));

    const chipItens = separaretedFilters.map(item => {
      const [key, value] = item;

      return {
        label: Object({
          participant_id: 'Participante',
          username: 'Usuário',
          email: 'Email',
        })[key],
        key,
        value: decodeURIComponent(value),
      };
    });

    return (
      <div className="wrapper-chips-params-filtered">
        {chipItens.map(item => (
          <Chip
            key={item.key}
            className="chip-item-filter"
            icon={<FaceIcon />}
            label={`${item.label}: ${item.value}`}
            onDelete={() =>
              setFilter(
                filter.replace(
                  `${item.key}=${encodeURIComponent(item.value)}`,
                  '',
                ),
              )
            }
            color="secondary"
          />
        ))}
      </div>
    );
  }, [filter]);

  const exportXLS = useCallback(() => {
    if (exporting.exporting) {
      try {
        return (
          <ExportXLS
            filename="Usuários cadastradas"
            columns={exporting.columns}
            data={exporting.data}
          />
        );
      } finally {
        setTimeout(() => {
          setExporting({ exporting: false, columns: [], data: [] });
        }, 2000);
      }
    }

    return <></>;
  }, [exporting]);

  const Table = useCallback(() => {
    return (
      <BaseTableDataManager<User>
        isLoading={loading}
        options={{
          grouping: false,
          pageSizeOptions: [5, 20, 50, 100, Number(paginate.total)],
          exportCsv: (columns, data) => {
            setExporting({ exporting: true, columns, data });
          },
        }}
        title="Usuários cadastrados"
        columns={[
          {
            title: 'Username',
            field: 'username',
          },
          {
            title: 'Email',
            field: 'email',
          },
          {
            title: 'Cód. Participante',
            field: 'participant_id',
          },
          {
            title: 'Participante',
            field: 'participantName',
          },
          { title: 'Cód.', field: 'id' },
          {
            title: 'Ações',
            field: 'id',
            export: false,
            hidden: hideActions,
            render: (column: User) => (
              <>
                <IconButton
                  onClick={() =>
                    history.push(`/${apiPageUrl}/${column.id}/edit`)
                  }
                >
                  <Edit />
                </IconButton>
                <IconButton onClick={() => handleOnDelete(column)}>
                  <Delete />
                </IconButton>
                <IconButton
                  onClick={() =>
                    history.push(`/${apiPageUrl}/${column.id}/show`)
                  }
                >
                  <Visibility />
                </IconButton>
              </>
            ),
          },
        ]}
        data={informations}
        onSelectionChange={rows => setHideActions(rows.length > 0)}
        onShowCustomFilter={() => setFilterOpen(true)}
        onRefresh={() => loadData(paginate.page, paginate.perPage)}
        onAdd={() => history.push(`/${apiPageUrl}/new`)}
        components={{
          Pagination: (props: TablePaginationProps) => (
            <TablePagination
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              count={Number(paginate.total)}
              page={paginate.page - 1}
              rowsPerPage={paginate.perPage}
              onChangePage={(event, page) => handleOnChangePage(page)}
              rowsPerPageOptions={[5, 20, 50, 100, Number(paginate.total)]}
              onChangeRowsPerPage={
                (
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >,
                ) => handleOnChangeRowsPerPage(event)
                // eslint-disable-next-line react/jsx-curly-newline
              }
            />
          ),
        }}
      />
    );
  }, [
    loading,
    paginate.page,
    paginate.perPage,
    paginate.total,
    handleOnChangePage,
    handleOnChangeRowsPerPage,
    handleOnDelete,
    hideActions,
    history,
    informations,
    loadData,
  ]);

  return (
    <Container>
      <RenderFilters />
      {exportXLS()}
      <Table />

      {filterOpen && (
        <DialogScroll
          open={filterOpen}
          dialogTitle="Filtros de Usuários"
          dialogContentText="Utilize os filtros para obter resultados mais personalizados"
          onClose={handleOnFilterClose}
          onClickActionCancelButton={handleOnFilterClose}
          dialogActions={<div />}
        >
          <Filter
            onCancel={handleOnFilterClose}
            onSubmit={handleOnSubmitFilter}
          />
        </DialogScroll>
      )}
    </Container>
  );
}
