/* eslint-disable react/jsx-one-expression-per-line */
import React, { useCallback, useMemo } from 'react';

import {
  Table,
  TableCell,
  TableHeader,
  DataTableCell,
  TableBody,
} from '@david.kucsai/react-pdf-table';
import { Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { Order } from 'types';
import { cnpjMask, cpfMask } from 'utils/format';
import { formatNumberWithExtension } from 'utils/formatCurrency';
import {
  getNameFromParticipant,
  getIdentificationFromParticipant,
} from 'utils/renderNameParticipant';

import { Container } from './styles';

const styles = StyleSheet.create({
  companySignature: {
    maxWidth: 400,
    maxHeight: 175,
    minHeight: 100,
  },
  page: {
    // flexDirection: 'row',
    paddingTop: 50,
    backgroundColor: '#fff',
    paddingLeft: 40,
    paddingRight: 40,
  },
  section: {
    marginBottom: 10,
    // flexGrow: 1
  },
  signatureWitnessContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    fontSize: 12,
    textAlign: 'center',
  },
  signatureWitnessSection: {
    flex: 1,
    margin: 10,
  },
  title: {
    fontSize: 14,
    alignSelf: 'center',
    fontWeight: 'bold',
    marginBottom: 20,
  },
  paragrafy: {
    textAlign: 'justify',
    textDecoration: 'none',
    fontSize: 12,
  },
  spotlight: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    textTransform: 'uppercase',
  },
  tableText: {
    padding: 5,
    textAlign: 'left',
  },
  tableNumber: {
    padding: 5,
    textAlign: 'center',
  },
  signatureMainSection: {
    fontSize: 12,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
    marginBottom: 20,
  },
  spaceToSign: {
    height: 50,
  },
  dataAlign: {
    alignSelf: 'flex-end',
    fontSize: 12,
  },
});

interface OwnProps {
  order: Order;
}

const Contract: React.FC<OwnProps> = ({ order }) => {
  const farmsServicesFormated = useMemo(() => {
    return order.farms.map(farm => {
      return {
        name: farm.farm?.name,
        hectares: String(farm.hectares).replace('.', ','),
        services: farm.services
          ?.map(service => service.service?.name)
          .join(', '),
      };
    });
  }, [order]);

  const SignatureContratadaSection = () => {
    if (!order.company.signature?.url) return <View style={styles.section} />;

    return (
      <View style={styles.signatureMainSection} wrap={false}>
        <Image
          style={styles.companySignature}
          cache={false}
          src={{
            uri: order.company.signature.url,
            method: 'GET',
            headers: {
              'Cache-Control': 'no-cache',
              'Access-Control-Allow-Origin': '*',
            },
            body: '',
          }}
        />
        <Text style={styles.spotlight}>{order.company.social_name}</Text>
        <Text style={styles.spotlight}>CONTRATADA</Text>
      </View>
    );
  };

  const SignatureContratanteSection = () => {
    return (
      <View style={styles.signatureMainSection} wrap={false}>
        <View style={styles.spaceToSign} />
        <Text style={styles.spotlight}>
          {getNameFromParticipant(order.client)}
        </Text>
        <Text style={styles.spotlight}>CONTRATANTE</Text>
      </View>
    );
  };

  const WitnessSection = () => {
    if (order.company.witness) {
      return (
        <View style={styles.signatureWitnessContainer} wrap={false}>
          {order.company.witness.map(witness => {
            return (
              <View
                style={styles.signatureWitnessSection}
                wrap={false}
                key={witness.id}
              >
                {witness.file?.url && (
                  <Image
                    style={styles.companySignature}
                    cache={false}
                    src={{
                      uri: witness.file.url,
                      method: 'GET',
                      headers: {
                        'Cache-Control': 'no-cache',
                        'Access-Control-Allow-Origin': '*',
                      },
                      body: '',
                    }}
                  />
                )}
                <Text style={styles.spotlight}>{witness.name}</Text>
                <Text style={styles.spotlight}>{cpfMask(witness.cpf)}</Text>
              </View>
            );
          })}
        </View>
      );
    }

    return <View />;
  };

  const renderDaySignature = useCallback(() => {
    const actualDate = new Date();

    return (
      <View style={styles.section}>
        <Text style={styles.dataAlign}>
          Rondonópolis,{' '}
          {actualDate.toLocaleDateString('pt-br', {
            day: '2-digit',
          })}{' '}
          de{' '}
          {actualDate.toLocaleDateString('pt-br', {
            month: 'long',
          })}{' '}
          de{' '}
          {actualDate.toLocaleDateString('pt-br', {
            year: 'numeric',
          })}
          .
        </Text>
      </View>
    );
  }, []);

  return (
    <Container>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>
            CONTRATO DE PRESTAÇÃO DE PRODUTOS E SERVIÇOS
          </Text>

          <Text style={styles.paragrafy}>
            {`Pelo presente instrumento particular e melhor forma de direito, `}
            <Text
              style={styles.spotlight}
            >{` ${order.company.social_name}`}</Text>
            {`, empresa jurídica, inscrita no CNPJ sob n.° ${cnpjMask(
              order.company.cnpj,
            )}, estabelecido na`}
            {` ${order.company.address?.street} – ${order.company.address?.neighborhood} `}
            {` ${order.company.address?.city?.name} - ${order.company.address?.city?.state?.initials}, neste ato denominado `}
            <Text style={styles.spotlight}>CONTRATADA</Text>
            {' e, de outro lado'}
            <Text style={styles.spotlight}>{` ${getNameFromParticipant(
              order.client,
            )}`}</Text>
            {`, produtor rural, inscrito no CPF/CNPJ sob n.º ${getIdentificationFromParticipant(
              order.client,
            )}, residente e domiciliado na ${
              order.client.address.length >= 1 && order.client.address[0].street
            }`}
            {`, ${
              order.client.address.length >= 1 &&
              order.client.address[0].neighborhood
            }, cidade de Rondonópolis, estado de Mato Grosso, proprietário da ${order.farms.map(
              farm => farm.farm?.name,
            )}, aqui denominado simplesmente `}
            <Text style={styles.spotlight}>CONTRATANTE</Text>, ajustam o
            presente Contrato de Prestação de Serviços, que se regerá pelas
            seguintes cláusulas e condições:
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            <Text style={styles.spotlight}>CLÁUSULA PRIMEIRA</Text>
            {`: A `}
            <Text style={styles.spotlight}>CONTRATADA</Text> tem como atividade
            e fim, entre outras a prestação de serviços em Agricultura de
            Precisão.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            <Text style={styles.spotlight}>PARÁGRAFO PRIMEIRO</Text>: Nessa
            condição, a <Text style={styles.spotlight}>CONTRATADA</Text>{' '}
            prestará serviços à{' '}
            <Text style={styles.spotlight}>CONTRATANTE</Text>, serviços que
            integram o “MONITORAMENTO E AGRICULTURA DE PRECISÃO – MAP”, cujas
            regras e especificações passam a fazer parte integrante deste
            instrumento, através do anexo I.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            <Text style={styles.spotlight}>PARÁGRAFO SEGUNDO</Text>: Fica claro
            e expressamente consignado que os contratantes têm ciência de que o
            equilíbrio da produtividade nas áreas objeto de acompanhamento pelo
            “MONITORAMENTO EM AGRICULTURA DE PRECISÃO” dependem, além do
            respeito às regras e especificações constantes do anexo I, deste
            instrumento, dos fatores climáticos que atingem as áreas
            contratadas, da correta aplicação dos insumos e adubos recomendados,
            das sementes utilizadas no plantio, do controle eficaz de pragas,
            doenças e ervas daninhas, dentre outros, fatores estes que deverão
            ser corretamente respeitados pelo{' '}
            <Text style={styles.spotlight}>CONTRATANTE</Text>
            {`, para que se obtenha um bom desempenho nas áreas contratadas. Fica de inteira responsabilidade da `}
            <Text style={styles.spotlight}>CONTRATANTE</Text>
            {`, os relativos a analises laboratoriais de quaisquer amostras indicadas pela `}
            <Text style={styles.spotlight}>CONTRATADA</Text>.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            <Text style={styles.spotlight}>CLÁUSULA SEGUNDA</Text>
            {`: Pela prestação de serviços necessários ao cumprimento das obrigações assumidas neste instrumento, referidas na cláusula anterior, ao `}
            <Text style={styles.spotlight}>CONTRATANTE</Text>
            {` serão realizadas gratuitamente como demonstração do serviço.`}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            <Text style={styles.spotlight}>PARÁGRAFO PRIMEIRO</Text>: Refere-se
            ao preço do trabalho, em equivalência por hectare contratado, tendo
            em vista que o “MONITORAMENTO EM AGRICULTURA DE PRECISÃO” compreende
            produtos e serviços, a serem realizados e avaliados por “hectare”,
            ficando desde já consignado que a{' '}
            <Text style={styles.spotlight}>CONTRATADA</Text> acompanhará, para o{' '}
            <Text style={styles.spotlight}>CONTRATANTE</Text>,
            {` ${formatNumberWithExtension(order.hectares)}`} as quais serão
            cultivadas lavouras temporária,
            {` ${order.farms.map(farm => farm.farm?.name)}`}, regularmente
            explorada pelo CONTRATANTE, na qualidade de Proprietário.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            A <Text style={styles.spotlight}>CONTRATADA</Text>
            {` compromete a realizar os serviços contratados por fazenda pela `}
            <Text style={styles.spotlight}>CONTRATANTE</Text>{' '}
            {` conforme a tabela abaixo:`}
          </Text>
        </View>
        <View style={styles.section}>
          <Table data={farmsServicesFormated}>
            <TableHeader>
              <TableCell style={styles.tableText}>Fazenda</TableCell>
              <TableCell style={styles.tableText}>Hectares</TableCell>
              <TableCell style={styles.tableText}>
                Serviços contratados
              </TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell
                style={styles.tableText}
                getContent={r => r.name}
              />
              <DataTableCell
                style={styles.tableNumber}
                getContent={r => r.hectares}
              />
              <DataTableCell
                style={styles.tableText}
                getContent={r => r.services}
              />
            </TableBody>
          </Table>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            <Text style={styles.spotlight}>CLÁUSULA TERCEIRA</Text>: Para
            dirimir questões oriundas da execução do presente contrato, fica
            eleito o foro da Comarca de Rondonópolis, Estado de Mato Grosso, com
            renúncia expressa a qualquer outro, por mais privilegiado que seja.
          </Text>
          <Text style={styles.paragrafy}>
            <Text style={styles.spotlight}>CLÁUSULA QUARTA</Text>
            {`: As partes declaram que manterão em sigilo todos os aspectos da contratação, estratégias e políticas do `}
            <Text style={styles.spotlight}>CONTRATADO</Text>
            {` e do `}
            <Text style={styles.spotlight}>CONTRATANTE</Text>, de forma a
            preservar os interesses comuns ou não, independentemente da duração
            do contrato.
          </Text>
          <Text style={styles.paragrafy}>
            {` E por estarem assim justas e contratadas, as partes assinam o presente contrato, em duas vias de igual teor e efeito, e na presença das testemunhas abaixo.`}
          </Text>
        </View>
        {renderDaySignature()}
        <SignatureContratadaSection />
        <SignatureContratanteSection />
        <Text style={styles.paragrafy}>Testemunhas</Text>
        <WitnessSection />
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>Anexo I</Text>
          <Text style={styles.title}>
            MAP – MONITORAMENTO EM AGRICULTURA DE PRECISÃO
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            <Text style={styles.spotlight}>Objetivo</Text>: Fornecer dados
            informativos que permitam o nivelamento e a manutenção da
            fertilidade do solo, tendo como ponto de partida o mapa de produção
            da área a ser monitorada, que é gerado a partir de imagem via
            satélite ou aérea, permitindo o direcionamento da coleta de amostras
            de solo nos diferentes pontos de produtividade, tendo assim
            condições de efetuar correções localizadas de elementos (Calagem,
            Gessagem, Potassagem e Fosfatagem), se necessário
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            <Text style={styles.spotlight}>Descrição e Procedimentos</Text>: De
            posse da imagem de satélite ou aérea, é localizada e demarcada a
            área a ser trabalhada. Inicia-se o levantamento do mapa de produção,
            elaborando um mapa preciso que demonstre estes diferenciais e,
            posteriormente, loca-los, quantifica-los e demonstra-los em tabelas
            específicas;
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            Após este levantamento, marcam-se no mapa os pontos onde faremos as
            coletas de amostras de solo. Esses pontos geram coordenadas
            geográficas, os quais são marcados tendo por base o mapa de
            produção, originando um arquivo de pontos onde serão coletadas as
            amostras de solo;
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            De posse deste arquivo, a equipe de campo dirige-se à área onde
            serão coletadas as amostras e as realizam. Esse trabalho é de grande
            precisão, pois a localização destes pontos é realizada com o auxílio
            de GPS - Global Position System;
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            A equipe de campo, por ocasião da coleta de amostras, deverá marcar
            estes pontos novamente no GPS – Global Position System, gerando um
            arquivo dos pontos coletados, que irá ser comparado com o arquivo
            dos pontos anteriormente marcados para coleta. Isto é realizado, com
            a finalidade de verificar se as amostras foram coletadas, com a
            precisão desejada, nos locais indicados. Caso ocorra que em algum
            ponto demarcado não tenha sido coletada a respectiva amostra, ou que
            isto tenha sido realizado em local não indicado, deverá ser
            realizada nova coleta;
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            {`As amostras de solo coletadas deverão ser encaminhadas ao Laboratório Indicado pela `}
            <Text style={styles.spotlight}>CONTRATANTE</Text>, para análise
            completa: solo (química, física e microelementos).
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            Após a análise das informações, gera-se e emitem-se recomendações
            precisas e necessárias para equilibrar a composição desses elementos
            no solo, levando-se em conta a cultura a ser implantada e a
            produtividade desejada;
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            De acordo com a necessidade de nutrientes do solo e com as
            recomendações emitidas, geram-se mapas de correções localizadas de
            elementos. Estes mapas são de Calagem, Gessagem, Fosfatagem e
            Potassagem (se necessários). A partir destes mapas (mapas de
            nivelamento nutricional), gera-se um arquivo com coordenadas
            geográficas dos pontos delimitantes da área onde deverão ser
            realizadas as correções recomendadas;
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            A equipe de campo desloca-se até o local onde serão realizadas as
            correções, demarcando as áreas para as aplicações de calcário,
            gesso, fósforo e potássio;
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            O produtor rural é o responsável pela correta aplicação dos
            nutrientes recomendados, sendo co-responsável pelo sucesso do
            programa, porém as regulagens e acompanhamento são de
            responsabilidade da Contratada.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            É necessário ser feito às análises dos fertilizantes e corretivos a
            serem utilizados nas áreas contratadas, com a finalidade de
            certificar as garantias mínimas dos produtos.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            {`As análises gerais necessárias ficam a critério e por conta do `}
            <Text style={styles.spotlight}>CONTRATANTE</Text>
          </Text>
        </View>
        {renderDaySignature()}
        <SignatureContratadaSection />
        <SignatureContratanteSection />
        <Text style={styles.paragrafy}>Testemunhas</Text>
        <WitnessSection />
      </Page>
    </Container>
  );
};

export default Contract;
