import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import TablePagination, {
  TablePaginationProps,
} from '@material-ui/core/TablePagination';
import { Edit, Delete, Visibility } from '@material-ui/icons';
import FaceIcon from '@material-ui/icons/Face';
import { AxiosError } from 'axios';
import { User, BaseAdonisPaginateReponseInterface, Participant } from 'types';
import {
  cpfMask,
  cnpjMask,
  formatDateWithTimestamps,
  formatDateWithoutTimestamps,
} from 'utils/format';
import { getNameFromParticipant } from 'utils/renderNameParticipant';

import BaseTableDataManager from 'components/BaseTableDataManager';
import DialogScroll from 'components/DialogScroll';
import ExportXLS from 'components/ExportXLS';
import api from 'services/api';
import errorHandler from 'services/errorHandler';

import SellerFilter from './Filter';
import { Container } from './styles';

export interface ApiResponseData extends BaseAdonisPaginateReponseInterface {
  data: User[];
}

export default function SellersParticipants() {
  const history = useHistory();
  const [filterOpen, setFilterOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const [hideActions, setHideActions] = useState(false);
  const [loading, setLoading] = useState(true);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [paginate, setPaginate] = useState<ApiResponseData>({
    lastPage: 1,
    page: 1,
    perPage: 5,
    total: '0',
    data: [],
  });
  const [exporting, setExporting] = useState<{
    exporting: boolean;
    columns: any[];
    data: any[];
  }>({
    exporting: false,
    columns: [],
    data: [],
  });

  const loadData = useCallback(async (filters = '') => {
    try {
      setLoading(true);
      const { data } = await api.get<User[]>(`/sellers?${filters}`);

      const participantsMapped = data
        .filter(item => !!item.participant)
        .map(user => {
          if (user.participant) {
            const item = user.participant;
            return {
              ...item,
              nameFormatted: getNameFromParticipant(item),
              typeFormatted: item.type === 'fisical' ? 'Física' : 'Jurídica',
              cpf: item.fisical?.cpf,
              cnpj: item.juridical?.cnpj,
              ie: item.juridical?.state_registration,
              birthday: item.fisical?.birthday,
            };
          }

          return undefined;
        });

      setParticipants(participantsMapped as Participant[]);
      setPaginate({
        lastPage: 1,
        page: 1,
        perPage: data.length,
        total: String(data.length),
        data,
      });

      setFilterOpen(false);
    } catch (error) {
      toast.error('Não foi possível carregar as informações');
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData(filter);
  }, [filter, loadData]);

  const handleOnDelete = useCallback(async (participant: Participant) => {
    const { error }: any = await api
      .delete(`/participants/${participant.id}`)
      .then(response => ({ response }))
      .catch((err: AxiosError) => ({ error: err }));

    if (error) {
      if (error.reponse.status === 404) {
        toast.error('Participante não encontrado');
      }

      if (error.response.status === 500) {
        toast.error('A uma inconsistência no servidor. Contate o suporte');
      }
    } else {
      toast.success(
        `Participante ${
          participant.fisical?.name || participant.juridical?.social_name
        } removido com sucesso!`,
      );
      setParticipants(current => current.filter(i => i.id !== participant.id));
    }
  }, []);

  const handleOnChangeRowsPerPage = useCallback(
    async (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setLoading(true);
      setPaginate(current => ({
        ...current,
        perPage: Number(event.target.value),
      }));
      setLoading(false);
    },
    [],
  );

  const handleOnFilterClose = useCallback(() => {
    setFilterOpen(false);
  }, []);

  const handleOnSubmitFilter = useCallback((filters: string) => {
    setFilter(filters);
  }, []);

  const renderFilterValue = useCallback((value: string) => {
    if (value === 'true') {
      return 'Sim';
    }

    if (value === 'false') {
      return 'Não';
    }

    return value;
  }, []);

  const unRenderFilterValue = useCallback((value: string) => {
    if (value === 'Sim') {
      return 'true';
    }

    if (value === 'Não') {
      return 'false';
    }

    return value;
  }, []);

  const renderFilters = useCallback(() => {
    const separaretedFilters = filter
      .split('&')
      .filter(a => a !== '')
      .map(i => i.split('='));

    const chipItens = separaretedFilters.map(item => {
      const [key, value] = item;

      return {
        label: Object({
          type: 'Tipo do participante',
          is_employee: 'Empregado',
          is_seller: 'Vendedor',
          is_supllier: 'Fornecedor',
          is_client: 'Cliente',
          is_partner: 'Parceiro',
          'fisicals.name': 'Nome',
          'fisicals.cpf': 'CPF',
          'fisicals.mother_name': 'Nome da Mãe',
          'fisicals.birthdayInitial': 'Data de nascimento inicial',
          'fisicals.birthdayFinal': 'Data de nascimento final',
          'juridicals.CNPJ': 'CNPJ',
          'juridicals.social_name': 'Razão social',
          'juridicals.state_registration': 'Inscrição Estadual',
          'juridicals.fantasy_name': 'Nome fantasia',
        })[key],
        key,
        value: renderFilterValue(decodeURIComponent(value)),
      };
    });

    return (
      <div className="wrapper-chips-params-filtered">
        {chipItens.map(item => (
          <Chip
            key={item.key}
            className="chip-item-filter"
            icon={<FaceIcon />}
            label={`${item.label}: ${item.value}`}
            onDelete={
              () =>
                setFilter(
                  filter.replace(
                    `${item.key}=${encodeURIComponent(
                      unRenderFilterValue(item.value),
                    )}`,
                    '',
                  ),
                )
              // eslint-disable-next-line react/jsx-curly-newline
            }
            color="secondary"
          />
        ))}
      </div>
    );
  }, [filter, renderFilterValue, unRenderFilterValue]);

  const exportXLS = useCallback(() => {
    if (exporting.exporting) {
      try {
        return (
          <ExportXLS
            filename="Vendedores cadastrados"
            columns={exporting.columns}
            data={exporting.data}
          />
        );
      } finally {
        setTimeout(() => {
          setExporting({ exporting: false, columns: [], data: [] });
        }, 2000);
      }
    }

    return <></>;
  }, [exporting]);

  return (
    <Container>
      {renderFilters()}
      {exportXLS()}
      <BaseTableDataManager
        isLoading={loading}
        options={{
          grouping: false,
          pageSizeOptions: [20, 50, 100, Number(paginate.total)],
          exportCsv: (columns, data) => {
            setExporting({ exporting: true, columns, data });
          },
        }}
        title="Vendedores cadastrados"
        columns={[
          {
            title: 'Tipo',
            field: 'typeFormatted',
          },
          {
            title: 'Nome',
            field: 'nameFormatted',
          },
          {
            title: 'Empresa',
            field: 'company.fantasy_name',
          },
          {
            title: 'CPF',
            field: 'cpf',
            render: (column: Participant) => {
              return cpfMask(column.fisical?.cpf || '');
            },
          },
          {
            title: 'CNPJ',
            field: 'cnpj',
            render: (column: Participant) => {
              return cnpjMask(column.juridical?.cnpj || '');
            },
          },
          {
            title: 'I.E',
            field: 'ie',
          },
          {
            title: 'Data de Nascimento',
            field: 'birthday',
            render: (column: Participant) => {
              if (column.type === 'fisical')
                return formatDateWithoutTimestamps(column.fisical?.created_at);

              if (column.type === 'juridical')
                return formatDateWithoutTimestamps(
                  column.juridical?.created_at,
                );

              return '';
            },
            type: 'date',
          },
          {
            title: 'Ações',
            field: 'id',
            export: false,
            hidden: hideActions,
            render: (column: Participant) => (
              <>
                <IconButton
                  onClick={() =>
                    history.push(`/participants/${column.id}/edit`)
                  }
                >
                  <Edit />
                </IconButton>
                <IconButton onClick={() => handleOnDelete(column)}>
                  <Delete />
                </IconButton>
                <IconButton
                  onClick={() =>
                    history.push(`/participants/${column.id}/show`)
                  }
                >
                  <Visibility />
                </IconButton>
              </>
            ),
          },
          { title: 'Cód.', field: 'id' },
          {
            title: 'Última alteração',
            field: 'updated_at',
            render: (column: Participant) => {
              if (column.type === 'fisical')
                return formatDateWithTimestamps(column.fisical?.updated_at);

              if (column.type === 'juridical')
                return formatDateWithTimestamps(column.juridical?.updated_at);

              return '';
            },
            type: 'date',
          },
        ]}
        data={participants}
        onSelectionChange={rows => setHideActions(rows.length > 0)}
        onShowCustomFilter={() => setFilterOpen(true)}
        onRefresh={() => loadData()}
        onAdd={() => history.push('/participants/new?type=seller')}
        components={{
          Pagination: (props: TablePaginationProps) => (
            <TablePagination
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              count={Number(paginate.total)}
              page={paginate.page - 1}
              rowsPerPage={paginate.perPage}
              // onChangePage={(event, page) => handleOnChangePage(page)}
              rowsPerPageOptions={[5, 20, 50, 100, Number(paginate.total)]}
              onChangeRowsPerPage={
                (
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >,
                ) => handleOnChangeRowsPerPage(event)
                // eslint-disable-next-line react/jsx-curly-newline
              }
            />
          ),
        }}
      />

      {filterOpen && (
        <DialogScroll
          open={filterOpen}
          dialogTitle="Filtros de Vendedores"
          dialogContentText="Utilize os filtros para obter resultados mais personalizados"
          onClose={handleOnFilterClose}
          onClickActionCancelButton={handleOnFilterClose}
          dialogActions={<div />}
        >
          <SellerFilter
            onCancel={handleOnFilterClose}
            onSubmit={handleOnSubmitFilter}
          />
        </DialogScroll>
      )}
    </Container>
  );
}
