import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { Edit } from '@material-ui/icons';
import { Region } from 'types';

import { CreateAndUpdatesInfos } from 'components/DefaultRenders';
import Fab from 'components/Fab';
import { Header } from 'components/Layout';
import api from 'services/api';
import errorHandler from 'services/errorHandler';

import { Wrapper as Container } from './styles';

interface RouteParam {
  id: string;
}

export default function Show() {
  const params = useParams<RouteParam>();
  const history = useHistory();
  const [region, setRegion] = useState<Region>();

  const loadRegion = useCallback(async (id: string) => {
    try {
      const { data } = await api.get<Region>(`regions/${id}`);

      setRegion(data);
    } catch (err) {
      errorHandler(err);
    }
  }, []);

  useEffect(() => {
    loadRegion(params.id);
  }, [params.id, loadRegion]);

  if (!region) {
    return <div>Carregando...</div>;
  }

  return (
    <Container>
      <Header>
        <h1>{`Região ${region?.description}`}</h1>
        {region && <CreateAndUpdatesInfos model={region} />}
      </Header>

      <Fab color="secondary" onClick={() => history.push(`edit`)}>
        <Edit />
      </Fab>
    </Container>
  );
}
