import React, { useCallback } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tab, { TabProps } from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

interface OwnProps {
  tabs: TabProps[];
  onChangeTab?: (value: number) => void;
  panels: React.ReactNode[];
}

const ScrollableTabsButtonForce: React.FC<OwnProps> = ({
  tabs,
  onChangeTab,
  panels,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = useCallback(
    (event: React.ChangeEvent<unknown>, newValue: number) => {
      setValue(newValue);
      if (onChangeTab) {
        onChangeTab(newValue);
      }
    },
    [onChangeTab],
  );

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="Abas por tipo de participante"
        >
          {tabs.map((tab, index) => (
            <Tab
              {...tab}
              {...a11yProps(index)}
              key={`${Date.now()}-${index}`}
            />
          ))}
        </Tabs>
      </AppBar>
      {panels.map((panel, index) => (
        <TabPanel value={value} index={index} key={`${Date.now()}-${index}`}>
          {panel}
        </TabPanel>
      ))}
    </div>
  );
};

export default ScrollableTabsButtonForce;
