import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { Edit } from '@material-ui/icons';
import { User } from 'types';
import { getIdentificationFromParticipant } from 'utils/renderNameParticipant';

import { CreateAndUpdatesInfos } from 'components/DefaultRenders';
import Fab from 'components/Fab';
import { Header, ContainerSectionResponsive } from 'components/Layout';
import api from 'services/api';
import errorHandler from 'services/errorHandler';

import { Wrapper as Container } from './styles';

interface RouteParam {
  id: string;
}

export default function Show() {
  const params = useParams<RouteParam>();
  const history = useHistory();
  const [user, setUser] = useState<User>();
  const [loading, setloading] = useState(true);

  const loadUser = useCallback(async (id: string) => {
    try {
      setloading(true);
      const { data } = await api.get<User>(`users/${id}`);

      setUser(data);
      setloading(false);
    } catch (err) {
      errorHandler(err);
    }
  }, []);

  useEffect(() => {
    loadUser(params.id);
  }, [params.id, loadUser]);

  if (loading) {
    return <Container>Carregando...</Container>;
  }

  return (
    <Container>
      <Header>
        <h1>{` Usuário: ${user?.username}`}</h1>
        {user && <CreateAndUpdatesInfos model={user} />}
      </Header>
      <ContainerSectionResponsive>
        <p>{`Email: ${user?.email}`}</p>
      </ContainerSectionResponsive>

      {user?.participant && (
        <>
          <h2>Dados do participante</h2>
          <ContainerSectionResponsive>
            <p>{`Participante: ${
              user?.participant?.fisical?.name ||
              user?.participant?.juridical?.social_name
            }`}</p>
            <p>{` CPF/CNPJ: ${
              user?.participant &&
              getIdentificationFromParticipant(user?.participant)
            }`}</p>
          </ContainerSectionResponsive>
        </>
      )}

      <Fab color="secondary" onClick={() => history.push(`edit`)}>
        <Edit />
      </Fab>
    </Container>
  );
}
