import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Switch,
  FormControlLabel,
  IconButton,
  Slider,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Settings } from '@material-ui/icons';
import {
  GoogleMap,
  LoadScript,
  GroundOverlay,
  Polygon,
} from '@react-google-maps/api';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import Rainbow from 'rainbowvis.js';
import { Plot } from 'types';
import { getNameFromParticipant } from 'utils/renderNameParticipant';
import { uuid } from 'uuidv4';
import * as Yup from 'yup';

import Button from 'components/Button';
import ColorPicker from 'components/ColorPicker';
import DialogScroll from 'components/DialogScroll';
import { InputNumber } from 'components/Form';
import TabsContainer from 'components/Layout/TabsContainer';
import MarkerWithLabel from 'components/MarkerWithLabel';
import apiConvertFiles from 'services/apiConvertFiles';

import {
  Container,
  PlotInfo,
  BoxHectares,
  MapaAplicacaoContainer,
  MapaAplicacaoLegenda,
  SectionCustomStyle,
  ElementLabel,
  Legenda,
  MapTypeOptions,
  ButtonMapType,
} from './styles';

interface LatLong {
  lat: number;
  lng: number;
}

interface Params {
  id: string;
}

interface BaseConversaoFile {
  _id: string;
  plot_id: number;
  file_id: number;
  center: number[];
  url: string;
  plot: Plot;
  vintage: number | string;
  options?: {
    fillColor?: string;
    fillOpacity?: number;
    strokeColor?: string;
    strokeOpacity?: number;
    strokeWeight?: number;
  };
}

interface MapaProducao extends BaseConversaoFile {
  palette: {
    gray: number;
    red: number;
    yellow: number;
    green: number;
    blue: number;
  };
  coordinates: {
    north: number;
    west: number;
    east: number;
    south: number;
  };
}

interface Croqui extends BaseConversaoFile {
  hectares: number;
  vintage_id: string;
  geometry: {
    type: 'Polygon' | 'MultiPolygon';
    coordinates: number[][][][];
  };
  formattedCoordinates?: LatLong[][][];
}

interface Points extends BaseConversaoFile {
  points: {
    altitudeMode: string;
    geometry: {
      type: 'Point';
      coordinates: number[];
    };
    point: string;
  }[];
}

interface MaGrid {
  hectares: number;
  grid: string;
  elements: Array<{
    element: string;
    value: number;
  }>;
  geometry: {
    type: 'Polygon' | 'MultiPolygon';
    coordinates: number[][][][];
  };
  polygonConverted?: LatLong[][][];
}

interface MapaAplicacao extends BaseConversaoFile {
  total_hectares: number;
  grids: MaGrid[];
  sumarry?: {
    elements: Element;
  };
  geometryConvertted?: LatLong[][][];
}

interface ElementItem {
  isSelected: boolean;
  amount: number;
  itens: number[];
  min?: number;
  max?: number;
  visualizationType: 'class' | 'individual';
  classes?: ClasseAmplitude[];
  classAmplitude?: number;
  individualMonoColor?: string;
  individualClasses?: ClasseIndividual[];
}

interface Element {
  [key: string]: ElementItem;
}

interface Pallet {
  [key: string]: number;
}

interface DefaultClasseProps {
  id: string;
  color: string;
  totalHa: number;
  polygons: LatLong[][][][];
}

interface ClasseIndividual extends DefaultClasseProps {
  value: number;
}

interface ClasseAmplitude extends DefaultClasseProps {
  min: number;
  max: number;
}

interface OptionsColor {
  key: string;
  value: string;
}

interface OptionsSizes {
  key: string;
  value: number;
}

interface IndividualProps {
  label: string;
  color?: string;
  onChangeColor(color: string): void;
}

interface ClassesFormatProps {
  onAmplitudeChange: (amplitude: number) => void;
}

type fileClassType =
  | 'none'
  | 'mp'
  | 'croqui'
  | 'pontos-coleta'
  | 'mapa-aplicacao';

const ClassesFormat: React.FC<ClassesFormatProps> = ({
  onAmplitudeChange,
  children,
}) => {
  const formRef = useRef<FormHandles>(null);

  const onSubmit = useCallback(
    async (data: SubmitHandler<FormData>) => {
      try {
        // Remove all previous errors
        if (formRef && formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          classSize: Yup.number().required('A amplitude é obrigatória'),
        });

        const { classSize } = await schema.validate(data, {
          abortEarly: false,
        });

        onAmplitudeChange(classSize);
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          if (formRef && formRef.current) {
            formRef.current.setErrors(validationErrors);
          }
        }
      }
    },
    [onAmplitudeChange],
  );

  return (
    <>
      <Form onSubmit={onSubmit} ref={formRef}>
        <InputNumber
          name="classSize"
          label="Tamanho da classe"
          inputProps={{
            prefix: '',
          }}
        />

        <Button type="submit">Enviar</Button>
      </Form>
      {children}
    </>
  );
};

const IndividualFormat: React.FC<IndividualProps> = ({
  label,
  color = 'green',
  onChangeColor,
  children,
}) => {
  const [value, setValue] = React.useState('monocolor');

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue((event.target as HTMLInputElement).value);
    },
    [],
  );

  const handleOnChangeColor = useCallback(
    (colorValue: string) => {
      onChangeColor(colorValue);
    },
    [onChangeColor],
  );

  return (
    <div>
      <FormControl component="fieldset">
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup
          aria-label="Cor Individual"
          name="individualColorPicker"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value="monocolor"
            control={<Radio />}
            label="Monocolor"
          />
          {/* <FormControlLabel value="multicolor" control={<Radio />} label="MultiColor" /> */}
        </RadioGroup>
      </FormControl>
      {value === 'monocolor' && (
        <p>
          {`Cor: `}
          <ColorPicker
            onChangeColor={(colorValue: string) =>
              handleOnChangeColor(colorValue)
            }
            color={color}
          />
        </p>
      )}
      {children}
    </div>
  );
};

type MapTypeIdTypes = 'satellite' | 'hybrid' | 'terrain';

const ViewFile: React.FC = () => {
  const location = useLocation();
  const { id } = useParams<Params>();
  const [error, setError] = useState(false);
  // const [is404, setIs404] = useState(false);
  const [open, setOpen] = useState<fileClassType>('none');
  const [fileClass, setFileClass] = useState<fileClassType>('none');
  const [mapTypeId, setMapTypeId] = useState<MapTypeIdTypes>('terrain');
  const [file, setFile] = useState<
    MapaProducao | Croqui | Points | MapaAplicacao
  >();
  const [center, setCenter] = useState<LatLong>({
    lng: -54.6232381,
    lat: -16.4811028,
  });
  const [map, setMap] = React.useState<GoogleMap | undefined>();

  const onLoad = React.useCallback(function callback(mapLoaded: GoogleMap) {
    setMap(mapLoaded);
  }, []);

  const onUnmount = React.useCallback(function callback() {
    setMap(undefined);
  }, []);

  const loadMP = useCallback(async (file_id: string) => {
    try {
      const mp = await apiConvertFiles.get<MapaProducao>(`/mp/${file_id}`);

      // const { south, west, north, east } = mp.data.coordinates;

      // var sw = new window.google.maps.LatLng(south, west);
      // var ne = new window.google.maps.LatLng(north, east);

      // var { Va, Za } = new window.google.maps.LatLngBounds(sw, ne);

      // setCenter({ lat: (Za.i + Za.j) / 2, lng: (Va.i + Va.j) / 2 });

      setFile(mp.data);
      setError(false);
    } catch {
      setError(true);

      toast.error('Algo errado com o formato do arquivo');
    }
  }, []);

  const loadCroqui = useCallback(async (file_id: string) => {
    try {
      const croqui = await apiConvertFiles.get<Croqui>(`/croqui/${file_id}`);

      const polygonArray = croqui.data.geometry.coordinates.map(polygon => {
        const newPolygon: LatLong[][] = [];

        polygon.map(pointArray => {
          return newPolygon.push(
            pointArray.map(latlong => {
              const [lng, lat] = latlong;
              return {
                lat: Number(String(lat).replace(',', '.')),
                lng: Number(String(lng).replace(',', '.')),
              };
            }),
          );
        });

        return newPolygon;
      });

      setCenter({
        lng: croqui.data.center[0],
        lat: croqui.data.center[1],
      });

      setFile({
        ...croqui.data,
        formattedCoordinates: polygonArray,
        options: {
          fillColor: '#ff4501',
          fillOpacity: 1,
          strokeColor: 'red',
          strokeOpacity: 1,
          strokeWeight: 2,
        },
      });
      setError(false);
    } catch {
      toast.error('Algo errado com o formato do arquivo');
      setError(true);
    }
  }, []);

  const loadPoints = useCallback(async (file_id: string) => {
    try {
      const points = await apiConvertFiles.get<Points>(`/points/${file_id}`);
      const [lng, lat] = points.data.points[0].geometry.coordinates;

      setCenter({
        lng,
        lat,
      });

      setFile(points.data);
      setError(false);
    } catch {
      toast.error('Algo errado com o formato do arquivo');
      setError(true);
    }
  }, []);

  const loadMapaAplicacao = useCallback(async (file_id: string) => {
    try {
      const ma = await apiConvertFiles.get<MapaAplicacao>(`/ma/${file_id}`);

      const elements: Element = {};
      const polygonArray: LatLong[][][] = [];

      const grids = ma.data.grids.map(grid => {
        grid.elements.forEach(element => {
          let item = elements[element.element];

          if (item) {
            item.amount += element.value;
            item.itens = [...item.itens, element.value];
          } else {
            item = {
              isSelected: false,
              visualizationType: 'class',
              amount: element.value,
              itens: [element.value],
            };
          }

          elements[element.element] = item;
        });

        const polygonConverted = grid.geometry.coordinates.map(polygon => {
          const newPolygon: LatLong[][] = [];

          polygon.forEach(pointArray => {
            newPolygon.push(
              pointArray.map(latlong => {
                const [lng, lat] = latlong;
                return {
                  lat: Number(String(lat).replace(',', '.')),
                  lng: Number(String(lng).replace(',', '.')),
                };
              }),
            );
          });

          polygonArray.push(newPolygon);
          return newPolygon;
        });

        return {
          ...grid,
          polygonConverted,
        };
      });

      const [lng, lat] = ma.data.center;

      setCenter({
        lng,
        lat,
      });
      setFile({
        ...ma.data,
        grids,
        sumarry: {
          elements,
        },
        geometryConvertted: polygonArray,
      });
      setError(false);
    } catch {
      toast.error('Algo errado com o formato do arquivo');
      setError(true);
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const classe = params.get('classe');
    setFileClass(classe ? (classe as fileClassType) : 'none');
    switch (classe) {
      case 'mp':
        loadMP(id);
        break;
      case 'croqui':
        loadCroqui(id);
        break;
      case 'pontos-coleta':
        loadPoints(id);
        break;
      case 'mapa-aplicacao':
        loadMapaAplicacao(id);
        break;
      default:
        toast.error('Tipo não suportado');
        setError(true);
        break;
    }
  }, [id, loadMP, loadCroqui, loadMapaAplicacao, loadPoints, location.search]);

  const handleOnCloseDialog = useCallback(() => {
    setOpen('none');
  }, []);

  const handleOnChangeTab = useCallback((tabIndex: number, element: string) => {
    switch (tabIndex) {
      case 0:
        setFile(current => {
          if (!current) return;

          const { sumarry, grids } = current as MapaAplicacao;

          if (sumarry && !sumarry?.elements[element].classes) {
            const gridsWithElement = grids
              .filter(grid => {
                const { elements } = grid;

                return (
                  elements.findIndex(
                    gridElement =>
                      gridElement.element.toUpperCase() ===
                      element.toUpperCase(),
                  ) >= 0
                );
              })
              .map(gridFiltered => {
                const { hectares, polygonConverted, elements } = gridFiltered;
                const elementIndex = elements.findIndex(
                  gridElement =>
                    gridElement.element.toUpperCase() === element.toUpperCase(),
                );

                return {
                  hectares,
                  polygonConverted,
                  elementValue: Number(elements[elementIndex].value.toFixed(2)),
                };
              });

            const elementItens = sumarry.elements[element];

            const itemsSorted = elementItens.itens.sort((a, b) => {
              return a - b;
            });

            const min = itemsSorted[0];
            const max = itemsSorted[itemsSorted.length - 1];

            const amplitude =
              !elementItens.classAmplitude || elementItens.classAmplitude <= 0
                ? Number(((max - min) / 5).toFixed(2))
                : elementItens.classAmplitude;

            const numberClass = max / amplitude;

            const classes: ClasseAmplitude[] = [];

            /** Encontrar todos os grids que tem elementos da classe,
             * retornar o somatório dos hectares
             * retornar o array de geometrias
             * filtrar classes que não tenham geometrias
             */

            for (let index = 0; index < numberClass; index += 1) {
              const minFor = amplitude * index + (index === 0 ? 0 : 1);
              const maxFor = amplitude * (index + 1);
              const haAndPolygons = gridsWithElement
                .filter(grid => {
                  const { elementValue } = grid;

                  return elementValue >= minFor && elementValue <= maxFor;
                })
                .map(item => ({
                  ha: item.hectares,
                  polygon: item.polygonConverted,
                }));

              const totalElementHa = haAndPolygons.reduce(
                (prev, curr) => prev + curr.ha,
                0,
              );
              const polygons = haAndPolygons
                .filter(item => !!item.polygon)
                .map(item => item.polygon || []);

              classes.push({
                id: uuid(),
                min: minFor,
                max: maxFor,
                color: '#fff',
                totalHa: totalElementHa,
                polygons,
              });
            }

            /** Limpa classes vazias */
            const classesClean = classes.filter(item => item.totalHa > 0) || [];

            const rainbow = new Rainbow();
            rainbow.setNumberRange(0, classesClean.length);
            rainbow.setSpectrum('blue', 'green', 'yellow', 'red');

            for (let index = 0; index < classesClean.length; index += 1) {
              const color = rainbow.colourAt(index);
              classesClean[index].color = `#${color}`;
            }

            // eslint-disable-next-line consistent-return
            return {
              ...(current as MapaAplicacao),
              sumarry: {
                elements: {
                  ...sumarry?.elements,
                  [element]: {
                    ...sumarry?.elements[element],
                    classes: classesClean,
                    isSelected: true,
                    min,
                    max,
                    visualizationType: 'class',
                  },
                },
              },
            } as MapaAplicacao;
          }

          // eslint-disable-next-line consistent-return
          return {
            ...current,
            sumarry: {
              elements: {
                ...sumarry?.elements,
                [element]: {
                  ...sumarry?.elements[element],
                  visualizationType: 'class',
                },
              },
            },
          } as MapaAplicacao;
        });
        break;
      case 1:
        setFile(current => {
          if (!current) return;

          const { sumarry, grids } = current as MapaAplicacao;

          if (sumarry && !sumarry?.elements[element].individualClasses) {
            const gridsWithElement = grids
              .filter(grid => {
                const { elements } = grid;

                return (
                  elements.findIndex(
                    gridElement =>
                      gridElement.element.toUpperCase() ===
                      element.toUpperCase(),
                  ) >= 0
                );
              })
              .map(gridFiltered => {
                const { hectares, polygonConverted, elements } = gridFiltered;
                const elementIndex = elements.findIndex(
                  gridElement =>
                    gridElement.element.toUpperCase() === element.toUpperCase(),
                );

                return {
                  hectares,
                  polygonConverted,
                  elementValue: Number(elements[elementIndex].value.toFixed(2)),
                };
              });

            const differentDoses: number[] = [];

            // Pega todas as diferentes dosagens
            gridsWithElement.forEach(grid => {
              if (!differentDoses.includes(grid.elementValue)) {
                differentDoses.push(grid.elementValue);
              }
            });

            // Ordena as diferentes doses
            const dosesOrdenadas = differentDoses.sort((a, b) => {
              return a - b;
            });

            // Raibow Strategy
            const rainbow = new Rainbow();
            rainbow.setNumberRange(0, dosesOrdenadas.length);
            rainbow.setSpectrum('white', 'green');

            // classes básicas
            const classes: ClasseIndividual[] = [];

            // Percorrer cada dose e achar os grids que tem essa dosagem
            dosesOrdenadas.forEach(dose => {
              const haAndPolygons = gridsWithElement
                .filter(grid => grid.elementValue === dose)
                .map(item => ({
                  ha: item.hectares,
                  polygon: item.polygonConverted,
                }));

              const totalElementHa = haAndPolygons.reduce(
                (prev, curr) => prev + curr.ha,
                0,
              );
              const polygons = haAndPolygons
                .filter(item => !!item.polygon)
                .map(item => item.polygon || []);

              classes.push({
                id: uuid(),
                value: dose,
                color: '#fff',
                totalHa: totalElementHa,
                polygons,
              });
            });

            for (let index = 0; index < classes.length; index += 1) {
              const color = rainbow.colourAt(index);
              classes[index].color = `#${color}`;
            }

            // eslint-disable-next-line consistent-return
            return {
              ...current,
              sumarry: {
                elements: {
                  ...sumarry?.elements,
                  [element]: {
                    ...sumarry?.elements[element],
                    isSelected: true,
                    individualClasses: classes,
                    visualizationType: 'individual',
                  },
                },
              },
            } as MapaAplicacao;
          }

          // eslint-disable-next-line consistent-return
          return {
            ...current,
            sumarry: {
              elements: {
                ...sumarry?.elements,
                [element]: {
                  ...sumarry?.elements[element],
                  visualizationType: 'individual',
                },
              },
            },
          } as MapaAplicacao;
        });
        break;
      default:
        break;
    }
  }, []);

  const handleOnChangeOptionsColor = useCallback(
    (change: OptionsColor) => {
      if (file) {
        setFile({
          ...file,
          options: {
            ...file.options,
            [change.key]: change.value,
          },
        });
      }
    },
    [file],
  );

  const handleOnChangeOptionsSize = useCallback(
    (change: OptionsSizes) => {
      if (file) {
        setFile({
          ...file,
          options: {
            ...file.options,
            [change.key]: change.value,
          },
        });
      }
    },
    [file],
  );

  const handleOnChangeElement = useCallback(
    (checked, element: string, elementItens: ElementItem, grids: MaGrid[]) => {
      if (!checked) {
        setFile(current => {
          if (!current) return;

          const { sumarry } = current as MapaAplicacao;

          // eslint-disable-next-line consistent-return
          return {
            ...current,
            sumarry: {
              elements: {
                ...sumarry?.elements,
                [element]: {
                  ...sumarry?.elements[element],
                  isSelected: false,
                },
              },
            },
          } as MapaAplicacao;
        });
      } else {
        /**
         * [x] Ordernar os itens
         * [x] Verificar o tipo de visualização classe ou individual
         * [x] Verificar o tamanho da classe se for individual e gerar as classes de acordo
         * [x] Verificar se o tipo for individual como fica a cor para cada valor
         */
        const itemsSorted = elementItens.itens.sort((a, b) => {
          return a - b;
        });

        const arraySize = itemsSorted.length;

        const gridsWithElement = grids
          .filter(grid => {
            const { elements } = grid;

            return (
              elements.findIndex(
                gridElement =>
                  gridElement.element.toUpperCase() === element.toUpperCase(),
              ) >= 0
            );
          })
          .map(gridFiltered => {
            const { hectares, polygonConverted, elements } = gridFiltered;
            const elementIndex = elements.findIndex(
              gridElement =>
                gridElement.element.toUpperCase() === element.toUpperCase(),
            );

            return {
              hectares,
              polygonConverted,
              elementValue: Number(elements[elementIndex].value.toFixed(2)),
            };
          });

        if (elementItens.visualizationType === 'class') {
          if (!elementItens.classes) {
            const min = itemsSorted[0];
            const max = itemsSorted[arraySize - 1];

            const amplitude =
              !elementItens.classAmplitude || elementItens.classAmplitude <= 0
                ? Number(((max - min) / 5).toFixed(2))
                : elementItens.classAmplitude;

            const numberClass = max / amplitude;

            const classes: ClasseAmplitude[] = [];

            /** Encontrar todos os grids que tem elementos da classe,
             * retornar o somatório dos hectares
             * retornar o array de geometrias
             * filtrar classes que não tenham geometrias
             */

            for (let index = 0; index < numberClass; index += 1) {
              const minFor = amplitude * index + (index === 0 ? 0 : 1);
              const maxFor = amplitude * (index + 1);
              const haAndPolygons = gridsWithElement
                .filter(grid => {
                  const { elementValue } = grid;

                  return elementValue >= minFor && elementValue <= maxFor;
                })
                .map(item => ({
                  ha: item.hectares,
                  polygon: item.polygonConverted,
                }));

              const totalElementHa = haAndPolygons.reduce(
                (prev, curr) => prev + curr.ha,
                0,
              );
              const polygons = haAndPolygons
                .filter(item => !!item.polygon)
                .map(item => item.polygon || []);

              classes.push({
                id: uuid(),
                min: minFor,
                max: maxFor,
                color: '#fff',
                totalHa: totalElementHa,
                polygons,
              });
            }

            /** Limpa classes vazias */
            const classesClean = classes.filter(item => item.totalHa > 0) || [];

            const rainbow = new Rainbow();
            rainbow.setNumberRange(0, classesClean.length);
            rainbow.setSpectrum('blue', 'green', 'yellow', 'red');

            for (let index = 0; index < classesClean.length; index += 1) {
              const color = rainbow.colourAt(index);
              classesClean[index].color = `#${color}`;
            }

            setFile(current => {
              if (!current) return;

              const { sumarry } = current as MapaAplicacao;

              // eslint-disable-next-line consistent-return
              return {
                ...current,
                sumarry: {
                  elements: {
                    ...sumarry?.elements,
                    [element]: {
                      ...sumarry?.elements[element],
                      classes: classesClean,
                      isSelected: true,
                      min,
                      max,
                    },
                  },
                },
              } as MapaAplicacao;
            });
          } else {
            setFile(current => {
              if (!current) return;

              const { sumarry } = current as MapaAplicacao;

              // eslint-disable-next-line consistent-return
              return {
                ...current,
                sumarry: {
                  elements: {
                    ...sumarry?.elements,
                    [element]: {
                      ...sumarry?.elements[element],
                      isSelected: true,
                    },
                  },
                },
              } as MapaAplicacao;
            });
          }
        } else {
          /**
           * Pegar todas as dosagens diferentes
           * Atribuir uma cor para cada uma
           * Gerar id para cada uma
           * Atualizar sumarry dos elementos
           */

          const differentDoses: number[] = [];

          // Pega todas as diferentes dosagens
          gridsWithElement.forEach(grid => {
            if (!differentDoses.includes(grid.elementValue)) {
              differentDoses.push(grid.elementValue);
            }
          });

          // Ordena as diferentes doses
          const dosesOrdenadas = differentDoses.sort((a, b) => {
            return a - b;
          });

          // Raibow Strategy
          const rainbow = new Rainbow();
          rainbow.setNumberRange(0, dosesOrdenadas.length);
          rainbow.setSpectrum('white', 'green');

          // classes básicas
          const classes: ClasseIndividual[] = [];

          // Percorrer cada dose e achar os grids que tem essa dosagem

          dosesOrdenadas.forEach(dose => {
            const haAndPolygons = gridsWithElement
              .filter(grid => grid.elementValue === dose)
              .map(item => ({
                ha: item.hectares,
                polygon: item.polygonConverted,
              }));

            const totalElementHa = haAndPolygons.reduce(
              (prev, curr) => prev + curr.ha,
              0,
            );
            const polygons = haAndPolygons
              .filter(item => !!item.polygon)
              .map(item => item.polygon || []);

            classes.push({
              id: uuid(),
              value: dose,
              color: '#fff',
              totalHa: totalElementHa,
              polygons,
            });
          });

          for (let index = 0; index < classes.length; index += 1) {
            const color = rainbow.colourAt(index);
            classes[index].color = `#${color}`;
          }

          setFile(current => {
            if (!current) return;

            const { sumarry } = current as MapaAplicacao;

            // eslint-disable-next-line consistent-return
            return {
              ...current,
              sumarry: {
                elements: {
                  ...sumarry?.elements,
                  [element]: {
                    ...sumarry?.elements[element],
                    isSelected: true,
                    individualClasses: classes,
                    individualMonoColor: 'green',
                  },
                },
              },
            } as MapaAplicacao;
          });
        }
      }
    },
    [],
  );

  const handleOnChangeAmplitude = useCallback(
    (
      element: string,
      amplitude: number,
      elementItens: ElementItem,
      grids: MaGrid[],
    ) => {
      setFile(current => {
        if (!current) return;

        const { sumarry } = current as MapaAplicacao;

        const { max } = elementItens;

        const numberClass = (max || 0) / amplitude;

        const classes: ClasseAmplitude[] = [];

        /** Encontrar todos os grids que tem elementos da classe,
         * retornar o somatório dos hectares
         * retornar o array de geometrias
         * filtrar classes que não tenham geometrias
         */

        const gridsWithElement = grids
          .filter(grid => {
            const { elements } = grid;

            return (
              elements.findIndex(
                gridElement =>
                  gridElement.element.toUpperCase() === element.toUpperCase(),
              ) >= 0
            );
          })
          .map(gridFiltered => {
            const { hectares, polygonConverted, elements } = gridFiltered;
            const elementIndex = elements.findIndex(
              gridElement =>
                gridElement.element.toUpperCase() === element.toUpperCase(),
            );

            return {
              hectares,
              polygonConverted,
              elementValue: Number(elements[elementIndex].value.toFixed(2)),
            };
          });

        for (let index = 0; index < numberClass; index += 1) {
          const minFor = amplitude * index + (index === 0 ? 0 : 1);
          const maxFor = amplitude * (index + 1);
          const haAndPolygons = gridsWithElement
            .filter(grid => {
              const elementValue = Number(grid.elementValue.toFixed(2));

              return elementValue >= minFor && elementValue <= maxFor;
            })
            .map(item => ({
              ha: item.hectares,
              polygon: item.polygonConverted,
            }));

          const totalElementHa = haAndPolygons.reduce(
            (prev, curr) => prev + curr.ha,
            0,
          );
          const polygons = haAndPolygons
            .filter(item => !!item.polygon)
            .map(item => item.polygon || []);

          classes.push({
            id: uuid(),
            min: minFor,
            max: maxFor,
            color: '#fff',
            totalHa: totalElementHa,
            polygons,
          });
        }

        /** Limpa classes vazias */
        const classesClean = classes.filter(item => item.totalHa > 0) || [];

        const rainbow = new Rainbow();
        rainbow.setNumberRange(0, classesClean.length);
        rainbow.setSpectrum('blue', 'green', 'yellow', 'red');

        for (let index = 0; index < classesClean.length; index += 1) {
          const color = rainbow.colourAt(index);
          classesClean[index].color = `#${color}`;
        }

        // eslint-disable-next-line consistent-return
        return {
          ...current,
          sumarry: {
            elements: {
              ...sumarry?.elements,
              [element]: {
                ...sumarry?.elements[element],
                classAmplitude: amplitude,
                classes: classesClean,
              },
            },
          },
        } as MapaAplicacao;
      });
    },
    [],
  );

  const handleOnChangeIndividualMainColor = useCallback(
    (element: string, newColor: string, elementItens: ElementItem) => {
      setFile(current => {
        if (!current) return;
        const { sumarry } = current as MapaAplicacao;

        const { individualClasses = [] } = elementItens;

        const rainbow = new Rainbow();
        rainbow.setNumberRange(0, individualClasses.length || 1);
        rainbow.setSpectrum('white', newColor);

        for (let index = 0; index < individualClasses.length; index += 1) {
          const color = rainbow.colourAt(index);
          individualClasses[index].color = `#${color}`;
        }

        // eslint-disable-next-line consistent-return
        return {
          ...current,
          sumarry: {
            elements: {
              ...sumarry?.elements,
              [element]: {
                ...sumarry?.elements[element],
                individualClasses,
                individualMonoColor: newColor,
              },
            },
          },
        } as MapaAplicacao;
      });
    },
    [],
  );

  const handleOnChangeColorPicker = useCallback(
    (color: string, element: string, idColor: string) => {
      setFile(current => {
        if (!current) return;

        const { sumarry } = current as MapaAplicacao;

        // eslint-disable-next-line consistent-return
        return {
          ...current,
          sumarry: {
            elements: {
              ...sumarry?.elements,
              [element]: {
                ...sumarry?.elements[element],
                classes: sumarry?.elements[element].classes?.map(item => {
                  return item.id === idColor
                    ? {
                        ...item,
                        color,
                      }
                    : item;
                }),
              },
            },
          },
        } as MapaAplicacao;
      });
    },
    [],
  );

  const handleOnChangeIndividualColorPicker = useCallback(
    (color: string, element: string, idColor: string) => {
      setFile(current => {
        if (!current) return;

        const { sumarry } = current as MapaAplicacao;

        // eslint-disable-next-line consistent-return
        return {
          ...current,
          sumarry: {
            elements: {
              ...sumarry?.elements,
              [element]: {
                ...sumarry?.elements[element],
                individualClasses: sumarry?.elements[
                  element
                ].individualClasses?.map(item => {
                  return item.id === idColor
                    ? {
                        ...item,
                        color,
                      }
                    : item;
                }),
              },
            },
          },
        } as MapaAplicacao;
      });
    },
    [],
  );

  const renderMP = useCallback(() => {
    if (!file) return undefined;
    const { url, coordinates } = file as MapaProducao;

    return <GroundOverlay key="url" url={url} bounds={coordinates} />;
  }, [file]);

  const renderCroqui = useCallback(() => {
    if (!file) return <></>;
    const { formattedCoordinates } = file as Croqui;

    if (!formattedCoordinates) return <></>;
    const [lng, lat] = file.center;

    return (
      <>
        {formattedCoordinates.map((item, index) => {
          return (
            <Polygon
              paths={item}
              key={`${Date.now()}-${String(index)}`}
              options={file.options}
            />
          );
        })}
        <MarkerWithLabel
          label={file.plot.name}
          title={file.plot.name}
          position={{ lat, lng }}
        />
      </>
    );
  }, [file]);

  const renderPoints = useCallback(() => {
    if (!file) return <></>;

    const { points } = file as Points;

    if (!points) return <></>;

    return (
      <>
        {points.map((item, index) => {
          const [lng, lat] = item.geometry.coordinates;

          return (
            <MarkerWithLabel
              key={`${Date.now()}-${String(index)}`}
              label={item.point}
              title={item.point}
              position={{ lat, lng }}
            />
          );
        })}
      </>
    );
  }, [file]);

  const renderMapaAplicacao = useCallback(() => {
    if (!file) return <></>;

    const { geometryConvertted, sumarry, grids } = file as MapaAplicacao;

    if (!geometryConvertted) return <></>;

    const elementsSelected = Object.keys(sumarry?.elements || {}).filter(
      key => sumarry?.elements[key].isSelected,
    );

    if (elementsSelected) {
      return (
        <>
          {elementsSelected.map((element, elementIndex) => {
            return grids.map((grid, gridIndex) => {
              const { polygonConverted } = grid;

              if (!polygonConverted) return <></>;

              const hasElement = grid.elements.findIndex(
                item => item.element === element,
              );

              if (hasElement < 0) {
                return polygonConverted.map(polygon => (
                  <Polygon
                    paths={polygon}
                    key={`${String(elementIndex)}-${String(gridIndex)}`}
                  />
                ));
              }
              const elementValue = Number(
                grid.elements[elementIndex].value.toFixed(2),
              );
              let elementClassColor = '#fff';

              if (sumarry?.elements[element].visualizationType === 'class') {
                const provisoryColor = sumarry?.elements[element].classes?.find(
                  classe =>
                    elementValue >= classe.min && elementValue <= classe.max,
                )?.color;
                elementClassColor = provisoryColor || '#fff';
              } else {
                const provisoryColor = sumarry?.elements[
                  element
                ].individualClasses?.find(
                  classe => elementValue === classe.value,
                )?.color;
                elementClassColor = provisoryColor || '#fff';
              }

              const options = {
                fillColor: elementClassColor,
                fillOpacity: 1,
                strokeColor: 'ligthgreen',
                strokeOpacity: 0,
                strokeWeight: 0,
                clickable: true,
                draggable: false,
                editable: false,
                geodesic: false,
                zIndex: elementIndex,
              };

              return polygonConverted.map((polygon, index) => (
                <Polygon
                  paths={polygon}
                  key={`${Date.now()}-${String(index)}`}
                  options={options}
                />
              ));
            });
          })}
        </>
      );
    }

    return (
      <>
        {geometryConvertted.map((item, index) => (
          <Polygon paths={item} key={`${Date.now()}-${String(index)}`} />
        ))}
      </>
    );
  }, [file]);

  const renderMapaAplicacaoInfos = useCallback(() => {
    if (!file) return;
    const { sumarry, grids } = file as MapaAplicacao;
    const elements = sumarry?.elements || {};

    // eslint-disable-next-line consistent-return
    return (
      <MapaAplicacaoContainer>
        <header>
          <h1>Elementos</h1>
        </header>
        {Object.keys(elements).map(element => {
          const {
            classes,
            individualClasses,
            isSelected,
            individualMonoColor,
          } = elements[element];

          const totalHaClasses =
            classes
              ?.map(item => item.totalHa)
              .reduce((prev, curr) => prev + curr, 0) || 1;
          const totalHaIndividual =
            individualClasses
              ?.map(item => item.totalHa)
              .reduce((prev, curr) => prev + curr, 0) || 1;

          return (
            <>
              <ElementLabel>
                <div>
                  <FormControlLabel
                    key={element}
                    control={
                      <Switch
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                          checked: boolean,
                        ) => {
                          handleOnChangeElement(
                            checked,
                            element,
                            elements[element],
                            grids,
                          );
                        }}
                        name={element}
                        color="primary"
                      />
                    }
                    label={element}
                  />
                  <IconButton>
                    <Settings />
                  </IconButton>
                </div>
                <TabsContainer
                  onChangeTab={tab => handleOnChangeTab(tab, element)}
                  tabs={[
                    {
                      label: 'Classes',
                    },
                    {
                      label: 'individual',
                    },
                  ]}
                  panels={[
                    <ClassesFormat
                      key={`${Date.now()}-classformat-${element}`}
                      onAmplitudeChange={amplitude =>
                        handleOnChangeAmplitude(
                          element,
                          amplitude,
                          elements[element],
                          grids,
                        )
                      }
                    >
                      <Legenda>
                        <h2>Legenda</h2>
                        {isSelected && classes ? (
                          classes.map(classe => {
                            const { min, max, color, totalHa } = classe;

                            const pctTotalHa = Number(
                              (totalHa * 100) / totalHaClasses,
                            ).toFixed(2);

                            return (
                              <MapaAplicacaoLegenda key={classe.id}>
                                <ColorPicker
                                  color={color}
                                  onChangeColor={selectedColor =>
                                    handleOnChangeColorPicker(
                                      selectedColor,
                                      element,
                                      classe.id,
                                    )
                                  }
                                />
                                <strong>
                                  {`${min}-${Number(max.toFixed(2))} (${Number(
                                    totalHa,
                                  ).toFixed(2)} Ha | ${pctTotalHa}%)`}
                                </strong>
                              </MapaAplicacaoLegenda>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </Legenda>
                    </ClassesFormat>,
                    <IndividualFormat
                      key={`${Date.now()}-individualformat-${element}`}
                      label="Visualização"
                      color={individualMonoColor}
                      onChangeColor={color =>
                        handleOnChangeIndividualMainColor(
                          element,
                          color,
                          elements[element],
                        )
                      }
                    >
                      <Legenda>
                        <h2>Legenda</h2>
                        {isSelected && individualClasses ? (
                          individualClasses.map(classe => {
                            const { color, totalHa, value } = classe;
                            const pctTotalHa = Number(
                              (totalHa * 100) / totalHaIndividual,
                            ).toFixed(2);
                            return (
                              <MapaAplicacaoLegenda key={classe.id}>
                                <ColorPicker
                                  color={color}
                                  onChangeColor={selectColor =>
                                    handleOnChangeIndividualColorPicker(
                                      selectColor,
                                      element,
                                      classe.id,
                                    )
                                  }
                                />
                                <strong>{`${value} (${Number(totalHa).toFixed(
                                  2,
                                )} Ha | ${pctTotalHa}%)`}</strong>
                              </MapaAplicacaoLegenda>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </Legenda>
                    </IndividualFormat>,
                  ]}
                />
              </ElementLabel>
            </>
          );
        })}
      </MapaAplicacaoContainer>
    );
  }, [
    file,
    handleOnChangeElement,
    handleOnChangeAmplitude,
    handleOnChangeTab,
    handleOnChangeColorPicker,
    handleOnChangeIndividualMainColor,
    handleOnChangeIndividualColorPicker,
  ]);

  const palletPercentage: Pallet = useMemo(() => {
    if (!file || fileClass !== 'mp') {
      return {
        'Muito boa': 0,
        Boa: 0,
        Média: 0,
        Ruim: 0,
        Péssima: 0,
      };
    }
    const { palette } = file as MapaProducao;
    const { gray = 0, red = 0, yellow = 0, green = 0, blue = 0 } = palette;

    const sumColors = gray + red + yellow + green + blue;

    return {
      'Muito boa':
        blue > 0 ? Number(((blue / sumColors) * 100).toPrecision(2)) : 0,
      Boa: green > 0 ? Number(((green / sumColors) * 100).toPrecision(2)) : 0,
      Média:
        yellow > 0 ? Number(((yellow / sumColors) * 100).toPrecision(2)) : 0,
      Ruim: red > 0 ? Number(((red / sumColors) * 100).toPrecision(2)) : 0,
      Péssima: gray > 0 ? Number(((gray / sumColors) * 100).toPrecision(2)) : 0,
    };
  }, [file, fileClass]);

  const renderPlotInfos = useCallback(() => {
    if (!file?.plot) return <></>;

    const { plot } = file;
    const { farm } = plot;

    return (
      <PlotInfo>
        <header>
          <div>
            <h1>{`Talhão: ${plot.id} - ${plot.name}`}</h1>
            <h1>{`Fazenda: ${farm?.id} - ${farm?.name}`}</h1>
            <h1>{`Proprietário: ${getNameFromParticipant(farm?.owner)}`}</h1>
          </div>
          {['croqui'].includes(fileClass) && (
            <div>
              <IconButton onClick={() => setOpen(fileClass)}>
                <Settings />
              </IconButton>
            </div>
          )}
          {/* <button onClick={() => handleOnPrint()}>Imprimir</button> */}
        </header>
        {fileClass === 'mp' && (
          <HorizontalBar
            data={{
              labels: Object.keys(palletPercentage),
              datasets: [
                {
                  label: 'Níveis Produtivos (%)',
                  backgroundColor: [
                    'rgb(0,0,255)',
                    'rgb(0,100,0)',
                    'rgb(255,255,0)',
                    'rgb(255,0,0)',
                    'rgb(191,191,191)',
                  ],
                  data: Object.keys(palletPercentage).map(
                    key => palletPercentage[`${key}`],
                  ),
                },
              ],
            }}
          />
        )}
        {fileClass === 'croqui' && (
          <BoxHectares>
            <p>Área</p>
            <h1>{`${(file as Croqui).hectares} ha`}</h1>
          </BoxHectares>
        )}
      </PlotInfo>
    );
  }, [file, fileClass, palletPercentage]);

  if (error) {
    return <Container>Arquivo não suportado</Container>;
  }

  if (!map || !file) {
    return <Container>Carregando...</Container>;
  }

  return (
    <Container>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API || ''}>
        <GoogleMap
          id="teste-print"
          center={center}
          mapTypeId={mapTypeId}
          zoom={15}
          onLoad={onLoad}
          onUnmount={onUnmount}
          mapContainerStyle={{
            flex: 1,
            display: 'flex',
            minWidth: '250px',
            minHeight: '450px',
          }}
        >
          <MapTypeOptions>
            <ButtonMapType onClick={() => setMapTypeId('satellite')}>
              Satélite
            </ButtonMapType>
            <ButtonMapType onClick={() => setMapTypeId('hybrid')}>
              Hibrido
            </ButtonMapType>
            <ButtonMapType onClick={() => setMapTypeId('terrain')}>
              Terreno
            </ButtonMapType>
          </MapTypeOptions>
          {file && (
            <>
              {fileClass === 'croqui' && renderCroqui()}
              {fileClass === 'mp' && renderMP()}
              {fileClass === 'pontos-coleta' && renderPoints()}
              {fileClass === 'mapa-aplicacao' && renderMapaAplicacao()}
            </>
          )}
        </GoogleMap>
      </LoadScript>
      <div>
        {renderPlotInfos()}
        {fileClass === 'mapa-aplicacao' && renderMapaAplicacaoInfos()}
      </div>
      <DialogScroll
        open={open === 'croqui'}
        dialogTitle="Alteração da visualização"
        dialogContentText=""
        onClose={handleOnCloseDialog}
        onClickActionCancelButton={handleOnCloseDialog}
        dialogActions={<div />}
      >
        <SectionCustomStyle>
          <p>
            Preenchimento:
            <ColorPicker
              onChangeColor={(colorSelected: string) => {
                return handleOnChangeOptionsColor({
                  key: 'fillColor',
                  value: colorSelected,
                });
              }}
              color={file?.options?.fillColor}
            />
          </p>
          <p>
            Opacidade do Preenchimento:
            <Slider
              defaultValue={1}
              step={0.1}
              min={0}
              max={1}
              valueLabelDisplay="auto"
              value={file?.options?.fillOpacity || 1}
              onChange={(_, value) =>
                handleOnChangeOptionsSize({
                  key: 'fillOpacity',
                  value: value as number,
                })
              }
            />
          </p>
          <p>
            Cor da borda:
            <ColorPicker
              onChangeColor={(color: string) =>
                handleOnChangeOptionsColor({
                  key: 'strokeColor',
                  value: color,
                })
              }
              color={file?.options?.strokeColor}
            />
          </p>
          <p>
            Opacidade da borda:
            <Slider
              defaultValue={1}
              step={0.1}
              min={0}
              max={1}
              valueLabelDisplay="auto"
              value={file?.options?.strokeOpacity || 1}
              onChange={(_, value) =>
                handleOnChangeOptionsSize({
                  key: 'strokeOpacity',
                  value: value as number,
                })
              }
            />
          </p>
          <p>
            Tamanho da borda:
            <Slider
              defaultValue={1}
              step={1}
              min={1}
              max={5}
              valueLabelDisplay="auto"
              value={file?.options?.strokeWeight || 1}
              onChange={(_, value) =>
                handleOnChangeOptionsSize({
                  key: 'strokeWeight',
                  value: Number(value),
                })
              }
            />
          </p>
        </SectionCustomStyle>
      </DialogScroll>
    </Container>
  );
};

export default ViewFile;
