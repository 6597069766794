import styled from 'styled-components';

export const Container = styled.div`
  flex-grow: 1;
  padding: 20px;
  border-radius: 4px;

  -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.66);
  -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.66);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.66);

  .wrapper-filter-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 100%;
    margin: 10px 0px;
  }

  .wrapper-production-container {
    margin: 20px 0px;
    display: grid;
    grid-template-columns: repeat(auto-fill, max(200px));
    gap: 20px;

    .wrapper-production-seller {
      padding: 10px;
      font-size: 1rem;
      border-radius: 4px;
      border: 1px solid #333;
      max-width: 300px;
    }
  }
`;
