import { call, put, all, takeLatest } from 'redux-saga/effects';
import { Vintage } from 'types';

import api from 'services/api';

import { loadFailure, loadSuccess, setSelectedVintage } from './actions';
import { VintageTypes } from './types';

// interface Response {
//   data: {
//     data: Vintage[];
//   };
// }

export function* load() {
  try {
    const { data } = yield call(api.get, 'vintages');

    yield put(loadSuccess(data.data));

    if (data.data.length > 0) {
      yield put(setSelectedVintage(data.data[0]));
    }
  } catch (err) {
    yield put(loadFailure());
  }
}

export default all([takeLatest(VintageTypes.LOAD_REQUEST, load)]);
