import { toast } from 'react-toastify';

import { AxiosError } from 'axios';

export default function ({
  err,
  message404,
}: {
  err: AxiosError;
  message404?: string;
}) {
  const { response, message } = err;

  if (!response) {
    if (message === 'Network Error') {
      toast.error(
        'Falha de conexão. O aplicativo não está conseguindo buscar dados, verifique sua conexão, caso esteja tendo acesso a internet contate o suporte',
      );
    } else {
      toast.error(
        `Inconsistência Interna. Por favor contate o suporte com a mensagem: Inconsistência 002 - ${message}`,
      );
    }
  } else {
    const { status, data } = response;

    if (status === 404) {
      toast.error(message404);
    } else {
      const name = data.error.message.split(':', 1)[0];

      if (status === 401) {
        switch (name) {
          case 'E_INVALID_JWT_TOKEN': {
            toast.error(
              'Token expirado. Sua sessão expirou, realize o acesso novamente!',
            );

            break;
          }

          case 'E_PASSWORD_MISMATCH': {
            toast.error(
              'Ops, algo errado. Sua senha não está correta, verifique sua senha e tente novamente!',
            );
            break;
          }

          case 'E_USER_NOT_FOUND': {
            toast.error(
              'Ops, algo errado. Este email não foi encontrado, por favor verifique-o',
            );
            break;
          }

          default: {
            toast.error(`Inconsistência ${name}`);
            break;
          }
        }
      } else if (status === 500) {
        if (name === 'TypeError') {
          toast.error(
            'Inconsistência Interna. Por favor contate o suporte com a mensagem: Inconsistência 001',
          );
        }
      } else if (response) {
        toast.error(`Inconsistência encontrada, contate o suporte`);
      }
    }
  }
}
