import styled from 'styled-components';

import Container from 'components/Container';

export const ContainerStyled = styled(Container)`
  min-height: 100%;

  .wrapper-files-view {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 600px));
    gap: 20px;
  }

  .wrapper-map-container {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: row;

    .wrapper-map-view {
      flex-grow: 1;
      margin-right: 10px;
    }

    .wrapper-file-list {
      display: flex;
      flex-direction: column;
      min-width: 300px;
      padding: 20px;
    }

    & > div {
      border-radius: 5px;
    }
  }
`;

export const FileItem = styled.div`
  div {
    li {
      list-style: none;
      display: flex;
      align-items: center;

      button {
        margin-left: 10px;
      }

      h1 {
        font-size: 1rem;
      }
    }
  }
`;

export const MapaAplicacaoContainer = styled.ul`
  flex: 1;
  min-width: 300px;
  max-width: 640px;
  padding: 20px;
  font-size: 1rem;
  flex-direction: column;
  align-items: center;
`;

export const MapaAplicacaoLegenda = styled.div`
  & + div {
    margin-top: 10px;
  }

  display: flex;
  align-items: center;
`;

export const ElementLabel = styled.section``;

export const Legenda = styled.div`
  max-height: 600px;
  overflow: auto;
`;
