import React, { useCallback, useRef } from 'react';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import Button from 'components/Button';
import { InputNumber, DatePicker, Input } from 'components/Form';
import errorHandler from 'services/errorHandler';

import { Container } from './styles';

export interface AddParcelDTO {
  date: Date;
  value: number;
  own_code?: string;
}

interface FilterProps {
  onCancel: () => void;
  onSubmit: (values: AddParcelDTO) => void;
}

export const AddParcel: React.FC<FilterProps> = ({ onCancel, onSubmit }) => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: SubmitHandler<AddParcelDTO>) => {
      try {
        // Remove all previous errors
        if (formRef && formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          date: Yup.date().required('A data da parcela é obrigatória'),
          value: Yup.number()
            .required('O valor da parcela é obrigatório')
            .min(0.01, 'A parcela não pode ser 0'),
          own_code: Yup.string(),
        });

        const result = await schema.validate(data, {
          abortEarly: false,
        });

        try {
          onSubmit(result);
        } catch (err) {
          errorHandler(err);
        }
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          if (formRef && formRef.current) {
            formRef.current.setErrors(validationErrors);
          }
        }
      }
    },
    [onSubmit],
  );

  return (
    <Container>
      <Form onSubmit={handleSubmit} ref={formRef}>
        <Input
          name="own_code"
          required={false}
          label="Nota promissória"
          placeholder="Insira o código próprio da parcela"
        />
        <InputNumber label="Valor da Parcela" name="value" />
        <DatePicker name="date" />

        <Button type="button" color="secondary" onClick={onCancel}>
          Cancelar
        </Button>
        <Button type="submit">Confirmar</Button>
      </Form>
    </Container>
  );
};

export default AddParcel;
