import React from 'react';

import { Address } from 'types';

import { Container } from './styles';

interface OwnProps {
  address: Address;
}

const AddressInfos: React.FC<OwnProps> = ({ address }) => {
  return (
    <Container>
      <p>
        <b>Rua: </b>
        {address.street}
      </p>
      <p>
        <b>Bairro: </b>
        {address?.neighborhood}
      </p>
      <p>
        <b>Complemento: </b>
        {address?.complement}
      </p>
      <p>
        <b>Descrição: </b>
        {address?.description}
      </p>
      <p>
        <b>Cidade/Estado: </b>
        {`${address?.city?.name} - ${address.city?.state?.initials}`}
      </p>
    </Container>
  );
};

export default AddressInfos;
