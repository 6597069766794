import styled from 'styled-components';

export const Container = styled.div`
  border-right: 1px solid;
  border-right-color: ${props => props.theme.colors.secondary};
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 70px);
  min-width: 40px;
  max-width: 250px;

  .on {
    display: block;
  }

  &.off {
    max-width: 70px;
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    button {
      margin: 5px;
      padding: 10px;
      background: none;
      border: none;
      display: flex;
      align-items: center;
      border-bottom: 1px solid;
      border-bottom-color: ${props => props.theme.colors.secondary};
      color: ${props => props.theme.colors.main};

      svg {
        color: inherit;
      }

      li {
        display: none;
        font-weight: bold;
      }
    }

    button:hover {
      color: ${props => props.theme.colors.secondary};
      border-bottom-color: ${props => props.theme.colors.main};
    }
  }

  @media (max-width: 720px) {
    &.opened {
      display: block;
      position: absolute;
      background: #fff;
    }

    &.off {
      display: none;
    }
  }
`;
