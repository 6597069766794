import React, { useRef, useState, useEffect } from 'react';
import { OptionTypeBase } from 'react-select';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { isDate, format } from 'date-fns';
import { Participant, BaseAdonisPaginateReponseInterface, User } from 'types';
import { cnpjMask, cpfMask } from 'utils/format';
import * as Yup from 'yup';

import Button from 'components/Button';
import { Input, Select } from 'components/Form';
import api from 'services/api';
import errorHandler from 'services/errorHandler';

import { Container } from './styles';

interface FilterProps {
  onCancel: () => void;
  onSubmit: (filters: string) => void;
}

interface Options extends OptionTypeBase {
  value: string;
  label: string;
}

interface ApiResponseData extends BaseAdonisPaginateReponseInterface {
  data: Participant[];
}

interface ApiReponse {
  data: ApiResponseData;
}

const INITIAL_DATA: Options = {
  value: 'all',
  label: 'Todos',
};

interface ConvertFilters {
  [key: string]: number | string | boolean | Date | object;
}

const convertFiltersToParams: (
  data: ConvertFilters,
  prefix?: string | undefined,
) => string = (data: ConvertFilters, prefix?: string) => {
  return Object.keys(data)
    .map(key => {
      if (typeof data[key] === 'boolean') {
        if (data[key] === false) return null;
      }

      if (typeof data[key] === 'string') {
        if (data[key] === '') return null;
      }

      if (typeof data[key] === 'object') {
        if (isDate(data[key])) {
          return `${prefix ? `${prefix}.` : ''}${key}=${encodeURIComponent(
            format(data[key] as number | Date, 'yyyy-MM-dd'),
          )}`;
        }
        return convertFiltersToParams(data[key] as ConvertFilters, key);
      }

      return `${prefix ? `${prefix}.` : ''}${key}=${encodeURIComponent(
        data[key] as string | number | boolean,
      )}`;
    })
    .join('&');
};

export const UserFilter: React.FC<FilterProps> = ({ onCancel, onSubmit }) => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);
  const [participants, setParticipants] = useState<Options[]>([]);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const { data }: ApiReponse = await api.get('/participants');

        setParticipants(
          data.data.map(participant => {
            if (participant.type === 'fisical') {
              return {
                value: String(participant.id),
                label: `${participant.fisical?.name} (${cpfMask(
                  participant.fisical?.cpf || '',
                )})`,
              };
            }
            return {
              value: String(participant.id),
              label: `${participant.juridical?.social_name} (${cnpjMask(
                participant.juridical?.cnpj || '',
              )})`,
            };
          }) || [],
        );
        setLoading(false);
      } catch (err) {
        errorHandler(err);
      }
    }

    loadData();
  }, []);

  async function handleSubmit(data: SubmitHandler<User>) {
    try {
      // Remove all previous errors
      if (formRef && formRef.current) {
        formRef.current.setErrors({});
      }

      const schema = Yup.object().shape({
        participant_id: Yup.string(),
        username: Yup.string(),
        email: Yup.string(),
      });

      const result = await schema.validate(data, {
        abortEarly: false,
      });

      try {
        const paramsFormatted = convertFiltersToParams(result);

        onSubmit(paramsFormatted);
      } catch (err) {
        errorHandler(err);
      }
    } catch (err) {
      const validationErrors: Record<string, any> = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          if (error.path) {
            validationErrors[error.path] = error.message;
          }
        });

        if (formRef && formRef.current) {
          formRef.current.setErrors(validationErrors);
        }
      }
    }
  }

  function renderUserSection() {
    return (
      <section>
        <div className="wrapper-scope-inputs">
          <Input name="username" label="Usuário" />
          <Input name="email" label="E-mail" />
        </div>
      </section>
    );
  }

  if (loading) {
    return <Container>Carregando...</Container>;
  }

  return (
    <Container>
      <Form
        onSubmit={handleSubmit}
        ref={formRef}
        initialData={{
          type: INITIAL_DATA,
        }}
      >
        <Select
          name="participant_id"
          label="Participante"
          options={participants}
        />

        {renderUserSection()}

        <Button type="button" color="secondary" onClick={onCancel}>
          Cancelar
        </Button>
        <Button type="submit">Confirmar</Button>
      </Form>
    </Container>
  );
};

export default UserFilter;
