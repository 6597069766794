import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

export default reducers => {
  const persistedReducer = persistReducer(
    {
      key: process.env.REACT_APP_PERSIST_STORE_NAME,
      storage,
      whitelist: ['users', 'auth', 'companies', 'vintages'],
    },
    reducers,
  );

  return persistedReducer;
};
