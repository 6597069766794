import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { AxiosResponse } from 'axios';
import filesize from 'filesize';
import { optionsUploadPlots } from 'resources/constants';
import { Plot, FileModel, FileKMZLoad, Options } from 'types';
import {
  getPermissionsArray,
  getRolesArray,
} from 'utils/getRolesAndPermissions';
import { uuid } from 'uuidv4';
import * as Yup from 'yup';

import Button from 'components/Button';
import {
  CreateAndUpdatesInfos,
  HeaderSearchContainer,
} from 'components/DefaultRenders';
import DialogScroll from 'components/DialogScroll';
import { Header } from 'components/Layout';
import PlotAddKMZPDFFileList from 'components/PlotAddKMZPDFFileList';
import PlotAddMPFileList from 'components/PlotAddMPFileList';
import Upload from 'components/Upload';
import api from 'services/api';
import apiConvertFiles from 'services/apiConvertFiles';
import errorHandler from 'services/errorHandler';
import { ApplicationState } from 'store';

import RenderFiles from './components/RenderFiles';
import { Container, ButtonAddFile } from './styles';

interface Params {
  id: string;
}

interface AxiosProgress {
  loaded: number;
  total: number;
}

interface FilePlotImport extends FileModel {
  north?: number;
  south?: number;
  east?: number;
  west?: number;
}

type openOptions =
  | 'croqui'
  | 'mapa-producao'
  | 'pontos-coleta'
  | 'laudo'
  | 'mapa-aplicacao'
  | 'none';

const PlotShow: React.FC = () => {
  const params = useParams<Params>();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState<openOptions>('none');
  const [wordFilter, setWordFilter] = useState('');
  const [filtered, setFiltered] = useState<FilePlotImport[]>([]);
  const [plot, setPlot] = useState<Plot>();
  const [uploadedFile, setUploadedFiles] = useState<FileKMZLoad[]>([]);
  const [vintagesFormatted, setVintagesFormatted] = useState<Options[]>([]);
  const { data: vintages, selectedVintage } = useSelector(
    (state: ApplicationState) => state.vintages,
  );
  const { auth } = useSelector((state: ApplicationState) => state.auth);
  const user = useSelector((state: ApplicationState) => state.auth.auth?.user);

  const actualRoles = useMemo(() => {
    return user?.roles?.map(i => i.slug) || [];
  }, [user]);

  useEffect(() => {
    setVintagesFormatted(
      vintages.map(vintage => ({
        label: vintage.description,
        value: String(vintage.id),
      })),
    );
  }, [vintages]);

  const loadPlot = useCallback(async (id: string) => {
    try {
      setLoading(true);
      const { data } = await api.get<Plot>(`plots/${id}`);

      setPlot(data);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadPlot(params.id);
  }, [params.id, loadPlot]);

  const returnLabelOption = useCallback((key: string) => {
    switch (key) {
      case 'croqui':
        return 'Croqui';
      case 'mapa-producao':
        return 'Mapas de produção';
      case 'pontos-coleta':
        return 'Pontos de Coleta';
      case 'laudo':
        return 'Laudos';
      case 'mapa-aplicacao':
        return 'Mapas de Aplicação';
      default:
        return '';
    }
  }, []);

  const returnClassName = useCallback((classe: string, type: string) => {
    switch (classe) {
      case 'croqui':
        switch (type) {
          case 'application/vnd.google-earth.kmz':
            return 'croqui';
          case 'application/pdf':
            return 'croquipdf';
          default:
            return '';
        }
      case 'mapa-producao':
        switch (type) {
          case 'image/png':
            return 'mapa-producao';
          case 'application/pdf':
            return 'mppdf';
          default:
            return '';
        }
      case 'pontos-coleta':
        switch (type) {
          case 'application/vnd.google-earth.kmz':
            return 'pontos-coleta';
          case 'application/pdf':
            return 'pcspdf';
          default:
            return '';
        }
      case 'laudo':
        return 'laudo';
      case 'mapa-aplicacao':
        switch (type) {
          case 'application/vnd.google-earth.kmz':
            return 'mapa-aplicacao';
          case 'application/pdf':
            return 'mapdf';
          default:
            return '';
        }
      default:
        return '';
    }
  }, []);

  const updateFile = useCallback(
    (id: string, data: any) => {
      const index = uploadedFile.findIndex(file => String(file.id) === id);

      if (index >= 0) {
        const prevState = uploadedFile;
        prevState[index] = { ...prevState[index], ...data };
        setUploadedFiles([...prevState]);
      }
    },
    [uploadedFile],
  );

  const uploadNormalFile = useCallback(
    async (file: FileKMZLoad) => {
      const { classe, vintage_id, size } = file;
      const data = new FormData();

      data.append('file', file.file, file.name);

      api
        .post(`plots/${params.id}/files`, data, {
          params: {
            classe,
            vintage_id,
            size,
          },
          onUploadProgress: (e: AxiosProgress) => {
            // eslint-disable-next-line radix
            const progress = parseInt(
              String(Math.round((e.loaded * 100) / e.total)),
            );

            updateFile(file.id, { progress });
          },
        })
        .then(async (response: AxiosResponse<FileModel>) => {
          updateFile(file.id, {
            uploaded: true,
            id: String(response.data.id),
            url: response.data.url,
            error: false,
          });
        })
        .catch(() => {
          updateFile(file.id, {
            error: true,
          });
        });
    },
    [params.id, updateFile],
  );

  const handleOnCloseFilter = useCallback(() => {
    setOpen('none');
  }, []);

  const handleUploadFile = useCallback(
    (files: File[], classe: string) => {
      const filesUploaded = files.map(file => ({
        file,
        id: uuid(),
        vintage_id: String(selectedVintage?.id),
        vintage: selectedVintage,
        name: file.name,
        size: file.size,
        readableSize: filesize(file.size),
        preview: URL.createObjectURL(file),
        progress: 0,
        uploaded: false,
        error: false,
        classe: returnClassName(classe, file.type),
      }));

      setUploadedFiles(filesUploaded);
    },
    [returnClassName, selectedVintage],
  );

  const uploadCroqui = useCallback(
    async (file: FileKMZLoad) => {
      try {
        const schema = Yup.object().shape({
          vintage_id: Yup.string().required('A safra é obrigatória'),
        });

        const dataValidate = await schema.validate(
          {
            vintage_id: file.vintage_id,
          },
          {
            abortEarly: false,
          },
        );

        updateFile(file.id, {
          errors: {},
        });

        const data = new FormData();

        data.append('file', file.file, file.name);

        apiConvertFiles
          .post('croqui', data, {
            params: {
              plot_id: plot?.id,
              vintage_id: dataValidate.vintage_id,
            },
            onUploadProgress: (e: AxiosProgress) => {
              // eslint-disable-next-line radix
              const progress = parseInt(
                String(Math.round((e.loaded * 100) / e.total)),
              );

              updateFile(file.id, { progress });
            },
          })
          .then(async (response: AxiosResponse<FileModel>) => {
            updateFile(file.id, {
              uploaded: true,
              url: response.data.url,
              error: false,
            });
          })
          .catch(() => {
            updateFile(file.id, {
              error: true,
            });
          });
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          updateFile(file.id, {
            errors: validationErrors,
          });
        }
      }
    },
    [plot, updateFile],
  );

  const uploadMapaProducao = useCallback(
    async (file: FileKMZLoad) => {
      try {
        const schema = Yup.object().shape({
          vintage_id: Yup.string().required('A safra é obrigatória'),
          north: Yup.string()
            .matches(/(\d{2})((?:\.\d{8,15})?)/, {
              excludeEmptyString: true,
              message:
                'Coordenadas no formato inválido! Formato requerido XX.XXXXXXXX',
            })
            .required('O campo é obrigatório'),
          west: Yup.string()
            .matches(/(\d{2})((?:\.\d{8,15})?)/, {
              excludeEmptyString: true,
              message:
                'Coordenadas no formato inválido! Formato requerido XX.XXXXXXXXXX',
            })
            .required('O campo é obrigatório'),
          south: Yup.string()
            .matches(/(\d{2})((?:\.\d{8,15})?)/, {
              excludeEmptyString: true,
              message:
                'Coordenadas no formato inválido! Formato requerido XX.XXXXXXXXXX',
            })
            .required('O campo é obrigatório'),
          east: Yup.string()
            .matches(/(\d{2})((?:\.\d{8,15})?)/, {
              excludeEmptyString: true,
              message:
                'Coordenadas no formato inválido! Formato requerido XX.XXXXXXXXXX',
            })
            .required('O campo é obrigatório'),
        });

        const dataValidate = await schema.validate(
          {
            vintage_id: file.vintage_id,
            north: file.north,
            west: file.west,
            south: file.south,
            east: file.east,
          },
          {
            abortEarly: false,
          },
        );

        updateFile(file.id, {
          errors: {},
        });

        const data = new FormData();

        data.append('file', file.file, file.name);

        apiConvertFiles
          .post('mp', data, {
            params: {
              plot_id: plot?.id,
              vintage_id: dataValidate.vintage_id,
              north: Number(dataValidate.north),
              west: Number(dataValidate.west),
              east: Number(dataValidate.east),
              south: Number(dataValidate.south),
            },
            onUploadProgress: (e: AxiosProgress) => {
              // eslint-disable-next-line radix
              const progress = parseInt(
                String(Math.round((e.loaded * 100) / e.total)),
              );

              updateFile(file.id, { progress });
            },
          })
          .then(async (response: AxiosResponse<FileModel>) => {
            updateFile(file.id, {
              uploaded: true,
              id: String(response.data.id),
              url: response.data.url,
              error: false,
            });
          })
          .catch(() => {
            updateFile(file.id, {
              error: true,
            });
          });
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          updateFile(file.id, {
            errors: validationErrors,
          });
        }
      }
    },
    [plot, updateFile],
  );

  const uploadPontosColeta = useCallback(
    async (file: FileKMZLoad) => {
      try {
        const schema = Yup.object().shape({
          vintage_id: Yup.string().required('A safra é obrigatória'),
        });

        const dataValidate = await schema.validate(
          {
            vintage_id: file.vintage_id,
          },
          {
            abortEarly: false,
          },
        );

        updateFile(file.id, {
          errors: {},
        });

        const data = new FormData();

        data.append('file', file.file, file.name);

        apiConvertFiles
          .post('points', data, {
            params: {
              plot_id: plot?.id,
              vintage_id: dataValidate.vintage_id,
            },
            onUploadProgress: (e: AxiosProgress) => {
              // eslint-disable-next-line radix
              const progress = parseInt(
                String(Math.round((e.loaded * 100) / e.total)),
              );

              updateFile(file.id, { progress });
            },
          })
          .then(async (response: AxiosResponse<FileModel>) => {
            updateFile(file.id, {
              uploaded: true,
              url: response.data.url,
              error: false,
            });

            loadPlot(params.id);
          })
          .catch(() => {
            updateFile(file.id, {
              error: true,
            });
          });
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          updateFile(file.id, {
            errors: validationErrors,
          });
        }
      }
    },
    [loadPlot, params.id, plot, updateFile],
  );

  const uploadLaudo = useCallback(
    async (file: FileKMZLoad) => {
      const { vintage_id } = file;
      try {
        const schema = Yup.object().shape({
          vintage_id: Yup.string().required('A safra é obrigatória'),
        });

        const dataValidate = await schema.validate(
          {
            vintage_id,
          },
          {
            abortEarly: false,
          },
        );

        updateFile(file.id, {
          errors: {},
        });

        const data = new FormData();

        data.append('file', file.file, file.name);

        apiConvertFiles
          .post('laudo', data, {
            params: {
              plot_id: plot?.id,
              vintage_id: dataValidate.vintage_id,
            },
            onUploadProgress: (e: AxiosProgress) => {
              // eslint-disable-next-line radix
              const progress = parseInt(
                String(Math.round((e.loaded * 100) / e.total)),
              );

              updateFile(file.id, { progress });
            },
          })
          .then(async (response: AxiosResponse<FileModel>) => {
            updateFile(file.id, {
              uploaded: true,
              url: response.data.url,
              error: false,
            });

            loadPlot(params.id);
          })
          .catch(() => {
            updateFile(file.id, {
              error: true,
            });
          });
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          updateFile(file.id, {
            errors: validationErrors,
          });
        }
      }
    },
    [loadPlot, params.id, plot, updateFile],
  );

  const uploadMa = useCallback(
    async (file: FileKMZLoad) => {
      try {
        const schema = Yup.object().shape({
          vintage_id: Yup.string().required('A safra é obrigatória'),
        });

        const dataValidate = await schema.validate(
          {
            vintage_id: file.vintage_id,
          },
          {
            abortEarly: false,
          },
        );

        updateFile(file.id, {
          errors: {},
        });

        const data = new FormData();

        data.append('file', file.file, file.name);

        apiConvertFiles
          .post('ma/farm', data, {
            params: {
              farm_id: plot?.farm_id,
              vintage_id: dataValidate.vintage_id,
            },
            onUploadProgress: (e: AxiosProgress) => {
              // eslint-disable-next-line radix
              const progress = parseInt(
                String(Math.round((e.loaded * 100) / e.total)),
              );

              updateFile(file.id, { progress });
            },
          })
          .then(async (response: AxiosResponse<FileModel>) => {
            updateFile(file.id, {
              uploaded: true,
              url: response.data.url,
              error: false,
            });

            loadPlot(params.id);
          })
          .catch(() => {
            updateFile(file.id, {
              error: true,
            });
          });
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          updateFile(file.id, {
            errors: validationErrors,
          });
        }
      }
    },
    [loadPlot, params.id, plot, updateFile],
  );

  const handleOnFilter = useCallback(
    (word: string) => {
      setWordFilter(word);
      if (!word || word === '') setFiltered([]);

      if (plot?.files) {
        const regex = new RegExp(`${word}`, 'gmi');

        setFiltered(
          plot?.files?.filter(
            file =>
              file.name.match(regex) !== null ||
              file.classe.match(regex) !== null ||
              file.vintage.description.match(regex) !== null,
          ),
        );
      }
    },
    [plot, setFiltered],
  );

  const handleFileProcess = useCallback(
    (file: FileKMZLoad) => {
      switch (file.classe) {
        case 'croqui':
          uploadCroqui(file);
          break;
        case 'mapa-producao':
          uploadMapaProducao(file);
          break;
        case 'pontos-coleta':
          uploadPontosColeta(file);
          break;
        case 'laudo':
          uploadLaudo(file);
          break;
        case 'mapa-aplicacao':
          uploadMa(file);
          break;
        default:
          uploadNormalFile(file);
      }
    },
    [
      uploadCroqui,
      uploadPontosColeta,
      uploadLaudo,
      uploadMa,
      uploadNormalFile,
      uploadMapaProducao,
    ],
  );

  const handleOnListProcess = useCallback(() => {
    uploadedFile.filter(item => !item.uploaded).forEach(handleFileProcess);
  }, [handleFileProcess, uploadedFile]);

  const handleOnDeleteFiles = useCallback(
    async (id: number) => {
      try {
        await api.delete(`/plots/${params.id}/files/${id}`);

        if (plot) {
          setPlot({
            ...plot,
            files: plot?.files.filter(file => file.id !== id),
          });
        }
      } catch (err) {
        errorHandler(err);
      }
    },
    [plot, params.id],
  );

  const permissions = useMemo(() => {
    return getPermissionsArray(auth?.user.permissions);
  }, [auth]);

  const roles = useMemo(() => {
    return getRolesArray(auth?.user.roles);
  }, [auth]);

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <Container>
      <Header>
        <h1>{`Talhão ${plot?.name}`}</h1>
        {plot && <CreateAndUpdatesInfos model={plot} />}
      </Header>
      {actualRoles.findIndex(role => ['production', 'admin'].includes(role)) >=
        0 && (
        <section>
          {permissions.includes('manager_croqui') || roles.includes('admin') ? (
            <ButtonAddFile onClick={() => setOpen('croqui')}>
              Adicionar Croqui
            </ButtonAddFile>
          ) : (
            <></>
          )}
          {permissions.includes('manager_mapa_producao') ||
          roles.includes('admin') ? (
            <ButtonAddFile onClick={() => setOpen('mapa-producao')}>
              Adicionar Mapa de Produção
            </ButtonAddFile>
          ) : (
            <></>
          )}
          {permissions.includes('manager_pontos_coleta') ||
          roles.includes('admin') ? (
            <ButtonAddFile onClick={() => setOpen('pontos-coleta')}>
              Adicionar Pontos de Coleta
            </ButtonAddFile>
          ) : (
            <></>
          )}
          {permissions.includes('manager_laudo') || roles.includes('admin') ? (
            <ButtonAddFile onClick={() => setOpen('laudo')}>
              Adicionar Laudo
            </ButtonAddFile>
          ) : (
            <></>
          )}
          {permissions.includes('manager_mapa_aplicacao') ||
          roles.includes('admin') ? (
            <ButtonAddFile onClick={() => setOpen('mapa-aplicacao')}>
              Adicionar Mapa de Aplicação
            </ButtonAddFile>
          ) : (
            <></>
          )}
        </section>
      )}
      <HeaderSearchContainer
        onChangeText={handleOnFilter}
        textFieldLabel="Buscar arquivo"
      />
      {plot?.files && (
        <RenderFiles
          files={wordFilter ? filtered : plot?.files}
          onDeleteFile={handleOnDeleteFiles}
        />
      )}

      <DialogScroll
        open={!['none', 'laudo', 'mapa-producao'].includes(open)}
        fullWidth
        maxWidth={false}
        dialogTitle={`Inclusão de ${returnLabelOption(open)}`}
        dialogContentText="Arraste os arquivos para a caixa abaixo para incluí-los mais rapidamente"
        onClose={handleOnCloseFilter}
        onClickActionCancelButton={handleOnCloseFilter}
        dialogActions={<div />}
      >
        <section>
          <Upload
            onUpload={files => handleUploadFile(files, open)}
            accept={optionsUploadPlots[open]}
          />
          {!!uploadedFile.length && (
            <>
              <PlotAddKMZPDFFileList
                files={uploadedFile}
                onUpdadeFile={updateFile}
                vintages={vintagesFormatted}
              />
              <Button onClick={handleOnCloseFilter} color="secondary">
                Cancelar
              </Button>
              {uploadedFile.filter(item => !item.uploaded).length > 0 && (
                <Button
                  onClick={handleOnListProcess}
                  style={{ marginLeft: 10 }}
                >
                  Enviar Arquivos
                </Button>
              )}
            </>
          )}
        </section>
      </DialogScroll>
      <DialogScroll
        fullWidth
        maxWidth={false}
        open={open === 'mapa-producao'}
        dialogTitle={`Inclusão de ${returnLabelOption(open)}`}
        dialogContentText="Arraste os arquivos para a caixa abaixo para incluí-los mais rapidamente"
        onClose={handleOnCloseFilter}
        onClickActionCancelButton={handleOnCloseFilter}
        dialogActions={<div />}
      >
        <section>
          <Upload
            onUpload={files => handleUploadFile(files, open)}
            accept={optionsUploadPlots[open]}
          />
          {!!uploadedFile.length && (
            <>
              <PlotAddMPFileList
                files={uploadedFile}
                onUpdadeFile={updateFile}
                vintages={vintagesFormatted}
              />
              <Button onClick={handleOnCloseFilter} color="secondary">
                Cancelar
              </Button>
              {uploadedFile.filter(item => !item.uploaded).length > 0 && (
                <Button
                  onClick={handleOnListProcess}
                  style={{ marginLeft: 10 }}
                >
                  Enviar Arquivos
                </Button>
              )}
            </>
          )}
        </section>
      </DialogScroll>
      <DialogScroll
        open={open === 'laudo'}
        fullWidth
        maxWidth={false}
        dialogTitle={`Inclusão de ${returnLabelOption(open)}`}
        dialogContentText="Arraste os arquivos para a caixa abaixo para incluí-los mais rapidamente"
        onClose={handleOnCloseFilter}
        onClickActionCancelButton={handleOnCloseFilter}
        dialogActions={<div />}
      >
        <section>
          <strong>O laudo precisa ser um arquivo csv no formato abaixo:</strong>
          <strong>
            talhao,grid,deepness,production_level,Ca,Mg,P,K,H,Al,Argila,Silte,Areia
          </strong>

          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Campo</th>
                <th>Tipo de valores</th>
                <th>Unidade de Medida</th>
                <th>Observação</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>talhao</td>
                <td>texto</td>
                <td />
                <td>Nome do talhão</td>
              </tr>
              <tr>
                <td>grid</td>
                <td>texto</td>
                <td />
                <td>Nome do grid</td>
              </tr>
              <tr>
                <td>deepness</td>
                <td>texto</td>
                <td />
                <td>Profundidade da amostra</td>
              </tr>
              <tr>
                <td>production_level</td>
                <td>
                  {`none = 'nenhuma', very low = 'muito baixo', low = 'baixo',
                  medium = 'médio', good = 'bom', very good = 'Muito bom',
                  interference = 'interferência', not sended = 'Não informado'`}
                </td>
                <td />
                <td>Nível produtivo identificado no mapa de produção</td>
              </tr>
              <tr>
                <td>Ca,Mg,P,K,H,Al</td>
                <td>número no formato americano 0.00</td>
                <td>cmolc/dm³</td>
                <td>Elementos básicos</td>
              </tr>
              <tr>
                <td>Argila,Silte,Areia</td>
                <td>número no formato americano 0.00</td>
                <td>g/Kg</td>
                <td>Elementos básicos</td>
              </tr>
              <tr>
                <td>Outros complementos</td>
                <td>número no formato americano 0.00</td>
                <td />
                <td>Item complementares</td>
              </tr>
            </tbody>
          </table>

          <Upload
            onUpload={files => handleUploadFile(files, open)}
            accept={optionsUploadPlots[open]}
          />
          {!!uploadedFile.length && (
            <>
              <PlotAddKMZPDFFileList
                files={uploadedFile}
                onUpdadeFile={updateFile}
                vintages={vintagesFormatted}
              />
              <Button onClick={handleOnCloseFilter} color="secondary">
                Cancelar
              </Button>
              {uploadedFile.filter(item => !item.uploaded).length > 0 && (
                <Button
                  onClick={handleOnListProcess}
                  style={{ marginLeft: 10 }}
                >
                  Enviar Arquivos
                </Button>
              )}
            </>
          )}
        </section>
      </DialogScroll>
    </Container>
  );
};

export default PlotShow;
