import { Company } from 'types';

/**
 *
 * Action types
 */

export enum CompaniesTypes {
  LOAD_REQUEST = '@company/LOAD_REQUEST',
  LOAD_SUCCESS = '@company/LOAD_SUCCESS',
  LOAD_FAILURE = '@company/LOAD_FAILURE',
  SET_SELECTED_COMPANY = '@company/SET_SELECTED_COMPANY',
}

/**
 * State type
 */

export interface CompanyState {
  data: Company[];
  readonly selectedCompany?: Company;
  readonly loading: boolean;
  readonly error: boolean;
}
