import React from 'react';

import { Plot } from 'types';

import Item from './Item';
import { Container } from './styles';

interface OwnProps {
  plots: Plot[];
  letDetail?: boolean;
}

const RenderPlot: React.FC<OwnProps> = ({ plots, letDetail = false }) => {
  return (
    <Container>
      {plots.map(plot => (
        <Item plot={plot} key={plot.id} letDetail={letDetail} />
      ))}
    </Container>
  );
};

export default RenderPlot;
