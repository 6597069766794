/* eslint-disable react/jsx-one-expression-per-line */
import React, { useMemo } from 'react';

import {
  Table,
  TableCell,
  TableHeader,
  DataTableCell,
  TableBody,
} from '@david.kucsai/react-pdf-table';
import { Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { Order } from 'types';
import { cnpjMask, formatDateWithoutTimestamps, cpfMask } from 'utils/format';
import {
  formatCurrencyWithExtension,
  formatNumberWithExtension,
  formatCurrency,
} from 'utils/formatCurrency';
import {
  getNameFromParticipant,
  getIdentificationFromParticipant,
} from 'utils/renderNameParticipant';

import { Container } from './styles';

const styles = StyleSheet.create({
  companySignature: {
    maxWidth: 400,
    maxHeight: 175,
    minHeight: 100,
  },
  page: {
    // flexDirection: 'row',
    paddingTop: 50,
    backgroundColor: '#fff',
    paddingLeft: 40,
    paddingRight: 40,
  },
  section: {
    marginBottom: 10,
    // flexGrow: 1
  },
  signatureWitnessContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    fontSize: 12,
    textAlign: 'center',
  },
  signatureWitnessSection: {
    flex: 1,
    margin: 10,
  },
  title: {
    fontSize: 14,
    alignSelf: 'center',
    fontWeight: 'bold',
    marginBottom: 20,
  },
  paragrafy: {
    textAlign: 'justify',
    textDecoration: 'none',
    fontSize: 12,
  },
  spotlight: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    textTransform: 'uppercase',
  },
  tableText: {
    padding: 5,
    textAlign: 'left',
  },
  tableNumber: {
    padding: 5,
    textAlign: 'center',
  },
  signatureMainSection: {
    fontSize: 12,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
    marginBottom: 20,
  },
  spaceToSign: {
    height: 50,
  },
  dataAlign: {
    alignSelf: 'flex-end',
    fontSize: 12,
  },
});

interface OwnProps {
  order: Order;
}

const PartnerContract: React.FC<OwnProps> = ({ order }) => {
  const billsFormated = useMemo(() => {
    return order.bills.map((bill, index) => {
      return {
        parcel_order: index + 1,
        expired_date: formatDateWithoutTimestamps(bill.expired_date),
        value: formatCurrency(bill.value),
        ownCode: bill.own_code,
      };
    });
  }, [order]);

  const SignatureContratadaSection = () => {
    if (!order.company.signature?.url) return <View style={styles.section} />;
    return (
      <View style={styles.signatureMainSection} wrap={false}>
        <Image
          style={styles.companySignature}
          cache={false}
          // src="https://cors-anywhere.herokuapp.com/https://i.imgur.com/xdZxoTr.png"
          src={{
            uri: order.company.signature.url,
            method: 'GET',
            headers: {
              'Cache-Control': 'no-cache',
              'Access-Control-Allow-Origin': '*',
            },
            body: '',
          }}
        />
        <Text style={styles.spotlight}>{order.company.social_name}</Text>
        <Text style={styles.spotlight}>CONTRATADA</Text>
      </View>
    );
  };

  const SignatureContratanteSection = () => {
    return (
      <View style={styles.signatureMainSection} wrap={false}>
        <View style={styles.spaceToSign} />
        <Text style={styles.spotlight}>
          {getNameFromParticipant(order.client)}
        </Text>
        <Text style={styles.spotlight}>CONTRATANTE</Text>
      </View>
    );
  };

  const WitnessSection = () => {
    if (order.company.witness) {
      return (
        <View style={styles.signatureWitnessContainer} wrap={false}>
          {order.company.witness.map(witness => {
            return (
              <View
                style={styles.signatureWitnessSection}
                wrap={false}
                key={witness.id}
              >
                {witness.file?.url && (
                  <Image
                    style={styles.companySignature}
                    cache={false}
                    src={{
                      uri: witness.file.url,
                      method: 'GET',
                      headers: {
                        'Cache-Control': 'no-cache',
                        'Access-Control-Allow-Origin': '*',
                      },
                      body: '',
                    }}
                  />
                )}
                <Text style={styles.spotlight}>{witness.name}</Text>
                <Text style={styles.spotlight}>{cpfMask(witness.cpf)}</Text>
              </View>
            );
          })}
        </View>
      );
    }

    return <View />;
  };

  const renderDaySignature = () => {
    const actualDate = new Date();

    return (
      <View style={styles.section}>
        <Text style={styles.dataAlign}>
          {`Rondonópolis, ${actualDate.toLocaleDateString('pt-br', {
            day: '2-digit',
          })} de ${actualDate.toLocaleDateString('pt-br', {
            month: 'long',
          })} de
          ${actualDate.toLocaleDateString('pt-br', {
            year: 'numeric',
          })}.`}
        </Text>
      </View>
    );
  };

  return (
    <Container>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>
            CONTRATO DE PRESTAÇÃO DE PRODUTOS E SERVIÇOS
          </Text>
          <Text style={styles.paragrafy}>
            {`Pelo presente instrumento particular e melhor forma de direito, `}
            <Text
              style={styles.spotlight}
            >{` ${order.company.social_name}`}</Text>
            {`, empresa jurídica, inscrita no CNPJ sob n.° ${cnpjMask(
              order.company.cnpj,
            )}, estabelecido na`}
            {` ${order.company.address?.street} – ${order.company.address?.neighborhood} `}
            {` ${order.company.address?.city?.name} - ${order.company.address?.city?.state?.initials}, neste ato denominado `}
            <Text style={styles.spotlight}>CONTRATADA</Text>
            {' e, de outro lado'}
            <Text style={styles.spotlight}>{` ${getNameFromParticipant(
              order.seller,
            )}`}</Text>
            {`, pessoa ${
              order.seller.type === 'fisical' ? 'física' : 'jurídica'
            } de direito privado, inscrita no CPF/CNPJ sob n.º ${getIdentificationFromParticipant(
              order.seller,
            )}, localizada à ${
              order.seller.address.length >= 1 && order.seller.address[0].street
            }`}
            {`, ${
              order.seller.address.length >= 1 &&
              order.seller.address[0].neighborhood
            }, na cidade ${order.seller.address[0].city?.name} - ${
              order.seller.address[0].city?.state?.name
            }, aqui denominado simplesmente como `}
            <Text style={styles.spotlight}>CONTRATANTE</Text>
            {`, ajustam o presente Contrato de Prestação de Serviços, que
            se regerá pelas seguintes cláusulas e condições:`}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            <Text style={styles.spotlight}>CLÁUSULA PRIMEIRA</Text>
            {`: A `}
            <Text style={styles.spotlight}>CONTRATADA</Text>

            {` tem como atividade e fim, entre outras a prestação de
            confecção de materiais ligados a Agricultura de Precisão.`}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            <Text style={styles.spotlight}>PARÁGRAFO PRIMEIRO</Text>
            {`: Nessa condição, a `}
            <Text style={styles.spotlight}>CONTRATADA</Text>
            {` confeccionará materiais solicitados pela `}
            <Text style={styles.spotlight}>CONTRATANTE</Text>
            {` na(s) propriedades ${order.farms
              .map(farm => farm.farm?.name)
              .join(',')} de propriedade de ${getNameFromParticipant(
              order.client,
            )}, cliente da `}
            <Text style={styles.spotlight}>CONTRATANTE</Text>.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            <Text style={styles.spotlight}>PARÁGRAFO SEGUNDO</Text>: Os
            materiais contratados estão descritos abaixo:
            {`
            - Croqui da àrea;
            - Mapa de Produção;
            - Pontos de Coleta de Solo;
            - Recomendações Nutricionais, Solo e Folha;
            - Mapas de Aplicações de Fertilizantes e Corretivos;
            - Mapa de Nematóides, se necessário;
            - Mapa de Compactação, se necessário;
           `}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            <Text style={styles.spotlight}>PARÁGRAFO TERCEIRO</Text>
            {`: Fica claro e expressamente consignado que o cliente da `}
            <Text style={styles.spotlight}>CONTRATANTE</Text>
            {`, ${getNameFromParticipant(
              order.client,
            )}, beneficiário dos materiais gerados pela `}
            <Text style={styles.spotlight}>CONTRATADA</Text>
            {` têm ciência de que o equilíbrio da produtividade nas áreas objeto de acompanhamento pelo “MONITORAMENTO EM AGRICULTURA DE PRECISÃO” dependem, dos fatores climáticos que atingem as áreas contratadas, da correta aplicação dos insumos e adubos recomendados, das sementes utilizadas no plantio, do controle eficaz de pragas, doenças e ervas daninhas, dentre outros, fatores estes que deverão ser corretamente respeitados pelo cliente da `}
            <Text style={styles.spotlight}>CONTRATANTE</Text>
            {`, para que se obtenha um bom desempenho nas áreas contratadas. Fica de inteira responsabilidade da `}
            <Text style={styles.spotlight}>CONTRATANTE</Text>
            {`, os custos relativos a analises laboratoriais de quaisquer amostras indicadas pela `}
            <Text style={styles.spotlight}>CONTRATADA</Text>
            {`, não incidindo sobre a  `}{' '}
            <Text style={styles.spotlight}>CONTRATADA</Text>
            {` qualquer indenização, ou valores de execuções do cliente sobre a `}
            <Text style={styles.spotlight}>CONTRATANTE</Text>.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            <Text style={styles.spotlight}>CLÁUSULA SEGUNDA</Text>
            {`: Pelos materiais solicitados neste instrumento, referidas na cláusula anterior, a `}
            <Text style={styles.spotlight}>CONTRATANTE</Text>
            {` pagará a `}
            <Text style={styles.spotlight}>CONTRATADA</Text>
            {`, o equivalente, em moeda corrente nacional, a quantia de ${formatCurrencyWithExtension(
              order.amount,
            )} em ${formatNumberWithExtension(order.bills.length)} parcela(s).`}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            <Text style={styles.spotlight}>PARÁGRAFO PRIMEIRO</Text>
            {`: Refere-se ao preço do trabalho, em equivalência por hectare contratado, tendo em vista que o “MONITORAMENTO EM AGRICULTURA DE PRECISÃO” compreende produtos e serviços, ${
              order.years > 1 &&
              `por um período de ${formatNumberWithExtension(order.years)} anos`
            } a serem realizados e avaliados por “hectare”, ficando desde já consignado que a `}
            <Text style={styles.spotlight}>CONTRATADA</Text>
            {` acompanhará, para o cliente da `}
            <Text style={styles.spotlight}>CONTRATANTE</Text>
            {`, ${getNameFromParticipant(
              order.client,
            )}, ${formatNumberWithExtension(
              order.hectares,
            )}, as quais serão cultivadas lavouras temporárias na(s) `}
            {` ${order.farms.map(farm => farm.farm?.name)}.`}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            <Text style={styles.spotlight}>CLÁUSULA TERCEIRA:</Text>
            {` VENCIMENTO DA OBRIGAÇÃO: O valor ou a quantidade de produto devido pelo `}
            <Text style={styles.spotlight}>CONTRATANTE</Text> a
            <Text style={styles.spotlight}>CONTRATADA</Text>
            {`, pela geração dos materiais ora contratadas, serão distribuídos e entregues a `}
            <Text style={styles.spotlight}>CONTRATADA</Text>
            {` da forma abaixo:`}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            <Text style={styles.spotlight}>PARÁGRAFO PRIMEIRO</Text>
            {`: O valor devido deverá ser pago em ${formatNumberWithExtension(
              order.bills.length,
            )} parcela(s), sendo:`}
          </Text>
        </View>
        <View style={styles.section} wrap={false}>
          <Table data={billsFormated}>
            <TableHeader>
              <TableCell style={styles.tableText}>Parcela</TableCell>
              <TableCell style={styles.tableText}>Data de Vencimento</TableCell>
              <TableCell style={styles.tableText}>Valor</TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell
                style={styles.tableText}
                getContent={r => r.ownCode || r.parcel_order}
              />
              <DataTableCell
                style={styles.tableText}
                getContent={r => r.expired_date}
              />
              <DataTableCell
                style={styles.tableNumber}
                getContent={r => r.value}
              />
            </TableBody>
          </Table>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            <Text style={styles.spotlight}>PARÁGRAFO SEGUNDO</Text>
            <Text style={styles.spotlight}>A CONTRATADA</Text>
            {`, desde já, autoriza que o pagamento seja efetuado mediante depósito na Conta Corrente de titularidade da APMax Tecnologia em Agropecuária Rural e Urbana Ltda, inscrita no CNPJ nº 08.800.477/0001-63, Banco do Brasil, Ag. 3283-2, C/C nº 13.465-1, bem como através de pagamento de boleto bancário expedido pela `}
            <Text style={styles.spotlight}>CONTRATADA</Text>
            {`, em nome da `}
            <Text style={styles.spotlight}>CONTRATANTE</Text>
            {`, cujo comprovante de depósito ou pagamento do boleto será valido como recibo de quitação, devidamente autorizado pela `}
            <Text style={styles.spotlight}>CONTRATANTE.</Text>
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            <Text style={styles.spotlight}>CLÁUSULA QUARTA</Text>: Quanto a
            atrasos e quebra contratual, fica estipulando que:
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            <Text style={styles.spotlight}>PARÁGRAFO PRIMEIRO</Text>: Ocorrendo
            atraso no pagamento descrito, será cobrada uma multa de 2% (dois por
            cento) sobre o valor da parcela em atraso, acrescida de correção
            monetária. Bem como a antecipação imediata do vencimento das
            parcelas pendentes.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            <Text style={styles.spotlight}>PARÁGRAFO SEGUNDO</Text>: Ocorrendo o
            descumprimento contratual, a parte culpada pagará a parte inocente,
            uma multa de 10% (dez por cento) do valor deste contrato.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragrafy}>
            <Text style={styles.spotlight}>CLÁUSULA QUINTA</Text>: Para dirimir
            questões oriundas da execução do presente contrato, fica eleito o
            foro da Comarca de Rondonópolis, Estado de Mato Grosso, com renúncia
            expressa a qualquer outro, por mais privilegiado que seja.
          </Text>
          <Text style={styles.paragrafy}>
            {` E por estarem assim justas e contratadas, as partes assinam o presente contrato, em duas vias de igual teor e efeito, e na presença das testemunhas abaixo.`}
          </Text>
        </View>
        {renderDaySignature()}
        <SignatureContratadaSection />
        <SignatureContratanteSection />
        <Text style={styles.paragrafy}>Testemunhas</Text>
        <WitnessSection />
      </Page>
    </Container>
  );
};

export default PartnerContract;
