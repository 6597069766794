import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { Farm, Participant } from 'types';
import { getNameFromParticipant } from 'utils/renderNameParticipant';

import { HeaderSearchContainer } from 'components/DefaultRenders';
import { Header } from 'components/Layout';
import api from 'services/api';

import RenderFarm from './components/RenderFarm';
import { Container } from './styles';

interface Params {
  id: string;
}

const FarmList: React.FC = () => {
  const params = useParams<Params>();
  const history = useHistory();
  const [wordFilter, setWordFilter] = useState('');
  const [filtered, setFiltered] = useState<Farm[]>([]);
  const [client, setClient] = useState<Participant>();

  useEffect(() => {
    async function loadClient() {
      const { data } = await api.get<Participant>(`client/${params.id}`);
      setClient(data);
    }
    loadClient();
  }, [params.id]);

  const handleOnFilter = useCallback(
    (word: string) => {
      setWordFilter(word);
      if (!word || word === '') setFiltered([]);

      if (client) {
        const regex = new RegExp(`${word}`, 'gmi');

        setFiltered(
          client.farms.filter(farm => farm.name.match(regex) !== null),
        );
      }
    },
    [client],
  );

  const handleOnClickFarmItem = useCallback(
    (id: number) => history.push(`/farm/${id.toString()}/plots`),
    [history],
  );

  return (
    <Container>
      <Header>
        <h1>{`Fazendas do cliente: ${getNameFromParticipant(client)}`}</h1>
      </Header>

      <HeaderSearchContainer
        onChangeText={handleOnFilter}
        textFieldLabel="Buscar Fazendas"
      />

      {client?.farms && (
        <RenderFarm
          farms={wordFilter ? filtered : client?.farms}
          onItemClick={handleOnClickFarmItem}
        />
      )}
    </Container>
  );
};

export default FarmList;
