import React, { KeyboardEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { CoursesInterface, BaseAdonisPaginateReponseInterface } from 'types';

import api from 'services/api';
import errorHandler from 'services/errorHandler';

import { Container } from './styles';

export interface ApiResponseData extends BaseAdonisPaginateReponseInterface {
  data: CoursesInterface[];
}

export interface ApiReponse {
  data: ApiResponseData;
}

export function Courses() {
  const [courses, setCourses] = useState<CoursesInterface[]>([]);
  const history = useHistory();

  async function loadCourses() {
    try {
      const { data }: ApiReponse = await api.get('/courses');

      setCourses(data.data);
    } catch (err) {
      errorHandler({ err });
    }
  }

  useEffect(() => {
    loadCourses();
  }, []);

  function handleOnKeyPress(
    event: KeyboardEvent<HTMLDivElement>,
    path: string,
  ) {
    if (event.key === 'Enter') {
      history.push(`/courses/${path}`);
    }
  }

  return (
    <Container>
      {courses.map((course, index) => (
        <div
          className="wrapper-course-item"
          key={String(course.id)}
          onClick={() => history.push(`/courses/${course.slug}`)}
          onKeyPress={event => handleOnKeyPress(event, String(course.id))}
          role="button"
          tabIndex={index}
        >
          <div className="wrapper-course-banner" />
          <div className="wrapper-course-content">
            <h1>{course.name}</h1>
            <p>{course.description}</p>
          </div>
        </div>
      ))}
    </Container>
  );
}

export default Courses;
