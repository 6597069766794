import React from 'react';

import { Plot } from 'types';

import { Container } from './styles';

interface OwnProps {
  plot: Plot;
  letDetail: boolean;
}

const Item: React.FC<OwnProps> = ({ plot, letDetail }) => {
  return (
    <Container
      to={letDetail ? `/plots/${plot.id}/show` : '#'}
      target={letDetail ? '_blank' : ''}
    >
      <p>{`Cód: ${plot.id}`}</p>
      <p>{`Talhão: ${plot.name}`}</p>
    </Container>
  );
};

export default Item;
