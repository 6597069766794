import styled from 'styled-components';

import Container from 'components/Container';

export const Wrapper = styled(Container)`
  .wrapper-user-participants {
    & > form {
      display: flex;
      align-items: flex-end;
      flex-direction: row;
      justify-content: space-between;

      button {
        margin-left: 20px;
      }
    }

    .wrapper-user-participants-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, 300px);
      gap: 20px;
    }

    .wrapper-user-participants-item {
      border-radius: 4px;
      border: 1px solid #333;
      padding: 20px;
      max-width: 300px;

      font-size: 1rem;

      .delete-button-participants-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
`;
