import React, { useCallback } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { OptionTypeBase } from 'react-select';

import { CheckCircle, Error, Link as LinkIcon } from '@material-ui/icons';
import { FileLoad } from 'types';

import SelectInput from 'components/NormalInputs/Select';
import InputNumberFormat from 'components/NormalInputs/TextNumberFormat';

import { Container, FileInfo, Preview } from './styles';

interface FilePlotLoad extends FileLoad {
  north?: number;
  south?: number;
  east?: number;
  west?: number;
  errors?: Record<string, any>;
}

interface OwnProps {
  files: FilePlotLoad[];
  vintages: OptionTypeBase[];
  onUpdadeFile?: (id: string, data: any) => void;
}

const CoordinatesInputFormat = {
  prefix: '',
  thousandSeparator: false,
  decimalSeparator: '.',
  allowNegative: true,
  decimalScale: 10,
};
// onUpdadeFile ? onUpdadeFile(id, { vintage_id: String(value.) }) : () => {}}
const FileList: React.FC<OwnProps> = ({ files, onUpdadeFile, vintages }) => {
  const handleOnChange = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      id: string,
      key: string,
    ) => {
      if (onUpdadeFile) {
        onUpdadeFile(id, { [key]: String(event.target.value) });
      }
    },
    [onUpdadeFile],
  );

  console.log('files', files);

  return (
    <Container>
      {files.map(file => {
        const {
          id,
          name,
          preview,
          progress,
          error,
          uploaded,
          readableSize,
          url,
        } = file;

        console.log(file.classe);

        return (
          <li key={id}>
            <FileInfo>
              <Preview src={preview} />
              <div>
                <strong>{name}</strong>
                <span>
                  {`${readableSize}`}
                  {!!url && <button type="button">Excluir</button>}
                </span>
              </div>
            </FileInfo>

            {!url && (
              <div className="wrapper-classe-file-definition">
                <form>
                  {file.classe === 'mapa-producao' && (
                    <>
                      <InputNumberFormat
                        name="north"
                        label="Norte"
                        placeholder="Coordenada Norte"
                        error={(file.errors && file.errors.north) || ''}
                        onChange={event =>
                          handleOnChange(event, file.id, 'north')
                        }
                        inputProps={CoordinatesInputFormat}
                      />
                      <InputNumberFormat
                        name="south"
                        label="Sul"
                        placeholder="Coordenada Sul"
                        error={(file.errors && file.errors.south) || ''}
                        onChange={event =>
                          handleOnChange(event, file.id, 'south')
                        }
                        inputProps={CoordinatesInputFormat}
                      />
                      <InputNumberFormat
                        name="east"
                        label="Leste"
                        placeholder="Coordenada Leste"
                        error={(file.errors && file.errors.east) || ''}
                        onChange={event =>
                          handleOnChange(event, file.id, 'east')
                        }
                        inputProps={CoordinatesInputFormat}
                      />
                      <InputNumberFormat
                        name="west"
                        label="Oeste"
                        placeholder="Coordenada Oeste"
                        error={(file.errors && file.errors.west) || ''}
                        onChange={event =>
                          handleOnChange(event, file.id, 'west')
                        }
                        inputProps={CoordinatesInputFormat}
                      />
                    </>
                  )}
                  <SelectInput
                    name="vintage_id"
                    label="Safra"
                    defaultValue={{
                      value: file.vintage_id,
                      label: file.vintage?.description,
                    }}
                    onChange={(value: any) =>
                      onUpdadeFile &&
                      onUpdadeFile(id, { vintage_id: String(value.value) })
                    }
                    options={vintages}
                  />
                </form>
              </div>
            )}

            <div>
              {!uploaded && !error && (
                <CircularProgress variant="determinate" value={progress} />
              )}

              {url && (
                <a href={url} target="_blank" rel="noopener noreferrer">
                  <LinkIcon style={{ marginRight: 8, color: '#222' }} />
                </a>
              )}

              {uploaded && (
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  id={`file-plot-${id}`}
                >
                  <CheckCircle style={{ marginRight: 8, color: '#78e5d5' }} />
                </a>
              )}

              {error && (
                <a href={url} target="_blank" rel="noopener noreferrer">
                  <Error style={{ marginRight: 8, color: '#e57878' }} />
                </a>
              )}
            </div>
          </li>
        );
      })}
    </Container>
  );
};

export default FileList;
