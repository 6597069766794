import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import IconButton from '@material-ui/core/IconButton';
import { Visibility } from '@material-ui/icons';
import _ from 'lodash';
import { Order } from 'types';
import { formatDateWithTimestamps } from 'utils/format';
import { getNameFromParticipant } from 'utils/renderNameParticipant';

import BaseTableDataManager from 'components/BaseTableDataManager';
import Loading from 'components/Information/Loading';
import api from 'services/api';

import { Container } from './styles';

const FinanceBlocked: React.FC = () => {
  const history = useHistory();
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadOrders() {
      try {
        setLoading(true);
        const { data } = await api.get<Order[]>('order', {
          params: { status: 'finance_blocked' },
        });

        setOrders(_.sortBy(data, 'created_at'));
      } catch (error) {
        toast.error('Não foi possível carregar as informações');
      } finally {
        setLoading(false);
      }
    }

    loadOrders();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <BaseTableDataManager
        data={orders}
        isLoading={loading}
        title="Pedidos bloqueados por financeiro"
        options={{
          grouping: false,
          pageSizeOptions: [5, 20, 50, 100, orders.length],
        }}
        columns={[
          {
            title: 'Ações',
            field: 'id',
            render: (column: Order) => (
              <IconButton
                onClick={() => history.push(`/orders/${column.id}/show`)}
              >
                <Visibility />
              </IconButton>
            ),
          },
          {
            title: 'Cliente',
            field: 'client_name',
            render: (column: Order) => {
              return getNameFromParticipant(column.client);
            },
          },
          {
            title: 'Vendedor',
            field: 'seller_name',
            render: (column: Order) => {
              return getNameFromParticipant(column.seller);
            },
          },
          {
            title: 'Safra',
            field: 'vintage.description',
          },
          {
            title: 'Fazendas',
            field: 'farms',
            render: (column: Order) =>
              column.farms.map(farm => farm.farm?.name).join(','),
          },
          { title: 'Cód.', field: 'id' },
          {
            title: 'Criado em',
            field: 'created_at',
            type: 'date',
            render: (column: Order) =>
              formatDateWithTimestamps(column.created_at),
          },
          {
            title: 'Última alteração',
            field: 'updated_at',
            type: 'date',
            render: (column: Order) =>
              formatDateWithTimestamps(column.updated_at),
          },
        ]}
      />
    </Container>
  );
};

export default FinanceBlocked;
