import React, { useState } from 'react';

import { InfoWindow, Marker } from '@react-google-maps/api';

interface OwnProps {
  label: string;
  title: string;
  position: {
    lng: number;
    lat: number;
  };
}

const MarkerWithLabel: React.FC<OwnProps> = ({ label, title, position }) => {
  const [open, setOpen] = useState(true);

  if (open) {
    return (
      <InfoWindow position={position} onCloseClick={() => setOpen(false)}>
        <div style={{ padding: 4, background: '#fff' }}>{label}</div>
      </InfoWindow>
    );
  }

  return (
    <Marker title={title} position={position} onClick={() => setOpen(true)} />
  );
};

export default MarkerWithLabel;
