import { Vintage } from 'types';

/**
 *
 * Action types
 */

export enum VintageTypes {
  LOAD_REQUEST = '@vintage/LOAD_REQUEST',
  LOAD_SUCCESS = '@vintage/LOAD_SUCCESS',
  LOAD_FAILURE = '@vintage/LOAD_FAILURE',
  SET_SELECTED_VINTAGE = '@vintage/SET_SELECTED_VINTAGE',
}

/**
 * State type
 */

export interface VintageState {
  data: Vintage[];
  readonly selectedVintage?: Vintage;
  readonly loading: boolean;
  readonly error: boolean;
}
