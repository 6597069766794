import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { PDFViewer } from '@react-pdf/renderer';
import { isArray } from 'lodash';
import { Order, Participant, Address, OrderStatus } from 'types';
import { formatCurrency } from 'utils/formatCurrency';

import Contract from 'components/Contract';
import { CreateAndUpdatesInfos } from 'components/DefaultRenders';
import DemoContract from 'components/DemoContract';
import Item from 'components/Item';
import PartnerContract from 'components/PartnerContract';
import ShowFileList from 'components/ShowFileList';
import api from 'services/api';
import errorHandler from 'services/errorHandler';
import { ApplicationState } from 'store';

import AlterOrderStatus from './AlterOrderStatus';
import {
  Wrapper as Container,
  FarmItem,
  DisplayDetails,
  ListParcelas,
} from './styles';

interface RouteParam {
  id: string;
}

export interface ApiReponse {
  data: Order;
}

export default function Show() {
  const params = useParams<RouteParam>();
  const [order, setOrder] = useState<Order>();
  const [open, setOpen] = React.useState(false);

  const user = useSelector((state: ApplicationState) => state.auth.auth?.user);

  const actualRoles = useMemo(() => {
    return user?.roles?.map(i => i.slug) || [];
  }, [user]);

  // const actualPermissions = useMemo(() => {
  //   return user?.roles?.map(i => i.slug) || [];
  // }, [user]);

  const statusFormatted = useMemo(() => {
    if (order) {
      switch (order.status) {
        case 'sended':
          return 'Aguardando';
        case 'started':
          return 'Iniciado';
        case 'canceled':
          return 'Cancelado';
        case 'finance_blocked':
          return 'Bloqueado por financeiro';
        case 'finished':
          return 'Finalizado';
        default:
          return '';
      }
    }
    return '';
  }, [order]);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const loadOrder = useCallback(async (id: string) => {
    try {
      const { data }: ApiReponse = await api.get(`order/${id}`);

      setOrder(data);
    } catch (err) {
      errorHandler(err);
    }
  }, []);

  useEffect(() => {
    loadOrder(params.id);
  }, [params.id, loadOrder]);

  const handleOnUpdateStatus = useCallback((status: OrderStatus) => {
    setOrder(current => {
      if (current) {
        return {
          ...current,
          status,
        };
      }

      return current;
    });
  }, []);

  const renderAddressSection = useCallback((address: Address[] | Address) => {
    function renderSection(item: Address) {
      return (
        <DisplayDetails key={item.id}>
          <Item label="Rua">{item.street}</Item>
          <Item label="Bairro">{item?.neighborhood}</Item>
          <Item label="Complemento">{item?.complement}</Item>
          <Item label="Descrição">{item?.description}</Item>
          <Item label="Cidade/Estado">{`${item?.city?.name} - ${item.city?.state?.initials}`}</Item>
        </DisplayDetails>
      );
    }

    if (isArray(address)) {
      return <section>{address.map(item => renderSection(item))}</section>;
    }
    return renderSection(address);
  }, []);

  const renderParticipantName = useCallback((target: Participant): string => {
    if (target.type === 'fisical')
      return `${target.fisical?.name || 'Nome não informado'}`;

    return `${target.juridical?.social_name || 'Razão social não informada'}`;
  }, []);

  const ContractView = useCallback(() => {
    if (!order) return <></>;
    if (order?.is_demo) {
      return <DemoContract order={order} />;
    }

    if (!order?.is_demo && order?.seller.is_partner) {
      return <PartnerContract order={order} />;
    }

    if (!order?.is_demo && !order?.seller.is_partner) {
      return <Contract order={order} />;
    }

    return <></>;
  }, [order]);

  return (
    <Container>
      <Dialog fullScreen open={open} onClose={handleClose}>
        {order && (
          <PDFViewer style={{ height: '100vh' }}>
            <ContractView />
          </PDFViewer>
        )}
      </Dialog>
      <header>
        <h1>{`Pedido ${order?.id} ${
          order?.is_demo ? '- Demonstrativo' : ''
        }`}</h1>
        {order && <CreateAndUpdatesInfos model={order} />}
      </header>
      {actualRoles.findIndex(role =>
        ['finances', 'production'].includes(role),
      ) >= 0 && (
        <section
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {`Situação: ${statusFormatted} `}
          <AlterOrderStatus
            order_id={`${order?.id}`}
            onUpdate={handleOnUpdateStatus}
          />
        </section>
      )}
      {actualRoles.findIndex(role =>
        ['finances', 'admin', 'manager', 'seller'].includes(role),
      ) >= 0 && (
        <section>
          <Button onClick={handleClickOpen} variant="contained" color="primary">
            Gerar contrato
          </Button>
        </section>
      )}
      <section>
        <h3>Dados</h3>
        <DisplayDetails>
          <Item label="Empresa">{`${order?.company_id} - ${order?.company.social_name}`}</Item>
          <Item label="Safra">{`${order?.vintage.description}`}</Item>
          <Item label="Vendedor">{`${
            order?.seller && renderParticipantName(order?.seller)
          }`}</Item>
          <Item label="Cliente">{`${
            order?.client && renderParticipantName(order?.client)
          }`}</Item>
          <Item label="Hectares">{`${order?.hectares}`}</Item>
          <Item label="Total do pedido">{`${order?.amount}`}</Item>
          <Item label="Total das parcelas">{`${order?.amount_parcels}`}</Item>
          <Item label="Cadastrado por">{`${order?.user.username}`}</Item>
        </DisplayDetails>
        {order?.address && (
          <section>
            <h3>Endereço</h3>
            {renderAddressSection(order?.address)}
          </section>
        )}

        <Item label="Observação">{`${order?.observation || ''}`}</Item>
      </section>
      <section>
        <h3>Fazendas</h3>
        {order?.farms.map(farm => {
          return (
            <FarmItem key={farm.id}>
              <Item label="Fazenda: ">{farm.farm?.name}</Item>
              <Item label="Hectares Contratados: ">{farm.hectares}</Item>
              <h4>Serviços contratados</h4>
              <ul>
                {farm.services?.map(service => {
                  return (
                    <Item key={`${farm.id}-${service.id}`}>
                      {service.service?.name}
                    </Item>
                  );
                })}
              </ul>
            </FarmItem>
          );
        })}
      </section>
      {actualRoles.findIndex(role =>
        ['finances', 'admin', 'manager', 'seller', 'client'].includes(role),
      ) >= 0 && (
        <>
          {!!order?.bills && (
            <section>
              <h3>Parcelas</h3>
              <ListParcelas>
                {order?.bills.map(bill => {
                  return (
                    <li key={bill.id}>
                      <Item label="Parcela">{bill.id}</Item>
                      <Item label="Descrição">{bill.description}</Item>
                      <Item label="Valor">{formatCurrency(bill.value)}</Item>
                      <Item label="Status">{bill.status}</Item>
                    </li>
                  );
                })}
              </ListParcelas>
            </section>
          )}
        </>
      )}
      {order?.files && (
        <section>
          <h3>Arquivos</h3>
          <ShowFileList files={order.files} />
        </section>
      )}
    </Container>
  );
}
