import React, { useRef, useCallback } from 'react';

import { Divider } from '@material-ui/core';
import { Scope, FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { isDate, format } from 'date-fns';
import { Participant, BaseAdonisPaginateReponseInterface } from 'types';
import { FormFilterParticipant } from 'types/Forms/Participant';
import * as Yup from 'yup';

import Button from 'components/Button';
import {
  Input,
  Select,
  DatePicker,
  InputMask,
  SwitchInput,
} from 'components/Form';
import { ContainerInputResponsive } from 'components/Layout';
import errorHandler from 'services/errorHandler';

import { Container } from './styles';

export interface ApiResponseData extends BaseAdonisPaginateReponseInterface {
  data: Participant[];
}

export interface ApiReponse {
  data: ApiResponseData;
}

export interface ParticipantFilterProps {
  onCancel: () => void;
  // onSubmit: (data: ApiResponseData, filters: string) => void;
  onSubmit: (filters: string) => void;
}

const convertFiltersToParams: (
  data: any,
  prefix?: string | undefined,
) => string = (data: any, prefix?: string) => {
  return Object.keys(data)
    .map(key => {
      if (typeof data[key] === 'boolean') {
        if (data[key] === false) return null;
      }

      if (typeof data[key] === 'string') {
        if (data[key] === '') return null;
      }

      if (typeof data[key] === 'object') {
        if (isDate(data[key])) {
          return `${prefix ? `${prefix}.` : ''}${key}=${encodeURIComponent(
            format(data[key], 'yyyy-MM-dd'),
          )}`;
        }
        return convertFiltersToParams(data[key], key);
      }

      return `${prefix ? `${prefix}.` : ''}${key}=${encodeURIComponent(
        data[key],
      )}`;
    })
    .join('&');
};

export const ParticipantFilter: React.FC<ParticipantFilterProps> = ({
  onCancel,
  onSubmit,
}) => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: SubmitHandler<FormFilterParticipant>) => {
      try {
        // Remove all previous errors
        if (formRef && formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          type: Yup.string().oneOf(
            ['fisical', 'juridical', 'all'],
            'O valor do tipo de participante deve ser Físico ou Jurídico',
          ),
          fisicals: Yup.object().shape({
            name: Yup.string(),
            cpf: Yup.string(),
            birthdayInitial: Yup.date(),
            birthdayFinal: Yup.date(),
            mother_name: Yup.string(),
            filteringDate: Yup.bool(),
          }),
          juridicals: Yup.object()
            .shape({
              cnpj: Yup.string(),
              social_name: Yup.string(),
              state_registration: Yup.string(),
              fantasy_name: Yup.string(),
            })
            .required(),
        });

        const result = await schema.validate(data, {
          abortEarly: false,
        });

        try {
          if (!result.fisicals.filteringDate) {
            delete result.fisicals.birthdayInitial;
            delete result.fisicals.birthdayFinal;
          }
          delete result.fisicals.filteringDate;

          const paramsFormatted = convertFiltersToParams(result);
          onSubmit(paramsFormatted);
        } catch (err) {
          errorHandler(err);
        }
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          if (formRef && formRef.current) {
            formRef.current.setErrors(validationErrors);
          }
        }
      }
    },
    [onSubmit],
  );

  return (
    <Container>
      <Form
        onSubmit={handleSubmit}
        ref={formRef}
        initialData={{
          type: 'all',
        }}
      >
        <Select
          name="type"
          label="Tipo do Participante"
          options={[
            { value: 'fisical', label: 'Física' },
            { value: 'juridical', label: 'Jurídica' },
            { value: 'all', label: 'Todos' },
          ]}
        />
        <SwitchInput name="is_employee" label="Colaborador" />
        <SwitchInput name="is_seller" label="Vendedor" />
        <SwitchInput name="is_supllier" label="Fornecedor" />
        <SwitchInput name="is_client" label="Cliente" />
        <SwitchInput name="is_partner" label="Parceiro" />
        <h1>Filtros da Pessoa Física</h1>
        <Divider />
        <ContainerInputResponsive>
          <Scope path="fisicals">
            <Input name="name" label="Nome completo" required={false} />
            <InputMask
              name="cpf"
              label="CPF"
              mask={[
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
              ]}
              required={false}
            />
            <SwitchInput
              name="filteringDate"
              label="Filtrar datas?"
              required={false}
            />
            <DatePicker
              name="birthdayInitial"
              label="Dt. Nascimento Inicial"
              required={false}
            />
            <DatePicker
              name="birthdayFinal"
              label="Dt. de Nascimento Final"
              required={false}
            />
            <Input
              name="mother_name"
              label="Nome da mãe"
              placeholder="Insira o nome da mãe do participante"
              required={false}
            />
          </Scope>
        </ContainerInputResponsive>
        <h1>Filtros da Pessoa Jurídica</h1>
        <Divider />
        <ContainerInputResponsive>
          <Scope path="juridicals">
            <InputMask
              name="cnpj"
              label="CNPJ"
              required={false}
              mask={[
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '/',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
              ]}
            />
            <Input name="social_name" label="Razão social" required={false} />
            <Input
              name="state_registration"
              label="Inscrição Estadual"
              required={false}
            />
            <Input name="fantasy_name" label="Nome Fantasia" required={false} />
          </Scope>
        </ContainerInputResponsive>

        <Button type="button" color="secondary" onClick={onCancel}>
          Cancelar
        </Button>
        <Button type="submit">Confirmar</Button>
      </Form>
    </Container>
  );
};

export default ParticipantFilter;
