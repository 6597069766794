import React, { useCallback } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { FileLoad } from 'types';

import ImageDialogEdit from 'components/ImageDialogEdit';

import { Container } from './styles';

interface OwnProps {
  url: string;
  alt: string;
  onSendFile?: (file: FileLoad) => void;
  editable?: boolean;
}

const ImageDialogShow: React.FC<OwnProps> = ({
  url,
  alt,
  onSendFile,
  editable = true,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = useCallback(() => {
    if (url) setOpen(true);
  }, [url]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <Container>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        {url ? (
          <img src={url} alt={alt} width={190} height={200} />
        ) : (
          'Nenhuma foto adicionada'
        )}
      </Button>
      {editable && <ImageDialogEdit onSendFile={onSendFile} />}
      <Dialog fullWidth maxWidth={false} open={open} onClose={handleClose}>
        <DialogContent>
          <img src={url} alt={alt} width="100%" height="100%" />
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default ImageDialogShow;
