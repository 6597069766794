import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ValueType, OptionTypeBase } from 'react-select';

import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Business from '@material-ui/icons/Business';
import Event from '@material-ui/icons/Event';
import Menu from '@material-ui/icons/Menu';

import Select from 'components/Select';
import { ApplicationState } from 'store';
import { authLogout } from 'store/ducks/auth/actions';
import { loadRequest, setSelectedCompany } from 'store/ducks/companies/actions';
import {
  loadRequest as loadVintages,
  setSelectedVintage,
} from 'store/ducks/vintages/actions';
import logo from 'assets/logo.png';
import { Container } from './styles';
import { Hidden } from '@material-ui/core';
import { useDrawer } from 'hooks/useDrawer';

interface OwnProps {
  className?: string;
}

export const Header: React.FC<OwnProps> = props => {
  const { className } = props;
  const { open, toggleDrawer } = useDrawer();
  const history = useHistory();
  const dispatch = useDispatch();
  const [openSelects, setOpenSelects] = useState(false);
  const [anchorVintage, setAnchorVintage] = useState<HTMLButtonElement | null>(
    null,
  );
  const [anchorCompany, setAnchorCompany] = useState<HTMLButtonElement | null>(
    null,
  );
  const { data: companies, selectedCompany } = useSelector(
    (state: ApplicationState) => state.companies,
  );
  const { data: vintages, selectedVintage } = useSelector(
    (state: ApplicationState) => state.vintages,
  );
  const user = useSelector((state: ApplicationState) => state.auth.auth?.user);

  const actualRoles = useMemo(() => {
    return user?.roles?.map(i => i.slug) || [];
  }, [user]);

  useEffect(() => {
    if (companies.length === 0) {
      dispatch(loadRequest());
    }
    if (vintages.length === 0) {
      dispatch(loadVintages());
    }
  }, [companies, dispatch, vintages]);

  function handleOnSelectCompany(value: number): void {
    const company = companies.find(item => item.id === Number(value));

    if (company) {
      dispatch(setSelectedCompany(company));
    }
  }

  function handleOnChangeVintage(value: number): void {
    const vintage = vintages.find(item => item.id === Number(value));

    if (vintage) {
      dispatch(setSelectedVintage(vintage));
    }
  }

  const handleLogout = useCallback(async () => {
    dispatch(authLogout());
    history.push('/');
  }, [history, dispatch]);

  const companiesOptions = useMemo(() => {
    return companies.map(company => ({
      value: String(company.id),
      label: company.fantasy_name,
    }));
  }, [companies]);

  return (
    <Container className={className}>
      <Hidden mdUp>
        <button type="button" onClick={toggleDrawer(!open)}>
          <Menu fontSize="large" />
        </button>
      </Hidden>
      <img src={logo} height={40} onClick={() => history.push('/dashboard')} />

      <div className="wrapper-aside-options">
        {companies &&
        actualRoles.findIndex(role =>
          ['production', 'admin', 'finances', 'manager', 'employee'].includes(
            role,
          ),
        ) >= 0 ? (
          <>
            <Select
              className="wrapper-aside-options-select"
              name="company"
              value={{
                value: selectedCompany?.id,
                label: selectedCompany?.fantasy_name,
              }}
              onChange={(value: ValueType<OptionTypeBase, false>) => {
                handleOnSelectCompany(value?.value);
              }}
              options={companiesOptions}
            />
            <IconButton
              className="icon-used-on-mobile"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                setAnchorCompany(event.currentTarget)
              }
            >
              <Business />
            </IconButton>
            <Popover
              open={Boolean(anchorCompany)}
              anchorEl={anchorCompany}
              onClose={() => setAnchorCompany(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Select
                name="company"
                value={{
                  value: selectedCompany?.id,
                  label: selectedCompany?.fantasy_name,
                }}
                onChange={(value: ValueType<OptionTypeBase, false>) =>
                  handleOnSelectCompany(value?.value)
                }
                options={companies.map(company => ({
                  value: company.id,
                  label: company.fantasy_name,
                }))}
              />
            </Popover>
          </>
        ) : (
          <></>
        )}

        {vintages && (
          <>
            <Select
              className="wrapper-aside-options-select"
              name="vintage"
              value={{
                value: selectedVintage?.id,
                label: selectedVintage?.description,
              }}
              onChange={(value: ValueType<OptionTypeBase, false>) =>
                handleOnChangeVintage(value?.value)
              }
              options={vintages.map(vintage => ({
                value: vintage.id,
                label: vintage.description,
              }))}
            />
            <IconButton
              className="icon-used-on-mobile"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                setAnchorVintage(event.currentTarget)
              }
            >
              <Event />
            </IconButton>
            <Popover
              open={Boolean(anchorVintage)}
              anchorEl={anchorVintage}
              onClose={() => setAnchorVintage(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Select
                name="vintage"
                value={{
                  value: selectedVintage?.id,
                  label: selectedVintage?.description,
                }}
                onChange={(value: ValueType<OptionTypeBase, false>) =>
                  handleOnChangeVintage(value?.value)
                }
                options={vintages.map(vintage => ({
                  value: vintage.id,
                  label: vintage.description,
                }))}
              />
            </Popover>
          </>
        )}

        <aside>
          <button type="button" onClick={() => setOpenSelects(!openSelects)}>
            <AccountCircle fontSize="large" />
          </button>
          <ul
            className={`wrapper-header-profile-menu ${
              openSelects && 'openSelects'
            }`}
          >
            <button
              type="button"
              onClick={() => {
                history.push('/profile');
                setOpenSelects(false);
              }}
            >
              Perfil
            </button>
            <button type="button" onClick={handleLogout}>
              Sair
            </button>
          </ul>
        </aside>
      </div>
    </Container>
  );
};

export default Header;
