import { AnyAction } from 'redux';

import { UsersState, UserTypes } from './types';

const INITIAL_STATE: UsersState = {
  loading: false,
  error: false,
  paginate: {
    page: 1,
    lastPage: 0,
    perPage: 20,
    total: 0,
  },
  data: [],
};

const reducer = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case UserTypes.LOAD_REQUEST:
      return { ...state, loading: true, error: false };
    case UserTypes.LOAD_SUCCESS: {
      const { paginate, users } = action.payload;
      return {
        ...state,
        loading: false,
        error: false,
        paginate,
        data: users,
      };
    }
    case UserTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: INITIAL_STATE };
    default:
      return state;
  }
};

export default reducer;
