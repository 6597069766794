import React, { useState, useCallback } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import filesize from 'filesize';
import { FileLoad } from 'types';
import { uuid } from 'uuidv4';

import Upload from 'components/Upload';

import { Container, Preview } from './styles';

interface OwnProps {
  onSendFile?: (file: FileLoad) => void;
}

const ImageDialogEdit: React.FC<OwnProps> = ({ onSendFile }) => {
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState<FileLoad[]>([]);

  const handleOnClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOnOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleUpload = useCallback((filesSended: File[]) => {
    const filesUploaded = filesSended.map(file => ({
      file,
      id: uuid(),
      name: file.name,
      size: file.size,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
    }));

    setFiles(filesUploaded);
  }, []);

  const handleOnListProcess = useCallback(() => {
    if (onSendFile) {
      files.forEach(onSendFile);
    }
    handleOnClose();
  }, [handleOnClose, onSendFile, files]);

  return (
    <Container>
      <Button variant="outlined" color="primary" onClick={handleOnOpen}>
        Editar
      </Button>
      <Dialog fullWidth maxWidth={false} open={open} onClose={handleOnClose}>
        <DialogContent className="wrapper-files-list-container">
          <Upload onUpload={handleUpload} multiple={false} />
          {files.map((file, index) => (
            <Preview src={file.preview} key={String(index)} />
          ))}
          <Button onClick={handleOnClose} color="secondary">
            Cancelar
          </Button>
          {files.filter(item => !item.uploaded).length > 0 && (
            <Button onClick={handleOnListProcess} style={{ marginLeft: 10 }}>
              Enviar Arquivos
            </Button>
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default ImageDialogEdit;
