import React from 'react';

import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Witness } from 'types';
import { cpfMask } from 'utils/format';

import ImagemDialogShow from 'components/ImagemDialogShow';

import { Container } from './styles';

interface OwnProps {
  witness: Witness;
  onRemove?: () => void;
}

const Item: React.FC<OwnProps> = ({ witness, onRemove }) => {
  return (
    <Container>
      <p>{witness.id}</p>
      <p>{witness.name}</p>
      <p>{cpfMask(witness.cpf)}</p>
      <div className="delete-button-participants-item">
        <ImagemDialogShow
          url={witness.file?.url || ''}
          alt="Imagem de assinatura"
          editable={false}
        />
        <IconButton onClick={onRemove}>
          <Delete />
        </IconButton>
      </div>
    </Container>
  );
};

export default Item;
