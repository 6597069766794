import { Vintage } from 'types';
import { action } from 'typesafe-actions';

import { VintageTypes } from './types';

export const loadRequest = () => action(VintageTypes.LOAD_REQUEST);

export const loadSuccess = (vintages: Vintage[]) =>
  action(VintageTypes.LOAD_SUCCESS, { vintages });

export const loadFailure = () => action(VintageTypes.LOAD_FAILURE);

export const setSelectedVintage = (vintage: Vintage) =>
  action(VintageTypes.SET_SELECTED_VINTAGE, { vintage });
