import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import IconButton from '@material-ui/core/IconButton';
import { Edit, Visibility } from '@material-ui/icons';
import { Company } from 'types';
import { cnpjMask } from 'utils/format';

import BaseTableDataManager from 'components/BaseTableDataManager';
import ExportXLS from 'components/ExportXLS';
import api from 'services/api';
import errorHandler from 'services/errorHandler';

import { Container } from './styles';

export interface ApiReponse {
  data: Company[];
}

export default function Participants() {
  const history = useHistory();
  const [hideActions, setHideActions] = useState(false);
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [exporting, setExporting] = useState<{
    exporting: boolean;
    columns: any[];
    data: any[];
  }>({
    exporting: false,
    columns: [],
    data: [],
  });

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const { data }: ApiReponse = await api.get(`companies`);

      setCompanies(data);
    } catch (error) {
      toast.error('Não foi possível carregar as informações');
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const exportXLS = useCallback(() => {
    if (exporting.exporting) {
      try {
        return (
          <ExportXLS
            filename="Empresas cadastradas"
            columns={exporting.columns}
            data={exporting.data}
          />
        );
      } finally {
        setTimeout(() => {
          setExporting({ exporting: false, columns: [], data: [] });
        }, 2000);
      }
    }

    return <></>;
  }, [exporting]);

  return (
    <Container>
      {exportXLS()}
      <BaseTableDataManager
        isLoading={loading}
        options={{
          selection: false,
          grouping: false,
          exportCsv: (columns, data) => {
            setExporting({ exporting: true, columns, data });
          },
        }}
        title="Empresas cadastradas"
        columns={[
          {
            title: 'Razão social',
            field: 'social_name',
          },
          {
            title: 'CNPJ',
            field: 'cnpj',
            render: (column: Company) => {
              return cnpjMask(column.cnpj);
            },
          },
          {
            title: 'Nome Fantasia',
            field: 'fantasy_name',
          },
          { title: 'Cód.', field: 'id' },
          {
            title: 'Ações',
            field: 'id',
            hidden: hideActions,
            export: false,
            render: (column: Company) => (
              <>
                <IconButton
                  onClick={() => history.push(`/companies/${column.id}/edit`)}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() => history.push(`/companies/${column.id}/show`)}
                >
                  <Visibility />
                </IconButton>
              </>
            ),
          },
          {
            title: 'Última alteração',
            field: 'updated_at',
          },
        ]}
        data={companies}
        onSelectionChange={rows => setHideActions(rows.length > 0)}
        onRefresh={loadData}
        onAdd={() => history.push('/companies/new')}
      />
    </Container>
  );
}
