import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Farm, Plot } from 'types';
import api from 'services/api';
import { ApplicationState } from 'store';
import { IconButton } from '@material-ui/core';
import BaseTable from 'components/BaseTable';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Link } from 'react-router-dom';
import { Container } from './styles';

const ClassOptions: Record<string, string> = {
  pcs: 'Pontos de Coleta',
  'pontos-coleta': 'Pontos de Coleta',
  croquiKMZ: 'Croqui KMZ',
  croqui: 'Croqui',
  mp: 'Mapa de Produção',
  'mapa-aplicacao': 'Mapa de Aplicação',
};

interface FileClient {
  id: number;
  name: string;
  url: string;
  classe: string;
  extname: string;
  plots?: Plot[];
  farms?: Farm[];
  plotsNames?: string;
  farmsNames?: string;
  classFormatted: string;
}

const Client: React.FC = () => {
  const user = useSelector((state: ApplicationState) => state.auth.auth?.user);
  const selectedVintage = useSelector(
    (state: ApplicationState) => state.vintages.selectedVintage,
  );
  const [files, setFiles] = useState<FileClient[]>([]);

  useEffect(() => {
    async function loadFiles(participant_id: number, vintage_id: number) {
      const { data } = await api.get<FileClient[]>(`me/files`, {
        params: {
          vintage_id,
          participant_id,
        },
      });

      setFiles(
        data.map(file => {
          let farmsNames: string | undefined = '';
          if (file.plots) {
            farmsNames = file.plots.map(plot => plot.farm?.name).join(',');
          } else {
            farmsNames = file.farms?.map(farm => farm.name).join(',');
          }

          const classFormatted = ClassOptions[file.classe] || 'Não informado';

          return {
            ...file,
            plotsNames: file.plots?.map(plot => plot.name).join(','),
            farmsNames,
            classFormatted,
          };
        }),
      );
    }

    if (selectedVintage?.id && user?.participant_id)
      loadFiles(user.participant_id, selectedVintage.id);
  }, [user, selectedVintage]);

  return (
    <Container>
      <BaseTable<FileClient>
        data={files}
        title=""
        columns={[
          {
            title: 'Arquivo',
            field: 'name',
          },
          {
            title: 'Tipo de arquivo',
            field: 'classFormatted',
          },
          {
            title: 'Talhões',
            field: 'plotsNames',
          },
          {
            title: 'Fazendas',
            field: 'farmsNames',
          },
          {
            title: 'Ações',
            field: 'id',
            export: false,
            render: column => (
              <Link to={`/file/${column.id}?classe=${column.classe}`}>
                <IconButton>
                  <VisibilityIcon />
                </IconButton>
              </Link>
            ),
          },
          {
            title: 'Cód',
            field: 'id',
          },
        ]}
      />
    </Container>
  );
};

export default Client;
