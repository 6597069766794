import { toast } from 'react-toastify';

import api from '../../../../services/api';
import { State, Options } from '../../../../types';

export async function loadStatesParsed(): Promise<Options[]> {
  try {
    const { data } = await api.get<State[]>('states');

    const statesParsed: Options[] = data.map(item => ({
      value: String(item.id),
      label: item.name,
    }));

    return statesParsed;
  } catch {
    toast.error('Não foi possível carregar os estados');
    return [];
  }
}
