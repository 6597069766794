import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { IconButton } from '@material-ui/core';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { AccountBox, ArrowBack, ArrowForward } from '@material-ui/icons';
import Assignment from '@material-ui/icons/Assignment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AttachMoney from '@material-ui/icons/AttachMoney';
import Business from '@material-ui/icons/Business';
import Eco from '@material-ui/icons/Eco';
import Folder from '@material-ui/icons/Folder';
import HourglassEmpty from '@material-ui/icons/HourglassEmpty';
import Home from '@material-ui/icons/Home';
import LandscapeIcon from '@material-ui/icons/Landscape';
import Map from '@material-ui/icons/Map';
import People from '@material-ui/icons/People';
import School from '@material-ui/icons/School';
import { ApplicationState } from 'store';
import { useDrawer } from 'hooks/useDrawer';
import { Container } from './styles';

const list = [
  {
    url: '/dashboard',
    icon: <Home />,
    description: 'Dashboard',
  },
  {
    url: '/waiting-orders',
    description: 'Aguardando Aprovação',
    icon: <HourglassEmpty />,
    roles: ['admin', 'finances', 'manager'],
  },
  {
    url: '/companies',
    roles: ['admin', 'finances'],
    icon: <Business />,
    description: 'Empresas',
  },
  {
    url: '/regions',
    roles: ['admin', 'finances'],
    icon: <Map />,
    description: 'Regiões',
  },
  {
    url: '/courses',
    roles: ['admin'],
    icon: <School />,
    description: 'Academia',
  },
  {
    url: '/finances',
    description: 'Financeiro',
    icon: <AttachMoney />,
    roles: ['admin', 'finances'],
  },
  {
    url: '/farms',
    description: 'Fazendas',
    icon: <LandscapeIcon />,
    roles: ['admin', 'finances'],
  },
  {
    url: '/participants',
    description: 'Participantes',
    icon: <People />,
    roles: ['admin', 'finances'],
  },
  {
    url: '/users',
    description: 'Usuários',
    icon: <AccountBox />,
    roles: ['admin', 'manager', 'finances'],
  },
  {
    url: '/production',
    description: 'Produção',
    icon: <Eco />,
    roles: ['admin', 'production'],
  },
  {
    url: '/orders',
    description: 'Pedidos',
    icon: <Assignment />,
    roles: ['admin', 'finances', 'manager'],
  },
  {
    url: '/producao/pedidos',
    description: 'Pedidos - Produção',
    icon: <Assignment />,
    roles: ['admin', 'manager', 'production'],
  },
  {
    url: '/client/files',
    description: 'Meus arquivos',
    icon: <Folder />,
    roles: ['admin', 'client'],
  },
  {
    url: '/client/orders',
    description: 'Meus Pedidos',
    icon: <Assignment />,
    roles: ['client'],
  },
  {
    url: '/me/orders',
    description: 'Meus Pedidos',
    icon: <Assignment />,
    roles: ['seller', 'partners'],
  },
  {
    url: '/seller/files',
    description: 'Arquivos da Produção',
    icon: <Folder />,
    roles: ['seller', 'partners'],
  },
  {
    url: '/witness',
    description: 'Testemunhas',
    icon: <AssignmentTurnedInIcon />,
    roles: ['admin', 'finances', 'manager'],
  },
];

function Menu(props: WithWidth) {
  const history = useHistory();
  const { width } = props;
  const { open, toggleDrawer } = useDrawer();
  const [openMenu, setOpenMenu] = useState(true);
  const user = useSelector((state: ApplicationState) => state.auth.auth?.user);

  const listRender = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {list.map(item => {
          if (item.roles) {
            const actualRoles = user?.roles?.map(i => i.slug) || [];
            if (actualRoles?.filter(v => item.roles.includes(v)).length === 0) {
              return null;
            }
          }

          return (
            <ListItem
              button
              key={item.url}
              onClick={() => history.push(item.url)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.description} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );

  if (['sm', 'xs'].includes(width)) {
    return (
      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {listRender()}
      </SwipeableDrawer>
    );
  }

  return (
    <Container className={!openMenu ? 'off' : 'opened'}>
      <ul>
        <button type="button" style={{ justifyContent: 'flex-end' }}>
          <IconButton
            name="switch"
            color="primary"
            onClick={() => setOpenMenu(current => !current)}
          >
            {openMenu ? <ArrowBack /> : <ArrowForward />}
          </IconButton>
        </button>
        {list.map((item, index) => {
          if (item.roles) {
            const actualRoles = user?.roles?.map(i => i.slug) || [];
            if (actualRoles?.filter(v => item.roles.includes(v)).length === 0) {
              return null;
            }
          }

          return (
            <button
              type="button"
              key={String(index)}
              onClick={() => history.push(item.url)}
            >
              <div className="shadow-drop-center">{item.icon}</div>
              <li className={openMenu ? 'on' : ''}>{item.description}</li>
            </button>
          );
        })}
      </ul>
    </Container>
  );
}

export default withWidth()(Menu);
