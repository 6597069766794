import React from 'react';
import ReactExport from 'react-export-excel';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

export default ({ columns, data, filename }) => {
  if (columns && data) {
    const columnsExportable = columns.filter(column => column.export !== false);

    return (
      <ExcelFile hideElement filename={filename}>
        <ExcelSheet data={data} name={filename}>
          {columnsExportable.map(item => {
            return (
              <ExcelColumn
                label={item.title}
                value={item.field}
                key={item.tableData.id}
              />
            );
          })}
        </ExcelSheet>
      </ExcelFile>
    );
  }
  return <></>;
};
