import styled from 'styled-components';

export const Container = styled.ul`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  li {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #444;

    & + li {
      margin-top: 15px;
      border-top: 1px dashed #444;
    }

    .wrapper-classe-file-definition {
      margin: 0px 20px;
      display: flex;
      width: 100%;
      flex-direction: row;

      form {
        width: 100%;
        flex: 1;
        display: grid;
        gap: 5px;
        align-items: center;
        grid-template-columns: repeat(auto-fill, max(270px));
      }
    }
  }
`;

export const FileInfo = styled.div`
  display: flex;
  align-items: center;
  width: 250px;

  div {
    display: flex;
    flex-direction: column;

    span {
      font-size: 0.8rem;
      color: #999;
      margin-top: 5px;

      button {
        font-size: 0.8rem;
        border: 0;
        background: transparent;
        color: #e57878;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
`;

interface A {
  src: string;
}

export const Preview = styled.div<A>`
  width: 36px;
  height: 36px;

  border-radius: 5px;

  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 10px;
`;
