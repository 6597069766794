import styled from 'styled-components';

import DefaultContainer from 'components/Container';

export const Container = styled(DefaultContainer)`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const BoxHectares = styled.div`
  padding: 20px;
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: #005500;
  }
`;

export const PlotInfo = styled.section`
  flex: 1;
  min-width: 360px;
  max-width: 640px;
  padding: 10px;
  font-size: 1rem;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const MapaAplicacaoContainer = styled.ul`
  flex: 1;
  min-width: 360px;
  max-width: 640px;
  padding: 10px;
  font-size: 1rem;
  flex-direction: column;
  align-items: center;
`;

export const MapaAplicacaoLegenda = styled.div`
  & + div {
    margin-top: 10px;
  }

  display: flex;
  align-items: center;
`;

interface BoxProps {
  color: string;
}

export const Box = styled.div<BoxProps>`
  margin-right: 10px;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  background: ${props => props.color};
`;

export const SectionCustomStyle = styled.section`
  font-size: 1rem;

  p {
    display: flex;
    align-items: center;
  }
`;

export const ElementLabel = styled.section``;

export const Legenda = styled.div`
  max-height: 300px;
  overflow: auto;
`;

export const MapTypeOptions = styled.div`
  position: absolute;
`;

export const ButtonMapType = styled.button``;
