import styled from 'styled-components';

export const Container = styled.div``;

export const Swatch = styled.div`
  padding: 5px;
  background: '#fff';
  border-radius: 1px;
  box-shadow: '0 0 0 1px rgba(0,0,0,.1)';
  display: 'inline-block';
  cursor: 'pointer';
`;

interface ColorProps {
  color: string;
}

export const Color = styled.div<ColorProps>`
  width: 40px;
  height: 30px;
  border: 1px solid #000;
  border-radius: 2px;
  background: ${props => props.color};
`;

export const Popover = styled.div`
  position: 'absolute';
  z-index: 2;
`;

export const Cover = styled.div`
  position: 'fixed';
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;
