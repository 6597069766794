import styled from 'styled-components';

export const Container = styled.div`
  max-width: 600px;
  min-width: 300px;
  margin: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-bottom: 1rem;
    width: 100%;
    max-width: 360px;
  }

  h1 {
    margin: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  form {
    align-items: center;
    background: #fff;
    padding: ${props => props.theme.padding};
    border: 1px solid #fff;
    border-radius: ${props => props.theme.borderRadius};
  }

  .wrapper-buttons-actions {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    & {
      button:first-child {
        margin-right: 10px;
      }
    }
  }
`;
