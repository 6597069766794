import React, { useRef, useEffect } from 'react';
import MaskedInput, { maskArray, MaskedInputProps } from 'react-text-mask';

import FormHelperText from '@material-ui/core/FormHelperText';
import Input, { InputProps } from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { useField } from '@unform/core';

import { Container } from './styles';

interface TextMaskCustomProps extends MaskedInputProps {
  inputRef: (ref: HTMLInputElement | null) => void;
  mask:
    | boolean
    | (string | RegExp)[]
    | ((value: string) => maskArray)
    | undefined;
}

function TextMaskCustom(props: TextMaskCustomProps) {
  const { inputRef, mask, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

interface FormattedInputMaskProps extends InputProps {
  name: string;
  label?: string;
  mask:
    | boolean
    | (string | RegExp)[]
    | ((value: string) => maskArray)
    | undefined;
  required?: boolean;
}

export const FormattedInputMask: React.FC<FormattedInputMaskProps> = ({
  name,
  label,
  mask,
  required = true,
  ...rest
}) => {
  const inputRef = useRef(null);

  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref: any) => String(ref.value).replace(/\D/g, ''),
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <InputLabel
        htmlFor="formatted-text-mask-input"
        error={!!error}
        required={required}
      >
        {label}
      </InputLabel>
      <Input
        {...rest}
        name={name}
        error={!!error}
        required={required}
        inputRef={inputRef}
        inputProps={{ mask }}
        defaultValue={defaultValue}
        inputComponent={TextMaskCustom as any}
      />
      {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
    </Container>
  );
};

export default FormattedInputMask;
