/* eslint-disable react/no-array-index-key */
import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tab, { TabProps } from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  idHelper: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, idHelper, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${idHelper}-tabel-${index}`}
      aria-labelledby={`${idHelper}-tabel-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any, idHelper: string) {
  return {
    id: `${idHelper}-tabel-${index}`,
    'aria-controls': `${idHelper}-tabel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

interface TabsComponent extends TabProps {
  item?: React.ReactNode;
}

interface ScrollableTabsProps {
  tabs: TabsComponent[];
  idHelper: string;
}

const ScrollableTabsButtonForce: React.FC<ScrollableTabsProps> = ({
  tabs,
  idHelper,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="Opções de visualização da fazenda"
        >
          {tabs.map((tab, index) => {
            return (
              <Tab
                key={`${idHelper}-${index}`}
                {...tab}
                {...a11yProps(index, idHelper)}
              />
            );
          })}
        </Tabs>
      </AppBar>
      {tabs.map((tab, index) => {
        const { item: Component } = tab;

        return (
          <TabPanel
            key={`${idHelper}-tabpanel-${index}`}
            value={value}
            index={index}
            idHelper={idHelper}
          >
            {Component ? Component : <div>AHHHHHHHHHH</div>}
          </TabPanel>
        );
      })}
    </div>
  );
};

export default ScrollableTabsButtonForce;
