/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  useEffect,
  useState,
  useCallback,
  ChangeEvent,
  useMemo,
} from 'react';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { FcRadarPlot } from 'react-icons/fc';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import { Edit } from '@material-ui/icons';
import { Farm, Plot } from 'types';
import { getIdentificationFromParticipant } from 'utils/renderNameParticipant';

import {
  CreateAndUpdatesInfos,
  HeaderSearchContainer,
} from 'components/DefaultRenders';
import DialogScroll from 'components/DialogScroll';
import Fab from 'components/Fab';
import { ContainerSectionResponsive, Header } from 'components/Layout';
import Tabs from 'components/Tabs';
import RenderPlot from 'pages/Modules/Finances/Farms/components/RenderPlot';
import RenderFiles from 'pages/Modules/Register/Plot/components/RenderFiles';
import api from 'services/api';
import errorHandler from 'services/errorHandler';
import { ApplicationState } from 'store';

import { Wrapper as Container } from './styles';
import { AxiosError } from 'axios';

interface RouteParam {
  id: string;
}

export default function Show() {
  const params = useParams<RouteParam>();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [plotName, setPlotName] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [farm, setFarm] = useState<Farm>();
  const [plotFiltered, setPlotFiltered] = useState<Plot[]>([]);
  const [plotFilterString, setPlotFilterString] = useState<
    string | undefined
  >();

  const user = useSelector((state: ApplicationState) => state.auth.auth?.user);

  const actualRoles = useMemo(() => {
    return user?.roles?.map(i => i.slug) || [];
  }, [user]);

  async function loadFarm(id: string) {
    try {
      setLoading(true);
      const { data } = await api.get<Farm>(`farms/${id}`);
      setFarm(data as Farm);
    } catch (err) {
      if ((err as AxiosError).isAxiosError) {
        errorHandler(err);
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadFarm(params.id);
  }, [params.id]);

  const handleOnDialogClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOnSubmitPlot = useCallback(async () => {
    try {
      await api.post(`farms/${params.id}/plots`, { name: plotName });

      loadFarm(params.id);
      setPlotName('');
    } catch (error) {
      errorHandler(error);
    } finally {
      setOpen(false);
    }
  }, [params.id, plotName]);

  const handleOnFilterPlot = useCallback(
    (word: string) => {
      setPlotFilterString(word);
      if (!word || word === '') setPlotFiltered([]);

      if (farm?.plots) {
        const regex = new RegExp(`${word}`, 'gmi');
        setPlotFiltered(
          farm?.plots.filter(plot => plot.name.match(regex) !== null),
        );
      }
    },
    [farm],
  );

  if (loading || !farm) {
    return <Container>Carregando...</Container>;
  }

  return (
    <Container>
      <Header>
        <h1>{`Fazenda: ${farm?.name}`}</h1>
        {farm && <CreateAndUpdatesInfos model={farm} />}
      </Header>
      <ContainerSectionResponsive>
        <p>{`Inscrição Estadual: ${farm?.ie || 'Não informado'}`}</p>
        <p>{`Hectares: ${farm?.total_ha.toLocaleString('pt-BR')}`}</p>
      </ContainerSectionResponsive>

      <h3>Dados do proprietário</h3>
      <ContainerSectionResponsive>
        <p>{`Proprietário: ${
          farm?.owner?.fisical?.name || farm?.owner?.juridical?.social_name
        }`}</p>
        <p>{`CPF/CNPJ: ${
          farm?.owner && getIdentificationFromParticipant(farm?.owner)
        }`}</p>
      </ContainerSectionResponsive>

      <Tabs
        tabs={[
          {
            label: 'Talhões',
            icon: <FcRadarPlot size={30} />,
            item: (
              <div>
                <HeaderSearchContainer
                  onChangeText={handleOnFilterPlot}
                  onClickActionButton={() => setOpen(true)}
                  actionButtonText="Adicionar Talhão"
                  textFieldLabel="Buscar Talhão"
                />
                <h3>Talhões da Fazenda</h3>
                {farm?.plots && (
                  <RenderPlot
                    plots={plotFilterString ? plotFiltered : farm?.plots}
                    letDetail
                  />
                )}
              </div>
            ),
          },
          {
            label: 'Arquivos',
            icon: <AiOutlineFileSearch size={30} />,
            item: (
              <div>
                <RenderFiles
                  files={farm?.files || []}
                  onDeleteFile={() => {}}
                />
              </div>
            ),
          },
        ]}
        idHelper="exibicao-fazendas"
      />

      {open && (
        <DialogScroll
          open={open}
          dialogTitle="Adicionar talhão"
          dialogContentText="Preencha o nome do talhão para adicioná-lo a fazenda atual"
          onClose={handleOnDialogClose}
          onClickActionCancelButton={handleOnDialogClose}
          onClickActionConfirmButton={handleOnSubmitPlot}
        >
          <TextField
            value={plotName}
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            ) => {
              setPlotName(event.target.value);
            }}
            variant="outlined"
            label="Nome do talhão"
            style={{ flex: 1, marginRight: 20, minWidth: 350 }}
          />
        </DialogScroll>
      )}

      {actualRoles.findIndex(role => ['production', 'admin'].includes(role)) >
        0 && (
        <Fab color="secondary" onClick={() => history.push(`edit`)}>
          <Edit />
        </Fab>
      )}
    </Container>
  );
}
