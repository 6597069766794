import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Divider } from '@material-ui/core';
import { Scope, FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { Participant, State, City, Options } from 'types';
import * as Yup from 'yup';

import Button from 'components/Button';
import {
  Input,
  Select,
  DatePicker,
  InputMask,
  SwitchInput,
} from 'components/Form';
import { ContainerInputResponsive } from 'components/Layout';
import api from 'services/api';
import errorHandler from 'services/errorHandler';
import { ApplicationState } from 'store';

import { loadCitiesParsed } from '../../loaders/CitiesParsed';
import { loadStatesParsed } from '../../loaders/StateParsed';
import { Wrapper } from './styles';

interface RouteParam {
  id: string;
}

export interface ApiReponse {
  data: Participant;
}

export interface ApiStateResponse {
  data: State[];
}

export interface ApiCityResponse {
  data: City[];
}

export default function Add() {
  const params = useParams<RouteParam>();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);
  const [participant, setParticipant] = useState<Participant>();
  const [stateSelected, setStateSelected] = useState<string | undefined>();
  const [states, setStates] = useState<Options[]>();
  const [cities, setCities] = useState<Options[]>();
  const { data: companies } = useSelector(
    (state: ApplicationState) => state.companies,
  );

  const loadStates = useCallback(async () => {
    const statesParsed = await loadStatesParsed();

    setStates(statesParsed);
    if (statesParsed.length >= 1) {
      setStateSelected(statesParsed[0].value);
    }
  }, []);

  const loadCities = useCallback(async (state: string | undefined) => {
    if (!state) return;
    try {
      const citiesParsed = await loadCitiesParsed(state);

      setCities(citiesParsed);
    } catch {
      toast.error('Não foi possível carregar os estados');
    }
  }, []);

  useEffect(() => {
    loadCities(stateSelected);
  }, [loadCities, stateSelected]);

  useEffect(() => {
    loadStates();
  }, [loadStates]);

  const loadParticipant = useCallback(async (id: string) => {
    setLoading(true);
    try {
      const { data }: ApiReponse = await api.get(`participants/${id}`);

      setParticipant(data);
      setLoading(false);
    } catch (err) {
      errorHandler(err);
    }
  }, []);

  useEffect(() => {
    loadParticipant(params.id);
  }, [params.id, loadParticipant]);

  const handleSubmit = useCallback(
    async (data: SubmitHandler<FormData>) => {
      try {
        // Remove all previous errors
        if (formRef && formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          type: Yup.string()
            .oneOf(
              ['fisical', 'juridical'],
              'O valor do tipo de participante deve ser Físico ou Jurídico',
            )
            .required('O tipo do participante é obrigatório!'),
          is_employee: Yup.bool(),
          is_seller: Yup.bool(),
          is_supplier: Yup.bool(),
          is_client: Yup.bool(),
          is_partner: Yup.bool(),
          fisical: Yup.object().when('type', {
            is: 'fisical',
            then: Yup.object()
              .shape({
                name: Yup.string().required('O nome completo é obrigatório'),
                cpf: Yup.string()
                  .length(11, 'O CPF deve ter 11 caracteres')
                  .required('O CPF é obrigatório'),
                birthday: Yup.date()
                  // .max(subYears(new Date(), 18), 'O participante deve ser maior de idade')
                  .required('A data de nascimento é obrigatória'),
                mother_name: Yup.string().required(
                  'O nome da mãe é obrigatório',
                ),
              })
              .required(),
          }),
          juridical: Yup.object().when('type', {
            is: 'juridical',
            then: Yup.object()
              .shape({
                cnpj: Yup.string()
                  .length(14, 'O CNPJ deve ter 14 caracteres')
                  .required('O CNPJ é obrigatório'),
                social_name: Yup.string().required(
                  'A Razão social é obrigatória',
                ),
                state_registration: Yup.string(),
                fantasy_name: Yup.string().required(
                  'A Nome Fantasia é obrigatório',
                ),
              })
              .required(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        // Validation passed

        try {
          await api.put(`participants/${params.id}`, data);
          toast.success('Participante atualizado com sucesso!');
        } catch (err) {
          errorHandler(err);
        }
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          if (formRef && formRef.current) {
            formRef.current.setErrors(validationErrors);
          }
        }
      }
    },
    [params.id],
  );

  const RenderFisicalSection = useCallback(() => {
    return (
      <section>
        <h1>Dados da Pessoa Física</h1>
        <Divider />
        <ContainerInputResponsive>
          <Scope path="fisical">
            <Input name="name" label="Nome completo" />
            <InputMask
              name="cpf"
              label="CPF"
              mask={[
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
              ]}
            />
            <DatePicker name="birthday" label="Data de Nascimento" />
            <Input
              name="mother_name"
              label="Nome da mãe"
              placeholder="Insira o nome da mãe do participante"
            />
          </Scope>
        </ContainerInputResponsive>
      </section>
    );
  }, []);

  const RenderJuridicalSection = useCallback(() => {
    return (
      <section>
        <h1>Dados da Pessoa Jurídica</h1>
        <Divider />
        <ContainerInputResponsive>
          <Scope path="juridical">
            <InputMask
              name="cnpj"
              label="CNPJ"
              mask={[
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '/',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
              ]}
            />
            <Input name="social_name" label="Razão social" />
            <Input name="state_registration" label="Inscrição Estadual" />
            <Input name="fantasy_name" label="Nome Fantasia" />
          </Scope>
        </ContainerInputResponsive>
      </section>
    );
  }, []);

  const companyOptions = useMemo(() => {
    return companies.map(company => {
      const { id, social_name: label } = company;

      return {
        value: String(id),
        label,
      };
    });
  }, [companies]);

  if (
    loading ||
    !participant ||
    !stateSelected ||
    !companies ||
    !states ||
    !cities
  ) {
    return <Wrapper>Carregando dados...</Wrapper>;
  }

  return (
    <Wrapper>
      <header>
        <h1>Cadastro de participantes</h1>
        <Divider />
      </header>

      <Form
        ref={formRef}
        initialData={{
          ...participant,
          type: participant.type,
          company_id: participant.company_id,
          address: participant.address.length > 0 && {
            ...participant.address[0],
            city_id: participant.address[0].city?.id,
            state: participant.address[0].city?.state_id,
          },
        }}
        onSubmit={handleSubmit}
      >
        <Select
          name="type"
          label="Tipo do Participante"
          options={[
            { value: 'fisical', label: 'Física' },
            { value: 'juridical', label: 'Jurídica' },
          ]}
        />
        <Select
          name="company_id"
          label="Empresa vinculada"
          options={companyOptions}
        />
        <SwitchInput name="is_employee" label="Colaborador" />
        <SwitchInput name="is_seller" label="Vendedor" />
        <SwitchInput name="is_supplier" label="Fornecedor" />
        <SwitchInput name="is_client" label="Cliente" />
        <SwitchInput name="is_partner" label="Parceiro" />

        {participant?.type === 'fisical' && <RenderFisicalSection />}
        {participant?.type === 'juridical' && <RenderJuridicalSection />}

        <h1>Endereço</h1>
        <Divider />
        <ContainerInputResponsive>
          <Scope path="address">
            <Input name="street" label="Rua" />
            <Input name="neighborhood" label="Bairro" />
            <Input name="complement" label="Complemento" required={false} />
            <Input name="description" label="Descrição" required={false} />
            <InputMask
              name="cep"
              label="CEP"
              mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
            />
            <Select
              name="state"
              label="Estado"
              onValueChange={(value: string | string[] | number | number[]) =>
                setStateSelected(value as string)
              }
              options={states}
            />
            {cities && (
              <Select name="city_id" label="Cidade" options={cities} />
            )}
          </Scope>
        </ContainerInputResponsive>

        <Button type="submit">Atualizar</Button>
      </Form>
    </Wrapper>
  );
}
