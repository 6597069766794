/* eslint-disable no-console */
import React, { useCallback, useRef } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { CheckCircle, Error, Link as LinkIcon } from '@material-ui/icons';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FileLoad, Options } from 'types';

import SelectInput from 'components/Form/Select';

import { Container, FileInfo, Preview } from './styles';

interface FormData {
  classe: string;
  vintage_id: string;
}

interface OwnProps {
  files: FileLoad[];
  vintages?: Options[];
  onUpdadeFile?: (id: string, data: any) => void;
}

const FileList: React.FC<OwnProps> = ({ files, onUpdadeFile, vintages }) => {
  const formRef = useRef<FormHandles>(null);

  const handleOnSubmit = useCallback((data: FormData) => {
    console.log('data', data);
  }, []);

  return (
    <Container>
      {files.map(file => {
        const {
          id,
          name,
          preview,
          progress,
          error,
          uploaded,
          readableSize,
          url,
        } = file;

        return (
          <li key={id}>
            <FileInfo>
              <Preview src={preview} />
              <div>
                <strong>{name}</strong>
                <span>
                  {`${readableSize}`}
                  {!!url && <button type="button">Excluir</button>}
                </span>
              </div>
            </FileInfo>

            {!url && (
              <Form
                ref={formRef}
                onSubmit={handleOnSubmit}
                className="wrapper-classe-file-definition"
              >
                <SelectInput
                  name="classe"
                  placeholder="Tipo do arquivo"
                  label="Tipo de arquivo"
                  containerStyle={{
                    minWidth: 240,
                  }}
                  onValueChange={
                    (value: string | string[] | number | number[]) =>
                      onUpdadeFile &&
                      onUpdadeFile(id, { classe: String(value) })
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                  options={[
                    { value: 'croqui', label: 'Croqui' },
                    { value: 'trilha', label: 'Trilha' },
                    { value: 'others', label: 'Outros' },
                  ]}
                />
                {vintages && (
                  <SelectInput
                    name="vintage_id"
                    placeholder="Safra"
                    containerStyle={{
                      minWidth: 240,
                    }}
                    onValueChange={
                      (value: string | string[] | number | number[]) =>
                        onUpdadeFile && onUpdadeFile(id, { vintage_id: value })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    options={vintages}
                  />
                )}
              </Form>
            )}

            <div>
              {!uploaded && !error && (
                <CircularProgress variant="determinate" value={progress} />
              )}

              {url && (
                <a href={url} target="_blank" rel="noopener noreferrer">
                  <LinkIcon style={{ marginRight: 8, color: '#222' }} />
                </a>
              )}

              {uploaded && (
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  id={`file-plot-${id}`}
                >
                  <CheckCircle style={{ marginRight: 8, color: '#78e5d5' }} />
                </a>
              )}

              {error && (
                <a href={url} target="_blank" rel="noopener noreferrer">
                  <Error style={{ marginRight: 8, color: '#e57878' }} />
                </a>
              )}
            </div>
          </li>
        );
      })}
    </Container>
  );
};

export default FileList;
