import React from 'react';

import { Farm } from 'types';

import Item from './Item';
import { Container } from './styles';

interface OwnProps {
  farms: Farm[];
  onItemClick?: (key: number) => void;
}

const RenderFarm: React.FC<OwnProps> = ({ farms, onItemClick }) => {
  return (
    <Container>
      {farms.map(farm => (
        <Item
          farm={farm}
          key={farm.id}
          onClick={() => onItemClick && onItemClick(farm.id)}
        />
      ))}
    </Container>
  );
};

export default RenderFarm;
