import { User } from 'types';
import { action } from 'typesafe-actions';

import { Paginate } from '../types';
import { UserTypes } from './types';

export const loadRequest = () => action(UserTypes.LOAD_REQUEST);

export const loadSuccess = (paginate: Paginate, users: User[]) =>
  action(UserTypes.LOAD_SUCCESS, { paginate, users });

export const loadFailure = () => action(UserTypes.LOAD_FAILURE);
