import React, { useRef, useEffect, useState } from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch, { SwitchProps } from '@material-ui/core/Switch';
import { useField } from '@unform/core';

interface Props extends SwitchProps {
  name: string;
  label?: string;
  checked?: boolean;
}

// eslint-disable-next-line react/prop-types
export const SwitchInput: React.FC<Props> = ({ name, label, ...rest }) => {
  const switchRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const [value, setValue] = useState(defaultValue || false);

  function handleOnChange(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) {
    setValue(checked);
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: switchRef.current,
      path: 'checked',
    });
  }, [fieldName, registerField]);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <>
      <FormControlLabel
        inputRef={switchRef}
        defaultValue={defaultValue}
        control={
          <Switch
            name={name}
            checked={value}
            onChange={handleOnChange}
            {...rest}
          />
        }
        label={label}
      />
      {error && <span className="helper-text-error">{error}</span>}
    </>
  );
};

export default SwitchInput;
