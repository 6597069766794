import styled from 'styled-components';

import Container from 'components/Container';

export const Wrapper = styled(Container)`
  font-size: 0.8rem;

  header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .wrapper-alterinfos-participant {
      color: #999;
    }
  }

  .wrapper-participant-content {
    margin: 10px 0px;
    & > p {
      font-weight: bold;
      font-size: 1rem;
    }
  }
`;

interface A {
  src: string;
}

export const Preview = styled.div<A>`
  width: 200px;
  height: 200px;

  border-radius: 5px;

  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 10px;
`;
