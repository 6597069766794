import React, { useRef, useEffect } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

import TextField, { FilledTextFieldProps } from '@material-ui/core/TextField';
import { useField } from '@unform/core';

import { Container } from './styles';

interface NumberFormatCustomProps extends NumberFormatProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      decimalScale={2}
      thousandSeparator="."
      decimalSeparator=","
      allowNegative={false}
      prefix="R$"
      {...other}
    />
  );
}

interface FormattedNumberFormatProps
  extends Omit<FilledTextFieldProps, 'variant'> {
  name: string;
  label?: string;
  required?: boolean;
}

export const FormattedNumberFormat: React.FC<FormattedNumberFormatProps> = ({
  name,
  label,
  required = true,

  ...rest
}) => {
  const inputRef = useRef(null);

  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref: any) =>
        Number(
          String(ref.value)
            .replace(/[^,\d]/g, '')
            .replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2')
            .replace(',', '.'),
        ),
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <TextField
        inputRef={inputRef}
        required={required}
        name={name}
        label={`${label}`}
        defaultValue={defaultValue}
        error={!!error}
        helperText={!!error && error}
        fullWidth
        InputProps={{
          inputComponent: NumberFormatCustom as any,
        }}
        variant="outlined"
        {...rest}
      />
    </Container>
  );
};

export default FormattedNumberFormat;
