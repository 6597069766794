import styled from 'styled-components';

export const Container = styled.div`
  margin-left: 10px;
`;

export const OptionsContainer = styled.div`
  display: grid;
  flex-wrap: wrap;
  gap: 5px;
`;
