import React, { useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import InsertChart from '@material-ui/icons/InsertChart';
import TrendingDown from '@material-ui/icons/TrendingDown';
import TrendingUp from '@material-ui/icons/TrendingUp';

import Payment from './Payment';
import Receive from './Receive';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const tabsOptions = ['receber', 'pagar', 'relatorios'];

export default function ScrollableTabsButtonForce() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const history = useHistory();
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const typeIndex = tabsOptions.findIndex(item => item === query.get('type'));

    setValue(typeIndex >= 0 ? typeIndex : 0);
  }, [search]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<unknown>, newValue: number) => {
      history.push(`/finances?type=${tabsOptions[newValue]}`);
    },
    [history],
  );

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="Abas por tipo de participante"
        >
          <Tab
            label="Contas à receber"
            icon={<TrendingUp />}
            {...a11yProps(0)}
          />
          <Tab
            label="Contas à pagar"
            icon={<TrendingDown />}
            {...a11yProps(1)}
          />
          <Tab label="Relatórios" icon={<InsertChart />} {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Receive />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Payment />
      </TabPanel>
      <TabPanel value={value} index={2} />
    </div>
  );
}
