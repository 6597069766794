import React, { useRef, useCallback } from 'react';
import { OptionTypeBase } from 'react-select';

import { Divider } from '@material-ui/core';
import { Scope, FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { isDate, format } from 'date-fns';
import { Participant, BaseAdonisPaginateReponseInterface } from 'types';
import { FormFilterParticipant } from 'types/Forms/Participant';
import * as Yup from 'yup';

import Button from 'components/Button';
import { Input, DatePicker, InputMask, SwitchInput } from 'components/Form';

import { Container } from './styles';

interface Options extends OptionTypeBase {
  value: string;
  label: string;
}

export interface ApiResponseData extends BaseAdonisPaginateReponseInterface {
  data: Participant[];
}

export interface ApiReponse {
  data: ApiResponseData;
}

export interface ParticipantFilterProps {
  onCancel: () => void;
  onSubmit: (filters: string) => void;
}

interface Options extends OptionTypeBase {
  value: string;
  label: string;
}

const INITIAL_DATA: Options = {
  value: 'all',
  label: 'Todos',
};

const convertFiltersToParams: (
  data: any,
  prefix?: string | undefined,
) => string = (data: any, prefix?: string) => {
  return Object.keys(data)
    .map(key => {
      if (typeof data[key] === 'boolean') {
        if (data[key] === false) return null;
      }

      if (typeof data[key] === 'string') {
        if (data[key] === '') return null;
      }

      if (typeof data[key] === 'object') {
        if (isDate(data[key])) {
          return `${prefix ? `${prefix}.` : ''}${key}=${encodeURIComponent(
            format(data[key], 'yyyy-MM-dd'),
          )}`;
        }
        return convertFiltersToParams(data[key], key);
      }

      return `${prefix ? `${prefix}.` : ''}${key}=${encodeURIComponent(
        data[key],
      )}`;
    })
    .join('&');
};

export const SupplierFilter: React.FC<ParticipantFilterProps> = ({
  onCancel,
  onSubmit,
}) => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: SubmitHandler<FormFilterParticipant>) => {
      try {
        // Remove all previous errors
        if (formRef && formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          type: Yup.string().oneOf(
            ['fisical', 'juridical', 'all'],
            'O valor do tipo de participante deve ser Físico ou Jurídico',
          ),
          fisicals: Yup.object().shape({
            name: Yup.string(),
            cpf: Yup.string(),
            birthdayInitial: Yup.date(),
            birthdayFinal: Yup.date(),
            filteringDate: Yup.bool(),
          }),
          juridicals: Yup.object()
            .shape({
              cnpj: Yup.string(),
              social_name: Yup.string(),
              state_registration: Yup.string(),
              fantasy_name: Yup.string(),
            })
            .required(),
        });

        const result = await schema.validate(data, {
          abortEarly: false,
        });

        if (!result.fisicals.filteringDate) {
          delete result.fisicals.birthdayInitial;
          delete result.fisicals.birthdayFinal;
        }

        delete result.fisicals.filteringDate;

        const paramsFormatted = convertFiltersToParams(result);

        onSubmit(paramsFormatted);
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          if (formRef && formRef.current) {
            formRef.current.setErrors(validationErrors);
          }
        }
      }
    },
    [onSubmit],
  );

  const renderFisicalSection = useCallback(() => {
    return (
      <section>
        <h1>Filtros da Pessoa Física</h1>
        <Divider />
        <div className="wrapper-scope-inputs">
          <Scope path="fisicals">
            <Input name="name" label="Nome completo" required={false} />
            <InputMask
              name="cpf"
              label="CPF"
              mask={[
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
              ]}
              required={false}
            />
            <SwitchInput
              name="filteringDate"
              label="Filtrar datas?"
              required={false}
            />
            <DatePicker
              name="birthdayInitial"
              label="Dt. Nascimento Inicial"
              required={false}
            />
            <DatePicker
              name="birthdayFinal"
              label="Dt. de Nascimento Final"
              required={false}
            />
          </Scope>
        </div>
      </section>
    );
  }, []);

  const renderJuridicalSection = useCallback(() => {
    return (
      <section>
        <h1>Filtros da Pessoa Jurídica</h1>
        <Divider />
        <div className="wrapper-scope-inputs">
          <Scope path="juridicals">
            <InputMask
              name="cnpj"
              label="CNPJ"
              mask={[
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '/',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
              ]}
              required={false}
            />
            <Input name="social_name" label="Razão social" required={false} />
            <Input
              name="state_registration"
              label="Inscrição Estadual"
              required={false}
            />
            <Input name="fantasy_name" label="Nome Fantasia" required={false} />
          </Scope>
        </div>
      </section>
    );
  }, []);

  return (
    <Container>
      <Form
        onSubmit={handleSubmit}
        ref={formRef}
        initialData={{
          type: INITIAL_DATA,
        }}
      >
        {renderFisicalSection()}
        {renderJuridicalSection()}

        <Button type="button" color="secondary" onClick={onCancel}>
          Cancelar
        </Button>
        <Button type="submit">Confirmar</Button>
      </Form>
    </Container>
  );
};

export default SupplierFilter;
