import styled from 'styled-components';

import DefaultContainer from 'components/Container';

export const Container = styled(DefaultContainer)`
  margin: 0;
  padding: 0;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;

  div {
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
`;
