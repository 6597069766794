import { combineReducers } from 'redux';

import auth from './auth';
import companies from './companies';
import users from './users';
import vintages from './vintages';

export default combineReducers({
  users,
  auth,
  companies,
  vintages,
});
