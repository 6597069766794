import AppProvider from 'hooks';

import Routes from './routes/index';

const App = () => (
  <>
    <AppProvider>
      <Routes />
    </AppProvider>
  </>
);

export default App;
