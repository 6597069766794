import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { IconButton, FormControlLabel } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import { Settings } from '@material-ui/icons';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LocationSearching from '@material-ui/icons/LocationSearching';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import Rainbow from 'rainbowvis.js';
import {
  Farm,
  Plot,
  Points,
  Croqui,
  LatLong,
  MapaAplicacao,
  Element,
  ElementItem,
  MaGrid,
  ClasseAmplitude,
  ClasseIndividual,
  Options,
} from 'types';
import { uuid } from 'uuidv4';
import * as Yup from 'yup';

import Button from 'components/Button';
import ColorPicker from 'components/ColorPicker';
import { InputNumber } from 'components/Form';
import TabsContainer from 'components/Layout/TabsContainer';
import MapRenderFiles from 'components/MapRenderFiles';
import Select from 'components/Select';
import Switch from 'components/Switch';
import api from 'services/api';
import apiConvertFiles from 'services/apiConvertFiles';
import errorHandler from 'services/errorHandler';
import { ApplicationState } from 'store';

import {
  ContainerStyled as Container,
  MapaAplicacaoContainer,
  ElementLabel,
  Legenda,
  MapaAplicacaoLegenda,
} from './styles';

interface ClassesFormatProps {
  onAmplitudeChange: (amplitude: number) => void;
}

const ClassesFormat: React.FC<ClassesFormatProps> = ({
  onAmplitudeChange,
  children,
}) => {
  const formRef = useRef<FormHandles>(null);

  const onSubmit = useCallback(
    async (data: SubmitHandler<FormData>) => {
      try {
        // Remove all previous errors
        if (formRef && formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          classSize: Yup.number().required('A amplitude é obrigatória'),
        });

        const { classSize } = await schema.validate(data, {
          abortEarly: false,
        });

        onAmplitudeChange(classSize);
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          if (formRef && formRef.current) {
            formRef.current.setErrors(validationErrors);
          }
        }
      }
    },
    [onAmplitudeChange],
  );

  return (
    <>
      <Form onSubmit={onSubmit} ref={formRef}>
        <InputNumber
          name="classSize"
          label="Tamanho da classe"
          inputProps={{
            prefix: '',
          }}
        />

        <Button type="submit">Enviar</Button>
      </Form>
      {children}
    </>
  );
};

interface IndividualProps {
  label: string;
  color?: string;
  onChangeColor(color: string): void;
}

const IndividualFormat: React.FC<IndividualProps> = ({
  label,
  color = 'green',
  onChangeColor,
  children,
}) => {
  const [value, setValue] = React.useState('monocolor');

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue((event.target as HTMLInputElement).value);
    },
    [],
  );

  const handleOnChangeColor = useCallback(
    (colorValue: string) => {
      onChangeColor(colorValue);
    },
    [onChangeColor],
  );

  return (
    <div>
      <FormControl component="fieldset">
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup
          aria-label="Cor Individual"
          name="individualColorPicker"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value="monocolor"
            control={<Radio />}
            label="Monocolor"
          />
          {/* <FormControlLabel value="multicolor" control={<Radio />} label="MultiColor" /> */}
        </RadioGroup>
      </FormControl>
      {value === 'monocolor' && (
        <p>
          {`Cor: `}
          <ColorPicker
            onChangeColor={(itemColor: string) =>
              handleOnChangeColor(itemColor)
            }
            color={color}
          />
        </p>
      )}
      {children}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const Visualization: React.FC = () => {
  const classes = useStyles();
  const [farms, setFarms] = useState<Farm[]>([]);
  const [open, setOpen] = React.useState<number[]>([]);
  const [selectedFarm, setSelectedFarm] = useState<Farm>();
  const [selectedPlot, setSelectedPlot] = useState<Plot>();
  const [centeredFile, setCenterFile] = useState<number | undefined>();
  const user = useSelector((state: ApplicationState) => state.auth.auth?.user);
  const [loadedFiles, setLoadedFiles] = useState<
    Array<Points | Croqui | MapaAplicacao>
  >([]);

  const handleClick = useCallback((file_id: number) => {
    setOpen(current => {
      if (current.includes(file_id)) {
        return current.filter(item => item !== file_id);
      }

      return [...current, file_id];
    });
  }, []);

  useEffect(() => {
    async function loadFarms(id: number) {
      try {
        const { data } = await api.get<Farm[]>(`client/${id}/farms`);

        setFarms(data);
        setSelectedFarm(data[0]);
      } catch (err) {
        errorHandler(err);
      }
    }

    if (user && user.participant_id) {
      loadFarms(user.participant_id);
    }
  }, [user]);

  const loadPoints = useCallback(async (file_id: number, classe: string) => {
    try {
      const { data } = await apiConvertFiles.get<Points>(`/points/${file_id}`);
      const [lng, lat] = data.points[0].geometry.coordinates;

      const points: Points = {
        ...data,
        classe,
        visible: true,
        center: [lng, lat],
        centerFormatted: { lng, lat },
      };

      setLoadedFiles(current => [...current, points]);

      return;
    } catch (error) {
      toast.error('Algo errado com o formato do arquivo');
    }
  }, []);

  const loadCroqui = useCallback(async (file_id: number, classe: string) => {
    try {
      const { data } = await apiConvertFiles.get<Croqui>(`/croqui/${file_id}`);

      const polygonArray = data.geometry.coordinates.map(polygon => {
        const newPolygon: LatLong[][] = [];

        polygon.map(pointArray => {
          return newPolygon.push(
            pointArray.map(latlong => {
              const [lng, lat] = latlong;
              return {
                lat: Number(String(lat).replace(',', '.')),
                lng: Number(String(lng).replace(',', '.')),
              };
            }),
          );
        });

        return newPolygon;
      });

      const croquis: Croqui = {
        ...data,
        classe,
        visible: true,
        center: [data.center[0], data.center[1]],
        centerFormatted: {
          lng: data.center[0],
          lat: data.center[1],
        },
        formattedCoordinates: polygonArray,
        options: {
          fillColor: '#ff4501',
          fillOpacity: 1,
          strokeColor: 'red',
          strokeOpacity: 1,
          strokeWeight: 2,
        },
      };

      setLoadedFiles(current => [...current, croquis]);
    } catch (error) {
      toast.error('Algo errado com o formato do arquivo');
    }
  }, []);

  const loadMapaAplicacao = useCallback(
    async (file_id: number, classe: string) => {
      try {
        const { data } = await apiConvertFiles.get<MapaAplicacao>(
          `/ma/${file_id}`,
        );

        const elements: Element = {};
        const polygonArray: LatLong[][][] = [];

        const grids = data.grids.map(grid => {
          grid.elements.forEach(element => {
            if (elements[element.element]) {
              elements[element.element].amount += element.value;
              elements[element.element].itens.push(element.value);
            } else {
              elements[element.element] = {
                isSelected: false,
                visualizationType: 'class',
                amount: element.value,
                itens: [element.value],
              };
            }
          });

          const polygonConverted = grid.geometry.coordinates.map(polygon => {
            const newPolygon: LatLong[][] = [];

            polygon.forEach(pointArray => {
              newPolygon.push(
                pointArray.map(latlong => {
                  const [lng, lat] = latlong;
                  return {
                    lat: Number(String(lat).replace(',', '.')),
                    lng: Number(String(lng).replace(',', '.')),
                  };
                }),
              );
            });

            polygonArray.push(newPolygon);
            return newPolygon;
          });

          return {
            ...grid,
            polygonConverted,
          };
        });

        const [lng, lat] = data.center;

        const mapaAplicacao: MapaAplicacao = {
          ...data,
          classe,
          centerFormatted: {
            lng,
            lat,
          },
          grids,
          sumarry: {
            elements,
          },
          geometryConvertted: polygonArray,
        };
        setCenterFile(data.file_id);
        setLoadedFiles(current => [...current, mapaAplicacao]);
      } catch (error) {
        toast.error('Algo errado com o formato do arquivo');
      }
    },
    [],
  );

  const handleOnCheckFile = useCallback(
    async (file_id: number, checked: boolean, classe: string) => {
      if (checked) {
        const fileIndex = loadedFiles.findIndex(
          item => item.file_id === file_id,
        );

        if (fileIndex < 0) {
          switch (classe) {
            case 'pontos-coleta':
              await loadPoints(file_id, classe);
              break;
            case 'croqui':
              await loadCroqui(file_id, classe);
              break;
            case 'mapa-aplicacao':
              await loadMapaAplicacao(file_id, classe);
              break;
            default:
              break;
          }
        }
      }

      setLoadedFiles(current =>
        current.map(item => {
          if (item.file_id === file_id) {
            return {
              ...item,
              visible: checked,
            };
          }

          return item;
        }),
      );
    },
    [loadCroqui, loadPoints, loadedFiles, loadMapaAplicacao],
  );

  const handleOnChangeElement = useCallback(
    (
      file_id: number,
      checked,
      element: string,
      elementItens: ElementItem,
      grids: MaGrid[],
    ) => {
      if (!checked) {
        setLoadedFiles(current =>
          current.map(item => {
            if (item.file_id === file_id) {
              const { sumarry } = item as MapaAplicacao;

              return {
                ...item,
                sumarry: {
                  elements: {
                    ...sumarry?.elements,
                    [element]: {
                      ...sumarry?.elements[element],
                      isSelected: false,
                    },
                  },
                },
              } as MapaAplicacao;
            }

            return item;
          }),
        );
      } else {
        /**
         * [x] Ordernar os itens
         * [x] Verificar o tipo de visualização classe ou individual
         * [x] Verificar o tamanho da classe se for individual e gerar as classes de acordo
         * [x] Verificar se o tipo for individual como fica a cor para cada valor
         */
        const itemsSorted = elementItens.itens.sort((a, b) => {
          return a - b;
        });

        const arraySize = itemsSorted.length;

        const gridsWithElement = grids
          .filter(grid => {
            const { elements } = grid;

            return (
              elements.findIndex(
                gridElement =>
                  gridElement.element.toUpperCase() === element.toUpperCase(),
              ) >= 0
            );
          })
          .map(gridFiltered => {
            const { hectares, polygonConverted, elements } = gridFiltered;
            const elementIndex = elements.findIndex(
              gridElement =>
                gridElement.element.toUpperCase() === element.toUpperCase(),
            );

            return {
              hectares,
              polygonConverted,
              elementValue: Number(elements[elementIndex].value.toFixed(2)),
            };
          });

        if (elementItens.visualizationType === 'class') {
          if (!elementItens.classes) {
            const min = itemsSorted[0];
            const max = itemsSorted[arraySize - 1];

            const amplitude =
              !elementItens.classAmplitude || elementItens.classAmplitude <= 0
                ? Number(((max - min) / 5).toFixed(2))
                : elementItens.classAmplitude;

            const numberClass = max / amplitude;

            const classesAmplitude: ClasseAmplitude[] = [];

            /** Encontrar todos os grids que tem elementos da classe,
             * retornar o somatório dos hectares
             * retornar o array de geometrias
             * filtrar classesAmplitude que não tenham geometrias
             */

            for (let index = 0; index < numberClass; index += 1) {
              const minFor = amplitude * index + (index === 0 ? 0 : 1);
              const maxFor = amplitude * (index + 1);
              const haAndPolygons = gridsWithElement
                .filter(grid => {
                  const { elementValue } = grid;

                  return elementValue >= minFor && elementValue <= maxFor;
                })
                .map(item => ({
                  ha: item.hectares,
                  polygon: item.polygonConverted,
                }));

              const totalElementHa = haAndPolygons.reduce(
                (prev, curr) => prev + curr.ha,
                0,
              );
              const polygons = haAndPolygons
                .filter(item => !!item.polygon)
                .map(item => item.polygon || []);

              classesAmplitude.push({
                id: uuid(),
                min: minFor,
                max: maxFor,
                color: '#fff',
                totalHa: totalElementHa,
                polygons,
              });
            }

            /** Limpa classesAmplitude vazias */
            const classesClean =
              classesAmplitude.filter(item => item.totalHa > 0) || [];

            const rainbow = new Rainbow();
            rainbow.setNumberRange(0, classesClean.length);
            rainbow.setSpectrum('blue', 'green', 'yellow', 'red');

            for (let index = 0; index < classesClean.length; index += 1) {
              const color = rainbow.colourAt(index);
              classesClean[index].color = `#${color}`;
            }

            setLoadedFiles(current =>
              current.map(item => {
                if (item.file_id === file_id) {
                  const { sumarry } = item as MapaAplicacao;

                  return {
                    ...item,
                    sumarry: {
                      elements: {
                        ...sumarry?.elements,
                        [element]: {
                          ...sumarry?.elements[element],
                          classes: classesClean,
                          isSelected: true,
                          min,
                          max,
                        },
                      },
                    },
                  } as MapaAplicacao;
                }
                return item;
              }),
            );
          } else {
            setLoadedFiles(current =>
              current.map(item => {
                const { sumarry } = item as MapaAplicacao;

                return {
                  ...item,
                  sumarry: {
                    elements: {
                      ...sumarry?.elements,
                      [element]: {
                        ...sumarry?.elements[element],
                        isSelected: true,
                      },
                    },
                  },
                } as MapaAplicacao;
              }),
            );
          }
        } else {
          /**
           * Pegar todas as dosagens diferentes
           * Atribuir uma cor para cada uma
           * Gerar id para cada uma
           * Atualizar sumarry dos elementos
           */

          const differentDoses: number[] = [];

          // Pega todas as diferentes dosagens
          gridsWithElement.forEach(grid => {
            if (!differentDoses.includes(grid.elementValue)) {
              differentDoses.push(grid.elementValue);
            }
          });

          // Ordena as diferentes doses
          const dosesOrdenadas = differentDoses.sort((a, b) => {
            return a - b;
          });

          // Raibow Strategy
          const rainbow = new Rainbow();
          rainbow.setNumberRange(0, dosesOrdenadas.length);
          rainbow.setSpectrum('white', 'green');

          // classes básicas
          const individualClass: ClasseIndividual[] = [];

          // Percorrer cada dose e achar os grids que tem essa dosagem

          dosesOrdenadas.forEach(dose => {
            const haAndPolygons = gridsWithElement
              .filter(grid => grid.elementValue === dose)
              .map(item => ({
                ha: item.hectares,
                polygon: item.polygonConverted,
              }));

            const totalElementHa = haAndPolygons.reduce(
              (prev, curr) => prev + curr.ha,
              0,
            );
            const polygons = haAndPolygons
              .filter(item => !!item.polygon)
              .map(item => item.polygon || []);

            individualClass.push({
              id: uuid(),
              value: dose,
              color: '#fff',
              totalHa: totalElementHa,
              polygons,
            });
          });

          for (let index = 0; index < individualClass.length; index += 1) {
            const color = rainbow.colourAt(index);
            individualClass[index].color = `#${color}`;
          }

          setLoadedFiles(current =>
            current.map(item => {
              const { sumarry } = item as MapaAplicacao;

              return {
                ...item,
                sumarry: {
                  elements: {
                    ...sumarry?.elements,
                    [element]: {
                      ...sumarry?.elements[element],
                      isSelected: true,
                      individualClasses: individualClass,
                      individualMonoColor: 'green',
                    },
                  },
                },
              } as MapaAplicacao;
            }),
          );
        }
      }
    },
    [],
  );

  const handleOnChangeTab = useCallback(
    (file_id: number, tabIndex: number, element: string) => {
      switch (tabIndex) {
        case 0:
          setLoadedFiles(current =>
            current.map(item => {
              if (item.file_id === file_id) {
                const { sumarry, grids } = item as MapaAplicacao;

                if (sumarry && !sumarry?.elements[element].classes) {
                  const gridsWithElement = grids
                    .filter(grid => {
                      const { elements } = grid;

                      return (
                        elements.findIndex(
                          gridElement =>
                            gridElement.element.toUpperCase() ===
                            element.toUpperCase(),
                        ) >= 0
                      );
                    })
                    .map(gridFiltered => {
                      const {
                        hectares,
                        polygonConverted,
                        elements,
                      } = gridFiltered;
                      const elementIndex = elements.findIndex(
                        gridElement =>
                          gridElement.element.toUpperCase() ===
                          element.toUpperCase(),
                      );

                      return {
                        hectares,
                        polygonConverted,
                        elementValue: Number(
                          elements[elementIndex].value.toFixed(2),
                        ),
                      };
                    });

                  const elementItens = sumarry.elements[element];

                  const itemsSorted = elementItens.itens.sort((a, b) => {
                    return a - b;
                  });

                  const min = itemsSorted[0];
                  const max = itemsSorted[itemsSorted.length - 1];

                  const amplitude =
                    !elementItens.classAmplitude ||
                    elementItens.classAmplitude <= 0
                      ? Number(((max - min) / 5).toFixed(2))
                      : elementItens.classAmplitude;

                  const numberClass = max / amplitude;

                  const amplitudeClass: ClasseAmplitude[] = [];

                  /** Encontrar todos os grids que tem elementos da classe,
                   * retornar o somatório dos hectares
                   * retornar o array de geometrias
                   * filtrar classes que não tenham geometrias
                   */

                  for (let index = 0; index < numberClass; index += 1) {
                    const minFor = amplitude * index + (index === 0 ? 0 : 1);
                    const maxFor = amplitude * (index + 1);
                    const haAndPolygons = gridsWithElement
                      .filter(grid => {
                        const { elementValue } = grid;

                        return elementValue >= minFor && elementValue <= maxFor;
                      })
                      .map(selectedItem => ({
                        ha: selectedItem.hectares,
                        polygon: selectedItem.polygonConverted,
                      }));

                    const totalElementHa = haAndPolygons.reduce(
                      (prev, curr) => prev + curr.ha,
                      0,
                    );
                    const polygons = haAndPolygons
                      .filter(itemPolygon => !!itemPolygon.polygon)
                      .map(itemPolygon => itemPolygon.polygon || []);

                    amplitudeClass.push({
                      id: uuid(),
                      min: minFor,
                      max: maxFor,
                      color: '#fff',
                      totalHa: totalElementHa,
                      polygons,
                    });
                  }

                  /** Limpa classes vazias */
                  const classesClean =
                    amplitudeClass.filter(
                      selectItem => selectItem.totalHa > 0,
                    ) || [];

                  const rainbow = new Rainbow();
                  rainbow.setNumberRange(0, classesClean.length);
                  rainbow.setSpectrum('blue', 'green', 'yellow', 'red');

                  for (let index = 0; index < classesClean.length; index += 1) {
                    const color = rainbow.colourAt(index);
                    classesClean[index].color = `#${color}`;
                  }

                  return {
                    ...item,
                    sumarry: {
                      elements: {
                        ...sumarry?.elements,
                        [element]: {
                          ...sumarry?.elements[element],
                          classes: classesClean,
                          isSelected: true,
                          min,
                          max,
                          visualizationType: 'class',
                        },
                      },
                    },
                  } as MapaAplicacao;
                }
                return {
                  ...item,
                  sumarry: {
                    elements: {
                      ...sumarry?.elements,
                      [element]: {
                        ...sumarry?.elements[element],
                        visualizationType: 'class',
                      },
                    },
                  },
                } as MapaAplicacao;
              }

              return item;
            }),
          );
          break;
        case 1:
          setLoadedFiles(current =>
            current.map(item => {
              if (item.file_id === file_id) {
                const { sumarry, grids } = item as MapaAplicacao;

                if (sumarry && !sumarry?.elements[element].individualClasses) {
                  const gridsWithElement = grids
                    .filter(grid => {
                      const { elements } = grid;

                      return (
                        elements.findIndex(
                          gridElement =>
                            gridElement.element.toUpperCase() ===
                            element.toUpperCase(),
                        ) >= 0
                      );
                    })
                    .map(gridFiltered => {
                      const {
                        hectares,
                        polygonConverted,
                        elements,
                      } = gridFiltered;
                      const elementIndex = elements.findIndex(
                        gridElement =>
                          gridElement.element.toUpperCase() ===
                          element.toUpperCase(),
                      );

                      return {
                        hectares,
                        polygonConverted,
                        elementValue: Number(
                          elements[elementIndex].value.toFixed(2),
                        ),
                      };
                    });

                  const differentDoses: number[] = [];

                  // Pega todas as diferentes dosagens
                  gridsWithElement.forEach(grid => {
                    if (!differentDoses.includes(grid.elementValue)) {
                      differentDoses.push(grid.elementValue);
                    }
                  });

                  // Ordena as diferentes doses
                  const dosesOrdenadas = differentDoses.sort((a, b) => {
                    return a - b;
                  });

                  // Raibow Strategy
                  const rainbow = new Rainbow();
                  rainbow.setNumberRange(0, dosesOrdenadas.length);
                  rainbow.setSpectrum('white', 'green');

                  // classes básicas
                  const individualClass: ClasseIndividual[] = [];

                  // Percorrer cada dose e achar os grids que tem essa dosagem
                  dosesOrdenadas.forEach(dose => {
                    const haAndPolygons = gridsWithElement
                      .filter(grid => grid.elementValue === dose)
                      .map(selectItem => ({
                        ha: selectItem.hectares,
                        polygon: selectItem.polygonConverted,
                      }));

                    const totalElementHa = haAndPolygons.reduce(
                      (prev, curr) => prev + curr.ha,
                      0,
                    );
                    const polygons = haAndPolygons
                      .filter(selectItem => !!selectItem.polygon)
                      .map(selectItem => selectItem.polygon || []);

                    individualClass.push({
                      id: uuid(),
                      value: dose,
                      color: '#fff',
                      totalHa: totalElementHa,
                      polygons,
                    });
                  });

                  for (
                    let index = 0;
                    index < individualClass.length;
                    index += 1
                  ) {
                    const color = rainbow.colourAt(index);
                    individualClass[index].color = `#${color}`;
                  }

                  return {
                    ...item,
                    sumarry: {
                      elements: {
                        ...sumarry?.elements,
                        [element]: {
                          ...sumarry?.elements[element],
                          isSelected: true,
                          individualClasses: individualClass,
                          visualizationType: 'individual',
                        },
                      },
                    },
                  } as MapaAplicacao;
                }
                return {
                  ...item,
                  sumarry: {
                    elements: {
                      ...sumarry?.elements,
                      [element]: {
                        ...sumarry?.elements[element],
                        visualizationType: 'individual',
                      },
                    },
                  },
                } as MapaAplicacao;
              }

              return item;
            }),
          );
          break;
        default:
          break;
      }
    },
    [],
  );

  const handleOnChangeAmplitude = useCallback(
    (
      file_id: number,
      element: string,
      amplitude: number,
      elementItens: ElementItem,
      grids: MaGrid[],
    ) => {
      setLoadedFiles(current =>
        current.map(item => {
          if (item.file_id === file_id) {
            const { sumarry } = item as MapaAplicacao;

            const { max } = elementItens;

            const numberClass = (max || 0) / amplitude;

            const amplitudeClass: ClasseAmplitude[] = [];

            /** Encontrar todos os grids que tem elementos da classe,
             * retornar o somatório dos hectares
             * retornar o array de geometrias
             * filtrar classes que não tenham geometrias
             */

            const gridsWithElement = grids
              .filter(grid => {
                const { elements } = grid;

                return (
                  elements.findIndex(
                    gridElement =>
                      gridElement.element.toUpperCase() ===
                      element.toUpperCase(),
                  ) >= 0
                );
              })
              .map(gridFiltered => {
                const { hectares, polygonConverted, elements } = gridFiltered;
                const elementIndex = elements.findIndex(
                  gridElement =>
                    gridElement.element.toUpperCase() === element.toUpperCase(),
                );

                return {
                  hectares,
                  polygonConverted,
                  elementValue: Number(elements[elementIndex].value.toFixed(2)),
                };
              });

            for (let index = 0; index < numberClass; index += 1) {
              const minFor = amplitude * index + (index === 0 ? 0 : 1);
              const maxFor = amplitude * (index + 1);
              const haAndPolygons = gridsWithElement
                .filter(grid => {
                  const elementValue = Number(grid.elementValue.toFixed(2));

                  return elementValue >= minFor && elementValue <= maxFor;
                })
                .map(selectItem => ({
                  ha: selectItem.hectares,
                  polygon: selectItem.polygonConverted,
                }));

              const totalElementHa = haAndPolygons.reduce(
                (prev, curr) => prev + curr.ha,
                0,
              );
              const polygons = haAndPolygons
                .filter(selectItem => !!selectItem.polygon)
                .map(selectItem => selectItem.polygon || []);

              amplitudeClass.push({
                id: uuid(),
                min: minFor,
                max: maxFor,
                color: '#fff',
                totalHa: totalElementHa,
                polygons,
              });
            }

            /** Limpa classes vazias */
            const classesClean =
              amplitudeClass.filter(selectedItem => selectedItem.totalHa > 0) ||
              [];

            const rainbow = new Rainbow();
            rainbow.setNumberRange(0, classesClean.length);
            rainbow.setSpectrum('blue', 'green', 'yellow', 'red');

            for (let index = 0; index < classesClean.length; index += 1) {
              const color = rainbow.colourAt(index);
              classesClean[index].color = `#${color}`;
            }

            return {
              ...item,
              sumarry: {
                elements: {
                  ...sumarry?.elements,
                  [element]: {
                    ...sumarry?.elements[element],
                    classAmplitude: amplitude,
                    classes: classesClean,
                  },
                },
              },
            } as MapaAplicacao;
          }
          return item;
        }),
      );
    },
    [],
  );

  const handleOnChangeIndividualMainColor = useCallback(
    (
      file_id: number,
      element: string,
      color: string,
      elementItens: ElementItem,
    ) => {
      setLoadedFiles(current =>
        current.map(item => {
          if (item.file_id === file_id) {
            const { sumarry } = item as MapaAplicacao;

            const { individualClasses = [] } = elementItens;

            const rainbow = new Rainbow();
            rainbow.setNumberRange(0, individualClasses.length || 1);
            rainbow.setSpectrum('white', color);

            for (let index = 0; index < individualClasses.length; index += 1) {
              const actualColor = rainbow.colourAt(index);
              individualClasses[index].color = `#${actualColor}`;
            }

            return {
              ...item,
              sumarry: {
                elements: {
                  ...sumarry?.elements,
                  [element]: {
                    ...sumarry?.elements[element],
                    individualClasses,
                    individualMonoColor: color,
                  },
                },
              },
            } as MapaAplicacao;
          }
          return item;
        }),
      );
    },
    [],
  );

  const handleOnChangeColorPicker = useCallback(
    (file_id: number, color: string, element: string, idColor: string) => {
      setLoadedFiles(current =>
        current.map(item => {
          if (item.file_id === file_id) {
            const { sumarry } = item as MapaAplicacao;

            return {
              ...item,
              sumarry: {
                elements: {
                  ...sumarry?.elements,
                  [element]: {
                    ...sumarry?.elements[element],
                    classes: sumarry?.elements[element].classes?.map(
                      elementItem => {
                        return elementItem.id === idColor
                          ? {
                              ...elementItem,
                              color,
                            }
                          : elementItem;
                      },
                    ),
                  },
                },
              },
            } as MapaAplicacao;
          }

          return item;
        }),
      );
    },
    [],
  );

  const RenderMapaAplicacaoLegenda = useCallback(
    (file_id: number) => {
      const fileIndex = loadedFiles.findIndex(
        item => item.visible && item.file_id === file_id,
      );

      if (fileIndex < 0) {
        return <></>;
      }

      const file = loadedFiles[fileIndex] as MapaAplicacao;

      const { sumarry, grids } = file;
      const elements = sumarry?.elements || {};

      return (
        <MapaAplicacaoContainer>
          <header>
            <h1>Elementos</h1>
          </header>
          {Object.keys(elements).map(element => {
            const {
              classes: elementClasses,
              individualClasses,
              isSelected,
              individualMonoColor,
            } = elements[element];

            const totalHaClasses =
              elementClasses
                ?.map(item => item.totalHa)
                .reduce((prev, curr) => prev + curr, 0) || 1;
            const totalHaIndividual =
              individualClasses
                ?.map(item => item.totalHa)
                .reduce((prev, curr) => prev + curr, 0) || 1;

            return (
              <>
                <ElementLabel>
                  <div>
                    <FormControlLabel
                      key={element}
                      control={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Switch
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                            checked: boolean,
                          ) => {
                            handleOnChangeElement(
                              file_id,
                              checked,
                              element,
                              elements[element],
                              grids,
                            );
                          }}
                          name={element}
                          color="primary"
                        />
                      }
                      label={element}
                    />
                    <IconButton>
                      <Settings />
                    </IconButton>
                  </div>
                  <TabsContainer
                    onChangeTab={tab =>
                      handleOnChangeTab(file_id, tab, element)
                    }
                    tabs={[
                      {
                        label: 'Classes',
                      },
                      {
                        label: 'individual',
                      },
                    ]}
                    panels={[
                      <ClassesFormat
                        key={`${Date.now()}-classformat-${element}`}
                        onAmplitudeChange={
                          amplitude =>
                            handleOnChangeAmplitude(
                              file_id,
                              element,
                              amplitude,
                              elements[element],
                              grids,
                            )
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                      >
                        <Legenda>
                          <h2>Legenda</h2>
                          {isSelected && elementClasses ? (
                            elementClasses.map(classe => {
                              const { min, max, color, totalHa } = classe;

                              const pctTotalHa = Number(
                                (totalHa * 100) / totalHaClasses,
                              ).toFixed(2);

                              return (
                                <MapaAplicacaoLegenda key={classe.id}>
                                  <ColorPicker
                                    color={color}
                                    onChangeColor={
                                      selectColor =>
                                        handleOnChangeColorPicker(
                                          file_id,
                                          selectColor,
                                          element,
                                          classe.id,
                                        )
                                      // eslint-disable-next-line react/jsx-curly-newline
                                    }
                                  />
                                  <strong>
                                    {`${min}-${Number(
                                      max.toFixed(2),
                                    )} (${Number(totalHa).toFixed(
                                      2,
                                    )} Ha | ${pctTotalHa}%)`}
                                  </strong>
                                </MapaAplicacaoLegenda>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </Legenda>
                      </ClassesFormat>,
                      <IndividualFormat
                        key={`${Date.now()}-individualformat-${element}`}
                        label="Visualização"
                        color={individualMonoColor}
                        onChangeColor={
                          color =>
                            handleOnChangeIndividualMainColor(
                              file_id,
                              element,
                              color,
                              elements[element],
                            )
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                      >
                        <Legenda>
                          <h2>Legenda</h2>
                          {isSelected && individualClasses ? (
                            individualClasses.map(classe => {
                              const { color, totalHa, value } = classe;
                              const pctTotalHa = Number(
                                (totalHa * 100) / totalHaIndividual,
                              ).toFixed(2);
                              return (
                                <MapaAplicacaoLegenda key={classe.id}>
                                  <ColorPicker
                                    color={color}
                                    // onChangeColor={color =>
                                    //   handleOnChangeIndividualColorPicker(color, element, classe.id)
                                    // }
                                  />
                                  <strong>{`${value} (${Number(totalHa).toFixed(
                                    2,
                                  )} Ha | ${pctTotalHa}%)`}</strong>
                                </MapaAplicacaoLegenda>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </Legenda>
                      </IndividualFormat>,
                    ]}
                  />
                </ElementLabel>
              </>
            );
          })}
        </MapaAplicacaoContainer>
      );
    },
    [
      handleOnChangeElement,
      handleOnChangeTab,
      loadedFiles,
      handleOnChangeAmplitude,
      handleOnChangeColorPicker,
      handleOnChangeIndividualMainColor,
    ],
  );

  function handleOnChangeFarm(id: number) {
    console.log(id);
  }

  return (
    <Container>
      <header>
        <h1>Visualização de arquivos</h1>
      </header>

      <section className="wrapper-files-view">
        <Select
          label="Fazenda"
          name="farm"
          onChangeValue={(value: Options) => {
            handleOnChangeFarm(Number(value?.value));
          }}
          options={farms.map(farm => ({
            value: farm.id,
            label: farm.name,
          }))}
        />
        <Select
          label="Talhão"
          name="plot"
          onChangeValue={(value: Options) => {
            setSelectedPlot(
              selectedFarm?.plots?.find(
                plot => plot.id === Number(value?.value),
              ),
            );
          }}
          options={selectedFarm?.plots?.map(plot => ({
            value: plot.id,
            label: plot.name,
          }))}
        />
      </section>
      <section className="wrapper-map-container">
        <MapRenderFiles
          centeredFile={centeredFile}
          convertedFiles={loadedFiles.filter(map => map.visible)}
        />
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Arquivos disponíveis
            </ListSubheader>
          }
          className={classes.root}
        >
          {selectedPlot?.files
            .filter(file =>
              ['pontos-coleta', 'croqui', 'mapa-aplicacao'].includes(
                file.classe,
              ),
            )
            .map(file => {
              let fileName = file.name.replace(`.${file.extname}`, '');

              if (fileName.indexOf('-') >= 0) {
                fileName = fileName.slice(
                  fileName.indexOf('-') + 1,
                  fileName.length - 1,
                );
              }
              return (
                <>
                  <ListItem button onClick={() => handleClick(file.id)}>
                    <ListItemIcon>
                      <IconButton onClick={() => setCenterFile(file.id)}>
                        <LocationSearching />
                      </IconButton>
                    </ListItemIcon>
                    <ListItemText
                      primary={fileName}
                      secondary={`${file.classe} - ${file.extname}`}
                    />
                    <ListItemSecondaryAction>
                      <Switch
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                          checked: boolean,
                        ) => {
                          handleOnCheckFile(file.id, checked, file.classe);
                        }}
                      />
                    </ListItemSecondaryAction>
                    {open.includes(file.id) ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse
                    in={open.includes(file.id)}
                    timeout="auto"
                    unmountOnExit
                  >
                    {file.classe === 'mapa-aplicacao' &&
                      RenderMapaAplicacaoLegenda(file.id)}
                  </Collapse>
                </>
              );
            })}
        </List>
      </section>
    </Container>
  );
};

export default Visualization;
