import { createStore, applyMiddleware } from 'redux';
import { SagaMiddleware } from 'redux-saga';
import { Action, Reducer } from 'typesafe-actions';

export default (
  reducers: Reducer<any, Action<any>>,
  middlewares: SagaMiddleware<object>[],
) => {
  // const enhancer =
  //   process.env.NODE_ENV === 'development'
  //     ? compose(console.tron.createEnhancer(), applyMiddleware(...middlewares))
  //     : applyMiddleware(...middlewares);

  const enhancer = applyMiddleware(...middlewares);

  return createStore(reducers, enhancer);
};
