import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import getValidationErrors from 'utils/getValidationErros';
import * as Yup from 'yup';

import { Input } from 'components/Form';
import { RequestAuthParams, updateUser } from 'store/ducks/auth/actions';

import Button from '../../components/Button';
import { Container } from './styles';
import { ApplicationState } from 'store';
import { Divider } from '@material-ui/core';
import api from 'services/api';
import { User } from 'types';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import errorHandler from 'services/errorHandler';

export default function SignIn() {
  const formRef = useRef<FormHandles>(null);
  const user = useSelector((state: ApplicationState) => state.auth.auth?.user);
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    async (data: RequestAuthParams) => {
      try {
        if (formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('O email é inválido')
            .required('O campo email é obrigatório'),
          oldPassword: Yup.string(),
          password: Yup.string().when('oldPassword', {
            is: (val: any) => !!val,
            then: Yup.string().required(
              'Campo obrigatório para troca de senha',
            ),
            otherwise: Yup.string(),
          }),
          password_confirmation: Yup.string().when('password', {
            is: (val: any) => !!val,
            then: Yup.string().oneOf(
              [Yup.ref('password')],
              'As senhas não conferem',
            ),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.put<User>(`users/${user?.id}`, data);

        dispatch(updateUser(response.data));

        toast.success('Usuário atualizado com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          if (formRef.current) {
            formRef.current.setErrors(getValidationErrors(err));
          }
        } else if ((err as AxiosError).isAxiosError) {
          errorHandler(err);
        }
      }
    },
    [user?.id, dispatch],
  );

  return (
    <Container>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{
          email: user?.email,
        }}
      >
        <Input name="email" label="Email" required />
        <Divider />
        <Input name="oldPassword" label="Senha atual" required={false} />
        <Input
          name="password"
          label="Nova senha"
          type="password"
          required={false}
        />
        <Input
          name="password_confirmation"
          label="Confirme a nova senha"
          type="password"
          required={false}
        />

        <Button type="submit">Atualizar</Button>
      </Form>
    </Container>
  );
}
