import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Divider } from '@material-ui/core';
import { Scope, FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { Participant } from 'types';
import * as Yup from 'yup';

import Button from 'components/Button';
import {
  Input,
  Select,
  DatePicker,
  InputMask,
  SwitchInput,
} from 'components/Form';
import api from 'services/api';
import errorHandler from 'services/errorHandler';
import { ApplicationState } from 'store';

import { Wrapper } from './styles';

interface RouteParam {
  id: string;
}

export interface ApiReponse {
  data: Participant;
}

export default function Add() {
  const params = useParams<RouteParam>();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);
  const [participant, setParticipant] = useState<Participant>();

  const { data: companies } = useSelector(
    (state: ApplicationState) => state.companies,
  );

  async function loadParticipant(id: string) {
    setLoading(true);
    try {
      const { data }: ApiReponse = await api.get(`participants/${id}`);

      setParticipant(data);
      setLoading(false);
    } catch (err) {
      errorHandler(err);
    }
  }

  useEffect(() => {
    loadParticipant(params.id);
  }, [params.id]);

  async function handleSubmit(data: SubmitHandler<FormData>) {
    try {
      // Remove all previous errors
      if (formRef && formRef.current) {
        formRef.current.setErrors({});
      }

      const schema = Yup.object().shape({
        type: Yup.string()
          .oneOf(
            ['fisical', 'juridical'],
            'O valor do tipo de participante deve ser Físico ou Jurídico',
          )
          .required('O tipo do participante é obrigatório!'),
        fisical: Yup.object().when('type', {
          is: 'fisical',
          then: Yup.object()
            .shape({
              name: Yup.string().required('O nome completo é obrigatório'),
              cpf: Yup.string()
                .length(11, 'O CPF deve ter 11 caracteres')
                .required('O CPF é obrigatório'),
              birthday: Yup.date()
                // .max(subYears(new Date(), 18), 'O participante deve ser maior de idade')
                .required('A data de nascimento é obrigatória'),
              mother_name: Yup.string().required('O nome da mãe é obrigatório'),
            })
            .required(),
        }),
        juridical: Yup.object().when('type', {
          is: 'juridical',
          then: Yup.object()
            .shape({
              cnpj: Yup.string()
                .length(14, 'O CNPJ deve ter 14 caracteres')
                .required('O CNPJ é obrigatório'),
              social_name: Yup.string().required(
                'A Razão social é obrigatória',
              ),
              state_registration: Yup.string(),
              fantasy_name: Yup.string().required(
                'A Nome Fantasia é obrigatório',
              ),
            })
            .required(),
        }),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      // Validation passed

      try {
        await api.put(`participants/${params.id}`, data);
        toast.success('Participante atualizado com sucesso!');
      } catch (err) {
        errorHandler(err);
      }
    } catch (err) {
      const validationErrors: Record<string, any> = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          if (error.path) {
            validationErrors[error.path] = error.message;
          }
        });

        if (formRef && formRef.current) {
          formRef.current.setErrors(validationErrors);
        }
      }
    }
  }

  function renderFisicalSection() {
    return (
      <section>
        <h1>Dados da Pessoa Física</h1>
        <Divider />
        <div className="wrapper-scope-inputs">
          <Scope path="fisical">
            <Input name="name" label="Nome completo" />
            <InputMask
              name="cpf"
              label="CPF"
              mask={[
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
              ]}
            />
            <DatePicker name="birthday" label="Data de Nascimento" />
            <Input
              name="mother_name"
              label="Nome da mãe"
              placeholder="Insira o nome da mãe do participante"
            />
          </Scope>
        </div>
      </section>
    );
  }

  function renderJuridicalSection() {
    return (
      <section>
        <h1>Dados da Pessoa Jurídica</h1>
        <Divider />
        <div className="wrapper-scope-inputs">
          <Scope path="juridical">
            <InputMask
              name="cnpj"
              label="CNPJ"
              mask={[
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '/',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
              ]}
            />
            <Input name="social_name" label="Razão social" />
            <Input name="state_registration" label="Inscrição Estadual" />
            <Input name="fantasy_name" label="Nome Fantasia" />
          </Scope>
        </div>
      </section>
    );
  }

  if (loading) {
    return <Wrapper>Carregando dados...</Wrapper>;
  }

  return (
    <Wrapper>
      <header>
        <h1>Cadastro de participantes</h1>
        <Divider />
      </header>

      <Form
        onSubmit={handleSubmit}
        ref={formRef}
        initialData={{
          ...participant,
          type: {
            value: participant?.type || '',
            label: 'Fisica',
          },
          company_id: {
            value: participant?.company_id,
            label: participant?.company?.social_name,
          },
        }}
      >
        <Select
          name="type"
          label="Tipo do Participante"
          options={[
            { value: 'fisical', label: 'Física' },
            { value: 'juridical', label: 'Jurídica' },
          ]}
        />

        <Select
          name="company_id"
          label="Empresa vinculada"
          options={
            companies.map(company => ({
              value: company.id.toString(),
              label: company.social_name,
            })) || []
          }
        />

        <SwitchInput name="is_employee" label="Colaborador" />
        <SwitchInput name="is_seller" label="Vendedor" />
        <SwitchInput name="is_supllier" label="Fornecedor" />
        <SwitchInput name="is_client" label="Cliente" />
        <SwitchInput name="is_partner" label="Parceiro" />

        {participant?.type === 'fisical' && renderFisicalSection()}
        {participant?.type === 'juridical' && renderJuridicalSection()}

        <Button type="submit">Atualizar</Button>
      </Form>
    </Wrapper>
  );
}
