import React from 'react';

import { DefaultModelsProps } from 'types';

import { Container } from './styles';

interface OwnProps {
  model: DefaultModelsProps;
}

const CreateAndUpdatesInfos: React.FC<OwnProps> = ({ model }) => {
  return (
    <Container>
      <p>{`Criado em: ${model.created_at}`}</p>
      <p>{`Última alteração em: ${model.updated_at}`}</p>
    </Container>
  );
};

export default CreateAndUpdatesInfos;
