import React from 'react';

import { Farm } from 'types';

import { Container } from './styles';

interface OwnProps {
  farm: Farm;
  onClick?: () => void;
}

const Item: React.FC<OwnProps> = ({ farm, onClick }) => {
  return (
    <Container onClick={onClick} isClickable={!!onClick}>
      <p>{`Cód: ${farm.id}`}</p>
      <p>{`Fazenda: ${farm.name}`}</p>
    </Container>
  );
};

export default Item;
