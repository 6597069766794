import { Permission, Role } from 'types';

export function getRolesArray(roles: Role[] | undefined): string[] {
  if (!roles) {
    return [];
  }

  const arrayRoles = roles.map(role => role.slug);

  return arrayRoles;
}

export function getPermissionsArray(
  permissions: Permission[] | undefined,
): string[] {
  if (!permissions) {
    return [];
  }

  const arrayPermissions = permissions.map(permission => permission.slug);

  return arrayPermissions;
}
