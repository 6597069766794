import { AnyAction } from 'redux';

import { CompanyState, CompaniesTypes } from './types';

const INITIAL_STATE: CompanyState = {
  loading: false,
  error: false,
  data: [],
};

const reducer = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case CompaniesTypes.LOAD_REQUEST:
      return { ...state, loading: true, error: false };
    case CompaniesTypes.LOAD_SUCCESS: {
      const { companies } = action.payload;
      return {
        ...state,
        loading: false,
        error: false,
        data: companies,
      };
    }
    case CompaniesTypes.LOAD_FAILURE:
      return { ...state, ...INITIAL_STATE };
    case CompaniesTypes.SET_SELECTED_COMPANY: {
      const { company } = action.payload;

      return {
        ...state,
        selectedCompany: company,
      };
    }
    default:
      return state;
  }
};

export default reducer;
