import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import IconButton from '@material-ui/core/IconButton';
import { TextField } from '@material-ui/core';
import TablePagination, {
  TablePaginationProps,
} from '@material-ui/core/TablePagination';
import { Visibility, ThumbUp, ThumbDown } from '@material-ui/icons';
import _ from 'lodash';
import { Order, BaseAdonisPaginateReponseInterface } from 'types';
import { formatDateWithTimestamps } from 'utils/format';
import { getNameFromParticipant } from 'utils/renderNameParticipant';
import BaseTableDataManager from 'components/BaseTableDataManager';
import RenderTableFilters from 'components/DefaultRenders/RenderTableFilters';
import DialogScroll from 'components/DialogScroll';
import api from 'services/api';
import errorHandler from 'services/errorHandler';
import { ParticipantFilter } from './Filter';
import { Container } from './styles';

export interface Aprovacao {
  type: 'sended' | 'refused';
  order: Order;
}

export interface ApiResponseData extends BaseAdonisPaginateReponseInterface {
  data: Order[];
}

export interface ApiReponse {
  data: ApiResponseData;
}

export default function PendingOrders() {
  const history = useHistory();
  const [motivo, setMotivo] = useState<string>('');
  const [selectedOrder, setSelectedOrder] = useState<Aprovacao>();
  const [filterOpen, setFilterOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const [hideActions, setHideActions] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState<Order[]>([]);
  const [paginate, setPaginate] = useState<ApiResponseData>({
    lastPage: 1,
    page: 1,
    perPage: 5,
    total: '0',
    data: [],
  });

  const loadData = useCallback(async (page = 1, perPage = 5, filters = '') => {
    try {
      setLoading(true);
      const { data }: ApiReponse = await api.get(
        `order?page=${page}&perPage=${perPage}&status[]=waiting_check&${filters}`,
      );

      setOrders(
        _.sortBy(
          data.data.map(item => ({
            ...item,
            vintageName: item.vintage.description,
            clientName: getNameFromParticipant(item.client),
            sellerName: getNameFromParticipant(item.seller),
            companyName: item.company.social_name || item.company.fantasy_name,
            farmName: item.farms.map(farm => farm.farm?.name).join(','),
          })),
          'created_at',
        ),
      );
      setPaginate(data);

      setFilterOpen(false);
    } catch (error) {
      toast.error('Não foi possível carregar as informações');
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData(paginate.page, paginate.perPage, filter);
  }, [filter, paginate.page, paginate.perPage, loadData]);

  const handleOnChangePage = useCallback(
    async (page: number) => {
      setLoading(true);
      await loadData(page + 1, paginate.perPage);
      setLoading(false);
    },
    [paginate, loadData],
  );

  const handleOnChangeRowsPerPage = useCallback(
    async (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setLoading(true);
      setPaginate({
        ...paginate,
        perPage: Number(event.target.value),
      });
      await loadData(paginate.page, Number(event.target.value));
      setLoading(false);
    },
    [paginate, loadData],
  );

  const handleOnFilterClose = useCallback(() => {
    setFilterOpen(false);
  }, []);

  const handleOnSubmitFilter = useCallback((filters: string) => {
    setFilter(filters);
  }, []);

  function handleOnCloseAprovacao() {
    setSelectedOrder(undefined);
  }

  async function handleOnConfirmAction() {
    if (!selectedOrder) {
      return;
    }

    if (selectedOrder?.type === 'refused' && motivo.length <= 5) {
      return;
    }

    try {
      setLoading(true);
      await api.put(`/order/${selectedOrder.order.id}`, {
        status: selectedOrder.type,
        observation: `${
          selectedOrder.order.observation
            ? `${selectedOrder.order.observation} - `
            : ''
        }Recusado por: ${motivo}`,
      });
      toast.success(
        `Pedido ${
          selectedOrder.type === 'refused' ? 'Recusado' : 'Aprovado'
        } com sucesso!`,
      );

      setOrders(current =>
        current.filter(item => item.id !== selectedOrder.order.id),
      );

      setSelectedOrder(undefined);
      setMotivo('');
    } catch (err) {
      errorHandler({ err });
    } finally {
      setLoading(false);
    }
  }

  const Table = useCallback(() => {
    return (
      <BaseTableDataManager
        data={orders}
        isLoading={loading}
        title="Pedidos aguardando"
        onShowCustomFilter={() => setFilterOpen(true)}
        onSelectionChange={rows => setHideActions(rows.length > 0)}
        onRefresh={() => loadData(paginate.page, paginate.perPage)}
        options={{
          grouping: false,
          pageSize: Number(paginate.perPage),
          pageSizeOptions: [5, 20, 50, 100, Number(paginate.total)],
        }}
        columns={[
          {
            title: 'Aprovação',
            field: 'id',
            hidden: hideActions,
            render: (column: Order) => (
              <div style={{ display: 'flex' }}>
                <IconButton
                  onClick={() =>
                    setSelectedOrder({
                      order: column,
                      type: 'sended',
                    })
                  }
                >
                  <ThumbUp />
                </IconButton>
                <IconButton
                  onClick={() =>
                    setSelectedOrder({
                      order: column,
                      type: 'refused',
                    })
                  }
                >
                  <ThumbDown />
                </IconButton>
              </div>
            ),
          },
          {
            title: 'Empresa',
            field: 'companyName',
          },
          {
            title: 'Cliente',
            field: 'clientName',
          },
          {
            title: 'Vendedor',
            field: 'sellerName',
          },
          {
            title: 'Hectares',
            field: 'hectares',
            type: 'numeric',
          },
          {
            title: 'Total do pedido',
            field: 'amount',
            type: 'currency',
            currencySetting: {
              locale: 'pt-BR',
              currencyCode: 'BRL',
            },
          },
          {
            title: 'Total das parcelas',
            field: 'amount_parcels',
            type: 'currency',
            currencySetting: {
              locale: 'pt-BR',
              currencyCode: 'BRL',
            },
          },
          {
            title: 'Safra',
            field: 'vintageName',
          },
          {
            title: 'Fazendas',
            field: 'farmName',
          },
          {
            title: 'Ações',
            field: 'id',
            hidden: hideActions,
            render: (column: Order) => (
              <>
                <IconButton
                  onClick={() => history.push(`/orders/${column.id}/show`)}
                >
                  <Visibility />
                </IconButton>
              </>
            ),
          },
          { title: 'Cód.', field: 'id' },
          {
            title: 'Última alteração',
            field: 'updated_at',
            type: 'date',
            render: (column: Order) =>
              formatDateWithTimestamps(column.updated_at),
          },
        ]}
        components={{
          Pagination: (props: TablePaginationProps) => (
            <TablePagination
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              count={Number(paginate.total)}
              page={paginate.page - 1}
              rowsPerPage={paginate.perPage}
              onChangePage={(event, page) => handleOnChangePage(page)}
              rowsPerPageOptions={[5, 20, 50, 100, Number(paginate.total)]}
              onChangeRowsPerPage={
                (
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >,
                ) => handleOnChangeRowsPerPage(event)
                // eslint-disable-next-line react/jsx-curly-newline
              }
            />
          ),
        }}
      />
    );
  }, [
    handleOnChangePage,
    handleOnChangeRowsPerPage,
    hideActions,
    history,
    loadData,
    loading,
    orders,
    paginate.page,
    paginate.perPage,
    paginate.total,
  ]);

  return (
    <Container>
      <RenderTableFilters
        keys={{
          type: 'Tipo do participante',
          is_employee: 'Empregado',
          is_seller: 'Vendedor',
          is_supllier: 'Fornecedor',
          is_client: 'Cliente',
          is_partner: 'Parceiro',
          'fisicals.name': 'Nome',
          'fisicals.cpf': 'CPF',
          'fisicals.mother_name': 'Nome da Mãe',
          'fisicals.birthdayInitial': 'Data de nascimento inicial',
          'fisicals.birthdayFinal': 'Data de nascimento final',
          'juridicals.CNPJ': 'CNPJ',
          'juridicals.social_name': 'Razão social',
          'juridicals.state_registration': 'Inscrição Estadual',
          'juridicals.fantasy_name': 'Nome fantasia',
        }}
        filter={filter}
        onDelete={setFilter}
      />
      <Table />

      {selectedOrder?.order && (
        <DialogScroll
          loading={loading}
          open={!!selectedOrder}
          dialogTitle={
            selectedOrder.type === 'sended'
              ? 'Aprovação de pedido'
              : 'Recusar pedido'
          }
          onClose={handleOnCloseAprovacao}
          onClickActionCancelButton={handleOnCloseAprovacao}
          onClickActionConfirmButton={handleOnConfirmAction}
        >
          {selectedOrder.type === 'refused' && (
            <TextField
              variant="outlined"
              label="Motivo"
              multiline
              rows={5}
              error={motivo.length <= 5}
              helperText={motivo.length <= 5 && 'Mínimo de 5 caracteres'}
              onChange={event => setMotivo(event.target.value)}
            />
          )}
        </DialogScroll>
      )}

      {filterOpen && (
        <DialogScroll
          open={filterOpen}
          dialogTitle="Filtros de Pedidos"
          dialogContentText="Utilize os filtros para obter resultados mais personalizados"
          onClose={handleOnFilterClose}
          onClickActionCancelButton={handleOnFilterClose}
          dialogActions={<div />}
        >
          <ParticipantFilter
            onCancel={handleOnFilterClose}
            onSubmit={handleOnSubmitFilter}
          />
        </DialogScroll>
      )}
    </Container>
  );
}
