import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

declare global {
  interface Console {
    tron: any;
  }
}

declare global {
  interface Window {
    google: any;
  }
}

window.google = window.google || {};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
