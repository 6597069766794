import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Divider } from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  BaseAdonisPaginateReponseInterface,
  Participant,
  Options,
  Farm,
  ReceiveType,
  Order,
  Bill,
} from 'types';
import { formatCurrency } from 'utils/formatCurrency';
import { getNameFromParticipant } from 'utils/renderNameParticipant';
import * as Yup from 'yup';

import Button from 'components/Button';
import { Input, Select, DatePicker, InputNumber } from 'components/Form';
import { ContainerInputResponsive, Header } from 'components/Layout';
import api from 'services/api';
import errorHandler from 'services/errorHandler';
import { ApplicationState } from 'store';

import { Container } from './styles';

interface ParticipantApiResponse extends BaseAdonisPaginateReponseInterface {
  data: Participant[];
}

interface OrderApiResponse extends BaseAdonisPaginateReponseInterface {
  data: Order[];
}

interface FormSubmitData {
  company_id: string;
  vintage_id: string;
  participant_id: string;
  farm_id?: string;
  order_id?: string;
  received_type_id: string;
  value: number;
  expired_date: Date;
  own_code?: string;
  description?: string;
  observation?: string;
}

interface RouteParam {
  id: string;
}

export default function Add() {
  const formRef = useRef<FormHandles>(null);
  const params = useParams<RouteParam>();
  const { data: companies } = useSelector(
    (state: ApplicationState) => state.companies,
  );
  const { data: vintages } = useSelector(
    (state: ApplicationState) => state.vintages,
  );
  const [company_id, setCompanyId] = useState<string | undefined>();
  const [participant_id, setParticipantId] = useState<string | undefined>();
  const [participants, setParticipants] = useState<Options[]>([]);
  const [farms, setFarms] = useState<Options[]>([]);
  const [orders, setOrders] = useState<Options[]>([]);
  const [receivedType, setReceivedType] = useState<Options[]>([]);
  const [bill, setBill] = useState<Bill>();

  const loadBill = useCallback(async (id: string) => {
    try {
      const { data } = await api.get<Bill>(`bills/${id}`);
      setCompanyId(data.company_id);
      setParticipantId(data.participant_id.toString());
      setBill(data);
    } catch (err) {
      errorHandler(err);
    }
  }, []);

  useEffect(() => {
    loadBill(params.id);
  }, [params.id, loadBill]);

  const loadParticipants = useCallback(async (id: string | undefined) => {
    try {
      setParticipants([]);

      if (!id) return;

      const { data } = await api.get<ParticipantApiResponse>(`participants`, {
        params: {
          company_id: id,
          perPage: 999,
        },
      });

      setParticipants(
        data.data.map(participant => {
          return {
            value: String(participant.id),
            label: getNameFromParticipant(participant),
          };
        }),
      );
    } catch (err) {
      errorHandler(err);
    }
  }, []);

  useEffect(() => {
    if (company_id) {
      loadParticipants(company_id);
    }
  }, [loadParticipants, company_id]);

  useEffect(() => {
    if (formRef.current && bill?.participant_id) {
      api
        .get<Farm[]>(`/client/${bill.participant_id}/farms`)
        .then(({ data }) => {
          if (!data) return;
          const dataFormatted = data.map(farm => {
            return {
              value: String(farm.id),
              label: farm.name,
            };
          });

          if (dataFormatted.length > 0) {
            setFarms(dataFormatted);
          }
        })
        .catch(() => setFarms([]));

      api
        .get<OrderApiResponse>(`/order`, {
          params: {
            client_id: participant_id,
          },
        })
        .then(({ data }) => {
          if (!data.data) return;
          const dataFormatted = data.data.map(order => {
            return {
              value: String(order.id),
              label: `${order.id} (${formatCurrency(order.amount_parcels)})`,
            };
          });

          if (dataFormatted.length > 0) {
            setOrders(dataFormatted);
          }
        })
        .catch(() => setOrders([]));
    }
  }, [bill, participant_id]);

  useEffect(() => {
    api
      .get<ReceiveType[]>(`/receivedTypes`)
      .then(({ data }) => {
        if (!data) return;
        const dataFormatted = data.map(type => {
          return {
            value: String(type.id),
            label: `${type.description}`,
          };
        });

        if (dataFormatted.length > 0) {
          setReceivedType(dataFormatted);
        }
      })
      .catch(() => setReceivedType([]));
  }, []);

  const handleOnSubmit = useCallback(
    async (data: FormSubmitData) => {
      try {
        // Remove all previous errors
        if (formRef && formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          company_id: Yup.string()
            .transform(value => {
              return value.replace(/\D/g, '');
            })
            .required('É obrigatório selecionar a empresa!'),
          vintage_id: Yup.string()
            .transform(value => {
              return value.replace(/\D/g, '');
            })
            .required('É obrigatório selecionar a safra!'),
          participant_id: Yup.string()
            .transform(value => {
              return value.replace(/\D/g, '');
            })
            .required('É obrigatório selecionar o participante!'),
          farm_id: Yup.string().transform(value => {
            return value.replace(/\D/g, '');
          }),
          order_id: Yup.string().transform(value => {
            return value.replace(/\D/g, '');
          }),
          received_type_id: Yup.string()
            .transform(value => {
              return value.replace(/\D/g, '');
            })
            .required('É obrigatório selecionar o tipo de pagamento!'),
          value: Yup.number()
            .positive('O valor deve ser maior que zero!')
            .required('É obrigatório informar o valor!'),
          expired_date: Yup.date().required(
            'A data de vencimento é obrigatória!',
          ),
          own_code: Yup.string(),
          description: Yup.string(),
          observation: Yup.string(),
        });

        const dataResult = await schema.validate(data, {
          abortEarly: false,
        });

        try {
          const response = await api.put<Bill>(`/bills/${bill?.id}`, {
            ...dataResult,
            type: 'receber',
          });

          toast.success('Registro atualizado com sucesso!');

          setBill(response.data);

          if (formRef && formRef.current) {
            formRef.current.setData(response.data);
          }
        } catch (err) {
          errorHandler(err);
        }
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          if (formRef && formRef.current) {
            formRef.current.setErrors(validationErrors);
          }
        }
      }
    },
    [bill],
  );

  if (!bill || !participants) {
    return <Container>Carregando...</Container>;
  }

  return (
    <Container>
      <Header>
        <h1>{`Edição de Contas à Receber - ${bill.id}`}</h1>
        <Divider />
      </Header>

      <Form ref={formRef} onSubmit={handleOnSubmit} initialData={bill}>
        <ContainerInputResponsive>
          <Select
            name="company_id"
            label="Empresa"
            value={company_id}
            onValueChange={company => setCompanyId(company as string)}
            options={companies.map(company => ({
              value: company.id.toString(),
              label: company.social_name,
            }))}
          />
          <Select
            name="vintage_id"
            label="Safra"
            options={vintages.map(vintage => ({
              value: vintage.id.toString(),
              label: vintage.description,
            }))}
          />
          <Select
            name="participant_id"
            label="Participante"
            value={participant_id}
            onValueChange={participant =>
              setParticipantId(participant as string)
            }
            options={participants}
          />
          <Select
            name="farm_id"
            label="Fazenda"
            required={false}
            options={farms}
          />
          <Select
            name="order_id"
            label="Pedido"
            required={false}
            options={orders}
          />
          <Select
            name="received_type_id"
            label="Tipo de recebimento"
            options={receivedType}
          />
          <InputNumber name="value" label="Valor" />
          <DatePicker name="expired_date" label="Data de Vencimento" />
          <Input name="own_code" label="Cód. Próprio" required={false} />
          <Input
            name="description"
            label="Descrição"
            required={false}
            multiline
            rows={4}
          />
          <Input
            name="observation"
            label="Observação"
            required={false}
            multiline
            rows={4}
          />
        </ContainerInputResponsive>
        <Button type="submit">Atualizar</Button>
      </Form>
    </Container>
  );
}
