import styled from 'styled-components';

export const Container = styled.div`
  max-width: 600px;
  min-width: 300px;
  margin: 0px 20px;

  img {
    margin-bottom: 1rem;
  }

  form {
    background: #fff;
    border-radius: ${props => props.theme.borderRadius};
    padding: 20px;

    span {
      font-size: 0.8rem;
    }
  }

  .wrapper-buttons-actions {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
`;
