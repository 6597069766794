import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterList from '@material-ui/icons/FilterList';
import { Options, MaterialTableProps } from 'material-table';

import BaseTable from '../BaseTable';

interface OwnProps<RowData extends object> {
  onRefresh?: () => void;
  onShowCustomFilter?: () => void;
  onAdd?: (event: any, data: RowData | RowData[]) => void;
  onDeleteMany?: (data: object | object[]) => void;
  fileNameExport?: string;
  options?: Options<RowData>;
}

export default function BaseTableDataManager<RowData extends object>({
  onRefresh,
  onShowCustomFilter,
  onAdd,
  onDeleteMany,
  fileNameExport,
  options,
  actions = [],
  ...rest
}: MaterialTableProps<RowData> & OwnProps<RowData>) {
  return (
    <BaseTable
      options={{
        selection: true,
        filtering: true,
        grouping: true,
        exportButton: true,
        exportFileName: fileNameExport,
        exportDelimiter: ';',
        doubleHorizontalScroll: true,
        ...options,
      }}
      actions={[
        {
          icon: AddIcon,
          tooltip: 'Adicionar',
          isFreeAction: true,
          hidden: !onAdd,
          onClick: (event, rowData) => onAdd && onAdd(event, rowData),
        },
        {
          icon: () => <FilterList />,
          tooltip: 'Pesquisa Avançada',
          isFreeAction: true,
          hidden: !onShowCustomFilter,
          onClick: () => onShowCustomFilter && onShowCustomFilter(),
        },
        {
          icon: RefreshIcon,
          tooltip: 'Recarregar',
          isFreeAction: true,
          hidden: !onRefresh,
          onClick: () => onRefresh && onRefresh(),
        },
        {
          icon: DeleteIcon,
          tooltip: 'Remover',
          hidden: !onDeleteMany,
          onClick: (event, rowData) => onDeleteMany && onDeleteMany(rowData),
        },
        ...actions,
      ]}
      {...rest}
    />
  );
}
