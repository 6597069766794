import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { OptionTypeBase } from 'react-select';

import { CheckCircle, Error, Link as LinkIcon } from '@material-ui/icons';
import { FileLoad } from 'types';

import SelectInput from 'components/NormalInputs/Select';

import { Container, FileInfo, Preview } from './styles';

interface FilePlotLoad extends FileLoad {
  north?: number;
  south?: number;
  east?: number;
  west?: number;
  errors?: Record<string, any>;
}

interface OwnProps {
  files: FilePlotLoad[];
  pdfNeedsPlotId?: string;
  vintages: OptionTypeBase[];
  onUpdadeFile?: (id: string, data: any) => void;
}

const PlotAddKMZPDFFileList: React.FC<OwnProps> = ({
  files,
  onUpdadeFile,
  vintages,
}) => {
  return (
    <Container>
      {files.map(file => {
        const {
          id,
          name,
          preview,
          progress,
          error,
          uploaded,
          readableSize,
          url,
        } = file;

        return (
          <li key={id}>
            <FileInfo>
              <Preview src={preview} />
              <div>
                <strong>{name}</strong>
                <span>
                  {`${readableSize}`}
                  {!!url && <button type="button">Excluir</button>}
                </span>
              </div>
            </FileInfo>

            {!url && (
              <div className="wrapper-classe-file-definition">
                <form>
                  <SelectInput
                    name="vintage_id"
                    label="Safra"
                    defaultValue={{
                      value: file.vintage_id,
                      label: file.vintage?.description,
                    }}
                    onChange={(value: any) =>
                      onUpdadeFile &&
                      onUpdadeFile(id, { vintage_id: String(value.value) })
                    }
                    options={vintages}
                  />
                </form>
              </div>
            )}

            <div>
              {!uploaded && !error && (
                <CircularProgress variant="determinate" value={progress} />
              )}

              {url && (
                <a href={url} target="_blank" rel="noopener noreferrer">
                  <LinkIcon style={{ marginRight: 8, color: '#222' }} />
                </a>
              )}

              {uploaded && (
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  id={`file-plot-${id}`}
                >
                  <CheckCircle style={{ marginRight: 8, color: '#78e5d5' }} />
                </a>
              )}

              {error && (
                <a href={url} target="_blank" rel="noopener noreferrer">
                  <Error style={{ marginRight: 8, color: '#e57878' }} />
                </a>
              )}
            </div>
          </li>
        );
      })}
    </Container>
  );
};

export default PlotAddKMZPDFFileList;
