import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
  @import url('https://fonts.googleapis.com/icon?family=Material+Icons');

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  *:focus{
    outline: 0;
  }

  html {
    font-size: 16px;
  }

  html, body, #root {
    height: 100%;
    width: 100%;
  }

  body {
    -webkit-font-smoothing: antialiased;
    font-size: 62.5%;
  }

  .Toastify__toast-container {
    font-size: 1rem;
  }

  body, input, button {
    font-family: 'Roboto', sans-serif;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  .wrapper-section-show-inputinfos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .helper-text-error {
    font-size: .8rem;
    background: red;
    color: #fff;
    margin: 8px 0px!important;
    padding: 10px!important;
    border-radius: 4px;
    line-height: 32px;
    font-weight: bold;
  }

  .dialogcontent-wrapper {
    div > form > button {
      margin-right: 10px;
    }
  }

  .MuiToolbar-regular {
    @media (max-width: 900px) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-start;

      & > * {
        margin-left: 0;
        padding-left: 0;
      }
    }
  }

  .shadow-drop-center {
      border-radius: 50%;
      margin-right: 10px;
      padding: 4px;
      -webkit-animation: shadow-drop-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: shadow-drop-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    /* ----------------------------------------------
      * Generated by Animista on 2020-4-13 15:53:4
      * Licensed under FreeBSD License.
      * See http://animista.net/license for more info.
      * w: http://animista.net, t: @cssanimista
      * ---------------------------------------------- */

    /**
      * ----------------------------------------
      * animation shadow-drop-center
      * ----------------------------------------
      */
    @-webkit-keyframes shadow-drop-center {
      0% {
        -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      }
      100% {
        -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
        box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
      }
    }
    @keyframes shadow-drop-center {
      0% {
        -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      }
      100% {
        -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
        box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
      }
    }
`;
