import styled from 'styled-components';

import Container from 'components/Container';

export const Wrapper = styled(Container)`
  font-size: 1rem;

  .wrapper-witness-section {
    margin: 10px 0px;
    font-size: 1rem;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, max(275px));
  }

  .wrapper-witness-participant {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #333;
    font-size: 1rem;
  }
`;
