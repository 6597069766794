import styled from 'styled-components';

export const Container = styled.div`
  font-size: 0.8rem;
  height: 100%;

  header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .wrapper-alterinfos-participant {
      color: #999;
    }
  }

  .wrapper-plots-files {
    margin: 10px 0px;

    header:first-child {
      border-bottom: 1px solid #333;
    }

    header {
      margin: 5px 0px;
      padding-bottom: 5px;
    }
  }

  .wrapper-data {
    margin: 10px 0px;
    font-size: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, max(600px));
    gap: 10px;
  }

  .wrapper-plots-files-container {
    strong {
      width: 100%;
      display: block;
    }

    table {
      width: 100%;
      background: pink;
      td {
        padding: 5px;
      }
    }
  }
`;

export const ButtonAddFile = styled.button`
  padding: 20px;
  border: 0;
  font-size: 1rem;
  background-color: #cc2147;
  color: #fff;
  border-radius: 4px;
  margin-top: 5px;
  margin-right: 10px;
`;
