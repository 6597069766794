import styled from 'styled-components';

export const Container = styled.ul`
  margin-top: 20px;

  > li {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    align-items: center;
    color: #444;

    & + li {
      margin-top: 15px;
      border-top: 1px dashed #444;
    }

    .wrapper-classe-file-definition {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      align-content: center;
      margin: 20 20px;
      flex: 1;
    }

    & > div {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
    }
  }
`;

export const FileInfo = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;

    span {
      font-size: 0.8rem;
      color: #999;
      margin-top: 5px;

      button {
        font-size: 0.8rem;
        border: 0;
        background: transparent;
        color: #e57878;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
`;

interface A {
  src: string;
}

export const Preview = styled.div<A>`
  width: 36px;
  height: 36px;

  border-radius: 5px;

  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 10px;
`;
