import styled, { css } from 'styled-components';

import Container from 'components/Container';

export const Wrapper = styled(Container)`
  gap: 20px;
  display: grid;
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill, minmax(380px, 100%));
`;

export const InfoContainer = styled.div`
  padding: 20px;
  border: 1px solid #333;
  border-radius: 4px;
  align-content: flex-start;
  justify-content: flex-start;

  header {
    height: 70px;

    font-size: 0.5rem;
  }
`;

const payment = css`
  color: #f12547;
`;

const receive = css`
  color: #00b900;
`;

interface BillListProps {
  payment: boolean;
}

export const BillsList = styled.ul<BillListProps>`
  font-size: 1rem;
  margin-top: 10px;
  padding: 10px;

  li {
    a {
      text-decoration: none;
      display: flex;

      > div {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
      }

      > strong {
        margin-left: 15px;
        ${props => (props.payment ? payment : receive)}
      }
    }

    & + li {
      margin-top: 15px;
      padding-top: 15px;

      border-top: 1px solid #333;
    }
  }
`;
