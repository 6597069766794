import styled from 'styled-components';

import DefaultContainer from 'components/Container';

export const Container = styled(DefaultContainer)``;

interface A {
  src: string;
}

export const Preview = styled.div<A>`
  width: 36px;
  height: 36px;

  border-radius: 5px;

  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 10px;
`;
