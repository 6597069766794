import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Divider, IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import {
  Participant,
  BaseAdonisPaginateReponseInterface,
  Role,
  User,
  Options,
} from 'types';
import { cnpjMask, cpfMask } from 'utils/format';
import {
  getIdentificationFromParticipant,
  getNameFromParticipant,
} from 'utils/renderNameParticipant';
import * as Yup from 'yup';

import Button from 'components/Button';
import { Input, Select } from 'components/Form';
import { ContainerInputResponsive, Header } from 'components/Layout';
import api from 'services/api';
import errorHandler from 'services/errorHandler';

import { Wrapper } from './styles';

interface RouteParam {
  id: string;
}

interface ApiResponseData extends BaseAdonisPaginateReponseInterface {
  data: Participant[];
}

interface ApiReponse {
  data: ApiResponseData;
}

interface ApiResponseDataRole extends BaseAdonisPaginateReponseInterface {
  data: Role[];
}

interface ApiReponseRole {
  data: ApiResponseDataRole;
}

interface ApiReponseDataUser {
  data: User;
}

export default function Edit() {
  const params = useParams<RouteParam>();
  const formRef = useRef<FormHandles>(null);
  const formClientsRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User>();
  const [roles, setRoles] = useState<Options[]>([]);
  const [participants, setParticipants] = useState<Options[]>([]);
  const [clientsAvailable, setClientsAvailable] = useState<Options[]>([]);

  const loadClients = useCallback(async () => {
    try {
      const { data }: ApiReponse = await api.get('/participants');

      setParticipants(
        data.data.map(participant => {
          if (participant.type === 'fisical') {
            return {
              value: String(participant.id),
              label: `${participant.fisical?.name} (${cpfMask(
                participant.fisical?.cpf || '',
              )})`,
            };
          }
          return {
            value: String(participant.id),
            label: `${participant.juridical?.social_name} (${cnpjMask(
              participant.juridical?.cnpj || '',
            )})`,
          };
        }) || [],
      );
    } catch (err) {
      errorHandler(err);
    }
  }, []);

  const loadClientsAvailable = useCallback(async (id: string) => {
    try {
      const { data }: ApiResponseData = await api.get(
        `/seller/${id}/clients?clientsType=notmyclients`,
      );

      setClientsAvailable(
        data.map(participant => {
          if (participant.type === 'fisical') {
            return {
              value: String(participant.id),
              label: `${participant.fisical?.name} (${cpfMask(
                participant.fisical?.cpf || '',
              )})`,
            };
          }
          return {
            value: String(participant.id),
            label: `${participant.juridical?.social_name} (${cnpjMask(
              participant.juridical?.cnpj || '',
            )})`,
          };
        }) || [],
      );
    } catch (err) {
      errorHandler(err);
    }
  }, []);

  const loadRoles = useCallback(async () => {
    try {
      const { data }: ApiReponseRole = await api.get('/roles');

      setRoles(
        data.data.map(role => {
          return {
            value: role.slug,
            label: role.name,
          };
        }) || [],
      );
    } catch (err) {
      errorHandler(err);
    }
  }, []);

  const loadUser = useCallback(async (id: string) => {
    setLoading(true);
    try {
      const { data }: ApiReponseDataUser = await api.get(`users/${id}`);

      setUser(data);
    } catch (err) {
      errorHandler(err);
    }
  }, []);

  const loadAll = useCallback(
    async (id: string) => {
      try {
        setLoading(true);
        await loadClientsAvailable(id);
        await loadClients();
        await loadRoles();
        await loadUser(id);
      } catch (err) {
        errorHandler(err);
      } finally {
        setLoading(false);
      }
    },
    [loadClientsAvailable, loadClients, loadRoles, loadUser],
  );

  useEffect(() => {
    loadAll(params.id);
  }, [params.id, loadAll]);

  const handleSubmit = useCallback(
    async (data: SubmitHandler<FormData>) => {
      try {
        // Remove all previous errors
        if (formRef && formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          participant_id: Yup.string()
            .oneOf(
              participants.map(participant => participant.value),
              'O cliente deve ser selecionado!',
            )
            .required('O tipo do cliente é obrigatório!'),
          username: Yup.string().required('O usuário é obrigatório!'),
          email: Yup.string().required('O email é obrigatório!'),
          roles: Yup.array()
            .of(
              Yup.string().oneOf(
                roles.map(role => role.value),
                'Um dos valores informados não confere com os permitidos',
              ),
            )
            .required('Pelo menos uma regra deve ser selecionada!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        // Validation passed

        try {
          await api.put(`users/${params.id}`, data);
          toast.success('Usuário atualizado com sucesso!');
        } catch (err) {
          errorHandler(err);
        }
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          if (formRef && formRef.current) {
            formRef.current.setErrors(validationErrors);
          }
        }
      }
    },
    [params.id, participants, roles],
  );

  const handleDetachClient = useCallback(
    async (id: number) => {
      try {
        await api.delete(`/seller/${params.id}/clients/${id}`);
        await loadUser(params.id);
      } catch (err) {
        errorHandler(err);
      } finally {
        setLoading(false);
      }
    },
    [loadUser, params.id],
  );

  const handleAttachClient = useCallback(
    async (id: string) => {
      try {
        await api.put(`/seller/${params.id}/clients/${id}`);
        await loadUser(params.id);
        await loadClientsAvailable(params.id);
      } catch (err) {
        errorHandler(err);
      } finally {
        setLoading(false);
      }
    },
    [loadClientsAvailable, loadUser, params.id],
  );

  const handleOnAddCliente = useCallback(
    async (data: SubmitHandler<FormData>) => {
      try {
        // Remove all previous errors
        if (formRef && formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          client_id: Yup.string()
            .oneOf(
              clientsAvailable.map(participant => participant.value),
              'O cliente deve ser selecionado!',
            )
            .required('O tipo do cliente é obrigatório!'),
        });

        await schema
          .validate(data, {
            abortEarly: false,
          })
          .then(response => handleAttachClient(response.client_id));
        // Validation passed
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          if (formRef && formRef.current) {
            formRef.current.setErrors(validationErrors);
          }
        }
      }
    },
    [clientsAvailable, handleAttachClient],
  );

  const RenderClientes = useCallback(() => {
    if (
      (
        user?.roles?.filter(role =>
          ['seller', 'partners'].includes(role.slug),
        ) || []
      ).length > 0
    ) {
      return (
        <div className="wrapper-user-participants">
          <Form ref={formClientsRef} onSubmit={handleOnAddCliente}>
            <Select
              name="client_id"
              label="Cliente"
              options={clientsAvailable}
            />
            <Button type="submit">Adicionar</Button>
          </Form>
          <section className="wrapper-user-participants-container">
            {user?.clients?.map(client => {
              return (
                <div className="wrapper-user-participants-item" key={client.id}>
                  <div className="delete-button-participants-item">
                    <p>{client.id}</p>
                    <IconButton onClick={() => handleDetachClient(client.id)}>
                      <Delete />
                    </IconButton>
                  </div>
                  <p>{getNameFromParticipant(client)}</p>
                  <p>{getIdentificationFromParticipant(client)}</p>
                </div>
              );
            })}
          </section>
        </div>
      );
    }

    return null;
  }, [clientsAvailable, handleDetachClient, handleOnAddCliente, user]);

  if (loading || !user || !participants) {
    return <Wrapper>Carregando dados...</Wrapper>;
  }

  return (
    <Wrapper>
      <Header>
        <h1>Atualização de usuário</h1>
        <Divider />
      </Header>

      <Form
        onSubmit={handleSubmit}
        ref={formRef}
        initialData={{
          ...user,
          participant_id: user.participant_id || '',
          roles: (user?.roles && user.roles.map(role => role.slug)) || [],
        }}
      >
        <ContainerInputResponsive>
          <Select
            name="participant_id"
            label="Participante"
            options={participants}
          />
          <Input name="username" label="Usuário" />
          <Input name="email" label="Email" />
          <Select name="roles" label="Papéis" options={roles} multiple />
        </ContainerInputResponsive>
        <Button type="submit">Atualizar</Button>
      </Form>

      <RenderClientes />
    </Wrapper>
  );
}
