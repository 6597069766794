import { AnyAction } from 'redux';

import { AuthState, AuthTypes } from './types';

const INITIAL_STATE: AuthState = {
  loading: false,
  error: false,
  signed: false,
  auth: undefined,
};

const reducer = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case AuthTypes.AUTH_UPDATE_USER_REQUEST:
      return {
        ...state,
        auth: {
          ...state.auth,
          user: action.payload,
        },
      };
    case AuthTypes.AUTH_REQUEST:
      return { ...state, loading: true, error: false, signed: false };
    case AuthTypes.AUTH_SUCCESS: {
      const { token, user } = action.payload;
      return {
        ...state,
        loading: false,
        error: false,
        signed: true,
        auth: {
          token,
          user,
        },
      };
    }
    case AuthTypes.AUTH_FAILURE:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case AuthTypes.AUTH_LOGOUT:
      return INITIAL_STATE;
    case AuthTypes.AUTH_FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AuthTypes.AUTH_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case AuthTypes.AUTH_FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
