import React from 'react';

import { Typography } from '@material-ui/core';

import { Container } from './styles';

interface Errors {
  errors: string[];
}

const ErrorShow: React.FC<Errors> = ({ errors }) => {
  return (
    <Container>
      {errors.map(error => {
        return (
          <Typography color="error" key={`${Date.now()}-${error}`}>
            {error}
          </Typography>
        );
      })}
    </Container>
  );
};

export default ErrorShow;
