import React, { ReactChild } from 'react';

import { Wrapper } from './styles';

interface OwnProps {
  children: ReactChild;
}

export default function AuthLayout({ children }: OwnProps) {
  return <Wrapper>{children}</Wrapper>;
}
