/* eslint-disable import/no-named-as-default-member */
import React from 'react';
import { Switch } from 'react-router-dom';

import PrintMap from 'components/PrintMap';
import Teste from 'components/Teste';
import { Courses } from 'pages/Courses';
import Dashboard from 'pages/Dashboard';
import ForgotPassword from 'pages/ForgetPassword';
/** Participants CRUD Components */
import OrdersClient from 'pages/Modules/Client/OrdersClient';
import Visualization from 'pages/Modules/Client/Visualization';
import Company from 'pages/Modules/Finances/Company';
import CompanyAdd from 'pages/Modules/Finances/Company/Add';
import CompanyEdit from 'pages/Modules/Finances/Company/Edit';
import CompanyShow from 'pages/Modules/Finances/Company/Show';
import Farm from 'pages/Modules/Finances/Farms';
import FarmAdd from 'pages/Modules/Finances/Farms/Add';
import FarmEdit from 'pages/Modules/Finances/Farms/Edit';
import FarmShow from 'pages/Modules/Finances/Farms/Show';
import Order from 'pages/Modules/Finances/Orders';
import OrderAdd from 'pages/Modules/Finances/Orders/Add';
import OrderDemonstrativo from 'pages/Modules/Finances/Orders/Demonstrativo';
import OrderEdit from 'pages/Modules/Finances/Orders/Edit';
import OrderShow from 'pages/Modules/Finances/Orders/Show';
import Participant from 'pages/Modules/Finances/Participants';
import ParticipantAdd from 'pages/Modules/Finances/Participants/Add';
import ParticipantEdit from 'pages/Modules/Finances/Participants/Edit';
import ParticipantShow from 'pages/Modules/Finances/Participants/Show';
import Payments from 'pages/Modules/Finances/Payments';
import Payment from 'pages/Modules/Finances/Payments/Payment';
import PaymentAdd from 'pages/Modules/Finances/Payments/Payment/Add';
import PaymentEdit from 'pages/Modules/Finances/Payments/Payment/Edit';
import PaymentShow from 'pages/Modules/Finances/Payments/Payment/Show';
import Receives from 'pages/Modules/Finances/Payments/Receive';
import ReceivesAdd from 'pages/Modules/Finances/Payments/Receive/Add';
import ReceivesEdit from 'pages/Modules/Finances/Payments/Receive/Edit';
import ReceivesShow from 'pages/Modules/Finances/Payments/Receive/Show';
import Region from 'pages/Modules/Finances/Regions';
import RegionAdd from 'pages/Modules/Finances/Regions/Add';
import RegionEdit from 'pages/Modules/Finances/Regions/Edit';
import RegionShow from 'pages/Modules/Finances/Regions/Show';
import Witness from 'pages/Modules/Finances/Witness';
import WitnessAdd from 'pages/Modules/Finances/Witness/Add';
import WitnessEdit from 'pages/Modules/Finances/Witness/Edit';
import WitnessShow from 'pages/Modules/Finances/Witness/Show';
import User from 'pages/Modules/Manager/User';
import UserAdd from 'pages/Modules/Manager/User/Add';
import UserEdit from 'pages/Modules/Manager/User/Edit';
import UserShow from 'pages/Modules/Manager/User/Show';
import Production from 'pages/Modules/Production';
import ProductionClientList from 'pages/Modules/Production/ClientList';
import ProductionFarmList from 'pages/Modules/Production/FarmList';
import ProductionOrdersList from 'pages/Modules/Production/OrdersManager';
import ProductionPlotList from 'pages/Modules/Production/PlotList';
import PlotShow from 'pages/Modules/Register/Plot';
import SellerFilesShow from 'pages/Modules/Seller/FilesShow';
import SellerOrders from 'pages/Modules/Seller/Order';
import Playlist from 'pages/Playlist';
import SignOut from 'pages/SignOut';
import ViewFile from 'pages/ViewFile';
import SignIn from '../pages/SignIn';
import Route from './Route';
import ForgotPasswordSuccess from 'pages/ForgetPassword/Success';
import Profile from 'pages/Profile';
import PendingOrders from 'pages/Modules/Finances/PendingOrders';

/**
 * Seller Components
 */

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} isPrivate={false} />
      <Route
        path="/forgotPassword"
        exact
        component={ForgotPassword}
        isPrivate={false}
      />
      <Route
        path="/forgotPasswordSuccess"
        exact
        component={ForgotPasswordSuccess}
      />
      <Route path="/dashboard" exact component={Dashboard} isPrivate />
      <Route path="/signout" exact component={SignOut} isPrivate />

      <Route path="/profile" exact component={Profile} isPrivate />

      <Route
        path="/courses/:id"
        exact
        component={Playlist}
        isPrivate
        roles={['admin', 'seller']}
      />
      <Route
        path="/courses"
        exact
        component={Courses}
        isPrivate
        roles={['admin', 'seller']}
      />

      <Route
        exact
        isPrivate
        path="/finances"
        component={Payments}
        roles={['admin', 'finances']}
      />

      <Route
        path="/participants"
        exact
        component={Participant}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/participants/new"
        exact
        component={ParticipantAdd}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/participants/:id/edit"
        exact
        component={ParticipantEdit}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/participants/:id/show"
        exact
        component={ParticipantShow}
        isPrivate
        roles={['admin', 'finances']}
      />

      <Route
        path="/farms"
        exact
        component={Farm}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/farms/new"
        exact
        component={FarmAdd}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/farms/:id/edit"
        exact
        component={FarmEdit}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/farms/:id/show"
        exact
        component={FarmShow}
        isPrivate
        roles={['admin', 'finances', 'seller']}
      />

      <Route
        path="/users"
        exact
        component={User}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/users/new"
        exact
        component={UserAdd}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/users/:id/edit"
        exact
        component={UserEdit}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/users/:id/show"
        exact
        component={UserShow}
        isPrivate
        roles={['admin', 'finances']}
      />

      <Route
        path="/production"
        exact
        component={Production}
        isPrivate
        roles={['admin', 'production']}
      />

      <Route
        path="/client/files"
        exact
        component={Visualization}
        isPrivate
        roles={['admin', 'client']}
      />

      <Route
        path="/seller/:id/clients"
        exact
        component={ProductionClientList}
        isPrivate
        roles={['admin', 'production']}
      />
      <Route
        path="/client/:id/farms"
        exact
        component={ProductionFarmList}
        isPrivate
        roles={['admin', 'production']}
      />
      <Route
        path="/farm/:id/plots"
        exact
        component={ProductionPlotList}
        isPrivate
        roles={['admin', 'production']}
      />
      <Route
        path="/plots/:id/show"
        exact
        component={PlotShow}
        isPrivate
        roles={['admin', 'production', 'seller']}
      />

      <Route
        path="/teste"
        exact
        component={Teste}
        isPrivate
        roles={['admin', 'production']}
      />

      <Route
        path="/waiting-orders"
        exact
        component={PendingOrders}
        isPrivate
        roles={['admin', 'finances']}
      />

      <Route
        path="/orders"
        exact
        component={Order}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/orders/new"
        exact
        component={OrderAdd}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/orders/demonstrativo"
        exact
        component={OrderDemonstrativo}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/orders/:id/edit"
        exact
        component={OrderEdit}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        exact
        isPrivate
        component={OrderShow}
        path="/orders/:id/show"
        roles={['admin', 'finances', 'seller', 'production', 'client']}
      />

      <Route
        path="/companies"
        exact
        component={Company}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/companies/new"
        exact
        component={CompanyAdd}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/companies/:id/edit"
        exact
        component={CompanyEdit}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/companies/:id/show"
        exact
        component={CompanyShow}
        isPrivate
        roles={['admin', 'finances']}
      />

      <Route
        path="/regions"
        exact
        component={Region}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/regions/new"
        exact
        component={RegionAdd}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/regions/:id/edit"
        exact
        component={RegionEdit}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/regions/:id/show"
        exact
        component={RegionShow}
        isPrivate
        roles={['admin', 'finances']}
      />

      <Route
        path="/witness"
        exact
        component={Witness}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/witness/new"
        exact
        component={WitnessAdd}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/witness/:id/edit"
        exact
        component={WitnessEdit}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/witness/:id/show"
        exact
        component={WitnessShow}
        isPrivate
        roles={['admin', 'finances']}
      />

      <Route
        path="/receives"
        exact
        component={Receives}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/receives/new"
        exact
        component={ReceivesAdd}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/receives/:id/edit"
        exact
        component={ReceivesEdit}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/receives/:id/show"
        exact
        component={ReceivesShow}
        isPrivate
        roles={['admin', 'finances']}
      />

      <Route
        path="/payments"
        exact
        component={Payment}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/payments/new"
        exact
        component={PaymentAdd}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/payments/:id/edit"
        exact
        component={PaymentEdit}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/payments/:id/show"
        exact
        component={PaymentShow}
        isPrivate
        roles={['admin', 'finances']}
      />

      <Route
        path="/file/:id/print"
        exact
        component={PrintMap}
        isPrivate
        roles={['admin', 'finances']}
      />
      <Route
        path="/file/:id"
        exact
        component={ViewFile}
        isPrivate
        roles={['admin', 'finances', 'seller', 'production', 'client']}
      />

      <Route
        path="/me/orders"
        exact
        component={SellerOrders}
        isPrivate
        roles={['seller', 'partners']}
      />
      <Route
        path="/client/orders"
        exact
        component={OrdersClient}
        isPrivate
        roles={['client']}
      />
      <Route
        path="/seller/files"
        exact
        component={SellerFilesShow}
        isPrivate
        roles={['seller', 'partners']}
      />

      <Route
        path="/producao/pedidos"
        exact
        component={ProductionOrdersList}
        isPrivate
        roles={['admin', 'production']}
      />
    </Switch>
  );
}
