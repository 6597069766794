import React from 'react';

// import Vimeo from '@vimeo/player';
import { LessonInterface } from 'types';

import { TimeUpdate } from 'pages/Playlist/types';

interface OwnProps {
  video: LessonInterface;
  onTimeUpdate?: (
    data: TimeUpdate,
    concluded: boolean,
    lesson: LessonInterface,
  ) => void;
}

const VimeoPlayer: React.FC<OwnProps> = () => {
  return <></>;
  // const { video, onTimeUpdate } = props;
  // const [player, setPlayer] = useState<Vimeo>();
  // const iframeRef = useRef<HTMLIFrameElement>(null);
  // const [idInterval, setIdInterval] = useState<number | undefined>();

  // const handleOnTimeUpdate = useCallback(
  //   (data: TimeUpdate, concluded: boolean) => {
  //     if (onTimeUpdate) onTimeUpdate(data, concluded, video);
  //   },
  //   [onTimeUpdate, video],
  // );

  // const clearIdInterval = useCallback(async () => {
  //   await clearInterval(idInterval);
  //   setIdInterval(undefined);
  // }, [idInterval]);

  // const setPlayerWatchers = useCallback(async () => {

  //   let timeUpdate: TimeUpdate;

  //   if (player) {
  //     await player.ready();
  //     player.loadVideo(Number(video.movie));

  //     player.on('play', async () => {
  //       await clearIdInterval();
  //       setIdInterval(
  //         setInterval(() => {
  //           const intermediario = timeUpdate;

  //           handleOnTimeUpdate(intermediario, false);
  //         }, 5000),
  //       );
  //     });

  //     player.on('timeupdate', data => {
  //       timeUpdate = data;
  //     });

  //     player.on('pause', () => {
  //       clearInterval(idInterval);
  //     });

  //     player.on('ended', data => {
  //       handleOnTimeUpdate(data, true);
  //       clearInterval(idInterval);
  //     });
  //   }
  // }, [clearIdInterval, handleOnTimeUpdate, idInterval, player, video]);

  // useEffect(() => {
  //   setPlayerWatchers();
  // }, [video, setPlayerWatchers]);

  // useEffect(() => {
  //   if (iframeRef) {
  //     setPlayer(new Vimeo('vimeoid'));
  //   }
  // }, [iframeRef]);

  // function renderPlayer() {
  //   return (
  //     <iframe
  //       id="vimeoid"
  //       src={`https://player.vimeo.com/video/${video.movie}`}
  //       width="100%"
  //       height="100%"
  //       frameBorder="0"
  //       // webkitallowfullscreen
  //       // mozallowfullscreen
  //       allowFullScreen
  //       title="vimeoid"
  //       ref={iframeRef}
  //     />
  //   );
  // }

  // return renderPlayer();
};
export default VimeoPlayer;
