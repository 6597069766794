import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Divider } from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  Participant,
  BaseAdonisPaginateReponseInterface,
  Options,
  Farm,
} from 'types';
import { cnpjMask, cpfMask } from 'utils/format';
import * as Yup from 'yup';

import Button from 'components/Button';
import { Input, InputNumber, Select } from 'components/Form';
import { ContainerInputResponsive, Header } from 'components/Layout';
import api from 'services/api';
import errorHandler from 'services/errorHandler';

import { loadCitiesParsed } from '../../loaders/CitiesParsed';
import { loadStatesParsed } from '../../loaders/StateParsed';
import { Wrapper } from './styles';

interface RouteParam {
  id: string;
}

interface ApiReponseDataParticipant extends BaseAdonisPaginateReponseInterface {
  data: Participant[];
}

interface ApiResponseParticipant {
  data: ApiReponseDataParticipant;
}

interface FormSubmitData {
  participant_id: string;
  name: string;
  total_ha: number;
  state: string;
  citie: string;
  ie?: string;
}

export default function Edit() {
  const params = useParams<RouteParam>();
  const formRef = useRef<FormHandles>(null);
  const [farm, setFarm] = useState<Farm>();
  const [states, setStates] = useState<Options[]>();
  const [cities, setCities] = useState<Options[]>();
  const [stateSelected, setStateSelected] = useState<string>('');
  const [participants, setParticipants] = useState<Options[]>([]);

  const loadStates = useCallback(async () => {
    const statesParsed = await loadStatesParsed();

    setStates(statesParsed);
    if (statesParsed.length >= 1) {
      setStateSelected(statesParsed[0].value);
    }
  }, []);

  useEffect(() => {
    async function loadCities(state: string | undefined) {
      if (!state) return;
      try {
        const citiesParsed = await loadCitiesParsed(state);

        setCities(citiesParsed);
      } catch (err) {
        toast.error('Não foi possível carregar os estados');
      }
    }

    loadCities(stateSelected);
  }, [stateSelected]);

  useEffect(() => {
    loadStates();
  }, [loadStates]);

  const loadClients = useCallback(async () => {
    try {
      const { data }: ApiResponseParticipant = await api.get(
        '/participants?is_client=true',
      );

      setParticipants(
        data.data.map(participant => {
          if (participant.type === 'fisical') {
            return {
              value: String(participant.id),
              label: `${participant.fisical?.name} (${cpfMask(
                participant.fisical?.cpf || '',
              )})`,
            };
          }
          return {
            value: String(participant.id),
            label: `${participant.juridical?.social_name} (${cnpjMask(
              participant.juridical?.cnpj || '',
            )})`,
          };
        }) || [],
      );
    } catch (err) {
      errorHandler(err);
    }
  }, []);

  const loadFarm = useCallback(
    async (id: string) => {
      if (!states) return;

      try {
        const { data } = await api.get<Farm>(`farms/${id}`);

        setFarm(data);
        const stateFinded = states.find(state => state.label === data.state)
          ?.value;
        if (stateFinded) {
          setStateSelected(stateFinded);
        }
      } catch (err) {
        errorHandler(err);
      }
    },
    [states],
  );

  useEffect(() => {
    loadClients();
    loadFarm(params.id);
  }, [params.id, loadClients, loadFarm]);

  const handleSubmit = useCallback(
    async (data: FormSubmitData) => {
      try {
        // Remove all previous errors
        if (formRef && formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          participant_id: Yup.string()
            .oneOf(
              participants.map(participant => participant.value),
              'O cliente deve ser selecionado',
            )
            .required('O tipo do cliente é obrigatório!'),
          name: Yup.string().required('O nome da fazenda é obrigatório!'),
          total_ha: Yup.string().required(
            'O total de hectares da fazenda é obrigatório!',
          ),
          state: Yup.string().required('O estado é obrigatório'),
          citie: Yup.string().required('A cidade é obrigatória'),
          ie: Yup.string(),
        });

        const result = await schema.validate(
          {
            ...data,
            state: states?.find(state => state.value === data.state)?.label,
            citie: cities?.find(citie => citie.value === data.citie)?.label,
          },
          {
            abortEarly: false,
          },
        );
        // Validation passed

        try {
          await api.put(`farms/${params.id}`, result);
          toast.success('Fazenda atualizada com sucesso!');
        } catch (err) {
          errorHandler(err);
        }
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          if (formRef && formRef.current) {
            formRef.current.setErrors(validationErrors);
          }
        }
      }
    },
    [params.id, participants, cities, states],
  );

  if (!farm || !participants || !states || !cities) {
    return <Wrapper>Carregando dados...</Wrapper>;
  }

  return (
    <Wrapper>
      <Header>
        <h1>Atualização de fazenda</h1>
        <Divider />
      </Header>

      <Form
        onSubmit={handleSubmit}
        ref={formRef}
        initialData={{
          ...farm,
          state: states.find(state => state.label === farm.state)?.value,
          citie: cities.find(citie => citie.label === farm.citie)?.value,
        }}
      >
        <ContainerInputResponsive>
          <Input name="name" label="Nome" />
          <Input name="ie" label="Inscrição Estadual" required={false} />
          <InputNumber
            name="total_ha"
            label="Total de Hectares"
            inputProps={{
              suffix: '',
              prefix: '',
            }}
          />
          <Select
            name="participant_id"
            label="Cliente"
            options={participants}
          />
          <Select
            name="state"
            label="Estado"
            onValueChange={(value: string | string[] | number | number[]) =>
              setStateSelected(value as string)
            }
            options={states}
          />
          {cities && <Select name="citie" label="Cidade" options={cities} />}
        </ContainerInputResponsive>
        <Button type="submit">Atualizar</Button>
      </Form>
    </Wrapper>
  );
}
