import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { Edit } from '@material-ui/icons';
import { Participant } from 'types';
import { cpfMask, formatDateWithoutTimestamps, cnpjMask } from 'utils/format';
import { getNameFromParticipant } from 'utils/renderNameParticipant';

import { AddressInfos, CreateAndUpdatesInfos } from 'components/DefaultRenders';
import Fab from 'components/Fab';
import { ContainerSectionResponsive, Header } from 'components/Layout';
import api from 'services/api';
import errorHandler from 'services/errorHandler';

import { Wrapper as Container } from './styles';

interface RouteParam {
  id: string;
}

export default function Show() {
  const params = useParams<RouteParam>();
  const history = useHistory();
  const [participant, setParticipant] = useState<Participant>();

  const loadParticipant = useCallback(async (id: string) => {
    try {
      const { data } = await api.get<Participant>(`participants/${id}`);

      setParticipant(data);
    } catch (err) {
      errorHandler(err);
    }
  }, []);

  useEffect(() => {
    loadParticipant(params.id);
  }, [params.id, loadParticipant]);

  const RenderFisicalSection = useCallback(() => {
    return (
      <ContainerSectionResponsive>
        <p>
          <b>Nome: </b>
          {participant?.fisical?.name}
        </p>
        <p>
          <b>CPF: </b>
          {cpfMask(participant?.fisical?.cpf || '')}
        </p>
        <p>
          <b>Data de nascimento: </b>
          {formatDateWithoutTimestamps(participant?.fisical?.birthday)}
        </p>
        <p>
          <b>Nome da Mãe: </b>
          {participant?.fisical?.mother_name}
        </p>
      </ContainerSectionResponsive>
    );
  }, [participant]);

  const RenderJuridicalSection = useCallback(() => {
    return (
      <ContainerSectionResponsive>
        <p>
          <b>CNPJ: </b>
          {cnpjMask(participant?.juridical?.cnpj || '')}
        </p>
        <p>
          <b>Razão Social: </b>
          {participant?.juridical?.social_name}
        </p>
        <p>
          <b>Nome fantasia: </b>
          {participant?.juridical?.fantasy_name}
        </p>
        <p>
          <b>Inscrição estadual: </b>
          {participant?.juridical?.state_registration}
        </p>
      </ContainerSectionResponsive>
    );
  }, [participant]);

  const participantType = useMemo(() => {
    switch (participant?.type) {
      case 'fisical':
        return 'Física';

      case 'juridical':
        return 'Jurídica';
      default:
        return 'Não informado';
    }
  }, [participant]);

  if (!participant) return <Container>Carregando dados...</Container>;

  return (
    <Container>
      <Header>
        <h1>{`Participante ${getNameFromParticipant(participant)}`}</h1>
        <CreateAndUpdatesInfos model={participant} />
      </Header>
      <section className="wrapper-participant-content">
        <p>{`Pessoa ${participantType}`}</p>
        <h2>Atribuições</h2>
        <section className="wrapper-types-participant">
          {participant?.is_client && (
            <Chip
              avatar={<Avatar>CL</Avatar>}
              label="Cliente"
              clickable
              color="primary"
              variant="outlined"
            />
          )}
          {participant?.is_employee && (
            <Chip
              avatar={<Avatar>C</Avatar>}
              label="Colaborador"
              clickable
              color="primary"
              variant="outlined"
            />
          )}

          {participant?.is_supplier && (
            <Chip
              avatar={<Avatar>F</Avatar>}
              label="Fornecedor"
              clickable
              color="primary"
              variant="outlined"
            />
          )}

          {participant?.is_partner && (
            <Chip
              avatar={<Avatar>P</Avatar>}
              label="Parceiro"
              clickable
              color="primary"
              variant="outlined"
            />
          )}

          {participant?.is_seller && (
            <Chip
              avatar={<Avatar>V</Avatar>}
              label="Vendedor"
              clickable
              color="primary"
              variant="outlined"
            />
          )}
        </section>

        <h2>Dados</h2>
        {participant?.type === 'fisical' && <RenderFisicalSection />}

        {participant?.type === 'juridical' && <RenderJuridicalSection />}

        <h2>Endereços</h2>
        {participant.address.map(address => (
          <AddressInfos address={address} key={address.id} />
        ))}
      </section>

      <Fab color="secondary" onClick={() => history.push(`edit`)}>
        <Edit />
      </Fab>
    </Container>
  );
}
