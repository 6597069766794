import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Divider } from '@material-ui/core';
import { Scope, FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { Participant, State, City, Options } from 'types';
import { getNameFromParticipant } from 'utils/renderNameParticipant';
import * as Yup from 'yup';

import Button from 'components/Button';
import {
  Input,
  Select,
  DatePicker,
  InputMask,
  SwitchInput,
} from 'components/Form';
import { ContainerInputResponsive, Header } from 'components/Layout';
import api from 'services/api';
import errorHandler from 'services/errorHandler';
import { ApplicationState } from 'store';

import { loadCitiesParsed } from '../../loaders/CitiesParsed';
import { loadStatesParsed } from '../../loaders/StateParsed';
import { Container } from './styles';

export interface ApiReponse {
  data: Participant;
}

export interface ApiStateResponse {
  data: State[];
}

export interface ApiCityResponse {
  data: City[];
}

const INITIAL_DATA = 'fisical';

export default function Add() {
  const formRef = useRef<FormHandles>(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const type = params.get('type');
  const history = useHistory();
  const [stateSelected, setStateSelected] = useState<string | undefined>();
  const [typeValue, setTypeValue] = useState<string>(INITIAL_DATA);
  const { data: companies, selectedCompany } = useSelector(
    (state: ApplicationState) => state.companies,
  );
  const [states, setStates] = useState<Options[]>([]);
  const [cities, setCities] = useState<Options[]>([]);
  const [sellers, setSellers] = useState<Options[]>([]);

  const loadStates = useCallback(async () => {
    const statesParsed = await loadStatesParsed();

    setStates(statesParsed);
    if (statesParsed.length > 1) {
      setStateSelected(statesParsed[0].value);
    }
  }, []);

  const loadCities = useCallback(async (state: string | undefined) => {
    if (!state) return;
    try {
      const citiesParsed = await loadCitiesParsed(state);

      setCities(citiesParsed);
    } catch {
      toast.error('Não foi possível carregar os estados');
    }
  }, []);

  useEffect(() => {
    loadCities(stateSelected);
  }, [loadCities, stateSelected]);

  useEffect(() => {
    loadStates();
  }, [loadStates]);

  const optionsDisable = useMemo(() => {
    return !!type;
  }, [type]);

  const companyOptions = useMemo(() => {
    return (
      companies.map(company => ({
        value: company.id.toString(),
        label: company.social_name,
      })) || []
    );
  }, [companies]);

  const handleOnChangeCompany = useCallback(
    async (company_id: string | number | string[] | number[]) => {
      if (company_id) {
        try {
          const { data } = await api.get<Participant[]>(
            `/company/${company_id}/participants`,
            {
              params: {
                myClients: true,
              },
            },
          );

          const dataFormatted: Options[] = data.map(participant => ({
            value: String(participant?.user?.id),
            label: getNameFromParticipant(participant),
          }));

          setSellers(dataFormatted);
        } catch (error) {
          toast.error(
            'Não foi possível carregar os vendedores da empresa selecionada',
          );
        }
      }
    },
    [],
  );

  const handleSubmit = useCallback(
    async (data: SubmitHandler<FormData>) => {
      try {
        // Remove all previous errors
        if (formRef && formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          type: Yup.string()
            .oneOf(
              ['fisical', 'juridical'],
              'O valor do tipo de participante deve ser Físico ou Jurídico',
            )
            .required('O tipo do participante é obrigatório!'),
          company_id: Yup.string().required(
            'A empresa vinculada é obrigatória',
          ),
          seller_id: Yup.string(),
          fisical: Yup.object().when('type', {
            is: 'fisical',
            then: Yup.object()
              .shape({
                name: Yup.string().required('O nome completo é obrigatório'),
                cpf: Yup.string()
                  .length(11, 'O CPF deve ter 11 caracteres')
                  .required('O CPF é obrigatório'),
                birthday: Yup.date()
                  // .max(subYears(new Date(), 18), 'O participante deve ser maior de idade')
                  .required('A data de nascimento é obrigatória'),
                mother_name: Yup.string().required(
                  'O nome da mãe é obrigatório',
                ),
              })
              .required(),
          }),
          juridical: Yup.object().when('type', {
            is: 'juridical',
            then: Yup.object()
              .shape({
                cnpj: Yup.string()
                  .length(14, 'O CNPJ deve ter 14 caracteres')
                  .required('O CNPJ é obrigatório'),
                social_name: Yup.string().required(
                  'A Razão social é obrigatória',
                ),
                state_registration: Yup.string(),
                fantasy_name: Yup.string().required(
                  'A Nome Fantasia é obrigatório',
                ),
              })
              .required(),
          }),
          address: Yup.object().shape({
            street: Yup.string().required('A rua é obrigatória'),
            neighborhood: Yup.string().required('O bairro é obrigatório'),
            complement: Yup.string(),
            description: Yup.string(),
            cep: Yup.string()
              .transform(value => {
                return value.replace(/\D/g, '');
              })
              .length(8, 'O CEP está inválido'),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        try {
          const response: ApiReponse = await api.post('participants', data);

          toast.success('Participante cadastrado com sucesso!');
          history.push(`/participants/${response.data.id}/show`);

          if (formRef && formRef.current) {
            formRef.current.reset();
          }
        } catch (err) {
          errorHandler(err);
        }
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          if (formRef && formRef.current) {
            formRef.current.setErrors(validationErrors);
          }
        }
      }
    },
    [history],
  );

  const RenderFisicalSection = useCallback(() => {
    return (
      <section>
        <h1>Dados da Pessoa Física</h1>
        <Divider />
        <ContainerInputResponsive>
          <Scope path="fisical">
            <Input name="name" label="Nome completo" />
            <InputMask
              name="cpf"
              label="CPF"
              mask={[
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
              ]}
            />
            <DatePicker name="birthday" label="Data de Nascimento" />
            <Input
              name="mother_name"
              label="Nome da mãe"
              placeholder="Insira o nome da mãe do participante"
            />
          </Scope>
        </ContainerInputResponsive>
      </section>
    );
  }, []);

  const RenderJuridicalSection = useCallback(() => {
    return (
      <section>
        <h1>Dados da Pessoa Jurídica</h1>
        <Divider />
        <ContainerInputResponsive>
          <Scope path="juridical">
            <InputMask
              name="cnpj"
              label="CNPJ"
              mask={[
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '/',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
              ]}
            />
            <Input name="social_name" label="Razão social" />
            <Input name="state_registration" label="Inscrição Estadual" />
            <Input name="fantasy_name" label="Nome Fantasia" />
          </Scope>
        </ContainerInputResponsive>
      </section>
    );
  }, []);

  const returnOptionValue = useCallback(
    (option: string) => {
      return String(type) === option;
    },
    [type],
  );

  return (
    <Container>
      <Header>
        <h1>Cadastro de participantes</h1>
        <Divider />
      </Header>

      <Form
        onSubmit={handleSubmit}
        ref={formRef}
        initialData={{
          type: INITIAL_DATA,
          address: {
            state: stateSelected,
          },
          company_id: selectedCompany?.id,
          is_employee: returnOptionValue('employee'),
          is_seller: returnOptionValue('seller'),
          is_client: returnOptionValue('client'),
          is_supplier: returnOptionValue('supplier'),
          is_partner: returnOptionValue('partner'),
        }}
      >
        <Select
          name="type"
          label="Tipo do Participante"
          onValueChange={value => setTypeValue(value as string)}
          options={[
            { value: 'fisical', label: 'Física' },
            { value: 'juridical', label: 'Jurídica' },
          ]}
        />

        <Select
          name="company_id"
          label="Empresa vinculada"
          onValueChange={handleOnChangeCompany}
          options={companyOptions}
        />

        <SwitchInput
          name="is_employee"
          label="Colaborador"
          disabled={optionsDisable}
        />
        <SwitchInput
          name="is_seller"
          label="Vendedor"
          disabled={optionsDisable}
        />
        <SwitchInput
          name="is_supplier"
          label="Fornecedor"
          disabled={optionsDisable}
        />
        <SwitchInput
          name="is_client"
          label="Cliente"
          disabled={optionsDisable}
        />
        <SwitchInput
          name="is_partner"
          label="Parceiro"
          disabled={optionsDisable}
        />

        {typeValue === 'fisical' && <RenderFisicalSection />}
        {typeValue === 'juridical' && <RenderJuridicalSection />}

        <h1>Endereço</h1>
        <Divider />
        <ContainerInputResponsive>
          <Scope path="address">
            <Input name="street" label="Rua" />
            <Input name="neighborhood" label="Bairro" />
            <Input name="complement" label="Complemento" required={false} />
            <Input name="description" label="Descrição" required={false} />
            <InputMask
              name="cep"
              label="CEP"
              mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
            />

            <Select
              name="state"
              label="Estado"
              onValueChange={value => setStateSelected(value as string)}
              options={states}
            />
            <Select name="city_id" label="Cidade" options={cities} />
          </Scope>
        </ContainerInputResponsive>

        {type === 'client' && (
          <Select
            name="seller_id"
            label="Vendedor principal"
            options={sellers}
          />
        )}

        <Button type="submit">Cadastrar</Button>
      </Form>
    </Container>
  );
}
