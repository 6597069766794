import styled from 'styled-components';

export const Container = styled.div`
  & > form {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    button {
      margin-left: 20px;
      margin-bottom: 0px;
      margin-right: 0px;
    }
  }
`;
