import React, { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import MaterialTable, { MaterialTableProps, Icons } from 'material-table';

export const tableIcons: Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function BaseTable<RowData extends object>(
  props: MaterialTableProps<RowData>,
) {
  const { options } = props;

  return (
    <MaterialTable<RowData>
      icons={tableIcons}
      style={{
        fontSize: 15,
      }}
      options={{
        exportButton: true,
        filtering: false,
        ...options,
      }}
      localization={{
        pagination: {
          labelRowsSelect: 'registros',
          labelDisplayedRows: '{from}-{to} de {count}',
          labelRowsPerPage: 'Registros por página:',
          firstAriaLabel: 'Primeira página',
          firstTooltip: 'Primeira página',
          previousAriaLabel: 'Página anterior',
          previousTooltip: 'Página anterior',
          nextAriaLabel: 'Próxima página',
          nextTooltip: 'Próxima página',
          lastAriaLabel: 'Última página',
          lastTooltip: 'Última página',
        },
        toolbar: {
          addRemoveColumns: 'Adicionar ou remover colunas',
          showColumnsTitle: 'Mostrar colunas',
          showColumnsAriaLabel: 'Mostrar colunas',
          exportTitle: 'Exportar',
          exportAriaLabel: 'Exportar',
          nRowsSelected: '{0} registro(s) selecionado(s)',
          searchTooltip: 'Procurar',
          searchPlaceholder: 'Procurar',
        },
        header: {
          actions: 'Ações',
        },
        body: {
          addTooltip: 'Adicionar',
          deleteTooltip: 'Remover',
          editTooltip: 'Editar',
          emptyDataSourceMessage: 'Não há registros.',
          filterRow: {
            filterTooltip: 'Filtrar',
          },
          editRow: {
            cancelTooltip: 'Cancelar',
            saveTooltip: 'Salvar',
            deleteText: 'Tem certeza que quer remover esse registro ?',
          },
        },
        grouping: {
          placeholder: 'Arraste os títulos para agrupar.',
        },
      }}
      {...props}
    />
  );
}
