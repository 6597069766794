import { call, put, all, takeLatest } from 'redux-saga/effects';

import api from 'services/api';

import { loadFailure, loadSuccess } from './actions';
import { UserTypes } from './types';

export function* load() {
  try {
    const response = yield call(api.get, 'users');

    const { data, ...paginate } = response.data;

    yield put(loadSuccess(paginate, data));
  } catch (err) {
    yield put(loadFailure());
  }
}

export default all([takeLatest(UserTypes.LOAD_REQUEST, load)]);
