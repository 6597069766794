import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import GroupIcon from '@material-ui/icons/Group';
import GroupWork from '@material-ui/icons/GroupWork';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import StoreIcon from '@material-ui/icons/Store';

import AllParticipants from './Tables/All';
import ClientParticipants from './Tables/Client';
import EmployeeParticipants from './Tables/Employee';
import PartnerParticipants from './Tables/Partner';
import SellersParticipants from './Tables/Sellers';
import SupplierParticipants from './Tables/Supplier';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const tabsOptions = [
  'todos',
  'clientes',
  'vendedores',
  'parceiros',
  'fornecedores',
  'colaboradores',
];

export default function ScrollableTabsButtonForce() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const history = useHistory();
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const typeIndex = tabsOptions.findIndex(item => item === query.get('type'));

    setValue(typeIndex >= 0 ? typeIndex : 0);
  }, [search]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<unknown>, newValue: number) => {
      history.push(`/participants?type=${tabsOptions[newValue]}`);
    },
    [history],
  );

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="Abas por tipo de participante"
        >
          <Tab label="Todos" icon={<FavoriteIcon />} {...a11yProps(0)} />
          <Tab label="Clientes" icon={<PersonPinIcon />} {...a11yProps(1)} />
          <Tab label="Vendedores" icon={<StoreIcon />} {...a11yProps(2)} />
          <Tab label="Parceiros" icon={<GroupWork />} {...a11yProps(3)} />
          <Tab
            label="Fornecedores"
            icon={<ShoppingBasket />}
            {...a11yProps(4)}
          />
          <Tab label="Colaboradores" icon={<GroupIcon />} {...a11yProps(5)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <AllParticipants />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ClientParticipants />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SellersParticipants />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <PartnerParticipants />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <SupplierParticipants />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <EmployeeParticipants />
      </TabPanel>
    </div>
  );
}
