import React from 'react';

import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Participant } from 'types';
import { getNameFromParticipant } from 'utils/renderNameParticipant';

import { Container } from './styles';

interface OwnProps {
  participant: Participant;
  onClick?: () => void;
}

const Item: React.FC<OwnProps> = ({ participant, onClick }) => {
  return (
    <Container>
      <div className="delete-button-participants-item">
        <p>{participant.id}</p>
        <IconButton onClick={onClick}>
          <Delete />
        </IconButton>
      </div>
      <p>{getNameFromParticipant(participant)}</p>
    </Container>
  );
};

export default Item;
