import React, { useEffect, useRef } from 'react';

import { TextFieldProps } from '@material-ui/core';
import { useField } from '@unform/core';

import { TInput as TextInput } from './styles';

interface Props {
  name: string;
  label?: string;
}

type InputProps = TextFieldProps & JSX.IntrinsicElements['input'] & Props;

export const Input: React.FC<InputProps> = ({
  name,
  label,
  variant = 'outlined',
  required = true,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRef.current,
    });
  }, [fieldName, registerField]);

  return (
    <TextInput
      required={required}
      label={label}
      inputRef={inputRef}
      defaultValue={defaultValue}
      error={!!error}
      helperText={error}
      variant={variant}
      fullWidth
      {...rest}
    />
  );
};
