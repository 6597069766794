import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

// import RootRef from '@material-ui/core/RootRef';
import { DropContainer, UploadMessage } from './styles';

interface OwnProps {
  onUpload: (files: File[]) => void;
  multiple?: boolean;
  accept?: string | string[];
}

const Upload: React.FC<OwnProps> = ({ onUpload, multiple = true, accept }) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      // Do something with the files
      onUpload(acceptedFiles);
    },
    [onUpload],
  );

  const {
    isDragActive,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({ onDrop, multiple, accept });

  const RenderDragMessage = useCallback(() => {
    if (!isDragActive) {
      return <UploadMessage>Arraste os arquivos aqui</UploadMessage>;
    }

    if (isDragReject) {
      return (
        <UploadMessage type="error">Arquivo não suportado...</UploadMessage>
      );
    }

    return <UploadMessage type="success">Solte os arquivos aqui</UploadMessage>;
  }, [isDragActive, isDragReject]);

  return (
    <DropContainer
      {...getRootProps()}
      isDragActive={isDragActive}
      isDragReject={isDragReject}
    >
      <>
        <input {...getInputProps()} />
        <RenderDragMessage />
      </>
    </DropContainer>
  );
};

export default Upload;
