import React from 'react';

import { Company } from 'types';
import { cnpjMask } from 'utils/format';

import { Container } from './styles';

interface OwnProps {
  company: Company;
}

const CompanyInfos: React.FC<OwnProps> = ({ company }) => {
  return (
    <Container>
      <p>
        <b>CNPJ: </b>
        {cnpjMask(company?.cnpj || '')}
      </p>
      <p>
        <b>Razão Social: </b>
        {company?.social_name}
      </p>
      <p>
        <b>Nome fantasia: </b>
        {company?.fantasy_name}
      </p>
      <p>
        <b>Região: </b>
        {company?.region?.description}
      </p>
    </Container>
  );
};

export default CompanyInfos;
