/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { Order } from 'types';
import { formatCurrency } from 'utils/formatCurrency';

import api from 'services/api';
import { ApplicationState } from 'store';

import { Container, CardHa } from './styles';

interface DatasetInterface {
  [key: string]: number | string | string[] | number[] | boolean;
}

const Seller: React.FC = () => {
  const user = useSelector((state: ApplicationState) => state.auth.auth?.user);
  const selectedVintage = useSelector(
    (state: ApplicationState) => state.vintages.selectedVintage,
  );
  const [orders, setOrders] = useState<Order[]>([]);
  const [datasets, setDatasets] = useState<DatasetInterface>({
    label: 'Total vendido por mês',
    fill: false,
    lineTension: 0.1,
    backgroundColor: 'rgba(75,192,192,0.4)',
    borderColor: 'rgba(75,192,192,1)',
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: 'rgba(75,192,192,1)',
    pointBackgroundColor: '#fff',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    pointHoverBorderColor: 'rgba(220,220,220,1)',
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
    data: [],
  });

  useEffect(() => {
    async function loadOrders(id: number, vintage_id: number) {
      const { data } = await api.get(`seller/${id}/orders`, {
        params: {
          vintage_id,
        },
      });

      setOrders(data);
    }

    if (selectedVintage?.id && user?.participant_id)
      loadOrders(user.participant_id, selectedVintage.id);
  }, [user, selectedVintage]);

  const soldHa = useMemo(() => {
    return orders
      .filter(order => !order.is_demo)
      .reduce((prev, curr) => {
        return prev + curr.hectares;
      }, 0);
  }, [orders]);

  const demoHa = useMemo(() => {
    return orders
      .filter(order => order.is_demo)
      .reduce((prev, curr) => {
        return prev + curr.hectares;
      }, 0);
  }, [orders]);

  const totalAmountFormatted = useMemo(() => {
    return formatCurrency(
      orders
        .filter(order => !order.is_demo)
        .reduce((prev, curr) => {
          return prev + curr.amount;
        }, 0),
    );
  }, [orders]);

  const totalReceivedFormatted = useMemo(() => {
    return formatCurrency(
      orders
        .filter(order => !order.is_demo)
        .map(order =>
          order.bills
            .map(bill =>
              bill.payments
                .filter(payment => !payment.is_canceled)
                .reduce((prev, curr) => {
                  return prev + curr.value;
                }, 0),
            )
            .reduce((prev, curr) => {
              return prev + curr;
            }, 0),
        )
        .reduce((prev, curr) => {
          return prev + curr;
        }, 0),
    );
  }, [orders]);

  const amountGroupByMonth = useMemo(() => {
    const dates: { [key: string]: number } = {};

    orders
      .sort((a, b) => {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      })
      .forEach(value => {
        const monthIndex = new Date(value.created_at).getMonth();
        const yearIndex = new Date(value.created_at).getFullYear();

        dates[`${monthIndex}/${yearIndex}`] =
          (dates[`${monthIndex}/${yearIndex}`] || 0) + value.amount;
      });

    return dates;
  }, [orders]);

  useEffect(() => {
    setDatasets(current => ({
      ...current,
      data: Object.keys(amountGroupByMonth).map(
        item => amountGroupByMonth[item],
      ),
    }));
  }, [amountGroupByMonth]);

  return (
    <Container>
      <section>
        <CardHa>
          <h1>Hectares Vendidos</h1>
          <strong>{soldHa}</strong>
        </CardHa>
        <CardHa>
          <h1>Hectares Demonstrativos</h1>
          <strong>{demoHa}</strong>
        </CardHa>
        <CardHa>
          <h1>Total vendido</h1>
          <strong>{totalAmountFormatted}</strong>
        </CardHa>
        <CardHa>
          <h1>Total recebido</h1>
          <strong>{totalReceivedFormatted}</strong>
        </CardHa>
      </section>
      <section>
        <Line
          data={{
            labels: Object.keys(amountGroupByMonth)
              .sort((a, b) => {
                const [monthA, yearA] = a.split('/');
                const [monthB, yearB] = b.split('/');

                return (
                  new Date(Number(yearA), Number(monthA), 1).getTime() -
                  new Date(Number(yearB), Number(monthB), 1).getTime()
                );
              })
              .map(item => {
                const [month, year] = item.split('/');

                return format(
                  new Date(Number(year), Number(month), 1),
                  "MMMM'/'yyyy",
                  {
                    locale: ptBR,
                  },
                );
              }),
            datasets: [datasets],
          }}
        />
      </section>
    </Container>
  );
};

export default Seller;
