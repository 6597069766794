import React, { useRef } from 'react';
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
} from 'react-select';

import { Container } from './styles';

interface Props extends SelectProps<OptionTypeBase> {
  name: string;
  label?: string;
  required?: boolean;
  error?: string;
}

export const Select: React.FC<Props> = ({
  name,
  label,
  defaultValue,
  error,
  required = true,
  ...rest
}) => {
  const selectRef = useRef(null);

  return (
    <Container>
      {label && <b>{`${label}${required ? '*' : ''}`}</b>}
      <ReactSelect
        name={name}
        defaultValue={defaultValue}
        ref={selectRef}
        classNamePrefix="react-select"
        isClearable
        isSearchable
        {...rest}
      />

      {error && <span className="helper-text-error">{error}</span>}
    </Container>
  );
};

export default Select;
