import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-gap: 20px;

  .wrapper-course-item {
    background: #fff9;
    border-radius: 4px;
    border: none;
    border: 0.5px solid ${props => props.theme.colors.secondary};
    cursor: pointer;

    .wrapper-course-banner {
      height: 100px;
      background: ${props => props.theme.colors.secondary};
    }

    .wrapper-course-content {
      padding: 20px;

      h1 {
        color: ${props => props.theme.colors.main};
        border-bottom: 1px solid ${props => props.theme.colors.main};
        margin-bottom: 5px;
        padding-bottom: 5px;
        font-size: 1.3rem;
      }

      p {
        font-weight: bold;
        line-height: 20px;
        font-size: 0.9rem;
      }
    }
  }

  .wrapper-course-item:hover {
    padding: 4px;
    -webkit-animation: shadow-drop-center 0.4s
      cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: shadow-drop-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
`;
