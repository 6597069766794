import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupIcon from '@material-ui/icons/Group';
import GroupWork from '@material-ui/icons/GroupWork';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import FolderIcon from '@material-ui/icons/Folder';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import StoreIcon from '@material-ui/icons/Store';

import { ApplicationState } from 'store';

import Admin from './Admin';
import Employee from './Employee';
import Finance from './Finance';
import Manager from './Manager';
import Partner from './Partner';
import Production from './Production';
import Seller from './Seller';
import Client from './Client';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  className?: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  tab: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ScrollableTabsButtonForce() {
  const classes = useStyles();
  const user = useSelector((state: ApplicationState) => state.auth.auth?.user);
  const [roles, setRoles] = useState<string[]>([]);
  const [value, setValue] = useState(0);

  useEffect(() => {
    const userRoles = user?.roles?.map(role => role.slug);

    if (userRoles) {
      setRoles(userRoles);
    }
  }, [user]);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={(_, tabIndex) => setValue(tabIndex)}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="Dashboards para tipos de usuários"
        >
          {roles.map((role, index) => {
            switch (role) {
              case 'admin':
                return (
                  <Tab
                    key={role}
                    label="Administrador"
                    icon={<DashboardIcon />}
                    {...a11yProps(index)}
                  />
                );
              case 'production':
                return (
                  <Tab
                    key={role}
                    label="Produção"
                    icon={<PersonPinIcon />}
                    {...a11yProps(index)}
                  />
                );
              case 'manager':
                return (
                  <Tab
                    key={role}
                    label="Gerentes"
                    icon={<StoreIcon />}
                    {...a11yProps(index)}
                  />
                );
              case 'employee':
                return (
                  <Tab
                    key={role}
                    label="Colaboradores"
                    icon={<GroupWork />}
                    {...a11yProps(index)}
                  />
                );
              case 'partners':
                return (
                  <Tab
                    key={role}
                    label="Parceiros"
                    icon={<ShoppingBasket />}
                    {...a11yProps(index)}
                  />
                );
              case 'finances':
                return (
                  <Tab
                    key={role}
                    label="Financeiro"
                    icon={<GroupIcon />}
                    {...a11yProps(index)}
                  />
                );
              case 'seller':
                return (
                  <Tab
                    key={role}
                    label="Vendedores"
                    icon={<GroupIcon />}
                    {...a11yProps(index)}
                  />
                );
              case 'client':
                return (
                  <Tab
                    key={role}
                    label="Últimos arquivos adicionados"
                    icon={<FolderIcon />}
                    {...a11yProps(index)}
                  />
                );
              default:
                return null;
            }
          })}
        </Tabs>
      </AppBar>
      {roles.map((role, index) => {
        switch (role) {
          case 'admin':
            return (
              <TabPanel value={index} index={index} key={role}>
                <Admin />
              </TabPanel>
            );
          case 'production':
            return (
              <TabPanel value={index} index={index} key={role}>
                <Production />
              </TabPanel>
            );
          case 'manager':
            return (
              <TabPanel value={index} index={index} key={role}>
                <Manager />
              </TabPanel>
            );
          case 'employee':
            return (
              <TabPanel value={index} index={index} key={role}>
                <Employee />
              </TabPanel>
            );
          case 'partners':
            return (
              <TabPanel value={index} index={index} key={role}>
                <Partner />
              </TabPanel>
            );
          case 'finances':
            return (
              <TabPanel value={index} index={index} key={role}>
                <Finance />
              </TabPanel>
            );
          case 'seller':
            return (
              <TabPanel value={index} index={index} key={role}>
                <Seller />
              </TabPanel>
            );
          case 'client':
            return (
              <TabPanel value={index} index={index} key={role}>
                <Client />
              </TabPanel>
            );
          default:
            return null;
        }
      })}
    </div>
  );
}
