/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { useParams, useHistory } from 'react-router-dom';
import RenderFiles from 'pages/Modules/Register/Plot/components/RenderFiles';
import { FcRadarPlot } from 'react-icons/fc';
import { AxiosResponse } from 'axios';
import filesize from 'filesize';
import { Plot, Farm, FileKMZLoad, Options, FileModel } from 'types';
import { getPermissionsArray } from 'utils/getRolesAndPermissions';
import { uuid } from 'uuidv4';
import * as Yup from 'yup';
import Tabs from 'components/Tabs';
import Button from 'components/Button';
import { HeaderSearchContainer } from 'components/DefaultRenders';
import DialogScroll from 'components/DialogScroll';
import { Header } from 'components/Layout';
import PlotAddKMZPDFFileList from 'components/PlotAddKMZPDFFileList';
import Upload from 'components/Upload';
import api from 'services/api';
import apiConvertFiles from 'services/apiConvertFiles';
import { ApplicationState } from 'store';

import RenderPlot from './components/RenderPlots';
import { Container, ButtonAddFile } from './styles';

interface Params {
  id: string;
}

interface AxiosProgress {
  loaded: number;
  total: number;
}

type openOptions = 'croqui' | 'laudo' | 'mapa-aplicacao' | 'none';

const optionsUpload = {
  croqui: ['application/vnd.google-earth.kmz', 'application/pdf'],
  laudo: [
    '.csv',
    'text/csv',
    'application/vnd.ms-excel',
    'application/csv',
    'text/x-csv',
    'application/x-csv',
    'text/comma-separated-values',
    'text/x-comma-separated-values',
  ],
  // 'pontos-coleta': ['application/vnd.google-earth.kmz', 'application/pdf'],
  'mapa-aplicacao': ['application/vnd.google-earth.kmz', 'application/pdf'],
  none: [],
};

const PlotList: React.FC = () => {
  const params = useParams<Params>();
  const history = useHistory();
  const [open, setOpen] = useState<openOptions>('none');
  const [farm, setFarm] = useState<Farm>();
  const [wordFilter, setWordFilter] = useState('');
  const [filtered, setFiltered] = useState<Plot[]>([]);
  const [uploadedFile, setUploadedFiles] = useState<FileKMZLoad[]>([]);
  const [vintagesFormatted, setVintagesFormatted] = useState<Options[]>([]);
  const { data: vintages, selectedVintage } = useSelector(
    (state: ApplicationState) => state.vintages,
  );
  const { auth } = useSelector((state: ApplicationState) => state.auth);

  useEffect(() => {
    setVintagesFormatted(
      vintages.map(vintage => ({
        label: vintage.description,
        value: String(vintage.id),
      })),
    );
  }, [vintages]);

  useEffect(() => {
    async function loadFarm(id: string) {
      const { data } = await api.get<Farm>(`farms/${id}`);
      setFarm(data);
    }
    loadFarm(params.id);
  }, [params.id]);

  const updateFile = useCallback((id: string, data: any) => {
    setUploadedFiles(current => {
      if (!current) {
        return [];
      }

      const index = current.findIndex(file => String(file.id) === id);

      if (index >= 0) {
        const newState = current;
        newState[index] = { ...newState[index], ...data };

        return newState;
      }

      return current;
    });
  }, []);

  const returnLabelOption = useCallback((key: string) => {
    switch (key) {
      case 'croqui':
        return 'Croqui';
      // case 'pontos-coleta':
      //   return 'Pontos de Coleta';
      case 'laudo':
        return 'Laudos';
      case 'mapa-aplicacao':
        return 'Mapas de Aplicação';
      default:
        return '';
    }
  }, []);

  const returnClassName = useCallback((classe: string, type: string) => {
    switch (classe) {
      case 'croqui':
        switch (type) {
          case 'application/vnd.google-earth.kmz':
            return 'croqui';
          case 'application/pdf':
            return 'croquipdf';
          default:
            return '';
        }
      case 'laudo':
        return 'laudo';
      case 'mapa-aplicacao':
        switch (type) {
          case 'application/vnd.google-earth.kmz':
            return 'mapa-aplicacao';
          case 'application/pdf':
            return 'mapdf';
          default:
            return '';
        }
      default:
        return '';
    }
  }, []);

  const uploadNormalFile = useCallback(
    async (file: FileKMZLoad) => {
      const { classe, vintage_id, size } = file;
      const data = new FormData();

      data.append('file', file.file, file.name);

      api
        .post(`plots/${params.id}/files`, data, {
          params: {
            classe,
            vintage_id,
            size,
          },
          onUploadProgress: (e: AxiosProgress) => {
            // eslint-disable-next-line radix
            const progress = parseInt(
              String(Math.round((e.loaded * 100) / e.total)),
            );

            updateFile(file.id, { progress });
          },
        })
        .then(async (response: AxiosResponse<FileModel>) => {
          updateFile(file.id, {
            uploaded: true,
            id: String(response.data.id),
            url: response.data.url,
            error: false,
          });
        })
        .catch(() => {
          updateFile(file.id, {
            error: true,
          });
        });
    },
    [params.id, updateFile],
  );

  const uploadCroqui = useCallback(
    async (file: FileKMZLoad) => {
      try {
        const schema = Yup.object().shape({
          vintage_id: Yup.string().required('A safra é obrigatória'),
        });

        const dataValidate = await schema.validate(
          {
            vintage_id: file.vintage_id,
          },
          {
            abortEarly: false,
          },
        );

        updateFile(file.id, {
          errors: {},
        });

        const data = new FormData();

        data.append('file', file.file, file.name);

        apiConvertFiles
          .post('croqui/farm', data, {
            params: {
              farm_id: farm?.id,
              vintage_id: dataValidate.vintage_id,
            },
            onUploadProgress: (e: AxiosProgress) => {
              // eslint-disable-next-line radix
              const progress = parseInt(
                String(Math.round((e.loaded * 100) / e.total)),
              );
              updateFile(file.id, { progress });
            },
          })
          .then((response: AxiosResponse<FileModel>) => {
            updateFile(file.id, {
              uploaded: true,
              error: false,
              progress: 100,
              url: response.data.url,
            });
          })
          .catch(() => {
            updateFile(file.id, {
              error: true,
              uploaded: false,
            });
          });
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          updateFile(file.id, {
            errors: validationErrors,
          });
        }
      }
    },
    [farm, updateFile],
  );

  // const uploadPontosColeta = useCallback(
  //   async (file: FileKMZLoad) => {
  //     try {
  //       const schema = Yup.object().shape({
  //         vintage_id: Yup.string().required('A safra é obrigatória')
  //       });

  //       const dataValidate = await schema.validate(
  //         {
  //           vintage_id: file.vintage_id
  //         },
  //         {
  //           abortEarly: false
  //         }
  //       );

  //       updateFile(file.id, {
  //         errors: {}
  //       });

  //       const data = new FormData();

  //       data.append('file', file.file, file.name);

  //       apiConvertFiles
  //         .post('points', data, {
  //           params: {
  //             farm_id: farm!.id,
  //             vintage: dataValidate.vintage_id
  //           },
  //           onUploadProgress: (e: AxiosProgress) => {
  //             // eslint-disable-next-line radix
  //             const progress = parseInt(String(Math.round((e.loaded * 100) / e.total)));

  //             updateFile(file.id, { progress });
  //           }
  //         })
  //         .then(async (response: AxiosResponse<FileModel>) => {
  //           updateFile(file.id, {
  //             uploaded: true,
  //             url: response.data.url,
  //             error: false
  //           });
  //         })
  //         .catch(() => {
  //           updateFile(file.id, {
  //             error: true
  //           });
  //         });
  //     } catch (err) {
  //       const validationErrors: Record<string, any> = {};
  //       if (err instanceof Yup.ValidationError) {
  //         err.inner.forEach(error => {
  //           validationErrors[error.path] = error.message;
  //         });

  //         updateFile(file.id, {
  //           errors: validationErrors
  //         });
  //       }
  //     }
  //   },
  //   [farm, updateFile]
  // );

  const uploadLaudo = useCallback(
    async (file: FileKMZLoad) => {
      const { vintage_id } = file;
      try {
        // const schema = Yup.object().shape({
        //   vintage_id: Yup.string().required('A safra é obrigatória'),
        // });

        // const dataValidate = await schema.validate(
        //   {
        //     vintage_id,
        //   },
        //   {
        //     abortEarly: false,
        //   },
        // );

        // updateFile(file.id, {
        //   errors: {},
        // });

        const data = new FormData();

        data.append('file', file.file, file.name);

        api
          .post(`/farms/${farm?.id}/files`, data, {
            params: {
              classe: 'laudo',
              data,
              vintage_id,
            },
          })
          .then((response: AxiosResponse<FileModel>) => {
            updateFile(file.id, {
              uploaded: true,
              url: response.data.url,
              error: false,
            });
          })
          .catch(() => {
            updateFile(file.id, {
              error: true,
            });
          });

        // apiConvertFiles
        //   .post('laudo', data, {
        //     params: {
        //       farm_id: farm?.id,
        //       vintage: dataValidate.vintage_id,
        //     },
        //     onUploadProgress: (e: AxiosProgress) => {
        //       const progress = parseInt(
        //         String(Math.round((e.loaded * 100) / e.total)),
        //       );

        //       updateFile(file.id, { progress });
        //     },
        //   })
        //   .then(async (response: AxiosResponse<FileModel>) => {
        //     updateFile(file.id, {
        //       uploaded: true,
        //       url: response.data.url,
        //       error: false,
        //     });
        //   })
        //   .catch(() => {
        //     updateFile(file.id, {
        //       error: true,
        //     });
        //   });
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          updateFile(file.id, {
            errors: validationErrors,
          });
        }
      }
    },
    [farm, updateFile],
  );

  const uploadMa = useCallback(
    async (file: FileKMZLoad) => {
      try {
        const schema = Yup.object().shape({
          vintage_id: Yup.string().required('A safra é obrigatória'),
        });

        const dataValidate = await schema.validate(
          {
            vintage_id: file.vintage_id,
          },
          {
            abortEarly: false,
          },
        );

        updateFile(file.id, {
          errors: {},
        });

        const data = new FormData();

        data.append('file', file.file, file.name);

        apiConvertFiles
          .post('ma/farm', data, {
            params: {
              farm_id: farm?.id,
              vintage_id: dataValidate.vintage_id,
            },
            onUploadProgress: (e: AxiosProgress) => {
              const progress = parseInt(
                String(Math.round((e.loaded * 100) / e.total)),
              );

              updateFile(file.id, { progress });
            },
          })
          .then(async (response: AxiosResponse<FileModel>) => {
            updateFile(file.id, {
              uploaded: true,
              url: response.data.url,
              error: false,
            });
          })
          .catch(() => {
            updateFile(file.id, {
              error: true,
            });
          });
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          updateFile(file.id, {
            errors: validationErrors,
          });
        }
      }
    },
    [farm, updateFile],
  );

  const handleOnClickFarmItem = useCallback(
    (id: number) => history.push(`/plots/${id.toString()}/show`),
    [history],
  );

  const handleOnCloseFilter = useCallback(() => {
    setOpen('none');
  }, []);

  const handleOnFilter = useCallback(
    (word: string) => {
      setWordFilter(word);
      if (!word || word === '') setFiltered([]);

      if (farm?.plots) {
        const regex = new RegExp(`${word}`, 'gmi');

        setFiltered(
          farm?.plots?.filter(plot => plot.name.match(regex) !== null),
        );
      }
    },
    [farm],
  );

  const handleUploadFile = useCallback(
    (files: File[], classe: string) => {
      const filesUploaded = files.map(file => ({
        file,
        id: uuid(),
        vintage_id: String(selectedVintage?.id),
        vintage: selectedVintage,
        name: file.name,
        size: file.size,
        readableSize: filesize(file.size),
        preview: URL.createObjectURL(file),
        progress: 0,
        uploaded: false,
        error: false,
        classe: returnClassName(classe, file.type),
      }));

      setUploadedFiles(filesUploaded);
    },
    [returnClassName, selectedVintage],
  );

  const handleFileProcess = useCallback(
    (file: FileKMZLoad) => {
      switch (file.classe) {
        case 'croqui':
          uploadCroqui(file);
          break;
        // case 'pontos-coleta':
        //   uploadPontosColeta(file);
        //   break;
        case 'laudo':
          uploadLaudo(file);
          break;
        case 'mapa-aplicacao':
          uploadMa(file);
          break;
        default:
          uploadNormalFile(file);
      }
    },
    [uploadCroqui, uploadLaudo, uploadMa, uploadNormalFile],
  );

  const handleOnListProcess = useCallback(() => {
    uploadedFile.filter(item => !item.uploaded).forEach(handleFileProcess);
  }, [handleFileProcess, uploadedFile]);

  const permissions = useMemo(() => {
    return getPermissionsArray(auth?.user.permissions);
  }, [auth]);

  const actualRoles = useMemo(() => {
    return auth?.user.roles?.map(i => i.slug) || [];
  }, [auth]);

  return (
    <Container>
      <Header>
        <h1>{`Talhões da fazenda: ${farm?.name}`}</h1>
      </Header>
      <section>
        {permissions.includes('manager_croqui') ||
        actualRoles.includes('admin') ? (
          <ButtonAddFile onClick={() => setOpen('croqui')}>
            Adicionar Croqui
          </ButtonAddFile>
        ) : (
          <></>
        )}
        {/* <ButtonAddFile onClick={() => setOpen('pontos-coleta')}>Adicionar Pontos de Coleta</ButtonAddFile> */}
        {permissions.includes('manager_laudo') ||
        actualRoles.includes('admin') ? (
          <ButtonAddFile onClick={() => setOpen('laudo')}>
            Adicionar Laudo
          </ButtonAddFile>
        ) : (
          <></>
        )}
        {permissions.includes('manager_mapa_aplicacao') ||
        actualRoles.includes('admin') ? (
          <ButtonAddFile onClick={() => setOpen('mapa-aplicacao')}>
            Adicionar Mapa de Aplicação
          </ButtonAddFile>
        ) : (
          <></>
        )}
      </section>

      <Tabs
        tabs={[
          {
            label: 'Talhões',
            icon: <FcRadarPlot size={30} />,
            item: (
              <div>
                <HeaderSearchContainer
                  onChangeText={handleOnFilter}
                  textFieldLabel="Buscar Talhões"
                />

                {farm?.plots && (
                  <RenderPlot
                    plots={wordFilter ? filtered : farm?.plots}
                    onItemClick={handleOnClickFarmItem}
                  />
                )}
              </div>
            ),
          },
          {
            label: 'Arquivos',
            icon: <AiOutlineFileSearch size={30} />,
            item: (
              <RenderFiles files={farm?.files || []} onDeleteFile={() => {}} />
            ),
          },
        ]}
        idHelper="exibicao-fazendas"
      />

      <DialogScroll
        open={open !== 'none'}
        fullWidth
        maxWidth={false}
        dialogTitle={`Inclusão de ${returnLabelOption(open)}`}
        dialogContentText="Arraste os arquivos para a caixa abaixo para incluí-los mais rapidamente"
        onClose={handleOnCloseFilter}
        onClickActionCancelButton={handleOnCloseFilter}
        dialogActions={<div />}
      >
        <section>
          <Upload
            onUpload={files => handleUploadFile(files, open)}
            accept={optionsUpload[open]}
          />
          {!!uploadedFile.length && (
            <>
              <PlotAddKMZPDFFileList
                files={uploadedFile}
                onUpdadeFile={updateFile}
                vintages={vintagesFormatted}
              />
              <Button onClick={handleOnCloseFilter} color="secondary">
                Cancelar
              </Button>
              {uploadedFile.filter(item => !item.uploaded).length > 0 && (
                <Button
                  onClick={handleOnListProcess}
                  style={{ marginLeft: 10 }}
                >
                  Enviar Arquivos
                </Button>
              )}
            </>
          )}
        </section>
      </DialogScroll>
    </Container>
  );
};

export default PlotList;
