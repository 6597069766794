import React from 'react';

import { Plot } from 'types';

import { Container } from './styles';

interface OwnProps {
  plot: Plot;
  onClick?: () => void;
}

const Item: React.FC<OwnProps> = ({ plot, onClick }) => {
  return (
    <Container onClick={onClick} isClickable={!!onClick}>
      <p>{`Cód: ${plot.id}`}</p>
      <p>{`Talhão: ${plot.name}`}</p>
    </Container>
  );
};

export default Item;
