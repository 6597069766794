import React, { useCallback } from 'react';
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
  ValueType,
} from 'react-select';

import { Options } from 'types';

import { Container } from './styles';

export interface ValueOption extends OptionTypeBase {
  value: number;
  label?: string;
}

export interface Props extends SelectProps<OptionTypeBase> {
  name: string;
  label?: string;
}

interface OwnProps {
  error?: string;
  onChangeValue?(value: Options | undefined): void;
}

// eslint-disable-next-line react/prop-types
export const Select: React.FC<Props & OwnProps> = ({
  name,
  error,
  label,
  className,
  onChangeValue,
  ...rest
}) => {
  const handleValueChange = useCallback(
    (inputValue: ValueType<OptionTypeBase, false>) => {
      if (onChangeValue) {
        onChangeValue(inputValue?.values);
      }
    },
    [onChangeValue],
  );

  return (
    <Container className={className}>
      {label && <b>{label}</b>}
      <ReactSelect
        classNamePrefix="react-select"
        name={name}
        onChange={handleValueChange}
        {...rest}
      />

      {error && <span className="helper-text-error">{error}</span>}
    </Container>
  );
};

export default Select;
