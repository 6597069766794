import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { Edit } from '@material-ui/icons';
import { Company } from 'types';
import { cpfMask } from 'utils/format';

import {
  CreateAndUpdatesInfos,
  AddressInfos,
  Company as CompanyInfo,
} from 'components/DefaultRenders';
import Fab from 'components/Fab';
import ImagemDialogShow from 'components/ImagemDialogShow';
import { Section, Header } from 'components/Layout';
import api from 'services/api';
import errorHandler from 'services/errorHandler';

import { Wrapper as Container } from './styles';

interface RouteParam {
  id: string;
}

export interface ApiReponse {
  data: Company;
}

export default function Show() {
  const params = useParams<RouteParam>();
  const history = useHistory();
  const [company, setCompany] = useState<Company>();

  async function loadCompany(id: string) {
    try {
      const { data }: ApiReponse = await api.get(`companies/${id}`);

      setCompany(data);
    } catch (err) {
      errorHandler(err);
    }
  }

  useEffect(() => {
    loadCompany(params.id);
  }, [params.id]);

  function renderSignatureSection() {
    if (company && company.signature) {
      const { signature } = company;
      return (
        <section className="wrapper-">
          <ImagemDialogShow
            url={signature.url || ''}
            alt="Imagem de assinatura"
            editable={false}
          />
        </section>
      );
    }
    return <></>;
  }

  function renderWitnessSection() {
    return (
      <section className="wrapper-witness-section">
        {company &&
          company.witness?.map(witness => {
            return (
              <section
                className="wrapper-witness-participant"
                key={String(witness.id)}
              >
                <p>
                  <b>Nome: </b>
                  {witness.name}
                </p>
                <p>
                  <b>CPF: </b>
                  {cpfMask(witness.cpf)}
                </p>
                <ImagemDialogShow
                  url={witness.file?.url || ''}
                  alt="Imagem de assinatura"
                  editable={false}
                />
              </section>
            );
          })}
      </section>
    );
  }

  if (!company) {
    return <div>Carregando...</div>;
  }

  return (
    <Container>
      <Header>
        <h1>{`Empresa: ${company?.social_name}`}</h1>
        <CreateAndUpdatesInfos model={company} />
      </Header>
      <Section>
        <CompanyInfo company={company} />
        {company.address && (
          <>
            <h3>Endereço</h3>
            <AddressInfos address={company.address} />
          </>
        )}
      </Section>
      <Section>
        <h3>Assinatura</h3>
        {renderSignatureSection()}
      </Section>
      <Section>
        <h3>Testemunhas</h3>
        {renderWitnessSection()}
      </Section>

      <Fab color="secondary" onClick={() => history.push(`edit`)}>
        <Edit />
      </Fab>
    </Container>
  );
}
