import extenso from 'extenso';

import numeral from 'services/numeral';

export function formatCurrency(value: number) {
  try {
    return numeral(value).format('$ 0,0.00');
  } catch (err) {
    return 'invalid parameter';
  }
}

export function formatNumberString(value: number | undefined): string {
  if (!value) return '';
  const result = numeral(
    String(value).replace('.', '').replace(',', '.'),
  ).value();
  return numeral(result).format('0,0.00');
}

export function formatCurrencyWithExtension(value: number) {
  try {
    const result = formatCurrency(value);

    const description = extenso(formatNumberString(value), {
      mode: 'currency',
    });

    return `${result} (${description})`;
  } catch (err) {
    return 'invalid parameter';
  }
}

export function formatNumberWithExtension(value: number) {
  try {
    const result = formatNumberString(value);

    const description = extenso(result, {
      mode: 'number',
    });

    return `${result} (${description})`;
  } catch (err) {
    return 'invalid parameter';
  }
}
