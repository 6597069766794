import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch, { SwitchProps } from '@material-ui/core/Switch';

interface Props extends SwitchProps {
  label?: string;
}

export const SwitchInput: React.FC<Props> = ({ label, ...rest }) => {
  return <FormControlLabel control={<Switch {...rest} />} label={label} />;
};

export default SwitchInput;
