import styled from 'styled-components';

import DefaultContainer from 'components/Container';

export const Container = styled(DefaultContainer)`
  .wrapper-farms-order-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    border: 1px solid #eee;

    strong {
      font-size: 1rem;
      margin-right: 10px;
    }
  }

  .wrapper-order-payment-parcels-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, max(300px));
    gap: 10px;
  }

  .wrapper-order-payment-parcels-item {
    display: flex;
    background-color: #eef;
    flex-direction: column;
    font-size: 1rem;
    border: 1px solid #eee;
    padding: 20px;
    border-radius: 5px;
  }

  .wrapper-files-list {
    margin: 10px 0px;

    header:first-child {
      border-bottom: 1px solid #333;
    }

    header {
      margin: 5px 0px;
      padding-bottom: 5px;
    }
  }
`;
