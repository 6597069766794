import styled from 'styled-components';

export const Container = styled.ul`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, max(350px));
  gap: 10px;

  li {
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    color: #444;
    border: 1px dashed #444;
  }
`;

export const FileInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;

  div {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    strong {
      line-height: 28px;
    }

    span {
      font-size: 0.8rem;
      color: #999;
      margin-top: 5px;
    }
  }

  .wrapper-file-list-item-buttons {
    flex-direction: row;
  }
`;

interface A {
  src: string;
}

export const Preview = styled.div<A>`
  width: 36px;
  height: 36px;

  border-radius: 5px;

  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 10px;
`;
