import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import getValidationErrors from 'utils/getValidationErros';
import * as Yup from 'yup';

import { Input } from 'components/Form';
import { authRequest, RequestAuthParams } from 'store/ducks/auth/actions';
import logo from '../../assets/logo.png';
import Button from '../../components/Button';
import { Container } from './styles';

export default function SignIn() {
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: RequestAuthParams) => {
      try {
        if (formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('O email é inválido')
            .required('O campo email é obrigatório'),
          password: Yup.string()
            .min(6, 'Sua senha deve ter no mínimo 6 caracteres')
            .required('O campo senha é obrigatório'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(authRequest(data));
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          if (formRef.current) {
            formRef.current.setErrors(getValidationErrors(err));
          }
        }
      }
    },
    [formRef, dispatch],
  );

  return (
    <Container>
      <img src={logo} alt="APMAX" />
      <Form ref={formRef} onSubmit={handleSubmit}>
        <h1>Realize seu acesso ao sistema</h1>
        <Input name="email" label="E-mail" required />
        <Input
          name="password"
          label="Sua senha secreta"
          type="password"
          required
        />
        <div className="wrapper-buttons-actions">
          <Button type="submit">Acessar</Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => history.push('/forgotPassword')}
          >
            Esqueci minha senha
          </Button>
        </div>
      </Form>
    </Container>
  );
}
