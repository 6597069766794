import { call, put, all, takeLatest } from 'redux-saga/effects';

import api from 'services/api';
import errorHandler from 'services/errorHandler';

import { authFailure, authSuccess } from './actions';
import { AuthTypes } from './types';

export function* auth(props) {
  try {
    const { payload } = props;

    const response = yield call(api.post, 'session', payload);

    const { user, token } = response.data;

    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token.token}`;
    }

    yield put(authSuccess({ user, token }));
  } catch (err) {
    yield put(authFailure());
    yield call(errorHandler, {
      err,
      message404: 'Não foi possível encontrar este email',
    });
  }
}

export function setToken(param) {
  const { payload } = param;

  if (!payload) return;

  const token = payload.auth.auth?.token.token;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function* forgotPassword({ payload }) {
  try {
    const redirect_url = process.env.REACT_APP_RESET_PASSWORD_URL;

    yield call(api.post, 'forgotpassword', {
      email: payload,
      redirect_url,
    });
    yield put(forgotPasswordSuccess());
    history.push('forgotPasswordSuccess');
  } catch (err) {
    yield put(forgotPasswordFailure());
    yield call(errorHandler, err, 'Não foi possível encontrar este email');
  }
}

export default all([
  takeLatest(AuthTypes.AUTH_REQUEST, auth),
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest(AuthTypes.AUTH_FORGOT_PASSWORD_REQUEST, forgotPassword),
]);
