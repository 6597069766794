import styled from 'styled-components';

import Container from 'components/Container';

export const Wrapper = styled(Container)`
  font-size: 0.8rem;

  header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .wrapper-alterinfos-participant {
      color: #999;
    }
  }

  section {
    margin: 10px 0;
  }

  .wrapper-participant-content {
    flex: 1;
    margin: 10px 0px;
    & > p {
      font-weight: bold;
      font-size: 1rem;
    }
  }

  .wrapper-types-participant {
    margin: 10px 0px;
    padding: 10px 0px;
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;

    display: grid;
    grid-template-columns: repeat(auto-fill, max(150px));
    gap: 20px;
  }

  .wrapper-data-participant {
    margin: 10px 0px;
    font-size: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, max(600px));
    gap: 10px;
  }
`;

export const DisplayDetails = styled.div`
  margin: 15px 0px;
  display: grid;
  grid-template-columns: repeat(auto-fill, max(600px));
  gap: 5px;
`;

export const FarmItem = styled.div`
  padding: 15px;
  font-size: 1rem;
  border: 1px solid ${props => props.theme.colors.main};
  border-radius: 4px;
  max-width: 360px;

  ul {
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill);
    gap: 5px;
  }
`;

export const ListParcelas = styled.ul`
  padding: 10px 0px;
  display: block;
  display: grid;
  grid-template-columns: repeat(auto-fill, max(340px));
  gap: 5px;

  li {
    padding: 15px;
    text-decoration: none;
    border: 1px solid ${props => props.theme.colors.main};
    border-radius: 4px;
  }
`;
