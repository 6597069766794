import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .chip-item-filter {
    margin-right: 5px;
    margin-top: 5px;
  }

  &:last-child {
    margin-right: 0px;
  }
`;
