import React, { useRef, useCallback } from 'react';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { addMonths, setDay, getDay } from 'date-fns';
import * as Yup from 'yup';

import Button from 'components/Button';
import { InputNumber, DatePicker, SwitchInput } from 'components/Form';
import { ContainerInputResponsive } from 'components/Layout';
import errorHandler from 'services/errorHandler';

import { Container } from './styles';

export interface AddParcelDTO {
  date: Date;
  value: number;
}

interface GenerateParcelsProps {
  onCancel: () => void;
  onSubmit: (values: AddParcelDTO[]) => void;
}

export const GenerateParcels: React.FC<GenerateParcelsProps> = ({
  onCancel,
  onSubmit,
}) => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: SubmitHandler<AddParcelDTO>) => {
      try {
        // Remove all previous errors
        if (formRef && formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          date: Yup.date().required('A data da parcela é obrigatória'),
          qtdParcels: Yup.number()
            .required('A quantidade de parcelas é obrigatória')
            .min(1, 'A quantidade de parcelas não pode ser inferior a 1'),
          value: Yup.number()
            .required('O valor da parcela é obrigatório')
            .min(0.01, 'A parcela não pode ser 0'),
          fixDate: Yup.bool(),
        });

        const result = await schema.validate(data, {
          abortEarly: false,
        });

        const { date, value, qtdParcels, fixDate } = result;

        const parcels: AddParcelDTO[] = [];

        if (fixDate) {
          for (let index = 0; index < qtdParcels; index += 1) {
            parcels.push({
              date: setDay(addMonths(date, index), getDay(date)),
              value,
            });
          }
        } else {
          for (let index = 0; index < qtdParcels; index += 1) {
            parcels.push({
              date: addMonths(date, index),
              value,
            });
          }
        }

        try {
          onSubmit(parcels);
        } catch (err) {
          errorHandler(err);
        }
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          if (formRef && formRef.current) {
            formRef.current.setErrors(validationErrors);
          }
        }
      }
    },
    [onSubmit],
  );

  return (
    <Container>
      <Form
        onSubmit={handleSubmit}
        ref={formRef}
        initialData={{
          qtdParcels: 1,
        }}
      >
        <ContainerInputResponsive>
          <InputNumber label="Valor da Parcela" name="value" />
          <InputNumber
            label="Nº Parcelas"
            name="qtdParcels"
            inputProps={{
              prefix: '',
              decimalScale: 0,
            }}
          />
          <DatePicker name="date" />
          <SwitchInput name="fixDate" label="Data Fixa" />
        </ContainerInputResponsive>
        <Button type="button" color="secondary" onClick={onCancel}>
          Cancelar
        </Button>
        <Button type="submit">Confirmar</Button>
      </Form>
    </Container>
  );
};

export default GenerateParcels;
