import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { OrderStatus } from 'types';
import * as Yup from 'yup';

import Button from 'components/Button';
import DialogScroll from 'components/DialogScroll';
import SelectInput from 'components/Form/Select';
import api from 'services/api';
import errorHandler from 'services/errorHandler';
import { ApplicationState } from 'store';

import { Container, OptionsContainer } from './styles';

interface SubmitDataForm {
  status: string;
}

interface AlterOrderStatusProps {
  order_id: string;
  onUpdate(status: OrderStatus): void;
}

const AlterOrderStatus: React.FC<AlterOrderStatusProps> = ({
  order_id,
  onUpdate,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [open, setOpen] = useState(false);

  const user = useSelector((state: ApplicationState) => state.auth.auth?.user);

  const handleSubmit = useCallback(
    async (data: SubmitHandler<SubmitDataForm>) => {
      try {
        // Remove all previous errors
        if (formRef && formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          status: Yup.string().required('O novo status é obrigatório'),
        });

        const result = await schema.validate(data, {
          abortEarly: false,
        });

        try {
          await api.put(`order/${order_id}`, {
            status: result.status,
          });

          onUpdate(result.status as OrderStatus);
          setOpen(false);
        } catch (err) {
          errorHandler(err);
        }
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          if (formRef && formRef.current) {
            formRef.current.setErrors(validationErrors);
          }
        }
      }
    },
    [onUpdate, order_id],
  );

  const handleOnClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOnOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const options = useMemo(() => {
    const userRoles = user?.roles?.map(i => i.slug) || [];

    const arrayOptions = [{ value: 'sended', label: 'Aguardando' }];

    if (userRoles.includes('finances')) {
      arrayOptions.push({ value: 'canceled', label: 'Cancelado' });
      arrayOptions.push({
        value: 'finance_blocked',
        label: 'Bloqueado por financeiro',
      });
    }

    if (userRoles.includes('production')) {
      arrayOptions.push({ value: 'started', label: 'Iniciado' });
      arrayOptions.push({ value: 'finished', label: 'Finalizado' });
    }

    return arrayOptions;
  }, [user]);

  return (
    <Container>
      <Button onClick={handleOnOpen}>Alterar</Button>

      <DialogScroll
        open={open}
        dialogTitle=""
        onClose={handleOnClose}
        onClickActionCancelButton={handleOnClose}
        dialogActions={<div />}
      >
        <Form onSubmit={handleSubmit} ref={formRef}>
          <SelectInput
            name="status"
            placeholder="Novo status do pedido"
            label="Status"
            containerStyle={{
              minWidth: 240,
            }}
            options={options}
          />
          <OptionsContainer>
            <Button type="button" color="secondary" onClick={handleOnClose}>
              Cancelar
            </Button>
            <Button type="submit">Confirmar</Button>
          </OptionsContainer>
        </Form>
      </DialogScroll>
    </Container>
  );
};

export default AlterOrderStatus;
