import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px 0px;
  width: 100%;

  & > p {
    font-size: 1rem;
  }

  display: grid;
  grid-template-columns: repeat(auto-fill, max(500px));
  gap: 20px;
`;
