import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { OptionTypeBase } from 'react-select';
import { toast } from 'react-toastify';

import { Divider } from '@material-ui/core';
import TextInput from '@material-ui/core/TextField';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { AxiosResponse } from 'axios';
import filesize from 'filesize';
import {
  Participant,
  Address,
  Services,
  FileModel,
  FileLoad,
  AxiosProgress,
  Order,
  Options,
} from 'types';
import { formatDateWithoutTimestamps } from 'utils/format';
import { formatCurrency } from 'utils/formatCurrency';
import { getNameFromParticipant } from 'utils/renderNameParticipant';
import { uuid } from 'uuidv4';
import * as Yup from 'yup';

// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
import Button from 'components/Button';
import DialogScroll from 'components/DialogScroll';
import Errors from 'components/ErrorShow';
import FileInsertList from 'components/FileInsertList';
import FileList from 'components/FileList';
import { Select, InputNumber, Input } from 'components/Form';
import Item from 'components/Item';
import { ContainerInputResponsive, Header } from 'components/Layout';
import Switch from 'components/Switch';
import Upload from 'components/Upload';
import api from 'services/api';
import errorHandler from 'services/errorHandler';
import { ApplicationState } from 'store';

import { AddParcel } from '../AddParcel';
import { GenerateParcels } from '../GenerateParcels';
import { Container } from './styles';

interface OptionsFarm extends OptionTypeBase {
  value: string;
  label: string;
  hectares: number;
  isSelected?: boolean;
  services?: number[];
}

export interface ApiSellersReponse {
  data: Participant[];
}

export interface ApiClientResponse {
  data: Participant[];
}

export interface ApiServiceResponse {
  data: Services[];
}

export interface AddParcelDTO {
  date: Date;
  value: number;
  own_code?: string;
}

export interface LocalParcelsDTO extends AddParcelDTO {
  id: string;
}

interface ApiOrderReponse {
  data: Order;
}

interface FarmServicesState {
  [key: string]: number[];
}

export default function Add() {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { data: companies, selectedCompany } = useSelector(
    (state: ApplicationState) => state.companies,
  );
  const { data: vintages, selectedVintage: defaultVintage } = useSelector(
    (state: ApplicationState) => state.vintages,
  );
  const [addFilesOpen, setAddFilesOpen] = useState(false);
  const [formAddParcelaOpen, setFormAddParcelaOpen] = useState(false);
  const [formAddMultipleParcelsOpen, setFormAddMultipleParcelsOpen] = useState(
    false,
  );
  const [errors, setErrors] = useState<string[]>([]);
  // const [openFarmAdd, setOpenFarmAdd] = useState(false);
  const [wordFilter, setWordFilter] = useState('');
  const [files, setFiles] = useState<FileLoad[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<FileModel[]>([]);
  const [filtered, setFiltered] = useState<FileModel[]>([]);
  const [years, setYears] = useState<number>(1);
  const [sellers, setSellers] = useState<Options[]>();
  const [clients, setClients] = useState<Participant[]>();
  const [clientsOptions, setClientsOptions] = useState<Options[]>();
  const [addressesOptions, setAddressesOptions] = useState<Options[]>();
  const [selectedAddresses, setSelectedAddresses] = useState<Address>();
  const [services, setServices] = useState<Services[]>();
  const [farmsSelected, setFarmsSelected] = useState<string[]>([]);
  const [farmsOptions, setFarmsOptions] = useState<OptionsFarm[]>();
  const [
    farmServicesSelected,
    setFarmServicesSelected,
  ] = useState<FarmServicesState>({});
  const [parcels, setParcels] = useState<LocalParcelsDTO[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<
    string | undefined
  >();
  const [selectedSeller, setSelectedSeller] = useState<string | undefined>();
  const [selectedClient, setSelectedClient] = useState<string | undefined>();
  const [selectedVintage, setSelectedVintage] = useState<string>();

  const loadSellers = useCallback(async (company_id: string) => {
    try {
      const { data }: ApiSellersReponse = await api.get(
        `company/${company_id}/participants`,
      );

      const dataFormatted = data
        .filter(items => items.user)
        .map(seller => {
          const { user } = seller;
          return {
            value: String(user?.id),
            label: getNameFromParticipant(seller),
          };
        });

      setSellers(dataFormatted);
    } catch (err) {
      toast.error('Não foi possível carregar os vendedores');
    }
  }, []);

  const loadClients = useCallback(async (seller_id: string) => {
    try {
      const { data }: ApiClientResponse = await api.get(
        `seller/${seller_id}/clients`,
      );

      setClients(data);

      setClientsOptions(
        data.map(client => {
          return {
            value: client.id.toString(),
            label: getNameFromParticipant(client),
          };
        }),
      );
    } catch (error) {
      toast.error('Não foi possível carregar a lista de clientes');
    }
  }, []);

  const loadClientAddresses = useCallback(
    async (client_id: string) => {
      try {
        const client = clients?.find(item => client_id === String(item.id));

        if (client) {
          const { address } = client;

          setAddressesOptions(
            address.map(item => {
              return {
                value: String(item.id),
                label: `${item.street}, ${item.neighborhood} (${item.city?.name} - ${item.city?.state?.initials})`,
              };
            }),
          );
        }
      } catch (error) {
        toast.error('Não foi possível carregar os endereços do cliente');
      }
    },
    [clients],
  );

  const loadClientFarms = useCallback(
    async (client_id: string) => {
      try {
        const client = clients?.find(item => client_id === String(item.id));

        if (client) {
          const { farms } = client;

          setFarmsOptions(
            farms.map(item => {
              return {
                value: String(item.id),
                label: `${item.name}`,
                hectares: Number(item.total_ha),
              };
            }),
          );

          const farmServices: FarmServicesState = {};

          farms.forEach(item => {
            farmServices[String(item.id)] = services
              ? services?.map(service => service.id)
              : [];
          });

          setFarmServicesSelected(farmServices);
        }
      } catch (error) {
        toast.error('Não foi possível carregar os endereços do cliente');
      }
    },
    [clients, services],
  );

  useEffect(() => {
    async function loadServices() {
      try {
        const { data }: ApiServiceResponse = await api.get('services');

        setServices(data);
      } catch {
        toast.error('Não foi possível carregar os serviços!');
      }
    }

    loadServices();
  }, []);

  useEffect(() => {
    setSellers([]);
    setSelectedSeller(undefined);
    if (selectedCompanies) {
      loadSellers(selectedCompanies);
    }
  }, [loadSellers, selectedCompanies]);

  useEffect(() => {
    if (selectedSeller) {
      loadClients(selectedSeller);
    }
  }, [selectedSeller, loadClients]);

  useEffect(() => {
    if (selectedCompany) {
      setSelectedCompanies(String(selectedCompany.id));
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedClient) {
      loadClientAddresses(selectedClient);
    }
    if (selectedClient) {
      loadClientFarms(selectedClient);
    }
  }, [selectedClient, loadClientAddresses, loadClientFarms]);

  const totalParcelsFormatted = useMemo(() => {
    return formatCurrency(
      parcels.reduce((prev, curr) => {
        return prev + curr.value;
      }, 0),
    );
  }, [parcels]);

  const companiesOptions = useMemo(() => {
    return (
      companies.map(({ id, social_name }) => ({
        value: String(id),
        label: social_name,
      })) || []
    );
  }, [companies]);

  const vintagesOptions = useMemo(() => {
    return vintages.map(vintage => ({
      value: String(vintage.id),
      label: vintage.description,
    }));
  }, [vintages]);

  // const handleOnFarmToggle = useCallback(() => {
  //   setOpenFarmAdd(current => !current);
  // }, []);

  const handleSubmit = useCallback(
    async (data: SubmitHandler<FormData>) => {
      try {
        const submitErrors: string[] = [];
        setErrors([]);

        if (farmsSelected.length <= 0) {
          submitErrors.push('Pelo menos uma fazenda deve ser selecionada');
        }

        farmsSelected.forEach(farm => {
          if (
            !farmServicesSelected[farm] ||
            farmServicesSelected[farm].length <= 0
          ) {
            submitErrors.push(
              `Fazenda ${
                farmsOptions?.find(item => String(item.value) === String(farm))
                  ?.label
              } não possui nenhum serviço selecionado!`,
            );
          }
        });

        if (submitErrors.length > 0) {
          setErrors(submitErrors);
          return;
        }

        // Remove all previous errors
        if (formRef && formRef.current) {
          formRef.current.setErrors({});
        }

        const farmsHectaresVerification: {
          [key: string]: Yup.NumberSchema<number | undefined>;
        } = {};

        farmsSelected?.map(item => {
          farmsHectaresVerification[`hectares-${item}`] = Yup.number()
            .positive('Os hectares devem ser positivos')
            .required('Os hectares dessa fazenda são obrigatórios!');
        });

        const schema = Yup.object().shape({
          company_id: Yup.string().required('A empresa é obrigatória'),
          vintage_id: Yup.string().required('A safra é obrigatória'),
          seller_id: Yup.string().required('O vendedor é obrigatório'),
          client_id: Yup.string().required('O cliente é obrigatório'),
          address_id: Yup.string().required(
            'O endereço do cliente obrigatório',
          ),
          observation: Yup.string(),
          amount: Yup.number()
            .positive('O total do pedido deve ser maior que zero!')
            .required('O total do pedido é obrigatório!'),
          years: Yup.number()
            .positive('A duração do contrato deve ser de no mínimo 1 ano!')
            .required('Este campo é obrigatório!'),
          total_parcels: Yup.number()
            .positive()
            .oneOf(
              [Yup.ref('amount')],
              'O total das parcelas não bate com o total do pedido!',
            )
            .required('A empresa vinculada é obrigatória'),
          ...farmsHectaresVerification,
        });

        const result = await schema.validate(
          {
            ...data,
            company_id: selectedCompanies,
          },
          {
            abortEarly: false,
          },
        );

        const { total_parcels, ...rest } = result;

        const auxiliar: { [index: string]: any } = result;

        const formSubmitData = {
          ...rest,
          is_demo: false,
          amount_parcels: total_parcels,
          farms: farmsSelected.map(item => ({
            id: item,
            services: farmServicesSelected[item],
            hectares: auxiliar[`hectares-${item}`] as number,
          })),
          parcels,
          files: files.map(file => Number(file.id)),
        };

        try {
          const response: ApiOrderReponse = await api.post(
            'order',
            formSubmitData,
          );

          toast.success('Pedido cadastrado com sucesso!');
          history.push(`/orders/${response.data.id}/show`);

          if (formRef && formRef.current) {
            formRef.current.reset();
          }
        } catch (err) {
          errorHandler(err);
        }
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          if (formRef && formRef.current) {
            formRef.current.setErrors(validationErrors);
          }
        }
      }
    },
    [
      farmServicesSelected,
      farmsOptions,
      farmsSelected,
      files,
      history,
      parcels,
      selectedCompanies,
    ],
  );

  const handleOnAddressChange = useCallback(
    (option: string) => {
      if (!selectedClient || !clients) return;

      const clientIndex = clients?.findIndex(
        client => String(client.id) === selectedClient,
      );

      if (clientIndex) {
        setSelectedAddresses(
          clients[clientIndex].address.find(item => String(item.id) === option),
        );
      }
    },
    [clients, selectedClient],
  );

  const handleOnSubmitParcel = useCallback(
    ({ date, value, own_code }: AddParcelDTO) => {
      setParcels(current => [
        ...current,
        {
          id: uuid(),
          date,
          value,
          own_code,
        },
      ]);
    },
    [],
  );

  const handleOnSubmitMultipleParcels = useCallback(
    (submitedParcels: AddParcelDTO[]) => {
      setParcels(current => [
        ...current,
        ...submitedParcels.map(item => ({
          id: uuid(),
          date: item.date,
          value: item.value,
        })),
      ]);
    },
    [],
  );

  const handleOnFarmSelect = useCallback((checked: boolean, id: string) => {
    if (checked) {
      setFarmsSelected(current => [...current, id]);
    } else {
      setFarmsSelected(current => current.filter(itens => itens !== id));
    }
  }, []);

  const handleOnFarmServiceSelect = useCallback(
    (checked: boolean, farmId: string, serviceId: number) => {
      if (checked) {
        setFarmServicesSelected(current => ({
          ...current,
          [farmId]: [...current[farmId], serviceId],
        }));
      } else {
        setFarmServicesSelected(current => ({
          ...current,
          [farmId]: current[farmId]
            ? current[farmId].filter(itens => itens !== serviceId)
            : [],
        }));
      }
    },
    [],
  );

  const handleOnFilter = useCallback((word: string) => {
    setWordFilter(word);

    if (!word || word === '') {
      setFiltered([]);
    }

    setFiles(current => {
      const regex = new RegExp(`${word}`, 'gmi');
      return current.filter(file => file.name.match(regex) !== null);
    });
  }, []);

  const handleOnUpdateFile = useCallback((id: string, data: any) => {
    setFiles(current =>
      current.map(file => {
        if (file.id === id) {
          return { ...file, ...data };
        }
        return file;
      }),
    );
  }, []);

  const handleUpload = useCallback((filesSended: File[]) => {
    const filesUploaded = filesSended.map(file => ({
      file,
      id: uuid(),
      name: file.name,
      size: file.size,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
    }));

    setFiles(filesUploaded);
  }, []);

  const handleOnAddFilesClose = useCallback(() => {
    setAddFilesOpen(false);
  }, []);

  const handleOnDeleteFiles = useCallback(async (id: number) => {
    try {
      await api.delete(`order/files`);
      setUploadedFiles(current => current.filter(item => item.id !== id));
      setFiles(current => current.filter(file => file.id !== String(id)));
    } catch (err) {
      errorHandler(err);
    }
  }, []);

  const handleOnProcessUpload = useCallback(
    (file: FileLoad) => {
      const { classe, uploaded, size } = file;

      if (uploaded) return;

      const data = new FormData();

      data.append('file', file.file, file.name);

      api
        .post(`order/files`, data, {
          params: {
            classe,
            vintage_id: selectedVintage,
            size,
          },
          onUploadProgress: (e: AxiosProgress) => {
            // eslint-disable-next-line radix
            const progress = parseInt(
              String(Math.round((e.loaded * 100) / e.total)),
            );

            handleOnUpdateFile(file.id, { progress });
          },
        })
        .then(async (response: AxiosResponse<FileModel>) => {
          setUploadedFiles([...uploadedFiles, response.data as FileModel]);
          handleOnUpdateFile(file.id, {
            uploaded: true,
            id: String(response.data.id),
            url: response.data.url,
            error: false,
          });
        })
        .catch(() => {
          handleOnUpdateFile(file.id, {
            error: true,
          });
        });
    },
    [handleOnUpdateFile, selectedVintage, uploadedFiles],
  );

  const handleOnListProcess = useCallback(() => {
    files.forEach(handleOnProcessUpload);
  }, [files, handleOnProcessUpload]);

  const handleOnRemoveParcel = useCallback((id: string) => {
    setParcels(current => current.filter(parcel => parcel.id !== id));
  }, []);

  const RenderAddressFields = useCallback((address: Address) => {
    return (
      <ContainerInputResponsive>
        <TextInput
          label="Rua"
          variant="outlined"
          fullWidth
          defaultValue={address.street}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextInput
          label="Bairro"
          variant="outlined"
          fullWidth
          defaultValue={address.neighborhood}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextInput
          label="CEP"
          variant="outlined"
          fullWidth
          defaultValue={address.cep}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextInput
          label="Complemento"
          variant="outlined"
          fullWidth
          defaultValue={address.complement}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextInput
          label="Descrição"
          variant="outlined"
          fullWidth
          defaultValue={address.description}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextInput
          label="Cidade - Estado"
          variant="outlined"
          fullWidth
          defaultValue={`${address.city?.name} - ${address.city?.state?.initials}`}
          InputProps={{
            readOnly: true,
          }}
        />
      </ContainerInputResponsive>
    );
  }, []);

  const RenderAddressSection = useCallback(() => {
    return (
      <section>
        <Select
          name="address_id"
          label="Endereço"
          onValueChange={(value: string | string[] | number | number[]) =>
            handleOnAddressChange(value as string)
          }
          options={addressesOptions || []}
        />
        {selectedAddresses && RenderAddressFields(selectedAddresses)}
      </section>
    );
  }, [
    addressesOptions,
    handleOnAddressChange,
    RenderAddressFields,
    selectedAddresses,
  ]);

  const RenderFarmsSection = useCallback(() => {
    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h1 style={{ marginRight: 15 }}>Lista de Fazendas</h1>
          {/* <Button onClick={handleOnFarmToggle}>Nova fazenda</Button> */}
        </div>
        {/* <Dialog open={openFarmAdd} onClose={handleOnFarmToggle} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To subscribe to this website, please enter your email address here. We will send updates occasionally.
            </DialogContentText>
             <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
          />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleOnFarmToggle} color="primary">
              Cadastrar
            </Button>
            <Button onClick={handleOnFarmToggle} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </Dialog> */}
        <section className="wrapper-section-show-inputinfos">
          {farmsOptions?.map(farmOption => {
            return (
              <section
                className="wrapper-farms-order-item"
                key={farmOption.value}
              >
                <strong>
                  {`${farmOption.value} - ${farmOption.label} `}

                  <Switch
                    checked={farmsSelected.includes(farmOption.value)}
                    onChange={
                      (
                        event: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean,
                      ) => handleOnFarmSelect(checked, farmOption.value)
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                  />
                </strong>
                <InputNumber
                  name={`hectares-${farmOption.value}`}
                  label="Hectares contratados"
                  required={farmsSelected.includes(farmOption.value)}
                  defaultValue={farmOption.hectares}
                  inputProps={{
                    suffix: ' Ha',
                    prefix: '',
                  }}
                />
                <div className="wrapper-order-farm-item-service-container">
                  <h1>Serviços Contratados</h1>
                  {services?.map(service => {
                    return (
                      <Switch
                        label={service.name}
                        key={service.id}
                        checked={farmServicesSelected[
                          farmOption.value
                        ].includes(service.id)}
                        onChange={
                          (
                            event: React.ChangeEvent<HTMLInputElement>,
                            checked: boolean,
                          ) =>
                            handleOnFarmServiceSelect(
                              checked,
                              farmOption.value,
                              service.id,
                            )
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                      />
                    );
                  })}
                </div>
              </section>
            );
          })}
        </section>
      </>
    );
  }, [
    services,
    // openFarmAdd,
    farmsOptions,
    farmsSelected,
    farmServicesSelected,
    handleOnFarmSelect,
    handleOnFarmServiceSelect,
    // handleOnFarmToggle
  ]);

  const RenderPaymentParcels = useCallback(() => {
    return (
      <section className="wrapper-order-payment-parcels-container">
        {parcels.map(parcel => {
          return (
            <section
              key={parcel.id}
              className="wrapper-order-payment-parcels-item"
            >
              <Item label="Valor">{formatCurrency(parcel.value)}</Item>

              {parcel.own_code && (
                <Item label="Cód Próprio">{parcel.own_code}</Item>
              )}
              <Item label="Data de vencimento">
                {formatDateWithoutTimestamps(parcel.date)}
              </Item>

              <Button onClick={() => handleOnRemoveParcel(parcel.id)}>
                Remover
              </Button>
            </section>
          );
        })}
      </section>
    );
  }, [handleOnRemoveParcel, parcels]);

  const RenderFiles = useCallback(() => {
    if (wordFilter) {
      return <FileList files={filtered} onDeleteFile={handleOnDeleteFiles} />;
    }

    if (files) {
      return (
        <FileList files={uploadedFiles} onDeleteFile={handleOnDeleteFiles} />
      );
    }

    return <></>;
  }, [files, filtered, handleOnDeleteFiles, uploadedFiles, wordFilter]);

  if (
    !selectedCompanies ||
    !defaultVintage ||
    !vintages ||
    !companies ||
    !sellers
  ) {
    return <Container>Carregando...</Container>;
  }

  return (
    <Container>
      <Header>
        <h1>Cadastro de pedido</h1>
        <Divider />
      </Header>

      <Form
        onSubmit={handleSubmit}
        ref={formRef}
        initialData={{
          company_id: selectedCompanies,
          vintage_id: String(defaultVintage?.id),
          seller_id: sellers.length === 1 ? sellers[0].value : null,
        }}
      >
        <ContainerInputResponsive>
          <Select
            name="company_id"
            label="Empresa"
            value={selectedCompanies}
            options={companiesOptions}
            onValueChange={(value: string | string[] | number | number[]) =>
              setSelectedCompanies(value as string)
            }
          />
          <Select
            name="vintage_id"
            label="Safra"
            options={vintagesOptions}
            onValueChange={(value: string | string[] | number | number[]) =>
              setSelectedVintage(value as string)
            }
          />
          <Select
            name="seller_id"
            label="Vendedor"
            onValueChange={(value: string | string[] | number | number[]) =>
              setSelectedSeller(value as string)
            }
            options={sellers || []}
          />
          <Select
            name="client_id"
            label="Cliente"
            onValueChange={(value: string | string[] | number | number[]) =>
              setSelectedClient(value as string)
            }
            options={clientsOptions || []}
          />
        </ContainerInputResponsive>
        {selectedClient && (
          <>
            <ContainerInputResponsive>
              <RenderAddressSection />
              <InputNumber
                name="years"
                value={years}
                label="Anos de contrato"
                onChange={event =>
                  setYears(Number(Number(event.target.value).toFixed(0)))
                }
                inputProps={{
                  prefix: '',
                }}
              />
            </ContainerInputResponsive>
            <RenderFarmsSection />
            <div>
              <h3>
                <strong>Pagamentos</strong>
              </h3>
              <ContainerInputResponsive>
                <InputNumber name="amount" label="Total do pedido" />
                <InputNumber
                  name="total_parcels"
                  label="Total das parcelas"
                  value={totalParcelsFormatted}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </ContainerInputResponsive>
              <ContainerInputResponsive>
                <Button
                  type="button"
                  onClick={() => setFormAddParcelaOpen(true)}
                >
                  Adicionar Parcela
                </Button>
                <Button
                  type="button"
                  onClick={() => setFormAddMultipleParcelsOpen(true)}
                >
                  Gerar Parcelas
                </Button>
              </ContainerInputResponsive>
            </div>
          </>
        )}

        {parcels && <RenderPaymentParcels />}

        {selectedVintage && (
          <section className="wrapper-files-list">
            <Header>
              <h1>Arquivos</h1>
            </Header>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <TextInput
                fullWidth
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >,
                ) => handleOnFilter(event.target.value)}
                label="Buscar arquivo"
                style={{ flex: 1, marginRight: 10 }}
              />
              <Button type="button" onClick={() => setAddFilesOpen(true)}>
                Adicionar Arquivo
              </Button>
            </div>
            <RenderFiles />

            <DialogScroll
              open={addFilesOpen}
              dialogTitle="Inclusão de arquivos"
              dialogContentText="Arraste os arquivos para a caixa abaixo para incluí-los mais rapidamente"
              onClose={handleOnAddFilesClose}
              onClickActionCancelButton={handleOnAddFilesClose}
              dialogActions={<></>}
            >
              <section className="wrapper-files-list-container">
                <Upload onUpload={handleUpload} />
                {!!files.length && (
                  <>
                    <FileInsertList
                      files={files}
                      onUpdadeFile={handleOnUpdateFile}
                    />
                    <Button onClick={handleOnAddFilesClose} color="secondary">
                      Cancelar
                    </Button>
                    {files.filter(item => !item.uploaded).length > 0 && (
                      <Button
                        onClick={handleOnListProcess}
                        style={{ marginLeft: 10 }}
                      >
                        Enviar Arquivos
                      </Button>
                    )}
                  </>
                )}
              </section>
            </DialogScroll>
          </section>
        )}

        <Input
          name="observation"
          label="Observação"
          required={false}
          multiline
          rows={8}
        />

        {errors && <Errors errors={errors} />}

        <Button type="submit">Cadastrar</Button>
      </Form>

      {formAddParcelaOpen && (
        <DialogScroll
          open={formAddParcelaOpen}
          dialogTitle="Adicionar parcela"
          onClose={() => setFormAddParcelaOpen(false)}
          onClickActionCancelButton={() => setFormAddParcelaOpen(false)}
          dialogActions={<div />}
        >
          <AddParcel
            onCancel={() => setFormAddParcelaOpen(false)}
            onSubmit={handleOnSubmitParcel}
          />
        </DialogScroll>
      )}

      {formAddMultipleParcelsOpen && (
        <DialogScroll
          open={formAddMultipleParcelsOpen}
          dialogTitle="Gerar parcelas"
          onClose={() => setFormAddMultipleParcelsOpen(false)}
          onClickActionCancelButton={() => setFormAddMultipleParcelsOpen(false)}
          dialogActions={<div />}
        >
          <GenerateParcels
            onCancel={() => setFormAddMultipleParcelsOpen(false)}
            onSubmit={handleOnSubmitMultipleParcels}
          />
        </DialogScroll>
      )}
    </Container>
  );
}
