export const optionsUpload = {
  croqui: ['application/vnd.google-earth.kmz', 'application/pdf'],
  laudo: [
    '.csv',
    'text/csv',
    'application/vnd.ms-excel',
    'application/csv',
    'text/x-csv',
    'application/x-csv',
    'text/comma-separated-values',
    'text/x-comma-separated-values',
  ],
  'pontos-coleta': ['application/vnd.google-earth.kmz', 'application/pdf'],
  'mapa-aplicacao': ['application/vnd.google-earth.kmz', 'application/pdf'],
  none: [],
};

export const optionsUploadPlots = {
  ...optionsUpload,
  'mapa-producao': ['image/png', 'application/pdf'],
};
