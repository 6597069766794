import React, { useEffect, useState, useCallback } from 'react';

import { Grid, ElementValue } from 'types';

import api from 'services/api';

import { ContainerStyeld as Container } from './styles';

const Teste: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [file, setData] = useState<Grid>();

  function makeElements(ox: string) {
    const result = ox
      .replace(/(<([^>]+)>)/gi, '')
      .replace(/\r/gi, '')
      .split('\n')
      .filter(item => {
        return item !== '';
      });

    const elements = result.slice(
      result.findIndex(item => item === 'Subdivisao'),
      result.length,
    );

    let i = 0;
    const newElements: ElementValue[] = [];

    while (i <= elements.length - 1) {
      const valueFormatted = elements[i + 1].replace('.', '').replace(',', '.');
      switch (elements[i].trim()) {
        case 'Subdivisao': {
          if (i + 1 <= elements.length) {
            newElements.push({
              key: 'subdivisao',
              value: valueFormatted,
            });
            i += 2;
          }
          break;
        }
        case 'Ponto': {
          newElements.push({
            key: 'ponto',
            value: valueFormatted,
          });
          i += 2;
          break;
        }
        default: {
          if (i + 1 <= elements.length && parseFloat(valueFormatted) >= 0) {
            newElements.push({
              key: elements[i],
              value: parseFloat(valueFormatted),
            });
            i += 2;
          } else {
            newElements.push({
              key: elements[i],
              value: 0,
            });
            i += 1;
          }
          break;
        }
      }
    }

    // console.tron.log('newElements', newElements);

    return newElements;
  }

  const loadFile = useCallback(async () => {
    const { data } = await api.get<Grid>(`file/78/json`);

    const newGrid: Grid = {
      file_id: 78,
      type: data.type,
      showing: true,
      features: data.features.map(feature => {
        return {
          ...feature,
          properties: {
            description: '',
            elements: makeElements(feature.properties.description || ''),
          },
        };
      }),
    };

    const sum: { [key: string]: number } = {};

    for (let i = 0; i <= newGrid.features.length - 1; i += 1) {
      const { elements } = newGrid.features[i].properties;

      if (elements) {
        for (let j = 0; j <= elements?.length - 1; j += 1) {
          const element = elements[j];
          if (!['subdivisao', 'ponto'].includes(element.key)) {
            if (typeof element.value === 'number') {
              sum[element.key] = (element.value || 0) + (sum[element.key] || 0);
            }
          }
        }
      }
    }

    Object.keys(sum).map(key => {
      const media = sum[key] / newGrid.features.length;

      return {
        [key]: {
          q1: media * 0.5,
          q2: media,
          q3: media + media * 0.5,
        },
      };
    });

    setData(newGrid);
    setLoading(false);
  }, []);

  useEffect(() => {
    loadFile();
  }, [loadFile]);

  if (loading) {
    return <Container>Carregando...</Container>;
  }
  return <Container>{JSON.stringify(file)}</Container>;
};

export default Teste;
