import styled, { css } from 'styled-components';

interface ContainerProps {
  isClickable?: boolean;
}

export const Container = styled.div<ContainerProps>`
  border: 1px solid #999;
  border-radius: 4px;
  padding: 20px;

  ${props =>
    props.isClickable &&
    css`
      cursor: pointer;
    `}
`;
