import React, { useRef } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

import TextField, { FilledTextFieldProps } from '@material-ui/core/TextField';

interface NumberFormatCustomProps extends NumberFormatProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      decimalScale={2}
      thousandSeparator="."
      decimalSeparator=","
      allowNegative={false}
      prefix="R$"
      {...other}
    />
  );
}

interface FormattedNumberFormatProps
  extends Omit<Omit<FilledTextFieldProps, 'variant'>, 'error'> {
  name: string;
  label?: string;
  required?: boolean;
  error?: string;
  defaultValue?: any;
}

export const FormattedNumberFormat: React.FC<FormattedNumberFormatProps> = ({
  name,
  label,
  required = true,
  defaultValue,
  error,
  ...rest
}) => {
  const inputRef = useRef(null);

  return (
    <TextField
      required={required}
      inputRef={inputRef}
      name={name}
      label={label}
      defaultValue={defaultValue}
      error={!!error}
      helperText={!!error && error}
      fullWidth
      InputProps={{
        inputComponent: NumberFormatCustom as any,
      }}
      {...rest}
      variant="filled"
    />
  );
};

export default FormattedNumberFormat;
