import React, { useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Divider } from '@material-ui/core';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { Region } from 'types';
import * as Yup from 'yup';

import Button from 'components/Button';
import { Input } from 'components/Form';
import { Header } from 'components/Layout';
import api from 'services/api';
import errorHandler from 'services/errorHandler';

import { Container } from './styles';

export interface ApiReponse {
  data: Region;
}

export default function Add() {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: SubmitHandler<FormData>) => {
      try {
        // Remove all previous errors
        if (formRef && formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          description: Yup.string().required('A descrição é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        try {
          const response: ApiReponse = await api.post('regions', data);

          toast.success('Região cadastrada com sucesso!');
          history.push(`/regions/${response.data.id}/show`);

          if (formRef && formRef.current) {
            formRef.current.reset();
          }
        } catch (err) {
          errorHandler(err);
        }
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          if (formRef && formRef.current) {
            formRef.current.setErrors(validationErrors);
          }
        }
      }
    },
    [history],
  );

  return (
    <Container>
      <Header>
        <h1>Cadastro de Regiões</h1>
        <Divider />
      </Header>

      <Form onSubmit={handleSubmit} ref={formRef}>
        <Input name="description" label="Descrição" />
        <Button type="submit">Cadastrar</Button>
      </Form>
    </Container>
  );
}
