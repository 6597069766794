import React from 'react';
import { Box, Circle, Flex, SimpleGrid, Text } from '@chakra-ui/react';

const Admin: React.FC = () => {
  return (
    <Flex>
      <SimpleGrid spacing={4} minChildWidth="300px" flexGrow={1}>
        <Box p="8" h={300} bg="green.100" borderRadius="lg">
          <Text fontWeight="bold" color="green.800">
            Usuários ativos
          </Text>
          <Circle w="70%" h="90%" m="auto" bg="green.200">
            <Text fontSize="3xl" fontWeight="extrabold" color="green.800">
              75
            </Text>
          </Circle>
        </Box>

        <Box p="8" h={300} bg="red.300" borderRadius="lg">
          <Text fontWeight="bold" color="red.800">
            API novos erros
          </Text>
          <Circle w="70%" h="90%" m="auto" bg="red.400">
            <Text fontSize="3xl" fontWeight="extrabold" color="gray.50">
              75
            </Text>
          </Circle>
        </Box>

        <Box p="8" h={300} bg="red.300" borderRadius="lg">
          <Text fontWeight="bold" color="red.800">
            Webapp novos erros
          </Text>
          <Circle w="70%" h="90%" m="auto" bg="red.400">
            <Text fontSize="3xl" fontWeight="extrabold" color="gray.50">
              75
            </Text>
          </Circle>
        </Box>

        <Box p="8" h={300} bg="red.300" borderRadius="lg">
          <Text fontWeight="bold" color="red.800">
            API erros pending
          </Text>
          <Circle w="70%" h="90%" m="auto" bg="red.400">
            <Text fontSize="3xl" fontWeight="extrabold" color="gray.50">
              75
            </Text>
          </Circle>
        </Box>

        <Box p="8" h={300} bg="red.300" borderRadius="lg">
          <Text fontWeight="bold" color="red.800">
            Webapp erros pending
          </Text>
          <Circle w="70%" h="90%" m="auto" bg="red.400">
            <Text fontSize="3xl" fontWeight="extrabold" color="gray.50">
              75
            </Text>
          </Circle>
        </Box>
      </SimpleGrid>
    </Flex>
  );
};

export default Admin;
