import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Link as LinkIcon, Delete, Visibility } from '@material-ui/icons';
import { FileModel } from 'types';

import { ApplicationState } from 'store';

import { Container, FileInfo, Preview } from './styles';

interface OwnProps {
  files: FileModel[];
  onDeleteFile?: (id: number) => void;
}

const extensaoAvailable = ['geojson'];

const FileList: React.FC<OwnProps> = ({ files, onDeleteFile }) => {
  const user = useSelector((state: ApplicationState) => state.auth.auth?.user);

  const actualRoles = useMemo(() => {
    return user?.roles?.map(role => role.slug) || [];
  }, [user]);

  return (
    <Container>
      {files.map(file => {
        const { id, classe, url, extname, vintage, name } = file;
        const arraySplited = name.split('-');

        return (
          <li key={id}>
            <FileInfo>
              <Preview src={url} />
              <section>
                <div>
                  <strong>{`Cód.: ${id}`}</strong>
                  <strong>{`Nome: ${arraySplited[1]}`}</strong>
                  <strong>{`Tipo do arquivo: ${classe}`}</strong>
                </div>
                <div>
                  <strong>{`Extensão do arquivo: ${extname}`}</strong>
                  <strong>{`Safra: ${vintage.description}`}</strong>
                </div>

                <div className="wrapper-file-list-item-buttons">
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    <LinkIcon style={{ marginRight: 8, color: '#222' }} />
                  </a>
                  {actualRoles.findIndex(role =>
                    ['production', 'admin'].includes(role),
                  ) > 0 &&
                    onDeleteFile && (
                      <button
                        type="button"
                        onClick={() => onDeleteFile(id)}
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <Delete style={{ marginRight: 8, color: '#e57878' }} />
                      </button>
                    )}
                  {extensaoAvailable.includes(extname) && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${process.env.REACT_APP_URL}file/${file.id}?classe=${file.classe}`}
                    >
                      <Visibility
                        style={{ marginRight: 8, color: '#000055' }}
                      />
                    </a>
                  )}
                </div>
              </section>
            </FileInfo>
          </li>
        );
      })}
    </Container>
  );
};

export default FileList;
