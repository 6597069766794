import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  border-radius: 4px;

  -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.66);
  -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.66);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.66);

  .wrapper-chips-params-filtered {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .chip-item-filter {
      margin-right: 5px;
      margin-top: 5px;
    }

    &:last-child {
      margin-right: 0px;
    }
  }
`;
