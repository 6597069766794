import React from 'react';

import { Container } from './styles';

interface ItemProps {
  label?: string;
}

const Item: React.FC<ItemProps> = ({ children, label }) => {
  return (
    <Container>
      {label && <strong>{`${label}: `}</strong>}
      {children}
    </Container>
  );
};

export default Item;
