import styled from 'styled-components';

import Container from 'components/Container';

export const Wrapper = styled(Container)`
  font-size: 1rem;

  .wrapper-types-participant {
    margin: 10px 0px;
    padding: 10px 0px;
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;

    display: grid;
    grid-template-columns: repeat(auto-fill, max(150px));
    gap: 20px;
  }
`;
