import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import IconButton from '@material-ui/core/IconButton';
import { Edit, Visibility } from '@material-ui/icons';
import { Witness } from 'types';
import { cpfMask } from 'utils/format';

import BaseTableDataManager from 'components/BaseTableDataManager';
import ExportXLS from 'components/ExportXLS';
import api from 'services/api';
import errorHandler from 'services/errorHandler';

import { Container } from './styles';

export interface ApiReponse {
  data: Witness[];
}

export default function WitnessComponent() {
  const history = useHistory();
  const [hideActions, setHideActions] = useState(false);
  const [loading, setLoading] = useState(true);
  const [witness, setWitness] = useState<Witness[]>([]);
  const [exporting, setExporting] = useState<{
    exporting: boolean;
    columns: any[];
    data: any[];
  }>({
    exporting: false,
    columns: [],
    data: [],
  });

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const { data }: ApiReponse = await api.get(`witness`);

      setWitness(data);
    } catch (error) {
      toast.error('Não foi possível carregar as informações');
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const exportXLS = useCallback(() => {
    if (exporting.exporting) {
      try {
        return (
          <ExportXLS
            filename="Testemunhas cadastradas"
            columns={exporting.columns}
            data={exporting.data}
          />
        );
      } finally {
        setTimeout(() => {
          setExporting({ exporting: false, columns: [], data: [] });
        }, 2000);
      }
    }
    return <></>;
  }, [exporting]);

  return (
    <Container>
      {exportXLS()}
      <BaseTableDataManager
        isLoading={loading}
        options={{
          grouping: false,
          exportCsv: (columns, data) => {
            setExporting({ exporting: true, columns, data });
          },
        }}
        title="Testemunhas cadastradas"
        columns={[
          {
            title: 'Nome',
            field: 'name',
          },
          {
            title: 'CPF',
            field: 'cpf',
            render: (column: Witness) => cpfMask(column.cpf),
          },
          {
            title: 'Criado em',
            field: 'created_at',
            type: 'date',
          },
          { title: 'Cód.', field: 'id' },
          {
            title: 'Ações',
            field: 'id',
            export: false,
            hidden: hideActions,
            render: (column: Witness) => (
              <>
                <IconButton
                  onClick={() => history.push(`/witness/${column.id}/edit`)}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() => history.push(`/witness/${column.id}/show`)}
                >
                  <Visibility />
                </IconButton>
              </>
            ),
          },
          {
            title: 'Última alteração',
            field: 'updated_at',
            type: 'date',
          },
        ]}
        data={witness}
        onSelectionChange={rows => setHideActions(rows.length > 0)}
        onRefresh={() => loadData()}
        onAdd={() => history.push('/witness/new')}
      />
    </Container>
  );
}
