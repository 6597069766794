import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Divider } from '@material-ui/core';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { Witness, FileLoad, FileModel } from 'types';
import * as Yup from 'yup';

import Button from 'components/Button';
import { Input, InputMask } from 'components/Form';
import ImagemDialogShow from 'components/ImagemDialogShow';
import { ContainerInputResponsive, Header } from 'components/Layout';
import api from 'services/api';
import errorHandler from 'services/errorHandler';

import { Wrapper } from './styles';

interface RouteParam {
  id: string;
}

export default function Edit() {
  const params = useParams<RouteParam>();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);
  const [witness, setWitness] = useState<Witness>();

  useEffect(() => {
    async function loadWitness(id: string) {
      setLoading(true);
      try {
        const { data } = await api.get<Witness>(`witness/${id}`);

        setWitness(data);
      } catch (err) {
        errorHandler(err);
      } finally {
        setLoading(false);
      }
    }

    loadWitness(params.id);
  }, [params.id]);

  const handleSubmit = useCallback(
    async (dataForm: SubmitHandler<FormData>) => {
      try {
        // Remove all previous errors
        if (formRef && formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          name: Yup.string().required('O nome da testemunha é obrigatória'),
          cpf: Yup.string().required('O cpf da testemunha é obrigatória'),
        });

        const dataResult = await schema.validate(dataForm, {
          abortEarly: false,
        });
        // Validation passed

        try {
          const { data } = await api.put<Witness>(
            `witness/${params.id}`,
            dataResult,
          );

          setWitness(data);
          toast.success('Testemunha atualizada com sucesso!');
        } catch (err) {
          errorHandler(err);
        }
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          if (formRef && formRef.current) {
            formRef.current.setErrors(validationErrors);
          }
        }
      }
    },
    [params.id],
  );

  const handleOnAlterFile = useCallback(
    async (file: FileLoad) => {
      const formData = new FormData();

      formData.append('file', file.file, file.file.name);

      try {
        const { data } = await api.post<FileModel>(
          `witness/${params.id}/signature`,
          formData,
        );

        toast.success('Assinatura atualizada com sucesso.');
        if (witness) {
          setWitness({
            ...witness,
            file_id: data.id,
            file: data,
          });
        }
      } catch (error) {
        toast.error('Não foi atualizar a assinatura.');
      }
    },
    [params.id, witness],
  );

  if (loading) {
    return <Wrapper>Carregando dados...</Wrapper>;
  }

  return (
    <Wrapper>
      <Header>
        <h1>Edição de Empresa</h1>
        <Divider />
      </Header>

      <Form onSubmit={handleSubmit} ref={formRef} initialData={witness}>
        <ContainerInputResponsive>
          <Input name="name" label="Nome" />
          <InputMask
            name="cpf"
            label="CPF"
            mask={[
              /\d/,
              /\d/,
              /\d/,
              '.',
              /\d/,
              /\d/,
              /\d/,
              '.',
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
            ]}
          />
        </ContainerInputResponsive>
        {witness?.file && (
          <ImagemDialogShow
            onSendFile={handleOnAlterFile}
            url={witness?.file.url}
            alt={witness.file.name}
            key={witness.file_id}
            editable
          />
        )}

        <Button type="submit">Atualizar</Button>
      </Form>
    </Wrapper>
  );
}
