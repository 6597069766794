import { all } from 'redux-saga/effects';

// eslint-disable-next-line import/no-named-as-default
import auth from './auth/sagas.js';
import company from './companies/sagas.js';
import user from './users/sagas.js';
import vintages from './vintages/sagas.js';

export default function* rootSaga() {
  return yield all([user, auth, company, vintages]);
}
