import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .wrapper-playlist-content {
    display: flex;
    flex-grow: 1;
  }

  .wrapper-playlist-player-content {
    flex-grow: 1;
    border-radius: 4px;

    header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      align-items: center;
      width: 100%;
      height: 80px;

      div {
        display: flex;
        align-items: center;

        h1 {
          font-size: 1.8rem;
          margin-right: 20px;
          color: ${props => props.theme.colors.main};
        }
        b {
          font-size: 1.1rem;
          color: rgba(0, 0, 0, 0.6);
        }
      }

      button {
        background: ${props => props.theme.colors.main};
        border: none;
        border-radius: 4px;
        padding: 10px;

        color: #fff;
        display: flex;
        align-items: center;
        font-size: 0.9rem;
        font-weight: bold;

        svg {
          margin-right: 10px;
        }
      }

      button.open {
        flex-direction: row-reverse;

        svg {
          transform: rotate(180deg);
          margin-left: 10px;
          margin-right: 0;
        }
      }
    }
  }

  .wrapper-playlist-player {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: black;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .wrapper-playlist-navigation {
    border-radius: 4px;
    width: 300px;
    margin-left: 2rem;
    border: 1px solid ${props => props.theme.colors.secondary};

    header {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
      padding: 20px 20px 10px 20px;
      border-bottom: 2px solid ${props => props.theme.colors.secondary};
      margin-bottom: 10px;
      color: #fff;
      background: ${props => props.theme.colors.main};
      border-radius: 4px;

      svg {
        margin-right: 10px;
        color: ${props => props.theme.colors.secondary};
      }
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        display: flex;
        align-items: center;
        text-decoration: none;
        padding: 10px;
        color: ${props => props.theme.colors.main};

        .div-button-style-rounded {
          height: 20px;
          width: 20px;
          border-radius: 50%;
          background: rgba(0, 0, 0, 0.5);
          margin-right: 10px;
        }

        .div-button-style-rounded:hover {
          /* background: ${props => props.theme.colors.secondary}; */
          -webkit-box-shadow: 0px 0px 2px 1px rgba(66, 66, 66, 1);
          -moz-box-shadow: 0px 0px 2px 1px rgba(66, 66, 66, 1);
          box-shadow: 0px 0px 2px 1px rgba(66, 66, 66, 1);
        }

        p {
          opacity: 0.8;
        }

        p:hover {
          /* color: ${props => props.theme.colors.secondary}; */
          opacity: 1;
        }
      }

      button {
        background: none;
        border: none;
        color: inherit;
        font-weight: bold;
      }

      .current {
        color: purple;

        .div-button-style-rounded {
          background: purple;
        }
      }

      .completed {
        color: green;

        .div-button-style-rounded {
          background: green;
        }
      }

      .current,
      .completed {
        p {
          opacity: 1;
        }
      }
    }
  }

  .wrapper-playlist-assets {
    margin-top: 20px;
    border-radius: 4px;
    width: 100%;
    height: 200px;
    align-self: flex-end;
  }

  @media (max-width: 1200px) {
    .wrapper-playlist-player-content {
      header {
        button.open {
          svg {
            margin: 0;
          }
        }

        aside {
          p {
            display: none;
          }

          svg {
            margin: 0;
          }
        }
      }
    }
  }

  @media (max-width: 800px) {
    & {
      height: auto;
    }

    aside {
      display: none;
    }

    .wrapper-playlist-player-content {
      header {
        div {
          h1 {
            font-size: 1rem;
          }

          b {
            font-size: 0.8rem;
          }
        }
      }
    }

    .wrapper-playlist-content {
      flex-direction: column;

      .wrapper-playlist-player-content {
        height: auto;

        .wrapper-playlist-player {
          height: 60vh;
        }
      }

      .wrapper-playlist-navigation {
        display: block;
        height: auto;
        min-width: 100%;
        margin: 10px 0px;
      }
    }
  }
`;
