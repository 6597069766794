import { User } from 'types';
import { action } from 'typesafe-actions';

import { AuthTypes, Auth } from './types';

export interface RequestAuthParams {
  email: string;
  password: string;
}

export const updateUser = (data: User) =>
  action(AuthTypes.AUTH_UPDATE_USER_REQUEST, data);

export const authRequest = (params: RequestAuthParams) =>
  action(AuthTypes.AUTH_REQUEST, params);

export const authSuccess = (data: Auth) => action(AuthTypes.AUTH_SUCCESS, data);

export const authFailure = () => action(AuthTypes.AUTH_FAILURE);

export const authLogout = () => action(AuthTypes.AUTH_LOGOUT);

export const authForgotPasswordRequest = (email: string) =>
  action(AuthTypes.AUTH_FORGOT_PASSWORD_REQUEST, email);

export const authForgotPasswordSuccess = () =>
  action(AuthTypes.AUTH_FORGOT_PASSWORD_SUCCESS);

export const authForgotPasswordFailure = () =>
  action(AuthTypes.AUTH_FORGOT_PASSWORD_FAILURE);
