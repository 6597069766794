import { call, put, all, takeLatest } from 'redux-saga/effects';
import api from 'services/api';
import { loadFailure, loadSuccess, setSelectedCompany } from './actions';
import { CompaniesTypes } from './types';

// interface Response {
//   data: Company[];
// }

export function* load() {
  try {
    const { data } = yield call(api.get, 'companies');

    yield put(loadSuccess(data));

    if (data.length > 0) {
      yield put(setSelectedCompany(data[0]));
    }
  } catch (err) {
    yield put(loadFailure());
  }
}

export default all([takeLatest(CompaniesTypes.LOAD_REQUEST, load)]);
