import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Divider } from '@material-ui/core';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import filesize from 'filesize';
import { Witness, FileLoad } from 'types';
import { uuid } from 'uuidv4';
import * as Yup from 'yup';

import Button from 'components/Button';
import { Input, InputMask } from 'components/Form';
import ImagemDialogShow from 'components/ImagemDialogShow';
import { ContainerInputResponsive, Header } from 'components/Layout';
import Upload from 'components/Upload';
import api from 'services/api';
import errorHandler from 'services/errorHandler';

import { Container } from './styles';

export default function Add() {
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);

  const [files, setFiles] = useState<FileLoad[]>([]);

  const handleSubmit = useCallback(
    async (data: SubmitHandler<FormData>) => {
      try {
        // Remove all previous errors
        if (formRef && formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório!'),
          cpf: Yup.string()
            .length(11, 'O CPF deve ter 11 números.')
            .required('O cpf é obrigatório.'),
          files: Yup.array().min(
            1,
            'Deve ser adicionado a imagem da assinatura.',
          ),
        });

        const dataResult = await schema.validate(
          {
            ...data,
            files,
          },
          {
            abortEarly: false,
          },
        );

        try {
          const formData = new FormData();

          formData.append('file', files[0].file, files[0].file.name);

          const response = await api.post<Witness>('witness', formData, {
            params: {
              name: dataResult.name,
              cpf: dataResult.cpf,
            },
          });

          toast.success('Testemunha cadastrada com sucesso!');
          history.push(`/witness/${response.data.id}/show`);

          if (formRef && formRef.current) {
            formRef.current.reset();
          }
        } catch (err) {
          errorHandler(err);
        }
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          if (formRef && formRef.current) {
            formRef.current.setErrors(validationErrors);
          }
        }
      }
    },
    [files, history],
  );

  const handleOnUpload = useCallback((filesSended: File[]) => {
    const filesUploaded = filesSended.map(file => ({
      file,
      id: uuid(),
      name: file.name,
      size: file.size,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
    }));

    setFiles(filesUploaded);
  }, []);

  return (
    <Container>
      <Header>
        <h1>Cadastro de Testemunha</h1>
        <Divider />
      </Header>

      <Form onSubmit={handleSubmit} ref={formRef}>
        <ContainerInputResponsive>
          <Input name="name" label="Nome" />
          <InputMask
            name="cpf"
            label="CPF"
            mask={[
              /\d/,
              /\d/,
              /\d/,
              '.',
              /\d/,
              /\d/,
              /\d/,
              '.',
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
            ]}
          />
        </ContainerInputResponsive>
        <Upload onUpload={handleOnUpload} multiple={false} />
        {files.map((file, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <ImagemDialogShow
            key={`${file.id}-${index}`}
            url={file.preview}
            alt={file.name}
            editable={false}
          />
        ))}
        <Button type="submit">Cadastrar</Button>
      </Form>
    </Container>
  );
}
