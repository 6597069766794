import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import AuthLayout from 'libs/layouts/auth';
import SignedLayout from 'libs/layouts/signed';
import { ApplicationState } from 'store';

interface OwnProps {
  component: React.FunctionComponent | React.ComponentClass;
  isPrivate?: boolean;
  roles?: string[];
  isPublic?: boolean;
}

export default function RouteWrapper(props: OwnProps & RouteProps) {
  const { isPrivate, isPublic, component: Component, roles, ...rest } = props;
  const signed = useSelector((state: ApplicationState) => state.auth.signed);
  const user = useSelector((state: ApplicationState) => state.auth.auth?.user);

  if (!isPublic) {
    if (roles) {
      if (user) {
        const actualRoles = user?.roles?.map(i => i.slug) || [];
        if (actualRoles?.filter(v => roles.includes(v)).length === 0) {
          return <Redirect to="/403" />;
        }
      } else {
        return <Redirect to="/403" />;
      }
    }

    if (!signed && isPrivate) {
      return <Redirect to="/" />;
    }

    if (signed && !isPrivate) {
      return <Redirect to="/dashboard" />;
    }
  }

  const Layout = signed ? SignedLayout : AuthLayout;
  const RouteSpreeding = () => <Route {...rest} component={Component} />;

  return (
    <Layout>
      <RouteSpreeding />
    </Layout>
  );
}
