import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import VisibilityIcon from '@material-ui/icons/Visibility';
import { formatDistanceToNow } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';
import { Bill, Order } from 'types';
import { formatDateWithoutTimestamps } from 'utils/format';
import { formatCurrency } from 'utils/formatCurrency';
import { getNameFromParticipant } from 'utils/renderNameParticipant';

import { Header } from 'components/Layout';
import api from 'services/api';
import errorHandler from 'services/errorHandler';

import { Wrapper, InfoContainer, BillsList } from './styles';
import BaseTable from 'components/BaseTable';
import { IconButton } from '@material-ui/core';

interface OrderExtend extends Order {
  create_atFormatted: string | null;
}

const FinanceDashboard: React.FC = () => {
  const history = useHistory();
  const [payments, setPayment] = useState<Bill[]>([]);
  const [receives, setReceive] = useState<Bill[]>([]);
  const [orders, setOrders] = useState<OrderExtend[]>([]);

  useEffect(() => {
    async function loadPayment() {
      try {
        const { data } = await api.get<Bill[]>('bills', {
          params: {
            type: 'pagar',
            notPaginate: true,
            expired_date: new Date(),
          },
        });

        setPayment(
          data.map(bill => {
            return {
              ...bill,
              expiredDateFormatted:
                formatDateWithoutTimestamps(bill.expired_date) || '',
              valueFormatted: formatCurrency(bill.value) || '',
              name: getNameFromParticipant(bill.participant) || '',
            };
          }),
        );
      } catch (err) {
        errorHandler(err);
      }
    }

    async function loadReiceved() {
      try {
        const { data } = await api.get<Bill[]>('bills', {
          params: {
            type: 'receber',
            notPaginate: true,
            expired_date: new Date(),
          },
        });

        setReceive(
          data.map(bill => {
            return {
              ...bill,
              expiredDateFormatted:
                formatDateWithoutTimestamps(bill.expired_date) || '',
              valueFormatted: formatCurrency(bill.value) || '',
              name: getNameFromParticipant(bill.participant) || '',
            };
          }),
        );
      } catch (err) {
        errorHandler(err);
      }
    }

    async function loadOrders() {
      try {
        const { data } = await api.get<Order[]>(`order`);

        setOrders(
          data.map(order => {
            return {
              ...order,
              amount_parcelsFormatted: formatCurrency(order.amount_parcels),
              create_atFormatted: formatDistanceToNow(
                new Date(order.created_at),
                {
                  locale: ptBr,
                },
              ),
            };
          }),
        );
      } catch (err) {
        toast.error('Não foi possível carregar os pedidos');
      }
    }

    loadPayment();
    loadReiceved();
    loadOrders();
  }, []);

  return (
    <Wrapper>
      <InfoContainer>
        <Header>
          <h1>Recebimentos agendados para hoje</h1>
        </Header>
        <BillsList payment={false}>
          <BaseTable<Bill>
            data={receives}
            title=""
            columns={[
              {
                title: 'Fornecedor',
                field: 'name',
              },
              {
                title: 'Cód. Próprio',
                field: 'own_code',
              },
              {
                title: 'Valor',
                field: 'valueFormatted',
              },
              {
                title: 'Ações',
                field: 'id',
                export: false,
                render: column => (
                  <>
                    <IconButton
                      onClick={() =>
                        history.push(`/receives/${column.id}/show`)
                      }
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </>
                ),
              },
            ]}
          />
        </BillsList>
      </InfoContainer>
      <InfoContainer>
        <Header>
          <h1>Pagamentos agendados para hoje</h1>
        </Header>
        <BillsList payment>
          <BaseTable<Bill>
            data={payments}
            title=""
            columns={[
              {
                title: 'Fornecedor',
                field: 'name',
              },
              {
                title: 'Cód. Próprio',
                field: 'own_code',
              },
              {
                title: 'Valor',
                field: 'valueFormatted',
              },
              {
                title: 'Ações',
                field: 'id',
                export: false,
                render: column => (
                  <>
                    <IconButton
                      onClick={() =>
                        history.push(`/payments/${column.id}/show`)
                      }
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </>
                ),
              },
            ]}
          />
        </BillsList>
      </InfoContainer>
      <InfoContainer>
        <Header>
          <h1>Últimos pedidos adicionados</h1>
        </Header>
        <BillsList payment={false}>
          <BaseTable
            data={orders}
            title=""
            columns={[
              {
                title: 'Cód',
                field: 'id',
              },
              {
                title: 'Hectares',
                field: 'hectares',
              },
              {
                title: 'Valor',
                field: 'amount_parcelsFormatted',
              },
              {
                title: 'Incluído há',
                field: 'create_atFormatted',
              },
              {
                title: 'Ações',
                field: 'id',
                export: false,
                render: column => (
                  <>
                    <IconButton
                      onClick={() => history.push(`/orders/${column.id}/show`)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </>
                ),
              },
            ]}
          />
        </BillsList>
      </InfoContainer>
    </Wrapper>
  );
};

export default FinanceDashboard;
