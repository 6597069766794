import { AnyAction } from 'redux';

import { VintageState, VintageTypes } from './types';

const INITIAL_STATE: VintageState = {
  loading: false,
  error: false,
  data: [],
};

const reducer = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case VintageTypes.LOAD_REQUEST:
      return { ...state, loading: true, error: false };
    case VintageTypes.LOAD_SUCCESS: {
      const { vintages } = action.payload;
      return {
        ...state,
        loading: false,
        error: false,
        data: vintages,
      };
    }
    case VintageTypes.LOAD_FAILURE:
      return { ...state, ...INITIAL_STATE };
    case VintageTypes.SET_SELECTED_VINTAGE: {
      const { vintage } = action.payload;

      return {
        ...state,
        selectedVintage: vintage,
      };
    }
    default:
      return state;
  }
};

export default reducer;
