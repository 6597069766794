/* eslint-disable no-console */
import { Store } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware, { SagaMonitor } from 'redux-saga';

import createStore from './createStore';
import { AuthState } from './ducks/auth/types';
import { CompanyState } from './ducks/companies/types';
import rootReducer from './ducks/rootReducer';
// eslint-disable-next-line import/no-cycle
import rootSaga from './ducks/rootSaga.js';
import { UsersState } from './ducks/users/types';
import { VintageState } from './ducks/vintages/types';
import persistReducers from './persistReducers.js';

// const sagaMonitor: SagaMonitor | undefined =
//   process.env.NODE_ENV === 'development'
//     ? console.tron.createSagaMonitor()
//     : undefined;

const sagaMonitor: SagaMonitor | undefined = undefined;

const sagaMiddleware = createSagaMiddleware({
  sagaMonitor,
});

const middlewares = [sagaMiddleware];

export interface ApplicationState {
  users: UsersState;
  auth: AuthState;
  companies: CompanyState;
  vintages: VintageState;
}

const store: Store<ApplicationState> = createStore(
  persistReducers(rootReducer),
  middlewares,
);

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export default { store, persistor };
