import { User } from 'types';

/**
 *
 * Action types
 */
export enum AuthTypes {
  AUTH_REQUEST = '@auth/AUTH_REQUEST',
  AUTH_SUCCESS = '@auth/AUTH_SUCCESS',
  AUTH_FAILURE = '@auth/AUTH_FAILURE',
  AUTH_LOGOUT = '@auth/AUTH_LOGOUT',
  AUTH_FORGOT_PASSWORD_REQUEST = '@auth/FORGOT_PASSWORD_REQUEST',
  AUTH_FORGOT_PASSWORD_SUCCESS = '@auth/FORGOT_PASSWORD_SUCCESS',
  AUTH_FORGOT_PASSWORD_FAILURE = '@auth/FORGOT_PASSWORD_FAILURE',
  AUTH_UPDATE_USER_REQUEST = '@auth/AUTH_UPDATE_USER_REQUEST',
}

/**
 * Data types
 */

export interface Auth {
  token: {
    type: string;
    token: string;
    refreshToken: string;
  };
  user: User;
}

/**
 * State type
 */

export interface AuthState {
  readonly auth?: Auth;
  readonly loading: boolean;
  readonly error: boolean;
  readonly signed: boolean;
}
