import React, { ReactChild } from 'react';

import { Header } from 'components/Header';
import Menu from 'components/Menu';

import { Wrapper } from './styles';
// import { Hidden } from '@material-ui/core';

interface OwnProps {
  children: ReactChild;
}

export default function AuthLayout({ children }: OwnProps) {
  return (
    <Wrapper>
      <Header className="wrapper-root-header" />
      <div className="wrapper-root-container">
        <Menu />

        <div className={`wrapper-root-content`}>{children}</div>
      </div>
    </Wrapper>
  );
}
