import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 100vw;
  height: 100vh;
  background: ${props => props.theme.background};

  .wrapper-root-container {
    display: flex;
    min-width: 100%;
    min-height: calc(100vh - 70px);
    max-width: 100%;
  }

  .wrapper-root-content {
    padding: 20px;
    flex: 1;
  }

  .menu-closed {
    width: calc(100vw - 70px);
  }

  @media (max-width: 720px) {
    .wrapper-root-content {
      width: 100vw;
    }
  }
`;
