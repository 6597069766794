import React from 'react';

import LinkIcon from '@material-ui/icons/Link';
import filesize from 'filesize';
import { FileModel } from 'types';

import { Container } from './styles';

interface OwnProps {
  files: FileModel[];
}

const ShowFileList: React.FC<OwnProps> = ({ files }) => {
  return (
    <Container>
      {files.map(file => {
        return (
          <li key={file.id}>
            <p>{file.name}</p>
            <p>
              Classe:
              {file.classe}
            </p>
            <p>
              Extensão:
              {file.extname}
            </p>
            <p>{filesize(file.size)}</p>
            <a href={file.url} target="_blank" rel="noopener noreferrer">
              <LinkIcon style={{ marginRight: 8, color: '#222' }} />
            </a>
          </li>
        );
      })}
    </Container>
  );
};

export default ShowFileList;
