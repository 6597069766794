import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import IconButton from '@material-ui/core/IconButton';
import { Visibility } from '@material-ui/icons';
import _ from 'lodash';
import { Order } from 'types';
import { formatDateWithTimestamps } from 'utils/format';
import { getNameFromParticipant } from 'utils/renderNameParticipant';

import BaseTableDataManager from 'components/BaseTableDataManager';
import Loading from 'components/Information/Loading';
import api from 'services/api';
import errorHandler from 'services/errorHandler';
import { ApplicationState } from 'store';

import { Container } from './styles';

export default function OrdersClient() {
  const history = useHistory();
  const [hideActions, setHideActions] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState<Order[]>([]);
  const user = useSelector((state: ApplicationState) => state.auth.auth?.user);
  const selectedVintage = useSelector(
    (state: ApplicationState) => state.vintages.selectedVintage,
  );

  const loadData = useCallback(
    async (participant_id: string, vintage_id: string) => {
      try {
        setLoading(true);
        const { data } = await api.get<Order[]>(
          `client/${participant_id}/orders`,
          {
            params: { vintage_id },
          },
        );

        setOrders(_.sortBy(data, 'create_at'));
      } catch (error) {
        toast.error('Não foi possível carregar as informações');
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    if (selectedVintage && user?.participant_id) {
      loadData(user.participant_id.toString(), selectedVintage.id.toString());
    }
  }, [user, selectedVintage, loadData]);

  if (!user || !selectedVintage || loading) {
    return <Loading />;
  }

  return (
    <Container>
      <BaseTableDataManager
        data={orders}
        isLoading={loading}
        title="Meus pedidos"
        onSelectionChange={rows => setHideActions(rows.length > 0)}
        onRefresh={() =>
          loadData(
            user.participant_id?.toString() || '',
            selectedVintage.id.toString(),
          )
        }
        options={{
          grouping: false,
          pageSizeOptions: [5, 20, 50, 100, orders.length],
        }}
        columns={[
          {
            title: 'Ações',
            field: 'id',
            hidden: hideActions,
            render: (column: Order) => (
              <>
                <IconButton
                  onClick={() => history.push(`/orders/${column.id}/show`)}
                >
                  <Visibility />
                </IconButton>
              </>
            ),
          },
          {
            title: 'Empresa',
            field: 'company.fantasy_name',
          },
          {
            title: 'Vendedor',
            field: 'seller_name',
            render: (column: Order) => {
              return getNameFromParticipant(column.seller);
            },
          },
          {
            title: 'Fazendas',
            field: 'farms',
            render: (column: Order) =>
              column.farms.map(farm => farm.farm?.name).join(','),
          },
          {
            title: 'Hectares',
            field: 'hectares',
            type: 'numeric',
          },
          {
            title: 'Total do pedido',
            field: 'amount',
            type: 'currency',
            currencySetting: {
              locale: 'pt-BR',
              currencyCode: 'BRL',
            },
          },
          {
            title: 'Total das parcelas',
            field: 'amount_parcels',
            type: 'currency',
            currencySetting: {
              locale: 'pt-BR',
              currencyCode: 'BRL',
            },
          },
          {
            title: 'Safra',
            field: 'vintage.description',
          },

          { title: 'Cód.', field: 'id' },
          {
            title: 'Criado em',
            field: 'created_at',
            type: 'date',
            render: (column: Order) =>
              formatDateWithTimestamps(column.created_at),
          },
          {
            title: 'Última alteração',
            field: 'updated_at',
            type: 'date',
            render: (column: Order) =>
              formatDateWithTimestamps(column.updated_at),
          },
        ]}
      />
    </Container>
  );
}
