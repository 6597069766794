import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 4px;
  padding: 20px;
  max-width: 300px;
  font-size: 1rem;
  border: 1px solid #333;

  .delete-button-participants-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
`;
