import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';

import Chip from '@material-ui/core/Chip';
import FilterIcon from '@material-ui/icons/Search';

import { Container } from './styles';

interface FilterOptions {
  [key: string]: string;
}

interface ChipItens {
  label: string;
  key: string;
  value: string;
}

interface OwnProps {
  keys: FilterOptions;
  filter: string;
  onDelete: (key: string) => void;
}

const RenderTableFilters: React.FC<OwnProps> = ({ keys, filter, onDelete }) => {
  const [chipFilters, setChipFilters] = useState<ChipItens[]>([]);

  const renderFilterValue = useCallback((value: string) => {
    switch (value) {
      case 'true':
        return 'Sim';
      case 'false':
        return 'Não';
      case 'all':
        return 'Todos';
      default:
        return value;
    }
  }, []);

  useEffect(() => {
    try {
      const separaretedFilters = filter
        .split('&')
        .filter(a => a !== '')
        .map(i => i.split('='));

      const chipItens = separaretedFilters.map(item => {
        const [key, value] = item;

        return {
          label: keys[key],
          key,
          value: renderFilterValue(decodeURIComponent(value)),
        };
      });

      setChipFilters(chipItens);
    } catch {
      toast.error('Não foi possível exibir os filtros');
    }
  }, [filter, keys, renderFilterValue]);

  return (
    <Container>
      {chipFilters.map(item => (
        <Chip
          key={item.key}
          className="chip-item-filter"
          icon={<FilterIcon />}
          label={`${item.label}: ${item.value}`}
          onDelete={() =>
            onDelete(
              filter.replace(
                `${item.key}=${encodeURIComponent(item.value)}`,
                '',
              ),
            )
          }
          color="secondary"
        />
      ))}
    </Container>
  );
};

export default RenderTableFilters;
