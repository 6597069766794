import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';

import { ThemeProvider } from 'styled-components';
import { StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import GlobalStyle from '../libs/styles/global';
import history from '../services/history';
import { DrawerProvider } from './useDrawer';
import defaultTheme from '../libs/themes/defaultTheme';
import allStore from '../store';

const AppProvider: React.FC = ({ children }) => {
  return (
    <ChakraProvider resetCSS={false}>
      <ThemeProvider theme={defaultTheme}>
        <Provider store={allStore.store}>
          <PersistGate persistor={allStore.persistor}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
              <StylesProvider injectFirst>
                <Router history={history}>
                  <DrawerProvider>{children}</DrawerProvider>
                  <ToastContainer autoClose={3000} />
                </Router>
              </StylesProvider>
            </MuiPickersUtilsProvider>
          </PersistGate>
        </Provider>
        <GlobalStyle />
      </ThemeProvider>
    </ChakraProvider>
  );
};

export default AppProvider;
