import { Participant } from 'types';

import { cpfMask, cnpjMask } from './format';

export function getNameFromParticipant(participant: Participant | undefined) {
  if (!participant) {
    return '';
  }

  if (participant.type === 'fisical') {
    return `${participant.fisical?.name}`;
  }

  return `${participant.juridical?.social_name}`;
}

export function getIdentificationFromParticipant(participant: Participant) {
  if (participant.type === 'fisical') {
    return `${cpfMask(participant.fisical?.cpf)}`;
  }

  return `${cnpjMask(participant.juridical?.cnpj)}`;
}
