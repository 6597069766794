import styled from 'styled-components';

export const Container = styled.ul`
  margin: 10px 0px;
  display: grid;
  grid-template: repeat(auto-fill, max(200px));
  gap: 5px;

  li {
    padding: 10px;
    max-width: 200px;
    border: 1px solid ${props => props.theme.colors.main};
    border-radius: 4px;

    img {
      height: 100px;
      width: 180px;
    }
  }
`;
