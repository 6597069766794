import React from 'react';

import { CompanySellers } from 'types';

import Item from './Item';
import { Container } from './styles';

interface OwnProps {
  sellers: CompanySellers[];
  onItemClick?: (key: number) => void;
}

const RenderSellers: React.FC<OwnProps> = ({ sellers, onItemClick }) => {
  return (
    <Container>
      {sellers
        .filter(seller => !!seller.participants)
        .map(({ participants }) => {
          if (participants) {
            return (
              <Item
                participant={participants}
                key={participants.id}
                onClick={() => onItemClick && onItemClick(participants.id)}
              />
            );
          }

          return null;
        })}
    </Container>
  );
};

export default RenderSellers;
