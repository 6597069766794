import React, { useState, useEffect, ChangeEvent, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import { User } from 'types';

import { ContainerSectionResponsive, Header } from 'components/Layout';
import api from 'services/api';

import { Container } from './styles';

interface ApiReponse {
  data: User[];
}

const Production: React.FC = () => {
  const history = useHistory();
  const [wordFilter, setWordFilter] = useState('');
  const [filtered, setFiltered] = useState<User[]>([]);
  const [sellers, setSellers] = useState<User[]>([]);

  useEffect(() => {
    async function loadSellers() {
      const { data }: ApiReponse = await api.get('sellers');
      setSellers(data);
    }

    loadSellers();
  }, []);

  const handleOnFilter = useCallback(
    (word: string) => {
      setWordFilter(word);
      if (!word || word === '') setFiltered([]);

      if (sellers) {
        const regex = new RegExp(`${word}`, 'gmi');

        setFiltered(
          sellers?.filter(seller => {
            const { participant } = seller;
            if (participant && participant.type === 'fisical') {
              return participant.fisical?.name.match(regex) !== null;
            }

            if (participant && participant.type === 'juridical') {
              return participant.juridical?.fantasy_name.match(regex) !== null;
            }

            return false;
          }),
        );
      }
    },
    [sellers],
  );

  const renderSellersItens = useCallback(() => {
    if (wordFilter) {
      return filtered.map(seller => {
        const { participant } = seller;

        const label = participant
          ? participant.fisical?.name || participant.juridical?.social_name
          : seller.username;

        return (
          <button
            type="button"
            onClick={() => history.push(`/seller/${seller.id}/clients`)}
            className="wrapper-production-seller"
            key={seller.id}
          >
            {label}
          </button>
        );
      });
    }

    return sellers.map(seller => {
      const { participant } = seller;
      const label = participant
        ? participant.fisical?.name || participant.juridical?.social_name
        : seller.username;
      return (
        <button
          type="button"
          onClick={() => history.push(`/seller/${seller.id}/clients`)}
          className="wrapper-production-seller"
          key={seller.id}
        >
          {label}
        </button>
      );
    });
  }, [filtered, history, sellers, wordFilter]);

  return (
    <Container>
      <Header>
        <h1>Produção</h1>
      </Header>
      <ContainerSectionResponsive>
        <TextField
          onChange={(
            event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
          ) => handleOnFilter(event.target.value)}
          label="Parceiro/Vendedor"
          style={{ flex: 1 }}
        />
      </ContainerSectionResponsive>
      <section className="wrapper-production-container">
        {renderSellersItens()}
      </section>
    </Container>
  );
};

export default Production;
