import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Divider } from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  Participant,
  BaseAdonisPaginateReponseInterface,
  Farm,
  Options,
} from 'types';
import { cnpjMask, cpfMask } from 'utils/format';
import * as Yup from 'yup';

import Button from 'components/Button';
import { Input, Select, InputNumber } from 'components/Form';
import { ContainerInputResponsive, Header } from 'components/Layout';
import api from 'services/api';
import errorHandler from 'services/errorHandler';

import { loadCitiesParsed } from '../../loaders/CitiesParsed';
import { loadStatesParsed } from '../../loaders/StateParsed';
import { Container } from './styles';

interface ApiResponseData extends BaseAdonisPaginateReponseInterface {
  data: Participant[];
}

interface ApiReponse {
  data: ApiResponseData;
}

interface FormSubmitData {
  participant_id: string;
  name: string;
  total_ha: number;
  state: string;
  citie: string;
  ie?: string;
}

export default function Add() {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [stateSelected, setStateSelected] = useState<string | undefined>();
  const [participants, setParticipants] = useState<Options[]>([]);
  const [states, setStates] = useState<Options[]>();
  const [cities, setCities] = useState<Options[]>();

  const loadStates = useCallback(async () => {
    const statesParsed = await loadStatesParsed();

    setStates(statesParsed);
    if (statesParsed.length > 1) {
      setStateSelected(statesParsed[0].value);
    }
  }, []);

  const loadCities = useCallback(async (state: string | undefined) => {
    if (!state) return;
    try {
      const citiesParsed = await loadCitiesParsed(state);

      setCities(citiesParsed);
    } catch {
      toast.error('Não foi possível carregar os estados');
    }
  }, []);

  useEffect(() => {
    loadCities(stateSelected);
  }, [loadCities, stateSelected]);

  useEffect(() => {
    loadStates();
  }, [loadStates]);

  const loadClients = useCallback(async () => {
    try {
      setLoading(true);
      const { data }: ApiReponse = await api.get(
        '/participants?is_client=true',
      );

      setParticipants(
        data.data.map(participant => {
          if (participant.type === 'fisical') {
            return {
              value: String(participant.id),
              label: `${participant.fisical?.name} (${cpfMask(
                participant.fisical?.cpf || '',
              )})`,
            };
          }
          return {
            value: String(participant.id),
            label: `${participant.juridical?.social_name} (${cnpjMask(
              participant.juridical?.cnpj || '',
            )})`,
          };
        }) || [],
      );

      setLoading(false);
    } catch (err) {
      errorHandler(err);
    }
  }, []);

  useEffect(() => {
    loadClients();
  }, [loadClients]);

  const handleSubmit = useCallback(
    async (data: FormSubmitData) => {
      try {
        // Remove all previous errors
        if (formRef && formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          participant_id: Yup.string()
            .oneOf(
              participants.map(participant => participant.value),
              'O cliente deve ser selecionado',
            )
            .required('O tipo do cliente é obrigatório!'),
          name: Yup.string().required('O nome da fazenda é obrigatório!'),
          total_ha: Yup.string().required(
            'O total de hectares da fazenda é obrigatório!',
          ),
          state: Yup.string().required('O estado é obrigatório'),
          citie: Yup.string().required('A cidade é obrigatória'),
          ie: Yup.string(),
        });

        const result = await schema.validate(
          {
            ...data,
            state: states?.find(state => state.value === data.state)?.label,
            citie: cities?.find(citie => citie.value === data.citie)?.label,
          },
          {
            abortEarly: false,
          },
        );

        try {
          const response = await api.post<Farm>('farms', result);
          toast.success('Fazenda cadastrada com sucesso!');

          history.push(`/farms/${response.data.id}/show`);

          if (formRef && formRef.current) {
            formRef.current.reset();
          }
        } catch (err) {
          errorHandler(err);
        }
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          if (formRef && formRef.current) {
            formRef.current.setErrors(validationErrors);
          }
        }
      }
    },
    [history, participants, cities, states],
  );

  if (loading || !states || !participants || !cities) {
    return <Container>Carregando....</Container>;
  }

  return (
    <Container>
      <Header>
        <h1>Cadastro de fazendas</h1>
        <Divider />
      </Header>

      <Form onSubmit={handleSubmit} ref={formRef}>
        <ContainerInputResponsive>
          <Input name="name" label="Nome" />
          <InputNumber
            name="total_ha"
            label="Total de Hectares"
            inputProps={{
              suffix: '',
              prefix: '',
            }}
          />
          <Input name="ie" label="Inscrição Estadual" required={false} />
          <Select
            name="participant_id"
            label="Cliente"
            options={participants}
          />
          <Select
            name="state"
            label="Estado"
            onValueChange={(value: string | string[] | number | number[]) =>
              setStateSelected(value as string)
            }
            options={states}
          />
          <Select name="citie" label="Cidade" options={cities} />
        </ContainerInputResponsive>
        <Button type="submit">Cadastrar</Button>
      </Form>
    </Container>
  );
}
