import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import IconButton from '@material-ui/core/IconButton';
import TablePagination, {
  TablePaginationProps,
} from '@material-ui/core/TablePagination';
import { Edit, Delete, Visibility } from '@material-ui/icons';
import { AxiosError } from 'axios';
import { Farm, BaseAdonisPaginateReponseInterface } from 'types';
import { getNameFromParticipant } from 'utils/renderNameParticipant';

import BaseTableDataManager from 'components/BaseTableDataManager';
import RenderTableFilters from 'components/DefaultRenders/RenderTableFilters';
import DialogScroll from 'components/DialogScroll';
import ExportXLS from 'components/ExportXLS';
import api from 'services/api';
import errorHandler from 'services/errorHandler';

import { FarmFilter as Filter } from './Filter';
import { Container } from './styles';

export interface ApiResponseData extends BaseAdonisPaginateReponseInterface {
  data: Farm[];
}

export interface ApiReponse {
  data: ApiResponseData;
}

const apiPageUrl = 'farms';

export default function Participants() {
  const history = useHistory();

  const [filterOpen, setFilterOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const [hideActions, setHideActions] = useState(false);
  const [loading, setLoading] = useState(true);
  const [informations, setInformations] = useState<Farm[]>([]);
  const [paginate, setPaginate] = useState<ApiResponseData>({
    lastPage: 1,
    page: 1,
    perPage: 5,
    total: '0',
    data: [],
  });
  const [exporting, setExporting] = useState<{
    exporting: boolean;
    columns: any[];
    data: any[];
  }>({
    exporting: false,
    columns: [],
    data: [],
  });

  const loadData = useCallback(async (page = 1, perPage = 5, filters = '') => {
    try {
      setLoading(true);
      const { data }: ApiReponse = await api.get(
        `${apiPageUrl}?page=${page}&perPage=${perPage}&${filters}`,
      );

      setInformations(
        data.data.map(item => ({
          ...item,
          ownerName: getNameFromParticipant(item.owner),
        })),
      );
      setPaginate(data);

      setFilterOpen(false);
    } catch (error) {
      toast.error('Não foi possível carregar as informações');
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData(paginate.page, paginate.perPage, filter);
  }, [filter, paginate.page, paginate.perPage, loadData]);

  const handleOnDelete = useCallback(async (farm: Farm) => {
    const { error }: any = await api
      .delete(`${apiPageUrl}/${farm.id}`)
      .then(response => ({ response }))
      .catch((err: AxiosError) => ({ error: err }));

    if (error) {
      if (error.response.status === 404) {
        toast.error('Fazenda não encontrada');
      }

      if (error.response.status === 500) {
        toast.error('A uma inconsistência no servidor. Contate o suporte');
      }
    } else {
      toast.success(`Fazenda ${farm.name} removido com sucesso!`);
      setInformations(current => current.filter(i => i.id !== farm.id));
    }
  }, []);

  const handleOnChangePage = useCallback(
    async (page: number) => {
      await loadData(page + 1, paginate.perPage);
    },
    [loadData, paginate.perPage],
  );

  const handleOnChangeRowsPerPage = useCallback(
    async (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setLoading(true);
      setPaginate(current => ({
        ...current,
        perPage: Number(event.target.value),
      }));
      await loadData(paginate.page, Number(event.target.value));
      setLoading(false);
    },
    [loadData, paginate.page],
  );

  const handleOnFilterClose = useCallback(() => {
    setFilterOpen(false);
  }, []);

  const handleOnSubmitFilter = useCallback(
    (filters: string) => {
      setFilter(filters);
      handleOnFilterClose();
    },
    [handleOnFilterClose],
  );

  const exportXLS = useCallback(() => {
    if (exporting.exporting) {
      try {
        return (
          <ExportXLS
            filename="Fazendas cadastradas"
            columns={exporting.columns}
            data={exporting.data}
          />
        );
      } finally {
        setTimeout(() => {
          setExporting({ exporting: false, columns: [], data: [] });
        }, 2000);
      }
    }

    return <></>;
  }, [exporting]);

  return (
    <Container>
      <RenderTableFilters
        keys={{
          participant_id: 'Cliente',
          name: 'Fazenda',
          ie: 'I.E',
          total_haInitial: 'Hectares inicial',
          total_haFinal: 'Hectares final',
        }}
        filter={filter}
        onDelete={setFilter}
      />
      {exportXLS()}
      <BaseTableDataManager<Farm>
        isLoading={loading}
        options={{
          grouping: false,
          pageSizeOptions: [5, 20, 50, 100, Number(paginate.total)],
          exportCsv: (columns, data) => {
            setExporting({ exporting: true, columns, data });
          },
        }}
        title="Fazendas cadastradas"
        columns={[
          {
            title: 'Nome',
            field: 'name',
          },
          {
            title: 'Proprietário',
            field: 'ownerName',
          },
          {
            title: 'Hectares',
            field: 'total_ha',
            type: 'numeric',
            render: (column: Farm) => {
              return column.total_ha.toLocaleString('pt-BR');
            },
          },
          {
            title: 'Estado',
            field: 'state',
          },
          {
            title: 'Cidade',
            field: 'citie',
          },
          {
            title: 'I.E',
            field: 'ie',
          },
          {
            title: 'Ações',
            field: 'id',
            hidden: hideActions,
            export: false,
            render: (column: Farm) => (
              <>
                <IconButton
                  onClick={() => history.push(`/farms/${column.id}/edit`)}
                >
                  <Edit />
                </IconButton>
                <IconButton onClick={() => handleOnDelete(column)}>
                  <Delete />
                </IconButton>
                <IconButton
                  onClick={() => history.push(`/farms/${column.id}/show`)}
                >
                  <Visibility />
                </IconButton>
              </>
            ),
          },
          { title: 'Cód.', field: 'id' },
        ]}
        data={informations}
        onSelectionChange={rows => setHideActions(rows.length > 0)}
        onShowCustomFilter={() => setFilterOpen(true)}
        onRefresh={() => loadData(paginate.page, paginate.perPage)}
        onAdd={() => history.push('/farms/new')}
        components={{
          Pagination: (props: TablePaginationProps) => (
            <TablePagination
              {...props}
              count={Number(paginate.total)}
              page={paginate.page - 1}
              rowsPerPage={paginate.perPage}
              onChangePage={(event, page) => handleOnChangePage(page)}
              rowsPerPageOptions={[5, 20, 50, 100, Number(paginate.total)]}
              onChangeRowsPerPage={(
                event: React.ChangeEvent<
                  HTMLInputElement | HTMLTextAreaElement
                >,
              ) => handleOnChangeRowsPerPage(event)}
            />
          ),
        }}
      />

      {filterOpen && (
        <DialogScroll
          open={filterOpen}
          dialogTitle="Filtros de Fazendas"
          dialogContentText="Utilize os filtros para obter resultados mais personalizados"
          onClose={handleOnFilterClose}
          onClickActionCancelButton={handleOnFilterClose}
          dialogActions={<div />}
        >
          <Filter
            onCancel={handleOnFilterClose}
            onSubmit={handleOnSubmitFilter}
          />
        </DialogScroll>
      )}
    </Container>
  );
}
