import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  background: ${props => props.theme.colors.main};
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  height: 70px;

  img {
    cursor: pointer;
  }

  h1 {
    color: #fff;
  }

  button {
    background: none;
    border: none;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-content: center;

    svg {
      color: #fff;
    }
  }

  button:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  .wrapper-header-profile-menu {
    display: none;
    position: absolute;
    right: 30px;
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 4px;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    button {
      cursor: pointer;
      font-weight: bold;
      padding: 10px 0px;
      color: ${props => props.theme.colors.main};
      opacity: 0.8;
    }

    button:hover {
      opacity: 1;
    }
  }

  .open {
    display: block;
  }

  .wrapper-aside-options {
    display: flex;
    align-items: center;
  }

  .wrapper-aside-options-select {
    z-index: 999;
    min-width: 360px;
    margin-right: 10px;
  }

  @media (max-width: 1012px) {
    .wrapper-aside-options-select {
      display: none;
    }

    .icon-used-on-mobile {
      display: block;
    }
  }

  @media (min-width: 1013px) {
    .icon-used-on-mobile {
      display: none;
    }
  }
`;
