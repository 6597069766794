/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';

import { GoogleMap, useLoadScript, Polygon } from '@react-google-maps/api';
import { Points, Croqui, MapaAplicacao } from 'types';

import MarkerWithLabel from 'components/MarkerWithLabel';

// import errorHandler from 'services/errorHandler';

// const options: PolygonProps['options'] = {
//   // fillColor: "lightblue",
//   fillColor: '#fff',
//   fillOpacity: 1,
//   strokeColor: 'ligthgreen',
//   strokeOpacity: 1,
//   strokeWeight: 0.5,
//   clickable: false,
//   draggable: false,
//   editable: false,
//   geodesic: false,
//   zIndex: 1
// };

interface FileElements {
  [key: string]: {
    selected?: string;
    labels?: string[];
  };
}

interface OwnProps {
  convertedFiles: Array<Points | Croqui | MapaAplicacao>;
  filesElements?: FileElements;
  centeredFile?: number;
}

export const MapRenderFiles: React.FC<OwnProps> = ({
  convertedFiles,
  centeredFile,
}) => {
  const [center, setCenter] = useState({
    lat: -16.481106713285904,
    lng: -54.62159657076627,
  });
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API || '', // ,
    // ...otherOptions
  });

  useEffect(() => {
    if (centeredFile) {
      const findedIndex = convertedFiles.findIndex(
        item => item.file_id === centeredFile,
      );
      if (findedIndex >= 0) {
        setCenter(convertedFiles[findedIndex].centerFormatted);
      }
    }
  }, [centeredFile, convertedFiles]);

  const RenderPoints = React.useCallback((file: Points) => {
    return file.points.map((item, index) => {
      const [lng, lat] = item.geometry.coordinates;

      return (
        <MarkerWithLabel
          key={String(index)}
          label={item.point}
          title={item.point}
          position={{ lat, lng }}
        />
      );
    });
  }, []);

  const RenderCroqui = React.useCallback((file: Croqui) => {
    return (
      <>
        {file.formattedCoordinates?.map((item, index) => (
          <Polygon paths={item} key={index} options={file.options} />
        ))}
        <MarkerWithLabel
          label={file.plot.name}
          title={file.plot.name}
          position={file.centerFormatted}
        />
      </>
    );
  }, []);

  const RenderMapaAplicacao = React.useCallback((file: MapaAplicacao) => {
    const { geometryConvertted, sumarry, grids } = file;

    if (!geometryConvertted) return <></>;

    const elementsSelected = Object.keys(sumarry?.elements || {}).filter(
      key => sumarry?.elements[key].isSelected,
    );

    if (elementsSelected) {
      return elementsSelected.map((element, elementIndex) => {
        return grids.map((grid, gridIndex) => {
          const { polygonConverted } = grid;

          if (!polygonConverted) return <></>;

          const elementSelectedIndex = grid.elements.findIndex(
            item => item.element === element,
          );

          if (elementSelectedIndex < 0) {
            return polygonConverted.map((polygon, polygonIndex) => (
              <Polygon
                paths={polygon}
                key={`polygonconverted-${String(elementIndex)}-${String(
                  polygonIndex,
                )}-${String(gridIndex)}`}
              />
            ));
          }
          const elementValue = Number(
            grid.elements[elementSelectedIndex].value.toFixed(2),
          );
          let elementClassColor = '#fff';

          if (sumarry?.elements[element].visualizationType === 'class') {
            const provisoryColor = sumarry?.elements[element].classes?.find(
              classe =>
                elementValue >= classe.min && elementValue <= classe.max,
            )?.color;
            elementClassColor = provisoryColor || '#fff';
          } else {
            const provisoryColor = sumarry?.elements[
              element
            ].individualClasses?.find(classe => elementValue === classe.value)
              ?.color;
            elementClassColor = provisoryColor || '#fff';
          }

          const options = {
            fillColor: elementClassColor,
            fillOpacity: 1,
            strokeColor: 'ligthgreen',
            strokeOpacity: 0,
            strokeWeight: 0,
            clickable: true,
            draggable: false,
            editable: false,
            geodesic: false,
            zIndex: elementIndex,
          };

          return polygonConverted.map((polygon, index) => (
            <Polygon
              paths={polygon}
              key={`polygon1-${String(elementIndex)}-${String(index)}-${String(
                gridIndex,
              )}`}
              options={options}
            />
          ));
        });
      });
    }

    return geometryConvertted.map((item, index) => (
      <Polygon
        paths={item}
        key={`normalPolygon-${file._id}-${String(index)}`}
      />
    ));
  }, []);

  const renderMap = () => {
    // wrapping to a function is useful in case you want to access `window.google`
    // to eg. setup options or create latLng object, it won't be available otherwise
    // feel free to render directly if you don't need that

    return (
      <GoogleMap
        id="wrapper-print"
        center={center}
        mapTypeId="satellite"
        zoom={15}
        mapContainerStyle={{
          width: '700px',
          height: '500px',
        }}
      >
        {convertedFiles?.map((convertedFile, index) => {
          switch (convertedFile.classe) {
            case 'pontos-coleta':
              return RenderPoints(convertedFile as Points);
            case 'croqui':
              return RenderCroqui(convertedFile as Croqui);
            case 'mapa-aplicacao':
              return RenderMapaAplicacao(convertedFile as MapaAplicacao);
            default:
              return <div key={`${index}-${convertedFile._id}`} />;
          }
        })}
      </GoogleMap>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? (
    <div className="wrapper-map">{renderMap()}</div>
  ) : (
    <div>Carregando</div>
  );
};

export default MapRenderFiles;
