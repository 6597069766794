import { Company } from 'types';
import { action } from 'typesafe-actions';

import { CompaniesTypes } from './types';

export const loadRequest = () => action(CompaniesTypes.LOAD_REQUEST);

export const loadSuccess = (companies: Company[]) =>
  action(CompaniesTypes.LOAD_SUCCESS, { companies });

export const loadFailure = () => action(CompaniesTypes.LOAD_FAILURE);

export const setSelectedCompany = (company: Company) =>
  action(CompaniesTypes.SET_SELECTED_COMPANY, { company });
