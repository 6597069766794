import React, { useRef, useState, useEffect, useCallback } from 'react';

import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers';
import { useField } from '@unform/core';

import 'react-datepicker/dist/react-datepicker.css';
import { Container } from './styles';

interface Props extends Omit<KeyboardDatePickerProps, 'onChange' | 'value'> {
  name: string;
  label?: string;
}

export const DatePicker: React.FC<Props> = props => {
  const { name, label, ...rest } = props;
  const datepickerRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const [date, setDate] = useState(defaultValue || new Date());

  const getDate = useCallback(() => {
    return date;
  }, [date]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      getValue: getDate,
      clearValue: () => {
        setDate(new Date());
      },
    });
  }, [fieldName, registerField, getDate]);

  return (
    <Container>
      <KeyboardDatePicker
        inputRef={datepickerRef}
        margin="normal"
        id="date-picker-dialog"
        label={label}
        format="dd/MM/yyyy"
        value={date}
        onChange={setDate}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        error={!!error}
        helperText={error}
        {...rest}
      />
    </Container>
  );
};

export default DatePicker;
