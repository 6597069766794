import styled from 'styled-components';
import { darken } from 'polished';

export const Content = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 20px;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${darken(0.08, '#fff')};

  strong {
    font-size: 1.3rem;
  }

  > a {
    font-size: 1.6rem;
    display: flex;
    margin: 5px 0px;
    align-self: stretch;
    justify-content: center;
    color: ${darken(0.08, '#fff')};
    font-weight: bold;
    transition: color 0.2s;

    &:hover {
      color: ${darken(0.01, '#0a997f')};
      cursor: pointer;
    }
  }
`;
