/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Switch, FormControlLabel } from '@material-ui/core';
import {
  GoogleMap,
  LoadScript,
  GroundOverlay,
  Polygon,
} from '@react-google-maps/api';
import { Plot } from 'types';
import { getNameFromParticipant } from 'utils/renderNameParticipant';

import MarkerWithLabel from 'components/MarkerWithLabel';
import apiConvertFiles from 'services/apiConvertFiles';

import {
  Container,
  PlotInfo,
  BoxHectares,
  MapaAplicacaoContainer,
  MapaAplicacaoLegenda,
  Box,
} from './styles';

interface LatLong {
  lat: number;
  lng: number;
}

interface Params {
  id: string;
}

interface BaseConversaoFile {
  _id: string;
  plot_id: number;
  file_id: number;
  url: string;
  plot: Plot;
  vintage: number | string;
}

interface MapaProducao extends BaseConversaoFile {
  palette: {
    gray: number;
    red: number;
    yellow: number;
    green: number;
    blue: number;
  };
  coordinates: {
    north: number;
    west: number;
    east: number;
    south: number;
  };
}

interface Croqui extends BaseConversaoFile {
  hectares: number;
  geometry: {
    type: 'Polygon' | 'MultiPolygon';
    coordinates: number[][][][];
  };
  formattedCoordinates?: LatLong[][][];
}

interface Points extends BaseConversaoFile {
  points: {
    altitudeMode: string;
    geometry: {
      type: 'Point';
      coordinates: number[];
    };
    point: string;
  }[];
}

interface MapaAplicacao extends BaseConversaoFile {
  total_hectares: number;
  grids: Array<{
    hectares: number;
    grid: string;
    elements: Array<{
      element: string;
      value: number;
    }>;
    geometry: {
      type: 'Polygon' | 'MultiPolygon';
      coordinates: number[][][][];
    };
    polygonConverted?: LatLong[][][];
  }>;
  sumarry?: {
    elements: Element;
    selectedElement?: {
      element: string;
      min: number;
      max: number;
      classes: ClasseAmplitude;
    };
  };
  geometryConvertted?: LatLong[][][];
}

interface ElementItem {
  amount: number;
  itens: number[];
}

interface Element {
  [key: string]: ElementItem;
}

interface Pallet {
  [key: string]: number;
}

interface ClasseAmplitude {
  [key: string]: {
    color: string;
    min: number;
    max: number;
    label: string;
  };
}

const ViewFile: React.FC = () => {
  const { id } = useParams<Params>();
  const location = useLocation();
  const [map, setMap] = React.useState<GoogleMap | null>(null);

  const [center, setCenter] = useState<LatLong>({
    lng: -48.5864293839,
    lat: -19.8419575124,
  });
  const [error, setError] = useState(false);
  const [fileClass, setFileClass] = useState<string | null>(null);
  const [file, setFile] = useState<
    MapaProducao | Croqui | Points | MapaAplicacao
  >();

  const onLoad = React.useCallback(function callback(actualMap: GoogleMap) {
    setMap(actualMap);
  }, []);

  const onUnmount = React.useCallback(function callback() {
    setMap(null);
  }, []);

  const loadMP = useCallback(async (file_id: string) => {
    try {
      const mp = await apiConvertFiles.get<MapaProducao>(`/mp/${file_id}`);

      const { south, west, north, east } = mp.data.coordinates;

      const sw = new window.google.maps.LatLng(south, west);
      const ne = new window.google.maps.LatLng(north, east);

      const { Va, Za } = new window.google.maps.LatLngBounds(sw, ne);

      setCenter({ lat: (Za.i + Za.j) / 2, lng: (Va.i + Va.j) / 2 });

      setFile(mp.data);
      setError(false);
    } catch {
      toast.error('Algo errado com o formato do arquivo');
      setError(true);
    }
  }, []);

  const loadCroqui = useCallback(async (file_id: string) => {
    try {
      const croqui = await apiConvertFiles.get<Croqui>(`/croqui/${file_id}`);

      const polygonArray = croqui.data.geometry.coordinates.map(polygon => {
        const newPolygon: LatLong[][] = [];

        polygon.forEach(pointArray => {
          newPolygon.push(
            pointArray.map(latlong => {
              const [lng, lat] = latlong;
              return {
                lat: Number(String(lat).replace(',', '.')),
                lng: Number(String(lng).replace(',', '.')),
              };
            }),
          );
        });

        return newPolygon;
      });

      setFile({
        ...croqui.data,
        formattedCoordinates: polygonArray,
      });
      setError(false);
    } catch {
      toast.error('Algo errado com o formato do arquivo');
      setError(true);
    }
  }, []);

  const loadPoints = useCallback(async (file_id: string) => {
    try {
      const points = await apiConvertFiles.get<Points>(`/points/${file_id}`);

      setFile(points.data);
      setError(false);
    } catch {
      toast.error('Algo errado com o formato do arquivo');
      setError(true);
    }
  }, []);

  const loadMapaAplicacao = useCallback(async (file_id: string) => {
    try {
      const ma = await apiConvertFiles.get<MapaAplicacao>(`/ma/${file_id}`);

      const elements: Element = {};
      const polygonArray: LatLong[][][] = [];

      const grids = ma.data.grids.map(grid => {
        grid.elements.forEach(element => {
          if (elements[element.element]) {
            elements[element.element].amount += element.value;
            elements[element.element].itens = [
              ...elements[element.element].itens,
              element.value,
            ];
          } else {
            elements[element.element] = {
              amount: element.value,
              itens: [element.value],
            };
          }
        });

        const polygonConverted = grid.geometry.coordinates.map(polygon => {
          const newPolygon: LatLong[][] = [];

          polygon.forEach(pointArray => {
            newPolygon.push(
              pointArray.map(latlong => {
                const [lng, lat] = latlong;
                return {
                  lat: Number(String(lat).replace(',', '.')),
                  lng: Number(String(lng).replace(',', '.')),
                };
              }),
            );
          });

          polygonArray.push(newPolygon);
          return newPolygon;
        });

        return {
          ...grid,
          polygonConverted,
        };
      });

      setFile({
        ...ma.data,
        grids,
        sumarry: {
          elements,
        },
        geometryConvertted: polygonArray,
      });
      setError(false);
    } catch {
      toast.error('Algo errado com o formato do arquivo');
      setError(true);
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const classe = params.get('classe');
    setFileClass(classe);
    switch (classe) {
      case 'mp':
        loadMP(id);
        break;
      case 'croqui':
        loadCroqui(id);
        break;
      case 'pontos-coleta':
        loadPoints(id);
        break;
      case 'mapa-aplicacao':
        loadMapaAplicacao(id);
        break;
      default:
        toast.error('Tipo não suportado');
        setError(true);
        break;
    }
  }, [id, loadCroqui, loadMP, loadMapaAplicacao, loadPoints, location.search]);

  const handleOnChangeElement = useCallback(
    (checked, element: string, elementItens: ElementItem) => {
      if (!checked) {
        setFile(current => {
          if (!current) return undefined;

          const { sumarry } = current as MapaAplicacao;

          return {
            ...current,
            sumarry: {
              ...sumarry,
              selectedElement: undefined,
            },
          } as MapaAplicacao;
        });
      } else {
        const itemsSorted = elementItens.itens.sort((a, b) => {
          return a - b;
        });

        const size = itemsSorted.length;

        if (size > 0) {
          const min = itemsSorted[0];
          const max = itemsSorted[size - 1];

          const amplitude = Number(((max - min) / 5).toFixed(2));

          const classes: ClasseAmplitude = {
            none: {
              min: 0,
              color: 'rgb(0,0,0)',
              max: 0,
              label: 'Zero',
            },
            veryLow: {
              min,
              color: 'rgb(0,160,0)',
              max: min + amplitude,
              label: 'Baixíssima',
            },
            low: {
              min: min + amplitude,
              color: 'rgb(221,221,0)',
              max: min + amplitude * 2,
              label: 'Baixa',
            },
            medium: {
              min: min + amplitude * 2,
              color: 'rgb(255,121,0)',
              max: min + amplitude * 3,
              label: 'Média',
            },
            high: {
              min: min + amplitude * 3,
              color: 'rgb(255,0,0)',
              max: max + 0.0001,
              label: 'Alto',
            },
          };

          setFile(current => {
            if (!current) return undefined;

            const { sumarry } = current as MapaAplicacao;

            return {
              ...(current as MapaAplicacao),
              sumarry: {
                ...sumarry,
                selectedElement: {
                  element,
                  min,
                  max,
                  classes,
                },
              },
            } as MapaAplicacao;
          });
        }
      }
    },
    [],
  );

  const handleOnPrint = useCallback(() => {
    window.print();
    // const content = document.getElementById('divcontents');
    // const printConteiner = document.getElementById('ifmcontentstoprint');

    // if (content && printConteiner) {
    //   (content as HTMLIFrameElement).contentWindow?.print();
    //   const pri = (printConteiner as HTMLIFrameElement).contentWindow;
    //   if (pri) {
    //     pri.document.open();
    //     pri.document.write(content.innerHTML);
    //     pri.document.close();
    //     pri.focus();
    //     pri.print();
    //   }
    // }
  }, []);

  const renderMP = useCallback(() => {
    if (!file) return <></>;
    const { url, coordinates } = file as MapaProducao;

    return <GroundOverlay key="url" url={url} bounds={coordinates} />;
  }, [file]);

  const renderCroqui = useCallback(() => {
    if (!file) return <></>;
    const { formattedCoordinates } = file as Croqui;

    if (!formattedCoordinates) return <></>;

    return (
      <>
        {formattedCoordinates.map((item, index) => (
          <Polygon paths={item} key={String(index)} />
        ))}
      </>
    );
  }, [file]);

  const renderPoints = useCallback(() => {
    if (!file) return <></>;

    const { points } = file as Points;

    if (!points) return <></>;

    return (
      <>
        {points.map((item, index) => {
          const [lng, lat] = item.geometry.coordinates;

          return (
            <MarkerWithLabel
              key={String(index)}
              label={item.point}
              title={item.point}
              position={{ lat, lng }}
            />
          );
        })}
      </>
    );
  }, [file]);

  const renderMapaAplicacao = useCallback(() => {
    if (!file) return <></>;

    const { geometryConvertted, sumarry, grids } = file as MapaAplicacao;

    if (!geometryConvertted) return <></>;

    if (sumarry?.selectedElement?.element) {
      const { classes } = sumarry.selectedElement;

      return (
        <>
          {grids.map((item, index) => {
            const { polygonConverted } = item;

            if (!polygonConverted) return <> </>;

            const elementIndex = item.elements.findIndex(
              element => element.element === sumarry?.selectedElement?.element,
            );

            if (elementIndex < 0) {
              return polygonConverted.map(polygon => (
                <Polygon paths={polygon} key={String(index)} />
              ));
            }
            const elementValue = item.elements[elementIndex].value;

            let elementClassColor;

            if (elementValue === 0) {
              elementClassColor = classes.none.color;
            } else {
              const elementClass = Object.keys(classes).find(
                classe =>
                  elementValue >= classes[classe].min &&
                  elementValue < classes[classe].max,
              );

              if (elementClass) elementClassColor = classes[elementClass].color;
            }

            const options = {
              fillColor: elementClassColor,
              fillOpacity: 1,
              strokeColor: 'ligthgreen',
              strokeOpacity: 0,
              strokeWeight: 0,
              clickable: true,
              draggable: false,
              editable: false,
              geodesic: false,
              zIndex: 1,
            };

            return polygonConverted.map((polygon, polygonIndex) => (
              <Polygon
                paths={polygon}
                key={String(polygonIndex)}
                options={options}
              />
            ));
          })}
        </>
      );
    }

    return (
      <>
        {geometryConvertted.map((item, index) => (
          <Polygon paths={item} key={String(index)} />
        ))}
      </>
    );
  }, [file]);

  const renderMapaAplicacaoInfos = useCallback(() => {
    if (!file) return <></>;

    const { sumarry } = file as MapaAplicacao;

    const elements = sumarry?.elements || {};

    const classes = sumarry?.selectedElement?.classes || {};

    return (
      <MapaAplicacaoContainer>
        <header>
          <h1>Elementos</h1>
        </header>
        {Object.keys(elements).map(element => {
          return (
            <>
              <FormControlLabel
                key={element}
                control={
                  <Switch
                    onChange={
                      (
                        event: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean,
                      ) =>
                        handleOnChangeElement(
                          checked,
                          element,
                          elements[element],
                        )
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    name={element}
                    color="primary"
                  />
                }
                label={element}
              />
              {classes &&
                Object.keys(classes).map(key => {
                  const { label, color, min, max } = classes[key];

                  return (
                    <MapaAplicacaoLegenda key={`${Date.now()}-legenda-${key}`}>
                      <Box color={color} />
                      <strong>{`${label} (${min}-${Number(
                        max.toFixed(2),
                      )})`}</strong>
                    </MapaAplicacaoLegenda>
                  );
                })}
            </>
          );
        })}
      </MapaAplicacaoContainer>
    );
  }, [file, handleOnChangeElement]);

  const palletPercentage: Pallet = useMemo(() => {
    if (!file || fileClass !== 'mp') {
      return {
        'Muito boa': 0,
        Boa: 0,
        Média: 0,
        Ruim: 0,
        Péssima: 0,
      };
    }
    const { palette } = file as MapaProducao;
    const { gray = 0, red = 0, yellow = 0, green = 0, blue = 0 } = palette;

    const sumColors = gray + red + yellow + green + blue;

    return {
      'Muito boa':
        blue > 0 ? Number(((blue / sumColors) * 100).toPrecision(2)) : 0,
      Boa: green > 0 ? Number(((green / sumColors) * 100).toPrecision(2)) : 0,
      Média:
        yellow > 0 ? Number(((yellow / sumColors) * 100).toPrecision(2)) : 0,
      Ruim: red > 0 ? Number(((red / sumColors) * 100).toPrecision(2)) : 0,
      Péssima: gray > 0 ? Number(((gray / sumColors) * 100).toPrecision(2)) : 0,
    };
  }, [file, fileClass]);

  const renderPlotInfos = useCallback(() => {
    if (!file?.plot) return <></>;

    const { plot } = file;
    const { farm } = plot;

    return (
      <PlotInfo>
        <header>
          <h1>{`Talhão: ${plot.id} - ${plot.name}`}</h1>
          <h1>{`Fazenda: ${farm?.id} - ${farm?.name}`}</h1>
          <h1>{`Proprietário: ${getNameFromParticipant(farm?.owner)}`}</h1>
          <button type="button" onClick={() => handleOnPrint()}>
            Imprimir
          </button>
        </header>
        {fileClass === 'mp' && (
          <HorizontalBar
            data={{
              labels: Object.keys(palletPercentage),
              datasets: [
                {
                  label: 'Níveis Produtivos (%)',
                  backgroundColor: [
                    'rgb(0,0,255)',
                    'rgb(0,100,0)',
                    'rgb(255,255,0)',
                    'rgb(255,0,0)',
                    'rgb(191,191,191)',
                  ],
                  data: Object.keys(palletPercentage).map(
                    key => palletPercentage[`${key}`],
                  ),
                },
              ],
            }}
          />
        )}
        {fileClass === 'croqui' && (
          <BoxHectares>
            <p>Área</p>
            <h1>{`${(file as Croqui).hectares} ha`}</h1>
          </BoxHectares>
        )}
      </PlotInfo>
    );
  }, [file, fileClass, handleOnPrint, palletPercentage]);

  const renderMap = useCallback(() => {
    return (
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API || ''}>
        {file && (
          <GoogleMap
            id="teste-print"
            center={center}
            mapTypeId="satellite"
            zoom={15}
            onLoad={onLoad}
            onUnmount={onUnmount}
            mapContainerStyle={{
              flex: 1,
              display: 'flex',
              minWidth: '250px',
              minHeight: '450px',
            }}
          >
            {fileClass === 'mp' && renderMP()}
            {fileClass === 'croqui' && renderCroqui()}
            {fileClass === 'pontos-coleta' && renderPoints()}
            {fileClass === 'mapa-aplicacao' && renderMapaAplicacao()}
          </GoogleMap>
        )}
      </LoadScript>
    );
  }, [
    file,
    center,
    fileClass,
    onLoad,
    onUnmount,
    renderCroqui,
    renderMP,
    renderMapaAplicacao,
    renderPoints,
  ]);

  if (error) {
    return <Container>Arquivo não suportado</Container>;
  }

  return (
    <Container>
      {!map && <b>Carregando mapa...</b>}
      {renderPlotInfos()}

      {renderMap()}
      {fileClass === 'mapa-aplicacao' && renderMapaAplicacaoInfos()}
    </Container>
  );
};

export default React.memo(ViewFile);
