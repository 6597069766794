import { User } from 'types';

import { Paginate } from '../types';

/**
 *
 * Action types
 */

export enum UserTypes {
  LOAD_REQUEST = '@user/LOAD_REQUEST',
  LOAD_SUCCESS = '@user/LOAD_SUCCESS',
  LOAD_FAILURE = '@user/LOAD_FAILURE',
}

/**
 * Data types
 */

/**
 * State type
 */

export interface UsersState {
  paginate: Paginate;
  data: User[];
  readonly loading: boolean;
  readonly error: boolean;
}
