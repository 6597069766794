import React, { useState, useEffect, ChangeEvent } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import { Participant, User } from 'types';
import { getNameFromParticipant } from 'utils/renderNameParticipant';

import api from 'services/api';
import errorHandler from 'services/errorHandler';

import { Container } from './styles';

interface Params {
  id: string;
}

const ClientList: React.FC = () => {
  const history = useHistory();
  const params = useParams<Params>();
  const [wordFilter, setWordFilter] = useState('');
  const [filtered, setFiltered] = useState<Participant[]>([]);
  const [seller, setSeller] = useState<User>();

  useEffect(() => {
    async function loadSeller(id: string | undefined) {
      if (!id) return;
      try {
        const { data } = await api.get<User>(`sellers/${id}`);
        setSeller(data);
      } catch (error) {
        errorHandler(error);
      }
    }

    loadSeller(params.id);
  }, [params.id]);

  function handleOnFilter(word: string) {
    setWordFilter(word);
    if (!word || word === '') setFiltered([]);

    if (seller?.clients) {
      const regex = new RegExp(`${word}`, 'gmi');

      setFiltered(
        seller?.clients?.filter(participant => {
          if (participant.type === 'fisical') {
            return participant.fisical?.name.match(regex) !== null;
          }

          return participant.juridical?.fantasy_name.match(regex) !== null;
        }),
      );
    }
  }

  function renderClients() {
    if (wordFilter) {
      return filtered.map(participant => {
        return (
          <button
            type="button"
            onClick={() => history.push(`/client/${participant.id}/farms`)}
            className="wrapper-production-seller"
            key={participant.id}
          >
            {participant.fisical?.name || participant.juridical?.social_name}
          </button>
        );
      });
    }

    return (
      seller &&
      seller.clients?.map(participant => {
        return (
          <button
            type="button"
            onClick={() => history.push(`/client/${participant.id}/farms`)}
            className="wrapper-production-seller"
            key={participant.id}
          >
            {participant.fisical?.name || participant.juridical?.social_name}
          </button>
        );
      })
    );
  }

  return (
    <Container>
      <header>
        <h1>{`Clientes do vendedor: ${getNameFromParticipant(
          seller?.participant,
        )}`}</h1>
      </header>
      <section className="wrapper-filter-section">
        <TextField
          onChange={(
            event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
          ) => handleOnFilter(event.target.value)}
          label="Clientes"
          style={{ flex: 1 }}
        />
      </section>
      <section className="wrapper-production-container">
        {renderClients()}
      </section>
    </Container>
  );
};

export default ClientList;
