import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import { CoursesInterface, LessonInterface } from 'types';

import Player from 'components/Player';
import api from 'services/api';
import errorHandler from 'services/errorHandler';

import { Container } from './styles';
import { TimeUpdate } from './types';

export interface ApiReponse {
  data: CoursesInterface;
}

export interface RouteParams {
  id: string;
}

export default function Playlist() {
  const [open, setOpen] = useState(true);
  const [course, setCourse] = useState<CoursesInterface | undefined>();
  const [current, setCurrent] = useState<LessonInterface | undefined>();

  const params = useParams<RouteParams>();

  async function loadCourses(slug: string) {
    try {
      const { data }: ApiReponse = await api.get(`courses/${slug}`);

      setCourse(data);
      const { lessons = [] } = data;
      setCurrent(lessons[0] || {});
    } catch (err) {
      errorHandler(err);
    }
  }

  useEffect(() => {
    loadCourses(params.id);
  }, [params.id]);

  useEffect(() => {
    if (current) {
      try {
        api.post(`progress`, {
          lesson_id: current.id,
        });
      } catch (err) {
        errorHandler(err);
      }
    }
  }, [current]);

  async function goToNextClass(lesson: LessonInterface) {
    const order = lesson.order + 1;

    const nextLesson = course?.lessons?.find(async item => {
      return item.order === order;
    });

    if (course) {
      const newLessonsList =
        course?.lessons?.map(item => {
          return item.id === lesson.id
            ? {
                ...item,
                completed: true,
              }
            : item;
        }) || [];

      if (nextLesson) {
        toast.success('A próxima aula será carregada em 3 segundos', {
          onClose: () => {
            setCurrent(nextLesson);

            setCourse({
              ...course,
              lessons: newLessonsList,
            });
          },
        });
      }
    }
  }

  function setLessonConcluded(id: number, completed: boolean) {
    const actualCourseState = course;

    if (actualCourseState) {
      const newLessons = actualCourseState.lessons?.map(lesson => {
        const teste = lesson;
        const { progress } = lesson;

        if (lesson.id === id) {
          if (progress) {
            progress.completed = completed;
            teste.progress = progress;
          }
        }

        return teste;
      });

      setCourse({
        ...actualCourseState,
        lessons: newLessons,
      });
    }
  }

  async function handleOnConcluded(lesson: LessonInterface) {
    try {
      await api.put(`progress/${lesson.slug}`, {
        completed: !lesson.progress?.completed,
      });

      setLessonConcluded(lesson.id, !lesson.progress?.completed);
    } catch (err) {
      errorHandler(err);
    }
  }

  async function handleOnTimeUpdate(
    timeupdate: TimeUpdate,
    completed = false,
    lesson: LessonInterface,
  ) {
    try {
      await api.put(`progress/${lesson.slug}`, {
        completed,
        percentage: timeupdate.percent,
        progress_time: timeupdate.seconds,
      });

      if (completed) goToNextClass(lesson);
    } catch (err) {
      errorHandler(err);
    }
  }

  return (
    <Container>
      <div className="wrapper-playlist-content">
        <section className="wrapper-playlist-player-content">
          <header>
            <div>
              <h1>{current?.title}</h1>
              <b>{course?.name}</b>
            </div>

            <aside>
              <button
                type="button"
                className={!open ? 'open' : ''}
                onClick={() => setOpen(!open)}
              >
                <ExitToAppIcon />
                <p>{open ? 'Esconder Navegação' : 'Mostrar Navegação'}</p>
              </button>
            </aside>
          </header>

          <div className="wrapper-playlist-player">
            {current && (
              <Player onTimeUpdate={handleOnTimeUpdate} video={current} />
            )}
          </div>
        </section>

        {open && (
          <section className="wrapper-playlist-navigation">
            <header>
              <LocalLibraryIcon fontSize="large" />
              {course?.name}
            </header>
            <ul>
              {course?.lessons?.map(lesson => {
                let lessonState = '';

                if (lesson.progress?.completed) {
                  lessonState = 'completed';
                }

                if (lesson.id === current?.id) {
                  lessonState = 'current';
                }

                return (
                  <li key={lesson.id} className={lessonState}>
                    <button
                      type="button"
                      onClick={() => handleOnConcluded(lesson)}
                    >
                      <div className="div-button-style-rounded" />
                    </button>
                    <p>
                      <button type="button" onClick={() => setCurrent(lesson)}>
                        {lesson.title}
                      </button>
                    </p>
                  </li>
                );
              })}
            </ul>
          </section>
        )}
      </div>
      <section className="wrapper-playlist-assets" />
    </Container>
  );
}
