import { toast } from 'react-toastify';

import api from '../../../../services/api';
import { City, Options } from '../../../../types';

export async function loadCitiesParsed(state_id: string): Promise<Options[]> {
  try {
    const { data } = await api.get<City[]>(`cities?state=${state_id}`);

    const citiesParsed: Options[] = data.map(item => {
      return { value: String(item.id), label: `${item.name}` };
    });

    return citiesParsed;
  } catch {
    toast.error('Não foi possível carregar as cidades');
    return [];
  }
}
