import styled from 'styled-components';

import DefaultContainer from 'components/Container';

export const Container = styled(DefaultContainer)``;

export const ButtonAddFile = styled.button`
  border: 0;
  color: #fff;
  padding: 20px;
  font-size: 1rem;
  background-color: #cc2147;
  border-radius: 4px;
  margin-top: 10px;
  margin-right: 10px;
`;
