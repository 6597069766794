import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { Edit } from '@material-ui/icons';
import { Bill, BillPayment } from 'types';
import { formatCurrency } from 'utils/formatCurrency';
import { getNameFromParticipant } from 'utils/renderNameParticipant';

import BaseTableDataManager from 'components/BaseTableDataManager';
import { CreateAndUpdatesInfos } from 'components/DefaultRenders';
import ExportXLS from 'components/ExportXLS';
import Fab from 'components/Fab';
import Item from 'components/Item';
import { ContainerSectionResponsive, Header } from 'components/Layout';
import api from 'services/api';
import errorHandler from 'services/errorHandler';

import AddBillPayment from '../../BillPayment/Add';
import { Wrapper } from './styles';

interface RouteParam {
  id: string;
}

export default function Show() {
  const params = useParams<RouteParam>();
  const history = useHistory();
  const [addParcelOpen, setAddParcelOpen] = useState(false);
  const [bill, setBill] = useState<Bill>();
  const [exporting, setExporting] = useState<{
    exporting: boolean;
    columns: any[];
    data: any[];
  }>({
    exporting: false,
    columns: [],
    data: [],
  });

  const loadBill = useCallback(async (id: string) => {
    try {
      const { data } = await api.get<Bill>(`bills/${id}`);
      setBill({
        ...data,
        payments:
          data?.payments.map(payment => ({
            ...payment,
            total: payment.value + (payment.interest || 0),
            interestFormatted: formatCurrency(payment.interest || 0),
          })) || [],
      });
    } catch (err) {
      errorHandler(err);
    }
  }, []);

  useEffect(() => {
    loadBill(params.id);
  }, [params.id, loadBill]);

  const handleOnAddParcel = useCallback((parcel: BillPayment) => {
    setBill(current => {
      if (!current) return undefined;
      const payments = current.payments || [];
      payments.push({
        ...parcel,
        total: parcel.value + (parcel.interest || 0),
        interestFormatted: formatCurrency(parcel.interest || 0),
      });

      return {
        ...current,
        payments,
      };
    });
  }, []);

  const exportXLS = useCallback(() => {
    if (exporting.exporting) {
      try {
        return (
          <ExportXLS
            filename={`Baixas lançadas - ${bill?.own_code || bill?.id}`}
            columns={exporting.columns}
            data={exporting.data}
          />
        );
      } finally {
        setTimeout(() => {
          setExporting({ exporting: false, columns: [], data: [] });
        }, 2000);
      }
    }

    return <></>;
  }, [exporting, bill]);

  const PaymentTable = useCallback(() => {
    return (
      <BaseTableDataManager
        title="Baixas adicionadas"
        options={{
          grouping: false,
          selection: false,
          columnsButton: false,
          pageSizeOptions: [bill?.payments?.length || 0],
          exportCsv: (columns, data) => {
            setExporting({ exporting: true, columns, data });
          },
        }}
        columns={[
          {
            title: 'Data de Pagamento',
            field: 'date',
            type: 'date',
          },
          {
            title: 'Valor',
            field: 'value',
            type: 'currency',
          },
          {
            title: 'Juros',
            field: 'interest',
            type: 'currency',
          },
          {
            title: 'Total',
            field: 'total',
            type: 'currency',
          },
          {
            title: 'Tipo de Recebimento',
            field: 'receivedType.description',
          },
          {
            title: 'Cancelada',
            field: 'is_canceled',
            type: 'boolean',
          },
          { title: 'Cód.', field: 'id' },
          {
            title: 'Criado em',
            field: 'created_at',
            type: 'date',
          },
          {
            title: 'Última alteração',
            field: 'updated_at',
            type: 'date',
          },
        ]}
        onAdd={() => setAddParcelOpen(true)}
        data={bill?.payments || []}
      />
    );
  }, [bill]);

  const paymentAmount = useMemo(() => {
    if (!bill?.payments) {
      return 0;
    }

    return bill.payments
      .map(payment => payment.value + (payment?.interest || 0))
      .reduce((prev, curr) => prev + curr, 0);
  }, [bill]);

  const paymentAmountFormatted = useMemo(() => {
    return formatCurrency(paymentAmount);
  }, [paymentAmount]);

  const statusFormatted = useMemo(() => {
    if (!bill) return '';

    if (paymentAmount > 0 && paymentAmount >= bill?.value) {
      return 'Baixada';
    }
    if (paymentAmount > 0) {
      return 'Parcialmente baixada';
    }

    return 'Aberta';
  }, [paymentAmount, bill]);

  if (!bill) {
    return <div>Carregando...</div>;
  }

  return (
    <Wrapper>
      <Header>
        <h1>{`Parcela ${bill?.own_code || bill?.id}`}</h1>
        <CreateAndUpdatesInfos model={bill} />
      </Header>
      {exportXLS()}
      <ContainerSectionResponsive>
        <Item label="Cliente">{getNameFromParticipant(bill.participant)}</Item>
        <Item label="Fazenda">
          {bill.farm && `${bill.farm_id} - ${bill.farm?.name}`}
        </Item>
        <Item label="Empresa">
          {bill.company && `${bill.company_id} - ${bill.company.social_name}`}
        </Item>
        <Item label="Tipo de recebimento">
          {bill.receivedType &&
            `${bill.received_type_id} - ${bill.receivedType.description}`}
        </Item>
        <Item label="Safra">
          {bill.vintage && `${bill.vintage.description}`}
        </Item>
        <Item label="Usuário de Cadastro">
          {bill.user && `${bill.user.username}`}
        </Item>
        <Item label="Valor">{formatCurrency(bill.value)}</Item>
        <Item label="Valor pago">{paymentAmountFormatted}</Item>
        <Item label="Promissória">{bill.own_code}</Item>
        <Item label="Status">{statusFormatted}</Item>
        <Item label="Observação">{bill.observation}</Item>
        <Item label="Descrição">{bill.description}</Item>
      </ContainerSectionResponsive>
      <PaymentTable />
      <AddBillPayment
        open={addParcelOpen}
        onClose={() => setAddParcelOpen(false)}
        billId={bill.id}
        onAddParcel={handleOnAddParcel}
      />

      <Fab color="secondary" onClick={() => history.push(`edit`)}>
        <Edit />
      </Fab>
    </Wrapper>
  );
}
