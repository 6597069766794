import React from 'react';

import { ButtonProps } from '@material-ui/core';

import { StyledButton } from './styles';

export default function Button(props: ButtonProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <StyledButton {...props} />;
}

Button.defaultProps = {
  variant: 'contained',
  color: 'primary',
};
