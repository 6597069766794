import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Divider } from '@material-ui/core';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { Region } from 'types';
import * as Yup from 'yup';

import Button from 'components/Button';
import { Input } from 'components/Form';
import { ContainerInputResponsive, Header } from 'components/Layout';
import api from 'services/api';
import errorHandler from 'services/errorHandler';
import { loadRequest } from 'store/ducks/companies/actions';

import { Wrapper } from './styles';

interface RouteParam {
  id: string;
}

export default function Add() {
  const params = useParams<RouteParam>();
  const dispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);
  const [region, setRegion] = useState<Region>();

  const loadRegion = useCallback(async (id: string) => {
    setLoading(true);
    try {
      const { data } = await api.get<Region>(`regions/${id}`);

      setRegion(data);
      setLoading(false);
    } catch (err) {
      errorHandler(err);
    }
  }, []);

  useEffect(() => {
    loadRegion(params.id);
  }, [params.id, loadRegion]);

  const handleSubmit = useCallback(
    async (data: SubmitHandler<FormData>) => {
      try {
        // Remove all previous errors
        if (formRef && formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          description: Yup.string().required('A descrição é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        // Validation passed

        try {
          await api.put(`regions/${params.id}`, data);
          dispatch(loadRequest());
          toast.success('Região atualizada com sucesso!');
        } catch (err) {
          errorHandler(err);
        }
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          if (formRef && formRef.current) {
            formRef.current.setErrors(validationErrors);
          }
        }
      }
    },
    [dispatch, params.id],
  );

  if (loading || !region) {
    return <Wrapper>Carregando dados...</Wrapper>;
  }

  return (
    <Wrapper>
      <Header>
        <h1>Edição de Região</h1>
        <Divider />
      </Header>

      <Form onSubmit={handleSubmit} ref={formRef} initialData={region}>
        <ContainerInputResponsive>
          <Input name="description" label="Descrição" />
        </ContainerInputResponsive>
        <Button type="submit">Atualizar</Button>
      </Form>
    </Wrapper>
  );
}
