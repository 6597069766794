import React from 'react';

import { FileModel } from 'types';

import FileList from 'components/FileList';

interface OwnProps {
  files: FileModel[];
  onDeleteFile: (key: number) => void;
}

const RenderFiles: React.FC<OwnProps> = ({ files, onDeleteFile }) => {
  return <FileList files={files} onDeleteFile={onDeleteFile} />;
};

export default RenderFiles;
