import React, { useCallback, useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';

import { Container, Swatch, Color, Popover, Cover } from './styles';

interface OwnProps {
  onChangeColor?: (color: string) => void;
  color?: string;
}

const ColorPicker: React.FC<OwnProps> = ({
  onChangeColor,
  color: defaultColor = '#000',
}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(defaultColor);

  useEffect(() => {
    setColor(defaultColor);
  }, [defaultColor]);

  const handleClick = useCallback(() => {
    setDisplayColorPicker(current => !current);
  }, []);

  const handleClose = useCallback(() => {
    setDisplayColorPicker(false);
  }, []);

  const handleChange = useCallback(
    (colorSelected: string) => {
      setColor(colorSelected);
      if (onChangeColor) {
        onChangeColor(colorSelected);
      }
    },
    [onChangeColor],
  );

  return (
    <Container>
      <Swatch onClick={handleClick}>
        <Color color={color} />
      </Swatch>
      {displayColorPicker ? (
        <Popover>
          <Cover onClick={handleClose} />
          <SketchPicker
            color={color}
            onChange={changeColor => handleChange(changeColor.hex)}
          />
        </Popover>
      ) : null}
    </Container>
  );
};

export default ColorPicker;
