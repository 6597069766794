import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { OptionTypeBase } from 'react-select';
import { toast } from 'react-toastify';

import { Divider } from '@material-ui/core';
import { Scope, FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { Region, State, City, Company } from 'types';
import * as Yup from 'yup';

import Button from 'components/Button';
import { Input, Select, InputMask } from 'components/Form';
import { ContainerInputResponsive, Header } from 'components/Layout';
import api from 'services/api';
import errorHandler from 'services/errorHandler';
import { loadRequest } from 'store/ducks/companies/actions';

import { loadCitiesParsed } from '../../loaders/CitiesParsed';
import { loadStatesParsed } from '../../loaders/StateParsed';
import { Container } from './styles';

interface Options extends OptionTypeBase {
  value: string;
  label: string;
}

export interface ApiRegionReponse {
  data: Region[];
}

export interface ApiStateResponse {
  data: State[];
}

export interface ApiCityResponse {
  data: City[];
}

export interface ApiCompanyReponse {
  data: Company;
}

const INITIAL_DATA: Options = {
  value: 'fisical',
  label: 'Física',
};

export default function Add() {
  const history = useHistory();
  const dispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const [stateSelected, setStateSelected] = useState<string | undefined>();
  const [states, setStates] = useState<Options[]>();
  const [cities, setCities] = useState<Options[]>();
  const [regions, setRegions] = useState<Options[]>();

  const loadStates = useCallback(async () => {
    const statesParsed = await loadStatesParsed();

    setStates(statesParsed);
    if (statesParsed.length > 1) {
      setStateSelected(statesParsed[0].value);
    }
  }, []);

  const loadCities = useCallback(async (state: string | undefined) => {
    if (!state) return;
    try {
      const citiesParsed = await loadCitiesParsed(state);

      setCities(citiesParsed);
    } catch {
      toast.error('Não foi possível carregar os estados');
    }
  }, []);

  const loadRegions = useCallback(async () => {
    try {
      const { data }: ApiRegionReponse = await api.get(`regions`);

      setRegions(
        data.map(item => {
          return { value: String(item.id), label: `${item.description}` };
        }),
      );
    } catch {
      toast.error('Não foi possível carregar as regiões');
    }
  }, []);

  useEffect(() => {
    loadCities(stateSelected);
  }, [loadCities, stateSelected]);

  useEffect(() => {
    loadStates();
    loadRegions();
  }, [loadStates, loadRegions]);

  const handleSubmit = useCallback(
    async (data: SubmitHandler<FormData>) => {
      try {
        // Remove all previous errors
        if (formRef && formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          social_name: Yup.string().required('A Razão social é obrigatória'),
          fantasy_name: Yup.string().required('A Nome Fantasia é obrigatório'),
          cnpj: Yup.string()
            .length(14, 'O CNPJ deve ter 14 caracteres')
            .required('O CNPJ é obrigatório'),
          region_id: Yup.string(),
          address: Yup.object().shape({
            street: Yup.string().required('A rua é obrigatória'),
            neighborhood: Yup.string().required('O bairro é obrigatório'),
            complement: Yup.string(),
            description: Yup.string(),
            cep: Yup.string()
              .transform(value => {
                return value.replace(/\D/g, '');
              })
              .length(8, 'O CEP está inválido'),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        try {
          const response: ApiCompanyReponse = await api.post('companies', data);
          toast.success('Empresa cadastrada com sucesso!');
          history.push(`/companies/${response.data.id}/show`);

          dispatch(loadRequest());

          if (formRef && formRef.current) {
            formRef.current.reset();
          }
        } catch (err) {
          errorHandler(err);
        }
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          if (formRef && formRef.current) {
            formRef.current.setErrors(validationErrors);
          }
        }
      }
    },
    [dispatch, history],
  );

  return (
    <Container>
      <Header>
        <h1>Cadastro de empresas</h1>
        <Divider />
      </Header>

      <Form
        onSubmit={handleSubmit}
        ref={formRef}
        initialData={{
          type: INITIAL_DATA,
        }}
      >
        <ContainerInputResponsive>
          <Input name="social_name" label="Razão social" />
          <Input name="fantasy_name" label="Nome Fantasia" />
          <InputMask
            name="cnpj"
            label="CNPJ"
            mask={[
              /\d/,
              /\d/,
              '.',
              /\d/,
              /\d/,
              /\d/,
              '.',
              /\d/,
              /\d/,
              /\d/,
              '/',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
            ]}
          />
          <Select
            name="region_id"
            label="Região"
            required={false}
            options={regions || []}
          />
        </ContainerInputResponsive>

        <h1>Endereço</h1>
        <Divider />
        <ContainerInputResponsive>
          <Scope path="address">
            <Input name="street" label="Rua" />
            <Input name="neighborhood" label="Bairro" />
            <Input name="complement" label="Complemento" required={false} />
            <Input name="description" label="Descrição" required={false} />
            <InputMask
              name="cep"
              label="CEP"
              mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
            />
            <Select
              name="state"
              label="Estado"
              onValueChange={(state: string | string[] | number | number[]) =>
                setStateSelected(state as string)
              }
              options={states || []}
            />
            <Select name="city_id" label="Cidade" options={cities || []} />
          </Scope>
        </ContainerInputResponsive>

        <Button type="submit">Cadastrar</Button>
      </Form>
    </Container>
  );
}
