import React, { useRef, useEffect } from 'react';

import { FilledTextFieldProps } from '@material-ui/core';
import { useField } from '@unform/core';

import TextInput from 'components/TextInput';

interface OwnProps {
  name: string;
}

export default function TextFormInput(
  props: Partial<FilledTextFieldProps> & OwnProps,
) {
  const { name, variant = 'filled', ...rest } = props;
  const ref: React.MutableRefObject<
    | HTMLInputElement
    | HTMLTextAreaElement
    | HTMLSelectElement
    | HTMLInputElement[]
    | null[]
  > = useRef([]);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'value',
      clearValue: (pickerRef: HTMLInputElement) => {
        // eslint-disable-next-line no-param-reassign
        pickerRef.value = '';
      },
    });
  }, [ref.current, fieldName]); // eslint-disable-line

  return (
    <TextInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      inputRef={ref}
      variant={variant}
      defaultValue={defaultValue}
      error={!!error}
      helperText={error}
    />
  );
}
