import React, { ChangeEvent } from 'react';

import TextField from '@material-ui/core/TextField';

import Button from 'components/Button';

import { Container } from './styles';

interface OwnProps {
  onChangeText?: (word: string) => void;
  onClickActionButton?: () => void;
  actionButtonText?: string;
  textFieldLabel: string;
}

const HeaderSearchContainer: React.FC<OwnProps> = ({
  onChangeText,
  onClickActionButton,
  textFieldLabel,
  actionButtonText,
}) => {
  return (
    <Container>
      <TextField
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => {
          if (onChangeText) {
            onChangeText(event.target.value);
          }
        }}
        variant="filled"
        label={textFieldLabel}
        style={{
          flex: 1,
          marginRight: 20,
          minWidth: 350,
        }}
      />
      {onClickActionButton && actionButtonText && (
        <Button type="button" onClick={onClickActionButton}>
          {actionButtonText}
        </Button>
      )}
    </Container>
  );
};

export default HeaderSearchContainer;
