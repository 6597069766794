import React from 'react';
import { useDispatch } from 'react-redux';

import Button from 'components/Button';
import { authLogout } from 'store/ducks/auth/actions';

export default function SignOut() {
  const dispatch = useDispatch();

  function handleLogout() {
    dispatch(authLogout());
  }

  return <Button onClick={handleLogout}>Sair</Button>;
}
