import React from 'react';

import { Witness } from 'types';

import Item from './Item';
import { Container } from './styles';

interface OwnProps {
  witnesses: Witness[];
  onRemove?: (key: number) => void;
}

const RenderWitness: React.FC<OwnProps> = ({ witnesses, onRemove }) => {
  return (
    <Container>
      {witnesses.map(witness => (
        <Item
          witness={witness}
          key={witness.id}
          onRemove={() => onRemove && onRemove(witness.id)}
        />
      ))}
    </Container>
  );
};

export default RenderWitness;
