import React from 'react';

import { FilledTextFieldProps } from '@material-ui/core';

import { Container, TInput } from './styles';

export default function TextInput(props: FilledTextFieldProps) {
  return (
    <Container>
      <TInput
        fullWidth
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Container>
  );
}
