import React, { useState } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import AccessTimeSharpIcon from '@material-ui/icons/AccessTimeSharp';
import BlockSharpIcon from '@material-ui/icons/BlockSharp';
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import LockSharpIcon from '@material-ui/icons/LockSharp';

import Canceled from './Canceled';
import FinanceBlocked from './FinanceBlocked';
import Finished from './Finished';
import Sended from './Sended';
import Started from './Started';

interface TabPanelProps {
  index: any;
  value: any;
  className?: string;
  children?: React.ReactNode;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  tab: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function OrdersManager() {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={(_, tabIndex) => setValue(tabIndex)}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="Dashboards para tipos de usuários"
        >
          <Tab
            label="Aguardando"
            icon={<HourglassEmptyIcon />}
            {...a11yProps(0)}
          />
          ;
          <Tab
            label="Pendentes"
            icon={<AccessTimeSharpIcon />}
            {...a11yProps(1)}
          />
          ;
          <Tab label="Feitos" icon={<DoneSharpIcon />} {...a11yProps(2)} />
          ;
          <Tab
            label="Bloqueados por financeiro"
            icon={<LockSharpIcon />}
            {...a11yProps(3)}
          />
          ;
          <Tab label="Cancelados" icon={<BlockSharpIcon />} {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Sended />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Started />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Finished />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <FinanceBlocked />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Canceled />
      </TabPanel>
    </div>
  );
}
