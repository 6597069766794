import React, { useCallback, useRef, useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { BillPayment, Options, ReceiveType } from 'types';
import * as Yup from 'yup';

import DialogScroll from 'components/DialogScroll';
import { Select, DatePicker, InputNumber } from 'components/Form';
import { ContainerInputResponsive } from 'components/Layout';
import api from 'services/api';
import errorHandler from 'services/errorHandler';

interface OwnProps {
  open: boolean;
  onClose(): void;
  onAddParcel(parcel: BillPayment): void;
  billId: number;
}

interface AddBillPaymentFormData {
  value: number;
  date: Date;
  received_type_id: string;
}

const Add: React.FC<OwnProps> = ({ open, onClose, onAddParcel, billId }) => {
  const formRef = useRef<FormHandles>(null);
  const [receivedType, setReceivedType] = useState<Options[]>([]);

  useEffect(() => {
    api
      .get<ReceiveType[]>(`/receivedTypes`)
      .then(({ data }) => {
        if (!data) return;
        const dataFormatted = data.map(type => {
          return {
            value: String(type.id),
            label: `${type.description}`,
          };
        });

        if (dataFormatted.length > 0) {
          setReceivedType(dataFormatted);
        }
      })
      .catch(() => setReceivedType([]));
  }, []);

  const handleOnSubmit = useCallback(
    async (data: SubmitHandler<AddBillPaymentFormData>) => {
      try {
        // Remove all previous errors
        if (formRef && formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          value: Yup.number()
            .positive('O valor deve ser maior que zero!')
            .required('É obrigatório informar o valor!'),
          interest: Yup.number(),
          date: Yup.date().required('A data de vencimento é obrigatória!'),
          received_type_id: Yup.string()
            .transform(value => {
              return value.replace(/\D/g, '');
            })
            .required('É obrigatório selecionar o tipo de pagamento!'),
        });

        const dataResult = await schema.validate(data, {
          abortEarly: false,
        });

        try {
          const result = await api.post<BillPayment>('bills-payment', {
            ...dataResult,
            bill_id: billId,
          });

          onAddParcel(result.data);

          toast.success('Pedido cadastrado com sucesso!');
          onClose();

          if (formRef && formRef.current) {
            formRef.current.reset();
          }
        } catch (err) {
          errorHandler(err);
        }
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          if (formRef && formRef.current) {
            formRef.current.setErrors(validationErrors);
          }
        }
      }
    },
    [onClose, billId, onAddParcel],
  );

  return (
    <DialogScroll
      open={open}
      dialogTitle="Adicionar Baixa"
      dialogContentText="Insira as informações para a baixa."
      onClose={onClose}
      onClickActionCancelButton={onClose}
      onClickActionConfirmButton={() => formRef.current?.submitForm()}
    >
      <Form ref={formRef} onSubmit={handleOnSubmit}>
        <ContainerInputResponsive>
          <InputNumber name="value" label="Valor" />
          <InputNumber name="interest" label="Juros" />
          <DatePicker name="date" label="Data de pagamento" />
          <Select
            name="received_type_id"
            label="Tipo de recebimento"
            options={receivedType}
          />
        </ContainerInputResponsive>
      </Form>
    </DialogScroll>
  );
};

export default Add;
