import { format } from 'date-fns';

export function formatPlaca(value: string) {
  try {
    const firstPart = value.substring(0, 3);
    const rest = value.substring(2, value.length).replace(/\D/g, '');
    return `${firstPart.toUpperCase()}-${rest}`;
  } catch (err) {
    return 'Placa inválida';
  }
}

export function phoneMask(text: string) {
  return text
    .replace(/\D/g, '') // Remove tudo o que não é dígito
    .replace(/^(\d\d)(\d)/g, '($1) $2') // Coloca parênteses em volta dos dois primeiros dígitos
    .replace(/(\d{5})(\d)/, '$1-$2'); // Coloca hífen entre o quarto e o quinto dígitos
}

export function cpfMask(value: string | undefined): string {
  if (!value) return '';

  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export function cnpjMask(text: string | undefined): string {
  if (!text) return '';

  return text
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, '$1.$2.$3/$4-$5');
}

export function formatDateWithTimestamps(date: Date | string | undefined) {
  if (!date) return null;

  if (typeof date === 'string') {
    try {
      return format(new Date(date), "dd'/'MM'/'yyyy 'às' HH:mm:ss");
    } catch (err) {
      return null;
    }
  }

  return format(date, "dd'/'MM'/'yyyy 'às' HH:mm:ss");
}

export function formatDateWithoutTimestamps(date: Date | string | undefined) {
  if (!date) return null;

  if (typeof date === 'string') {
    try {
      return format(new Date(date), "dd'/'MM'/'yyyy");
    } catch (err) {
      return null;
    }
  }

  return format(date, "dd'/'MM'/'yyyy");
}

export function getOrderStatusLabel(status: string) {
  switch (status) {
    case 'waiting_check':
      return 'Aguardando checagem';
    case 'refused':
      return 'Recusado na checagem';
    case 'sended':
      return 'Aguardando';
    case 'started':
      return 'Iniciado';
    case 'canceled':
      return 'Cancelado';
    case 'finance_blocked':
      return 'Bloqueado por financeiro';
    case 'finished':
      return 'Finalizado';
    default:
      return '';
  }
}
