import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import { Container } from './styles';

const Loading: React.FC = () => {
  return (
    <Container>
      <CircularProgress size={40} />
      <h1>Carregando dados...</h1>
    </Container>
  );
};

export default Loading;
