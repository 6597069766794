import { Link } from 'react-router-dom';

import styled from 'styled-components';

import DefaultContainer from 'components/Container';

export const Container = styled(DefaultContainer)`
  display: flex;
`;

export const FilterContent = styled.div`
  width: 260px;
  margin-right: 20px;
`;

export const Content = styled.div`
  flex: 1;
`;

export const ListOrders = styled.ul`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, max(260px));
  gap: 20px;
`;

export const ListItem = styled(Link)`
  padding: 20px;
  border-radius: 4px;
  color: ${props => props.theme.colors.main};
  border: 1px solid ${props => props.theme.colors.main};
`;

export const Item = styled.div`
  display: flex;
  font-size: 1rem;
  line-height: 25px;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;
