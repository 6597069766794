/* eslint-disable func-names */
/* eslint-disable react/no-this-in-sfc */
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { OptionTypeBase } from 'react-select';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { isDate, format } from 'date-fns';
import { Participant, BaseAdonisPaginateReponseInterface } from 'types';
import { cnpjMask, cpfMask } from 'utils/format';
import * as Yup from 'yup';

import Button from 'components/Button';
import { Input, Select, SwitchInput, InputNumber } from 'components/Form';
import { ContainerInputResponsive } from 'components/Layout';
import api from 'services/api';
import errorHandler from 'services/errorHandler';

import { Container } from './styles';

interface FilterProps {
  onCancel: () => void;
  onSubmit: (filters: string) => void;
}

interface Options extends OptionTypeBase {
  value: string;
  label: string;
}

interface FilterSubmitData {
  participant_id: number;
  name: string;
  ie: string;
  filteringHa: boolean;
  total_haInitial: number;
  total_haFinal: number;
}

interface ApiResponseData extends BaseAdonisPaginateReponseInterface {
  data: Participant[];
}

interface ApiReponse {
  data: ApiResponseData;
}

const INITIAL_DATA: Options = {
  value: 'all',
  label: 'Todos',
};

const convertFiltersToParams: (
  data: any,
  prefix?: string | undefined,
) => string = (data: any, prefix?: string) => {
  return Object.keys(data)
    .map(key => {
      if (typeof data[key] === 'boolean') {
        if (data[key] === false) return null;
      }

      if (typeof data[key] === 'string') {
        if (data[key] === '') return null;
      }

      if (typeof data[key] === 'object') {
        if (isDate(data[key])) {
          return `${prefix ? `${prefix}.` : ''}${key}=${encodeURIComponent(
            format(data[key], 'yyyy-MM-dd'),
          )}`;
        }
        return convertFiltersToParams(data[key], key);
      }

      return `${prefix ? `${prefix}.` : ''}${key}=${encodeURIComponent(
        data[key],
      )}`;
    })
    .join('&');
};

export const FarmFilter: React.FC<FilterProps> = ({ onCancel, onSubmit }) => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);
  const [filteringHa, setFilteringHa] = useState(false);
  const [participants, setParticipants] = useState<Options[]>([]);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const { data }: ApiReponse = await api.get(
        '/participants?is_client=true',
      );

      setParticipants(
        data.data.map(participant => {
          if (participant.type === 'fisical') {
            return {
              value: String(participant.id),
              label: `${participant.fisical?.name} (${cpfMask(
                participant.fisical?.cpf || '',
              )})`,
            };
          }
          return {
            value: String(participant.id),
            label: `${participant.juridical?.social_name} (${cnpjMask(
              participant.juridical?.cnpj || '',
            )})`,
          };
        }) || [],
      );
      setLoading(false);
    } catch (err) {
      errorHandler(err);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSubmit = useCallback(
    async (data: FilterSubmitData) => {
      try {
        // Remove all previous errors
        if (formRef && formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          participant_id: Yup.string().transform(function (
            value,
            originalValue,
          ) {
            return originalValue !== '' && this.isType(value)
              ? Number(value).toString()
              : '';
          }),
          name: Yup.string(),
          ie: Yup.string(),
          filteringHa: Yup.bool(),
          total_haInitial: Yup.number().when(['filteringHa'], {
            is: true,
            then: Yup.number()
              .transform(function (value, originalValue) {
                return originalValue !== '' && this.isType(value)
                  ? Number(value)
                  : 0;
              })
              .required(
                'Hectares inicial é obrigatório quando o filtro de hectares está ativado.',
              ),
            otherwise: Yup.number().nullable(),
          }),
          total_haFinal: Yup.number().when(['filteringHa'], {
            is: true,
            then: Yup.number()
              .transform(function (value, originalValue) {
                return originalValue !== '' && this.isType(value)
                  ? Number(value)
                  : 0;
              })
              .required(
                'Hectares final é obrigatório quando o filtro de hectares está ativado.',
              )
              .min(
                Yup.ref('total_haInitial'),
                'Hectares final deve ser igual ou maior que o valor inicial',
              ),
            otherwise: Yup.number().nullable(),
          }),
        });

        const { total_haFinal, total_haInitial } = data as FilterSubmitData;

        const result = await schema.validate(
          {
            ...data,
            total_haFinal: Number(total_haFinal),
            total_haInitial: Number(total_haInitial),
          },
          {
            abortEarly: false,
          },
        );

        try {
          if (!result.filteringHa) {
            delete result.total_haInitial;
            delete result.total_haFinal;
          }

          delete result.filteringHa;

          const paramsFormatted = convertFiltersToParams(result);

          onSubmit(paramsFormatted);
        } catch (err) {
          errorHandler(err);
        }
      } catch (err) {
        const validationErrors: Record<string, any> = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
          });

          if (formRef && formRef.current) {
            formRef.current.setErrors(validationErrors);
          }
        }
      }
    },
    [onSubmit],
  );

  if (loading) {
    return <Container>Carregando...</Container>;
  }

  return (
    <Container>
      <Form
        onSubmit={handleSubmit}
        ref={formRef}
        initialData={{
          type: INITIAL_DATA,
        }}
      >
        <Select
          name="participant_id"
          label="Cliente"
          options={participants}
          required={false}
        />
        <ContainerInputResponsive>
          <Input name="name" label="Nome da Fazenda" required={false} />
          <Input name="ie" label="I.E" required={false} />
          <SwitchInput
            name="filteringHa"
            label="Filtrar hectares?"
            required={false}
            onChange={() => setFilteringHa(!filteringHa)}
          />
          <InputNumber
            name="total_haInitial"
            label="Hectares inicial"
            required={filteringHa}
            inputProps={{
              suffix: '',
              prefix: '',
            }}
          />
          <InputNumber
            name="total_haFinal"
            label="Hectares final"
            required={filteringHa}
            inputProps={{
              suffix: '',
              prefix: '',
            }}
          />
        </ContainerInputResponsive>
        <Button type="submit">Confirmar</Button>
        <Button type="button" color="secondary" onClick={onCancel}>
          Cancelar
        </Button>
      </Form>
    </Container>
  );
};

export default FarmFilter;
