import React from 'react';
import { Link } from 'react-router-dom';
import { Content } from './styles';

export default function ForgotPasswordSuccess() {
  return (
    <Content>
      <strong>
        Um link foi enviado para seu email para prosseguir com a recuperação.
      </strong>

      <Link to="/">Voltar ao início</Link>
    </Content>
  );
}
