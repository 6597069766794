import React from 'react';

import Fab, { FabProps } from '@material-ui/core/Fab';

import { Container } from './styles';

export const AFab: React.FC<FabProps> = ({ children, ...rest }) => {
  return (
    <Container>
      <Fab {...rest}>{children}</Fab>
    </Container>
  );
};

export default AFab;
