import styled from 'styled-components';

export const Container = styled.div`
  margin: 15px 0px;
  display: grid;
  gap: 20px;
  align-items: center;

  > div {
    padding: 0;
    margin: 0;
  }

  @media (min-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
