import React, { useCallback, useRef } from 'react';

import { useHistory } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import getValidationErrors from 'utils/getValidationErros';
import * as Yup from 'yup';

import Button from 'components/Button';
import TextFormInput from 'components/TextFormInput';
import logo from '../../assets/logo.png';
import { Container } from './styles';
import { AxiosError } from 'axios';
import errorHandler from 'services/errorHandler';
import api from 'services/api';

interface ForgetPasswordType {
  email: string;
}

export default function ForgetPassword() {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const handleOnSubmit = useCallback(
    async (data: ForgetPasswordType) => {
      console.log('data', data);
      try {
        if (formRef.current) {
          formRef.current.setErrors({});
        }

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('O email é inválido')
            .required('O email é obrigatório para recuperação de senha'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('forgotpassword', {
          email: data.email,
        });

        history.push(`forgotPasswordSuccess`);
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          if (formRef.current) {
            formRef.current.setErrors(getValidationErrors(err));
          }
        } else if ((err as AxiosError).isAxiosError) {
          errorHandler(err);
        }
      }
    },
    [history],
  );

  return (
    <Container>
      <img src={logo} alt="APMAX" />
      <Form onSubmit={handleOnSubmit}>
        <h1>Esqueceu sua senha ?</h1>
        <span>
          Insira o email de acesso da conta para receber um link de recuperação.
        </span>
        <TextFormInput name="email" label="E-mail" type="email" required />
        <div className="wrapper-buttons-actions">
          <Button type="submit">Recuperar</Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => history.goBack()}
          >
            Voltar
          </Button>
        </div>
      </Form>
    </Container>
  );
}
