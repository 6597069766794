import styled from 'styled-components';

export const Container = styled.div`
  height: 70vh;

  section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const CardHa = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 260px;
  max-width: 300px;

  padding: 20px;

  & + div {
    margin-right: 20px;
  }
`;
