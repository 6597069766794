import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  border-radius: 4px;

  -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.66);
  -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.66);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.66);

  min-height: 100%;
  width: 100%;
`;
