import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { IconButton } from '@material-ui/core';
import TablePagination, {
  TablePaginationProps,
} from '@material-ui/core/TablePagination';
import { Edit, Visibility, ArrowLeft, ArrowRight } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { subDays, addDays } from 'date-fns';
import { Bill, BaseAdonisPaginateReponseInterface } from 'types';
import { formatDateWithoutTimestamps } from 'utils/format';
import { formatCurrency } from 'utils/formatCurrency';
import { getNameFromParticipant } from 'utils/renderNameParticipant';

import BaseTableDataManager from 'components/BaseTableDataManager';
import ExportXLS from 'components/ExportXLS';
import api from 'services/api';

import { Container, Header } from './styles';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface ApiResponse extends BaseAdonisPaginateReponseInterface {
  data: Bill[];
}

interface BillExtends extends Bill {
  expiredDateFormatted: string;
  valueFormatted: string;
  name: string;
}

const Receive: React.FC = () => {
  const INITIAL_DATA = useMemo(() => {
    return {
      lastPage: 1,
      page: 1,
      perPage: 20,
      total: '0',
    };
  }, []);

  const history = useHistory();

  const [hideActions, setHideActions] = useState(false);
  const [initialDate, setInitialDate] = useState<Date>(new Date());
  const [finalDate, setFinalDate] = useState<Date>(new Date());
  const [bills, setBills] = useState<BillExtends[]>([]);
  const [paginate, setPaginate] = useState<BaseAdonisPaginateReponseInterface>(
    INITIAL_DATA,
  );
  const [exporting, setExporting] = useState<{
    exporting: boolean;
    columns: any[];
    data: any[];
  }>({
    exporting: false,
    columns: [],
    data: [],
  });

  const handleOnBackDate = useCallback((key: 'initial' | 'final') => {
    switch (key) {
      case 'initial':
        setInitialDate(current => subDays(current, 1));
        break;
      case 'final':
        setFinalDate(current => subDays(current, 1));
        break;
      default:
        break;
    }
  }, []);

  const handleOnNextDate = useCallback((key: 'initial' | 'final') => {
    switch (key) {
      case 'initial':
        setInitialDate(current => addDays(current, 1));
        break;
      case 'final':
        setFinalDate(current => addDays(current, 1));
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    async function loadData(
      page: number,
      perPage: number,
      expired_dateInitial: Date,
      expired_dateFinal: Date,
    ) {
      try {
        const response = await api.get<ApiResponse>('bills', {
          params: {
            type: 'receber',
            page,
            perPage,
            expired_dateInitial,
            expired_dateFinal,
          },
        });

        const { data, ...responsePaginate } = response.data;

        setBills(
          data.map(bill => {
            return {
              ...bill,
              expiredDateFormatted:
                formatDateWithoutTimestamps(bill.expired_date) || '',
              valueFormatted: formatCurrency(bill.value) || '',
              name: getNameFromParticipant(bill.participant) || '',
            };
          }),
        );
        setPaginate(responsePaginate);
      } catch {
        toast.error('Não foi possível carregar os documentos');
      }
    }

    loadData(paginate.page, paginate.perPage, initialDate, finalDate);
  }, [paginate.page, paginate.perPage, initialDate, finalDate]);

  const handleInitialDate = useCallback((e: MaterialUiPickersDate) => {
    if (!!e && String(e) != 'Invalid Date') {
      setInitialDate(e);
    }
  }, []);

  const handleFinalDate = useCallback((e: MaterialUiPickersDate) => {
    if (!!e && String(e) != 'Invalid Date') {
      setFinalDate(e);
    }
  }, []);

  const exportXLS = useCallback(() => {
    if (exporting.exporting) {
      try {
        return (
          <ExportXLS
            filename="Contas à receber cadastradas"
            columns={exporting.columns}
            data={exporting.data}
          />
        );
      } finally {
        setTimeout(() => {
          setExporting({ exporting: false, columns: [], data: [] });
        }, 2000);
      }
    }

    return <></>;
  }, [exporting]);

  return (
    <Container>
      {exportXLS()}
      <BaseTableDataManager
        title={
          <Header>
            <div>
              <IconButton onClick={() => handleOnBackDate('initial')}>
                <ArrowLeft />
              </IconButton>
              <KeyboardDatePicker
                showTodayButton
                format="dd/MM/yyyy"
                value={initialDate}
                onChange={e => handleInitialDate(e)}
              />
              <IconButton onClick={() => handleOnNextDate('initial')}>
                <ArrowRight />
              </IconButton>
            </div>
            <div>
              <IconButton onClick={() => handleOnBackDate('final')}>
                <ArrowLeft />
              </IconButton>
              <KeyboardDatePicker
                showTodayButton
                format="dd/MM/yyyy"
                value={finalDate}
                onChange={e => handleFinalDate(e)}
              />
              <IconButton onClick={() => handleOnNextDate('final')}>
                <ArrowRight />
              </IconButton>
            </div>
          </Header>
        }
        options={{
          grouping: false,
          pageSize: Number(paginate.perPage),
          pageSizeOptions: [20, 50, 100, Number(paginate.total)],
          exportCsv: (columns, data) => {
            setExporting({ exporting: true, columns, data });
          },
        }}
        columns={[
          {
            title: 'Empresa',
            field: 'company.name',
          },
          {
            title: 'Participante',
            field: 'name',
          },
          {
            title: 'Pedido',
            field: 'order_id',
          },
          {
            title: 'Valor',
            field: 'valueFormatted',
          },
          {
            title: 'Dt. Vencimento',
            field: 'expiredDateFormatted',
          },
          {
            title: 'Promissória',
            field: 'own_code',
          },
          {
            title: 'Ações',
            field: 'id',
            export: false,
            hidden: hideActions,
            render: (column: Bill) => (
              <>
                <IconButton
                  onClick={() => history.push(`/receives/${column.id}/edit`)}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() => history.push(`/receives/${column.id}/show`)}
                >
                  <Visibility />
                </IconButton>
              </>
            ),
          },
          {
            title: 'Criado em',
            field: 'created_at',
            type: 'date',
            render: column => formatDateWithoutTimestamps(column.created_at),
          },
          {
            title: 'Última alteração',
            field: 'updated_at',
            type: 'date',
            render: column => formatDateWithoutTimestamps(column.updated_at),
          },
          { title: 'Cód.', field: 'id' },
        ]}
        data={bills}
        onSelectionChange={rows => setHideActions(rows.length > 0)}
        onRefresh={() => setPaginate(INITIAL_DATA)}
        onAdd={() => history.push('/receives/new')}
        components={{
          Pagination: (props: TablePaginationProps) => (
            <TablePagination
              {...props}
              count={Number(paginate.total)}
              page={paginate.page - 1}
              rowsPerPage={paginate.perPage}
              onChangePage={
                (event, page) =>
                  setPaginate(current => ({
                    ...current,
                    page,
                  }))
                // eslint-disable-next-line react/jsx-curly-newline
              }
              rowsPerPageOptions={[20, 50, 100, Number(paginate.total)]}
              onChangeRowsPerPage={(
                event: React.ChangeEvent<
                  HTMLInputElement | HTMLTextAreaElement
                >,
              ) => {
                setPaginate(current => ({
                  ...current,
                  perPage: Number(event.target.value),
                }));
              }}
            />
          ),
        }}
      />
    </Container>
  );
};

export default Receive;
