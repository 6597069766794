import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import IconButton from '@material-ui/core/IconButton';
import { Edit, Visibility } from '@material-ui/icons';
import { Region } from 'types';

import BaseTableDataManager from 'components/BaseTableDataManager';
import ExportXLS from 'components/ExportXLS';
import api from 'services/api';
import errorHandler from 'services/errorHandler';

import { Container } from './styles';
import { formatDateWithoutTimestamps } from 'utils/format';

export interface ApiReponse {
  data: Region[];
}

export default function Regions() {
  const history = useHistory();
  const [hideActions, setHideActions] = useState(false);
  const [loading, setLoading] = useState(true);
  const [regions, setRegions] = useState<Region[]>([]);
  const [exporting, setExporting] = useState<{
    exporting: boolean;
    columns: any[];
    data: any[];
  }>({
    exporting: false,
    columns: [],
    data: [],
  });

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const { data }: ApiReponse = await api.get(`regions`);

      setRegions(data);
    } catch (error) {
      toast.error('Não foi possível carregar as informações');
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleOnDelete = useCallback(async (data: Region) => {
    try {
      setLoading(true);

      await api.delete(`regions/${data.id}`).catch(err => {
        errorHandler(err);
      });

      setRegions(current => current.filter(item => item.id !== data.id));
    } catch (error) {
      toast.error(
        'Não foi possível remover a região. Provavel que há ligações com outros registros',
      );
    } finally {
      setLoading(false);
    }
  }, []);

  const exportXLS = useCallback(() => {
    if (exporting.exporting) {
      try {
        return (
          <ExportXLS
            filename="Regiões cadastradas"
            columns={exporting.columns}
            data={exporting.data}
          />
        );
      } finally {
        setTimeout(() => {
          setExporting({ exporting: false, columns: [], data: [] });
        }, 2000);
      }
    }

    return <></>;
  }, [exporting]);

  return (
    <Container>
      {exportXLS()}
      <BaseTableDataManager
        isLoading={loading}
        options={{
          grouping: false,
          selection: false,
          exportCsv: (columns, data) => {
            setExporting({ exporting: true, columns, data });
          },
        }}
        title="Regiões cadastradas"
        columns={[
          {
            title: 'Descrição',
            field: 'description',
          },
          { title: 'Cód.', field: 'id' },
          {
            title: 'Ações',
            field: 'id',
            hidden: hideActions,
            export: false,
            render: (column: Region) => (
              <>
                <IconButton
                  onClick={() => history.push(`/regions/${column.id}/edit`)}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() => history.push(`/regions/${column.id}/show`)}
                >
                  <Visibility />
                </IconButton>
              </>
            ),
          },
          {
            title: 'Última alteração',
            field: 'updated_at',
            type: 'date',
            render: (column: Region) =>
              formatDateWithoutTimestamps(column.updated_at),
          },
        ]}
        data={regions}
        onSelectionChange={rows => setHideActions(rows.length > 0)}
        onRefresh={loadData}
        onDeleteMany={data => handleOnDelete(data as Region)}
        onAdd={() => history.push('/regions/new')}
      />
    </Container>
  );
}
