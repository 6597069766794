import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { Edit } from '@material-ui/icons';
import { Witness } from 'types';
import { cpfMask } from 'utils/format';

import Fab from 'components/Fab';
import api from 'services/api';
import errorHandler from 'services/errorHandler';

import { Wrapper as Container, Preview } from './styles';

interface RouteParam {
  id: string;
}

export interface ApiReponse {
  data: Witness;
}

export default function Show() {
  const params = useParams<RouteParam>();
  const history = useHistory();
  const [witness, setWitness] = useState<Witness>();

  async function loadWitness(id: string) {
    try {
      const { data }: ApiReponse = await api.get(`witness/${id}`);

      setWitness(data);
    } catch (err) {
      errorHandler(err);
    }
  }

  useEffect(() => {
    loadWitness(params.id);
  }, [params.id]);

  const renderAlterInfos = useCallback(() => {
    if (witness) {
      return (
        <div className="wrapper-alterinfos-participant">
          <p>
            Criado em:
            {witness.created_at}
          </p>
          <p>
            Última alteração em:
            {witness.updated_at}
          </p>
        </div>
      );
    }

    return <></>;
  }, [witness]);

  if (!witness) {
    return <div>Carregando...</div>;
  }

  return (
    <Container>
      <header>
        <h1>{`Testemunha ${witness?.name}`}</h1>
        <div>{renderAlterInfos()}</div>
      </header>
      <section className="wrapper-participant-content">
        <p>{`CPF: ${cpfMask(witness.cpf)}`}</p>
      </section>
      <section>
        <h1>Assinatura da testemunha</h1>
        {witness.file && <Preview src={witness.file?.url || ''} />}
      </section>
      <Fab color="secondary" onClick={() => history.push(`edit`)}>
        <Edit />
      </Fab>
    </Container>
  );
}
