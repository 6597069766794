import React from 'react';

import { Plot } from 'types';

import Item from './Item';
import { Container } from './styles';

interface OwnProps {
  plots: Plot[];
  onItemClick?: (key: number) => void;
}

const RenderPlot: React.FC<OwnProps> = ({ plots, onItemClick }) => {
  return (
    <Container>
      {plots.map(plot => (
        <Item
          plot={plot}
          key={plot.id}
          onClick={() => onItemClick && onItemClick(plot.id)}
        />
      ))}
    </Container>
  );
};

export default RenderPlot;
