import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TInput = styled(TextField)`
  margin: 5px 0px;

  input:valid {
    border: 2px solid green;
  }

  max-width: 600px;
`;
